import React, { useState } from "react";
import { Row, Input, Rate, message } from "antd";
import AlertWriteReview from "./AlertWriteReview";
const { TextArea } = Input;

const WriteReview = (props) => {
  const [review, setReview] = useState("");
  const [rating, setRating] = useState(5);
  const [alertComment, setAlertComment] = useState(false);
  const [acceptCondition, setAcceptCondition] = useState(false);

  message.config({
    top: "15%",
    duration: 2,
    maxCount: 1,
  });
  const onChangeReview = (e) => {
    setReview(e.target.value);
  };
  const onChangeRating = (e) => {
    setRating(e);
  };
  const onSubmitReview = () => {
    // if (review !== "") {
    props.addReview({ review: review, rating: rating });
    // }
    // else {
    //   message.error(<span className="spanSubcatFollow">กรุณาเขียนรีวิวให้กับเรื่องนี้</span>);
    // }
    setReview("");
    setRating(5);
  };
  return (
    <div style={{ textAlign: "center" }}>
      {/* <Row>
        <img
          style={{ width: "43px", height: "45px" }}
          src="/images/book/review.svg"
          alt={`review`}
        />
      </Row> */}
      <Row>
        <p className="writeReview">เขียนรีวิว</p>
      </Row>
      <Row style={{ marginTop: "-4px" }}>
        <Rate
          className="star-20px"
          value={rating}
          onChange={(e) => {
            if (props.memberUID === "") props.showLoginModal();
            else onChangeRating(e);
          }}
        />
      </Row>
      <Row>
        <p className="clickStar">คลิกดาวเพื่อให้คะแนน</p>
      </Row>
      <TextArea
        style={{ fontFamily: "Bai Jamjuree" }}
        value={review}
        onChange={(e) => {
          if (props.memberUID === "") props.showLoginModal();
          else if (acceptCondition) onChangeReview(e);
          else setAlertComment(true);
        }}
        placeholder="เขียนรีวิว..."
        autoSize={{ minRows: 3, maxRows: 5 }}
        onClick={(e) => {
          if (props.memberUID === "") props.showLoginModal();
          else if (acceptCondition) onChangeReview(e);
        
        }}
      />
      <button
        className="submitReview"
        onClick={(e) => {
          if (props.memberUID === "") props.showLoginModal();
          else onSubmitReview();
        }}
      >
        ส่งรีวิว
      </button>
      <AlertWriteReview
        visible={alertComment}
        onAccept={(isAccept) => {
          setAcceptCondition(isAccept);
          setAlertComment(false);
        }}
      />
    </div>
  );
};
export default WriteReview;
