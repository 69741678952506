import jwt from "jsonwebtoken";
import Cookies from "universal-cookie";
const cookies = new Cookies();

const getMemberUID = (token = cookies.get("userToken") || "", pathname) => {
  if (token !== "undefined" && token !== "") {
    // console.log(jwt.decode(token));
    return jwt.decode(token).uid || "";
  } else {
    return "";
  }
};
const isLogin = (token = cookies.get("userToken") || "", pathname) => {
  let memberUID = getMemberUID(token, pathname);
  if (memberUID === "") {
    return false;
  } else {
    return true;
  }
};
const URL =
  window.location.protocol +
  "//" +
  window.location.hostname +
  (window.location.hostname === "localhost" || window.location.hostname === "192.168.1.99"
    ? `:${window.location.port}`
    : "");

export default { getMemberUID, isLogin, URL };
