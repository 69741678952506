import React, { Fragment, useState, useEffect } from "react";
import PropTypes from "prop-types";

import CreditCard from "./forms/CreditCard";
import BankTransfer from "./forms/BankTransfer";
import HytextsCoin from "./forms/HytextsCoin";
import LinePay from "./forms/LinePay";
import PayPal from "./forms/PayPal";

const PaymentContainer = ({ type, setSelectedForm, contentType, history, coinID, coinAmount }) => {
  const [container, setContainer] = useState(null);
  useEffect(() => {
    switch (type) {
      case "CC":
        setContainer(
          <CreditCard
            setSelectedForm={setSelectedForm}
            contentType={contentType}
            coinID={coinID}
            coinAmount={coinAmount}
            history={history}
          />
        );
        break;
      case "PP":
        setContainer(
          <PayPal
            setSelectedForm={setSelectedForm}
            contentType={contentType}
            coinID={coinID}
            coinAmount={coinAmount}
            history={history}
          />
        );
        break;
      case "TF":
        setContainer(
          <BankTransfer
            setSelectedForm={setSelectedForm}
            contentType={contentType}
            coinID={coinID}
            coinAmount={coinAmount}
            history={history}
          />
        );
        break;
      case "HC":
        setContainer(
          <HytextsCoin
            setSelectedForm={setSelectedForm}
            contentType={contentType}
            history={history}
          />
        );
        break;
      case "LP":
        setContainer(
          <LinePay
            setSelectedForm={setSelectedForm}
            contentType={contentType}
            coinID={coinID}
            history={history}
          />
        );
        break;
      default:
        break;
    }
  }, [coinAmount]);

  return <Fragment>{container}</Fragment>;
};

PaymentContainer.propTypes = {
  type: PropTypes.string.isRequired,
  setSelectedForm: PropTypes.func.isRequired,
  contentType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

export default PaymentContainer;
