import React, { useState, useEffect } from "react";
import { withRouter } from "react-router-dom";
import HomeList from "../../BookList/HomeList";
import { connect } from "react-redux";
import { fetchCategoryInfoById } from "../../../Actions/Ebook";
import { ACTION_SHOW_LOADING } from "../../../Actions/Page";
import Helmet from "react-helmet";
import TopicList from "../../BookList/TopicList";

import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);

const Category = (props) => {
  const [catEbook, setcatEbook] = useState({});
  const [isOneSubcat, setIsOneSubcat] = useState(false);
  const catId = props.match.params.catId;
  useEffect(() => {
    props.dispatch(ACTION_SHOW_LOADING(true));
    const fetchData = async () => {
      let catInfo = await fetchCategoryInfoById(catId, 9);
      if (catInfo.status) {
        if (catInfo.data.subcats.length === 1) {
          setIsOneSubcat(true);
        }
        setcatEbook(catInfo.data);
        props.dispatch(ACTION_SHOW_LOADING(false));
      }
    };
    fetchData();
    // eslint-disable-next-line
  }, []);
  let numCollection = getNumberCollection(props.Page.screensize.width);
  return (
    <>
      <Helmet>
        <title>{`Hytexts: ${catEbook.catName ? catEbook.catName : ""}`}</title>
      </Helmet>

      {catEbook && catEbook.subcats && isOneSubcat ? (
        <TopicList
          title={catEbook.catName}
          catId={catId}
          subcatId={catEbook.subcats[0].subcatId}
          numCollection={numCollection}
          isOneSubcat={isOneSubcat}
        />
      ) : (
        <>
          <h1
            style={{
              textAlign: "center",
              fontSize: "32px",
              margin: "8px 0 8px 0",
              fontFamily: "Prompt",
            }}
          >
            {catEbook.catName}
          </h1>
          {catEbook &&
            catEbook.subcats &&
            catEbook.subcats.map((sc) => {
              if (sc.ebooks && sc.ebooks.length) {
                return (
                  <HomeList
                    key={sc.subcatId}
                    title={sc.subcatName}
                    subcatId={sc.subcatId}
                    subcatEbook={sc.ebooks}
                    numCollection={sixOrEight(props.Page.screensize.width)}
                  />
                );
              }
            })}
        </>
      )}
    </>
  );
};

const sixOrEight = (width) => {
  if (width <= 1020) {
    return 8;
  } else {
    return 6;
  }
};

const getNumberCollection = (width) => {
  if (width >= 1440) {
    return 6;
  } else if (width <= 768 && width > 440) {
    return 4;
  } else if (width <= 440) {
    return 3;
  } else {
    return 6;
  }
};
const mapStateToProps = (state) => {
  return { Page: state.Page, authentication: state.Authentication };
};
export default withRouter(connect(mapStateToProps)(Category));
