import React, { useState, useEffect } from "react";
import { Select, Col, Form, Input, Row } from "antd";
import { fetch } from "../../../Utilitys/ApiRequest";
const { Option } = Select;
const Address = props => {
  const [address, setAddress] = useState("");
  const [postcode, setPostcode] = useState("");
  const [address_postcode, setAddress_postcode] = useState({});
  const [province, setProvince] = useState(undefined);
  const [district, setDistrict] = useState(undefined);
  const [subdistrict_Arr, setSubdistrict_Arr] = useState([]);
  const [subdistrict, setSubdistrict] = useState(undefined);
  const [addressError, setAddressError] = useState("");
  const [addressErrorMessage, setAddressErrorMessage] = useState("");
  const [postcodeError, setPostcodeError] = useState("");
  const [postcodeErrorMessage, setPostcodeErrorMessage] = useState("");
  const [districtError, setDistrictError] = useState("");
  const [districtErrorMessage, setDistrictErrorMessage] = useState("");
  const [subdistrictError, setSubdistrictError] = useState("");
  const [subdistrictErrorMessage, setSubdistrictErrorMessage] = useState("");
  useEffect(() => {
    if (props.sameaddress) {
      if (props.addressStepTwo) {
        const a = props.addressStepTwo;
        setAddress(a.address);
        setPostcode(a.postcode);
        setProvince(a.province);
        setDistrict(a.district);
        setSubdistrict(a.subdistrict);
      }
    } else if (props.valuesAddress) {
      const a = props.valuesAddress;
      setAddress(a.address);
      setPostcode(a.postcode);
      setProvince(a.province);
      setDistrict(a.district);
      setSubdistrict(a.subdistrict);
    } else {
      setAddress("");
      setPostcode("");
      setProvince(undefined);
      setDistrict(undefined);
      setSubdistrict(undefined);
    }
    // eslint-disable-next-line
  }, [props.sameaddress]);
  useEffect(() => {
    if (props.valuesAddress) {
      const a = props.valuesAddress;
      setAddress(a.address);
      setPostcode(a.postcode);
      setProvince(a.province);
      setDistrict(a.district);
      setSubdistrict(a.subdistrict);
    }
    // eslint-disable-next-line
  }, []);
  useEffect(() => {
    if (props.addressComplete) {
      address !== "" &&
      address !== undefined &&
      postcode !== "" &&
      postcodeError === "" &&
      province !== "" &&
      province !== undefined &&
      district !== "" &&
      district !== undefined &&
      subdistrict !== "" &&
      subdistrict !== undefined
        ? props.addressComplete(true, {
            address: address,
            postcode: postcode,
            province: province,
            district: district,
            subdistrict: subdistrict
          })
        : props.addressComplete(false);
    }
    // eslint-disable-next-line
  }, [address, postcodeError, district, subdistrict]);
  const fieldOnchangehandle = async e => {
    switch (e.target.name) {
      case "address":
        if (e.target.value === "") {
          setAddress("");
          setAddressError("error");
          setAddressErrorMessage("กรุณาให้ข้อมูลที่อยู่");
        } else {
          setAddress(e.target.value);
          setAddressError("");
          setAddressErrorMessage("");
        }
        break;
      case "postcode":
        const { value } = e.target;
        const reg = /[0-9]{0,5}/;
        if (!isNaN(value) && reg.test(value) && value.length <= 5) {
          setPostcode(e.target.value);
          if (value.length === 5) {
            setPostcodeError("");
            setPostcodeErrorMessage("");
            let response = await fetch("get", "address/" + value);
            if (response.data) {
              setAddress_postcode(response.data);
              setProvince(response.data.PROVINCE_NAME);
              if (response.data.DISTRICT.length === 1) {
                setDistrict(response.data.DISTRICT[0].DISTRICT_NAME);
                setSubdistrict_Arr(response.data.DISTRICT[0].SUB_DISTRICT);
              }
            } else {
              setPostcodeError("error");
              setPostcodeErrorMessage("กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง");
            }
          }
          if (value.length < 5) {
            setPostcodeError("error");
            setPostcodeErrorMessage("กรุณากรอกรหัสไปรษณีย์ให้ถูกต้อง");
            setAddress_postcode({});
            setProvince("");
            setDistrict(undefined);
            setSubdistrict_Arr([]);
            setSubdistrict(undefined);
          }
        }
        break;
      default:
        break;
    }
  };
  const districtOnchangehandle = async e => {
    const result = await address_postcode.DISTRICT.find(({ DISTRICT_NAME }) => DISTRICT_NAME === e);
    if (result.DISTRICT_NAME) {
      setDistrict(result.DISTRICT_NAME);
      setSubdistrict(undefined);
      if (result.SUB_DISTRICT.length === 1) {
        setSubdistrict(result.SUB_DISTRICT[0].SUB_DISTRICT_NAME);
      }
      setSubdistrict_Arr(result.SUB_DISTRICT);
    }
  };
  const fieldOnSelectchange = (type, e) => {
    switch (type) {
      case "district":
        if (e.length) {
          setDistrictError("");
          setDistrictErrorMessage("");
        }
        break;
      case "subdistrict":
        if (e.length) {
          setSubdistrictError("");
          setSubdistrictErrorMessage("");
        }
        break;
      default:
        break;
    }
  };
  return (
    <div>
      {props.type === "personal" ? (
        <label>ที่อยู่ตามบัตรประชาชน</label>
      ) : (
        props.type === "company" && <label>ที่อยู่</label>
      )}
      <Form.Item validateStatus={addressError ? "error" : ""} help={addressErrorMessage}>
        <Input
          style={{ color: "black", backgroundColor: "white" }}
          name="address"
          placeholder="กรุณากรอกที่อยู่"
          value={address}
          onChange={fieldOnchangehandle}
          disabled={props.sameaddress}
        />
      </Form.Item>
      <Row gutter={16}>
        <Col span={12}>
          <label>รหัสไปรษณีย์</label>
          <Form.Item validateStatus={postcodeError ? "error" : ""} help={postcodeErrorMessage}>
            <Input
              style={{ color: "black", backgroundColor: "white" }}
              name="postcode"
              value={postcode}
              placeholder="กรอกรหัสไปรษณีย์"
              onChange={fieldOnchangehandle}
              disabled={props.sameaddress}
            />
          </Form.Item>
        </Col>
        <Col span={12}>
          <label>จังหวัด</label>
          <Form.Item>
            {province ? (
              <Input
                style={{ color: "black", backgroundColor: "white" }}
                value={province ? province : undefined}
                disabled={props.sameaddress}
              />
            ) : (
              <Input
                style={{ color: "black", backgroundColor: "white" }}
                disabled
                value={undefined}
              />
            )}
          </Form.Item>
        </Col>
      </Row>

      <Row gutter={16}>
        <Col span={12}>
          <label>เขต/อำเภอ</label>
          <Form.Item validateStatus={districtError ? "error" : ""} help={districtErrorMessage}>
            {district || address_postcode.DISTRICT ? (
              <Select
                style={{ color: "black", backgroundColor: "white" }}
                showSearch
                name="district"
                placeholder="เลือกเขต/อำเภอ"
                dropdownMenuStyle={{ fontFamily: "Prompt" }}
                optionFilterProp="children"
                onChange={e => {
                  districtOnchangehandle(e);
                  fieldOnSelectchange("district", e);
                }}
                onFocus={() => {}}
                onSearch={() => {}}
                value={district}
                disabled={props.sameaddress}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {address_postcode.DISTRICT &&
                  address_postcode.DISTRICT.map(d => {
                    return <Option key={d.DISTRICT_NAME}>{d.DISTRICT_NAME}</Option>;
                  })}
              </Select>
            ) : (
              <Input
                style={{ color: "black", backgroundColor: "white" }}
                disabled
                value={undefined}
              />
            )}
          </Form.Item>
        </Col>
        <Col span={12}>
          <label>แขวง/ตำบล</label>
          <Form.Item
            validateStatus={subdistrictError ? "error" : ""}
            help={subdistrictErrorMessage}
          >
            {subdistrict || subdistrict_Arr.length ? (
              <Select
                style={{ color: "black", backgroundColor: "white" }}
                showSearch
                placeholder="เลือกแขวง/ตำบล"
                dropdownMenuStyle={{ fontFamily: "Prompt" }}
                name="subdistrict"
                optionFilterProp="children"
                onChange={e => {
                  setSubdistrict(e);
                  fieldOnSelectchange("subdistrict", e);
                }}
                onFocus={() => {}}
                onSearch={() => {}}
                value={subdistrict}
                disabled={props.sameaddress}
                filterOption={(input, option) =>
                  option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {subdistrict_Arr.length
                  ? subdistrict_Arr.map(sd => {
                      return <Option key={sd.SUB_DISTRICT_NAME}>{sd.SUB_DISTRICT_NAME}</Option>;
                    })
                  : ""}
              </Select>
            ) : (
              <Input
                style={{ color: "black", backgroundColor: "white" }}
                disabled
                value={undefined}
              />
            )}
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default Address;
