import React from "react";
export const DownloadApp = () => {
  return (
    <div>
      <p className="appstore">ดาวน์โหลดแอปสำหรับอ่านบนอุปกรณ์ของคุณ</p>
      <a
        className="apps-icon"
        href={`https://apps.apple.com/app/hytexts-com-ebook-store/id908903530`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/logos/appstore.png" alt="appstore" />
      </a>
      <a
        className="plays-icon"
        href={`https://play.google.com/store/apps/details?id=com.IPJ1988.hytexts.ebookstore`}
        target="_blank"
        rel="noopener noreferrer"
      >
        <img src="/images/logos/playstore.png" alt="playstore" />
      </a>
    </div>
  );
};
