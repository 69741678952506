import axios from "axios";
import { getHeaderWithToken, BaseURL } from "../Constants/appInfo";
import Cookies from "universal-cookie";
import Object from "./Object";
const cookies = new Cookies();

export const fetch = (
  method = "get",
  url = "",
  params = null,
  headers = getHeaderWithToken(),
  baseUrlParam = ""
) => {
  let baseUrl = baseUrlParam;
  if (headers.authorization === "Bearer ") {
    headers.authorization = `Bearer ${cookies.get("userToken") || ""}`;
  }
  console.log(params);
  if (params) {
    // eslint-disable-next-line no-param-reassign
    params = Object.ValidateParam(params);
  }
  if (baseUrl === "") {
    baseUrl = BaseURL();
  }
  console.log(params);

  var new_url = `${baseUrl}/${url}`;
  var new_data = params;
  if (params && params.noBaseURL) {
    new_url = url;
    new_data = { token: params.token };
  }
  return new Promise((resolve, reject) => {
    let reqParams = {
      method: method,
      url: new_url,
      data: new_data,
      headers: headers,
    };
    axios(reqParams)
      .then((result) => {
        resolve(result.data);
      })
      .catch((error) => {
        reject(error);
      });
  });
};
