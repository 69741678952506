import firebase from 'firebase/app';
import "firebase/auth";
import "firebase/database";

const firebaseConfig = {
    apiKey: "AIzaSyAHOjDxdoAtciLWfPhWMjsvZ-qraDv8Kbg",
    authDomain: "oktutor-9a6f0.firebaseapp.com",
    databaseURL: "https://oktutor-9a6f0.firebaseio.com",
    projectId: "oktutor-9a6f0",
    storageBucket: "oktutor-9a6f0.appspot.com",
    messagingSenderId: "429939502930",
    appId: "1:429939502930:web:b045882dc8f2d9958ad48b"
  };

firebase.initializeApp(firebaseConfig)
export default firebase