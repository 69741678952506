import React, { useEffect } from "react";
import { withRouter, Redirect } from "react-router-dom";
import TopicList from "../BookList/TopicList";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { tabData } from "../App";

import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);

const Topic = (props) => {
  let numCollection = getNumberCollection(props.Page.screensize.width);
  let topicId = props.match.params.topicId;
  let catId = props.match.params.catId;
  let subcatTitle = props.match.params.catId
    ? decodeURIComponent(props.history.location.search.split("=")[1])
    : "";
  let subcatId = props.match.params.subcatId;
  let publisherUID = props.match.params.publisherUID;
  let authorName = props.match.params.authorName
    ? props.location.pathname.split("/author/")[1]
    : "";
  let keyword = props.match.params.keyword ? props.location.pathname.split("/keyword/")[1] : "";
  let tagName = props.match.params.tagName ? props.location.pathname.split("/tag/")[1] : "";
  let isWishlist = props.match.path === "/wishlist";
  let isLibrary = props.match.path === "/library";
  let isLibrary_Remove = props.match.path === "/library/archive";
  let isRelatedBook = props.match.path === "/relatedebooks/:bookUID";
  let relatedTitle =
    props.match.path === "/relatedebooks/:bookUID"
      ? decodeURIComponent(props.history.location.search.split("=")[1])
      : "";

  return (
    <div>
      {authorName ? (
        <TopicList title={authorName} authorName={authorName} numCollection={numCollection} />
      ) : publisherUID ? (
        <TopicList publisherUID={publisherUID} numCollection={numCollection} />
      ) : keyword ? (
        <TopicList
          title={"ค้นหาจากคำว่า" + ` "${keyword.trim()}"`}
          keyword={keyword}
          numCollection={numCollection}
        />
      ) : tagName ? (
        <TopicList
          title={"ค้นหาจาก " + `#${tagName.trim()}`}
          tagName={tagName}
          numCollection={numCollection}
        />
      ) : isWishlist ? (
        <TopicList title={"Wishlist"} numCollection={numCollection} />
      ) : isLibrary ? (
        <TopicList title={"ชั้นหนังสือ"} numCollection={numCollection} />
      ) : isLibrary_Remove ? (
        <TopicList title={"หนังสือนอกชั้น"} numCollection={numCollection} />
      ) : isRelatedBook ? (
        <TopicList
          title={"อีบุ๊คที่เกี่ยวข้อง"}
          bookUID={props.match.params.bookUID}
          numCollection={numCollection}
          relatedTitle={relatedTitle}
        />
      ) : catId && subcatId ? (
        <TopicList
          title={subcatTitle}
          catId={catId}
          subcatId={subcatId}
          numCollection={numCollection}
        />
      ) : topicId === "payment" ? (
        <Redirect to={"/"} />
      ) : (
        <TopicList
          title={getTitleFromParam(topicId)}
          filter={topicId}
          numCollection={numCollection}
        />
      )}
    </div>
  );
};

const getTitleFromParam = (param) => {
  if (param === "ontv") return "ละครฮิต";
  let path = "/all/" + param;
  for (var i = 0; i < tabData.length; i++) {
    if (tabData[i].path === path) {
      return tabData[i].title;
    }
  }
};

const getNumberCollection = (width) => {
  if (width >= 1440) {
    return 6;
  } else if (width <= 768 && width > 440) {
    return 4;
  } else if (width <= 440) {
    return 3;
  } else {
    return 6;
  }
};
const mapStateToProps = (state) => {
  return { Page: state.Page };
};
Topic.propTypes = {
  Authentication: PropTypes.object,
  dispatch: PropTypes.func,
  Page: PropTypes.object,
  history: PropTypes.object,
};
export default withRouter(connect(mapStateToProps)(Topic));
