import React from "react";
import PropTypes from "prop-types";
import { Route, Redirect } from "react-router-dom";
import authenUtility from "../../Utilitys/Authentication";

const GuestRoute = ({ component: Component, ...rest }) => (
  <Route
    {...rest}
    render={props => (authenUtility.isLogin() ? <Redirect to="/" /> : <Component {...props} />)}
  ></Route>
);

GuestRoute.propTypes = {
  component: PropTypes.elementType.isRequired
};
export default GuestRoute;
