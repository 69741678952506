import {
  SHOW_LOADING,
  REDIRECT,
  SCREEN_SIZE_CHANGE,
  SHOW_FLASHSALE,
  IS_ADD_CART,
  NAV_MODAL,
  NAV_MODAL_MODE,
  FLASH_SALE_EXP,
  RECEIVE_BROWSER_NAME,
  RECEIVE_OS_NAME,
  COVER_ARR,
} from "../Constants/actionTypes";
import moment from "moment";
import { fetch } from "../Utilitys/ApiRequest";

export const receiveBrowserName = (browserName) => ({
  type: RECEIVE_BROWSER_NAME,
  browserName: browserName,
});

export const receiveOSName = (OSName) => ({
  type: RECEIVE_OS_NAME,
  OSName: OSName,
});

export const ACTION_SHOW_LOADING = (isLoading = false) => {
  return {
    type: SHOW_LOADING,
    isLoading: isLoading,
  };
};

export const showLoading = (isLoading = false) => {
  return (dispatch) => {
    dispatch(ACTION_SHOW_LOADING(isLoading));
  };
};
export const redirect = (url) => ({
  type: REDIRECT,
  redirectURL: url,
});

export const screensizeChange = (screensize = { width: 0, height: 0 }) => ({
  type: SCREEN_SIZE_CHANGE,
  screensize: screensize,
});

export const showFlashSale = (
  showFlashSale = { isShow: false, discount: 0, offsetTop: 900, ebook: {} }
) => ({
  type: SHOW_FLASHSALE,
  showFlashSale: showFlashSale,
});

export const isAddCart = (isAddCart = false) => ({
  type: IS_ADD_CART,
  isAddCart: isAddCart,
});

export const navModal = (navModal = false) => ({
  type: NAV_MODAL,
  navModal: navModal,
});

export const navModalMode = (
  navModalMode = { loginModal: 0, registerModal: 0, registerEmailModal: 0 }
) => ({
  type: NAV_MODAL_MODE,
  navModalMode: navModalMode,
});

export const flashSaleExp = (flashSaleExp = moment().unix() + 703703) => ({
  type: FLASH_SALE_EXP,
  flashSaleExp: flashSaleExp,
});

export const fetchAllPublishers = async (order = "ebook", isAsc = "false") => {
  return await fetch("get", `publishers?isAsc=${isAsc}&order=${order}`);
};

export const saveCoverCategories = (coverArr) => ({
  type: COVER_ARR,
  coverArr: coverArr,
});
