import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { fetchAllCategoriesName } from "../../Actions/Ebook";
import ScrollContainer from "react-indiana-drag-scroll";
import { fetch } from "../../Utilitys/ApiRequest";
import FastAverageColor from "fast-average-color";
import { isMobile } from "react-device-detect";

export const topList = [
  {
    title: "สมัครอ่าน",
    link: "#",
    imagePath_top: `url("/images/badges/1.png")`,
  },
  {
    title: "สมัครขายอีบุ๊ค",
    link: "https://admin-content.hibrary.me",
    imagePath_top: `url("/images/badges/2.png")`,
  },
  {
    title: "E-READER",
    link: "https://ereader.hytexts.com/",
    imagePath_top: `url("/images/badges/3.png")`,
  },
  {
    title: "สมัครขายอีบุ๊คบน \nGoogle Play Books",
    link: "https://ereader.hytexts.com/writer",
    imagePath_top: `url("/images/badges/4.png")`,
  },
  {
    title: "ขอใบเสร็จช้อปช่วยชาติ",
    link: "https://ereader.hytexts.com/requestReciept",
    imagePath_top: `url("/images/badges/6.png")`,
  },
];

export const topList_member = [
  {
    title: "สมัครขายอีบุ๊ค",
    link: "https://admin-content.hibrary.me",
    imagePath_top: `url("/images/badges/2.png")`,
  },
  {
    title: "booxnova",
    link: "https://ereader.hytexts.com/boox_series/BOOX%20NOVA%20Series",
    imagePath_top: `url("/images/badges/boox-nova.png")`,
  },
  {
    title: "E-READER",
    link: "https://ereader.hytexts.com/",
    imagePath_top: `url("/images/badges/3.png")`,
  },
  {
    title: "สมัครขายอีบุ๊คบน \nGoogle Play Books",
    link: "https://ereader.hytexts.com/writer",
    imagePath_top: `url("/images/badges/4.png")`,
  },
  {
    title: "ขอใบเสร็จช้อปช่วยชาติ",
    link: "https://ereader.hytexts.com/requestReciept",
    imagePath_top: `url("/images/badges/6.png")`,
  },
];

export const topList_creator = [
  {
    title: "booxnova",
    link: "https://ereader.hytexts.com/boox_series/BOOX%20NOVA%20Series",
    imagePath_top: `url("/images/badges/boox-nova.png")`,
  },
  {
    title: "booxmax",
    link: "https://ereader.hytexts.com/boox_series/11",
    imagePath_top: `url("/images/badges/boox-max.png")`,
  },
  {
    title: "E-READER",
    link: "https://ereader.hytexts.com/",
    imagePath_top: `url("/images/badges/3.png")`,
  },
  {
    title: "สมัครขายอีบุ๊คบน \nGoogle Play Books",
    link: "https://ereader.hytexts.com/writer",
    imagePath_top: `url("/images/badges/4.png")`,
  },
  {
    title: "ขอใบเสร็จช้อปช่วยชาติ",
    link: "https://ereader.hytexts.com/requestReciept",
    imagePath_top: `url("/images/badges/6.png")`,
  },
];

export const catList = [
  {
    title: "การ์ตูน",
    catId: 65,
  },
  {
    title: "นวนิยาย",
    catId: 3,
  },
  {
    title: "วรรณกรรมแปลจีน",
    catId: 2,
  },
  {
    title: "หนังสือพิมพ์",
    catId: 57,
  },
  {
    title: "นิตยสาร",
    catId: 68,
  },
  {
    title: "บริหาร การจัดการ",
    catId: 7,
  },
  { title: "การศึกษา", catId: 66 },
  { title: "พัฒนาตนเอง", catId: 64 },
];

const Arrow = ({ text, className }) => {
  return <div className={className}>{text}</div>;
};

const ArrowLeft = Arrow({ text: "<", className: "arrow-prev" });
const ArrowRight = Arrow({ text: ">", className: "arrow-next" });

const BadgeList = ({ data, title, showRegisterModal, type, Page, history }) => {
  const showMemberMessage = () => {
    // message.success(
    //   <span style={{ fontFamily: "Prompt", fontSize: "16px" }}>คุณเป็นสมาชิกของ Hytexts แล้ว</span>
    // );
    history.push("/alreadymember");
  };

  const [dataa, setDataa] = useState([]);
  const [coverArr, setCoverArr] = useState([]);
  const [colorObj, setColorObj] = useState([]);
  const [magicNum, setMagicNum] = useState(0);

  useEffect(() => {
    setDataa(data);
    const fetchData = async () => {
      setMagicNum(Math.floor(Math.random() * 3));
      data.map(async (e) => {
        const reqCover = await fetch(
          "get",
          `categories/${e.catId}/cover-images`
        );
        if (reqCover.status) {
          setCoverArr((oldState) => [
            ...oldState,
            { catId: e.catId, data: reqCover.data },
          ]);
        }
      });
    };
    if (type !== "top") {
      fetchData();
    }
  }, []);

  useEffect(() => {
    if (Array.isArray(coverArr) && coverArr.length === data.length) {
      setDataa((oldState) => {
        return oldState.map((e) => {
          let eleele = coverArr.filter((el) => el.catId === e.catId)[0];
          var loaded = false;
          var img = document.getElementById(e.catId.toString());
          if (typeof img !== "undefined" && img !== null) {
            let imageURL = Page.OSName.match(/(iOS|Mac OS)/i)
              ? eleele.data.coverImage[magicNum]
              : Page.browserName.match(/(Safari|Firefox)/i)
              ? eleele.data.coverImage[magicNum]
              : eleele.data.coverImageWebp[magicNum];
            let googleProxyURL =
              "https://images1-focus-opensocial.googleusercontent.com/gadgets/proxy?container=focus&refresh=2592000&url=";
            img.crossOrigin = "Anonymous";
            img.addEventListener("load", loadHandler, false);
            img.src = googleProxyURL + encodeURIComponent(imageURL);
            // img.src = eleele.data.coverImage[magicNum];
            // img.src = document.getElementById(index.toString()).src;
            // img.src = "/images/book/tempAllCat.jpg";
            loadHandler();
          }
          function loadHandler() {
            if (loaded) {
              return;
            }
            loaded = true;
            var fac = new FastAverageColor();
            if (img) {
              fac
                .getColorAsync(img)
                .then(function (color) {
                  setColorObj((oldArray) => [
                    ...oldArray,
                    { catId: e.catId, color: color },
                  ]);
                })
                .catch(function () {
                  setColorObj((oldArray) => [
                    ...oldArray,
                    { catId: e.catId, color: "error" },
                  ]);
                });
            }
          }
          return Object.assign({}, e, {
            coverImage: eleele.data.coverImage,
            coverImageWebp: eleele.data.coverImageWebp,
          });
        });
      });
    }
  }, [coverArr, Page.screensize]);

  useEffect(() => {
    if (data.length === colorObj.length) {
      setDataa((oldState) => {
        return oldState.map((e) => {
          let bgEle = colorObj.filter((el) => el.catId === e.catId)[0];
          let bg =
            bgEle !== undefined
              ? bgEle.color &&
                bgEle.color.value &&
                bgEle.color.value[0] > 230 &&
                bgEle.color.value[1] > 230 &&
                bgEle.color.value[2] > 230
                ? "rgb(230, 230, 230)"
                : bgEle.color && bgEle.color.hex
              : "rgb(230, 230, 230)";
          return Object.assign({}, e, {
            bg: bg, //bgEle.color.isDark bgEle.color.isLight
            fontColor: bgEle
              ? (bgEle && !bgEle.color) ||
                (bgEle && bgEle.color && bgEle.color.isLight)
                ? "#000000"
                : "#ffffff"
              : "#000000",
          });
        });
      });
    }
  }, [colorObj]);

  const badgeUnder = (isScroll) =>
    dataa.map((el, index) => {
      return (
        <Link
          to={"/all/categories/" + el.catId}
          key={index}
          className="divdivStyle"
          style={{
            marginLeft: isScroll && index === 0 ? "16px" : "0",
            marginRight: !isScroll && index === dataa.length - 1 ? "0" : "16px",
          }}
        >
          <button
            className="gradient"
            style={{
              height: `${160}px`,
              backgroundColor: el.bg,
              display: "grid",
              borderRadius: "4px",
            }}
          >
            <p
              style={
                isMobile
                  ? {
                      color: el.fontColor,
                      fontSize: "16px",
                      margin: "10px 0 -10px 0",
                      transform: "translateY(-20px)",
                    }
                  : {
                      color: el.fontColor,
                      fontSize: "16px",
                      margin: "10px 0 -10px 0",
                    }
              }
            >
              {el.title}
            </p>
            <img
              alt=""
              id={el.catId}
              style={{ width: `${160 / 2.5}px`, margin: "0 auto" }}
            />
          </button>
        </Link>
      );
    });

  if (type === "top") {
    return (
      <div className={Page.screensize.width >= 320 ? "textBadge-list-top" : ""}>
        {title && Page.screensize.width >= 320 ? <h2>{title}</h2> : ""}
        <ScrollContainer className="Badge-list">
          {data.map((el, index) => {
            if (el.title === "สมัครอ่าน" && el.imagePath_top)
              return (
                <Link to={el.link}>
                  <div
                    className={"divTopStyle" + data.length}
                    key={index}
                    onClick={() => showRegisterModal()}
                    style={{
                      marginLeft:
                        Page.screensize.width <= 1414 ? "16px" : "0px",
                      backgroundSize: "100% 100%",
                      backgroundImage: `${el.imagePath_top}`,
                      marginRight: "8px",
                    }}
                  ></div>
                </Link>
              );
            else if (
              (index >= 1 ||
                el.title === "สมัครขายอีบุ๊ค" ||
                el.title === "booxnova" ||
                el.title === "booxmax") &&
              el.imagePath_top
            ) {
              return (
                <a href={el.link} target="_blank" rel="noopener noreferrer">
                  <div
                    className={"divTopStyle" + data.length}
                    key={index}
                    style={{
                      backgroundSize: "100% 100%",
                      backgroundImage: `${el.imagePath_top}`,
                      marginRight:
                        index === data.length - 1
                          ? Page.screensize.width <= 1414
                            ? "8px"
                            : "3px"
                          : "8px",
                      marginLeft:
                        index === 0
                          ? Page.screensize.width <= 1414
                            ? "16px"
                            : "0px"
                          : "8px",
                    }}
                  ></div>
                </a>
              );
            }
          })}
        </ScrollContainer>
      </div>
    );
  } else {
    return (
      <div className={Page.screensize.width >= 320 ? "textBadge-list-top" : ""}>
        {title && Page.screensize.width >= 320 ? (
          <h2
            style={
              Page.screensize.width <= 1414
                ? { marginLeft: "16px" }
                : { marginLeft: "0px" }
            }
          >
            {title}
          </h2>
        ) : (
          ""
        )}
        {Page.screensize.width > 1360 ? (
          <div
            style={
              Page.screensize.width <= 1414
                ? {
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "space-between",
                  }
                : {
                    display: "flex",
                    textAlign: "center",
                    justifyContent: "space-between",
                  }
            }
          >
            {badgeUnder()}
          </div>
        ) : Page.screensize.width >= 320 ? (
          <ScrollContainer className="scrollMenu">
            {badgeUnder(1)}
          </ScrollContainer>
        ) : (
          // <ScrollMenu
          // data={data.map((el, index) => {
          //   return (
          //     <div
          //       className={"divStyle"}
          //       style={{
          //         backgroundSize: "100% 100%",
          //         backgroundImage: `${el.imagePath}`
          //       }}
          //       key={index}
          //     >
          //       <Link to={el.link} className="atagStyle">
          //         {el.title}
          //       </Link>
          //     </div>
          //   );
          // })}
          //   className={type === "top" ? "scrollMenu-top" : "scrollMenu"}
          //   // arrowLeft={Page.screensize.width >= 900 ? ArrowLeft : ""}
          //   // arrowRight={Page.screensize.width >= 900 ? ArrowRight : ""}
          //   alignCenter={false}
          //   hideArrows={true}
          //   hideSingleArrow={true}
          //   dragging={true}
          //   wheel={false}
          // />
          ""
        )}
      </div>
    );
  }
};

BadgeList.propTypes = {
  data: PropTypes.array,
  title: PropTypes.string,
};

const mapStateToProps = (state) => {
  return { Authentication: state.Authentication, Page: state.Page };
};

export default withRouter(connect(mapStateToProps)(BadgeList));
