import React, { useState, useEffect } from "react";
import Qrcode from "../Views/qrcode";
import { connect } from "react-redux";
import { Row, Col } from "antd";
import { withRouter } from "react-router-dom";
import { getQrCodeData, fetchLogin } from "../../Actions/Authentication";
import firebase from "../../Service/Firebase";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);
const QrCodeView = props => {
  const [qrImage, setQrImage] = useState("");
  useEffect(() => {
    qrcodeRequest();
  }, []);
  const qrcodeRequest = async () => {
    let qrdata = await getQrCodeData();
    if (qrdata.status) {
      setQrImage(qrdata.data.qrCode);
      firebase
        .auth()
        .signInAnonymously()
        .catch(error => {
          console.error("error,signInAnonymously", error);
        });

      let loginRef = firebase.database().ref(qrdata.data.qrPath);
      loginRef.on("child_added", data => {
        if (data.val().userToken) {
          props.dispatch(fetchLogin("qrcode", data.val(), props.match.path));
        }
      });
    }
  };
  return (
    <div className="modal-content">
      <Row>
        <Col
          span={6}
          align="left"
          className="backtoLogin"
          onClick={() => {
            if (props.Page.screensize.width > 900) props.toQrcode(false);
          }}
        >
          {props.Page.screensize.width > 900 ? <span>{"<"}</span> : ""}
        </Col>
        <Col span={12}>
          <h1 className="center">เข้าสู่ระบบ</h1>
        </Col>
        <Col span={6} />
      </Row>
      <h3 className="center">ด้วยคิวอาร์โค้ดของคุณ</h3>
      <div className="qrCode">
        <img src={qrImage} alt="" />
      </div>
      <p className="center">สแกนคิวอาร์โค้ดเพื่อเข้าสู่ระบบ</p>
      {props.Page.screensize.width > 900 ? (
        <>
          <p style={{ textDecoration: "underline", marginTop: "27px" }}>
            วิธีเข้าสู่ระบบด้วยคิวอาร์โค้ด
          </p>
          <p>
            1. ไปที่แอป Hytexts "เมนู" > "สแกน QR Code"
            <br />
            &nbsp;&nbsp;แล้วสแกนคิวอาร์โค้ดเพื่อเข้าสู่ระบบบนเว็บ
          </p>
        </>
      ) : (
        <div className="center">
          <p style={{ textDecoration: "underline", marginTop: "27px" }}>
            วิธีเข้าสู่ระบบด้วยคิวอาร์โค้ด
          </p>
          <p>
            1. ไปที่แอป Hytexts "เมนู" > "สแกน QR Code"
            <br />
            &nbsp;&nbsp;แล้วสแกนคิวอาร์โค้ดเพื่อเข้าสู่ระบบบนเว็บ
          </p>
        </div>
      )}
      <div className="loginIconSet" style={{ textAlign: "center" }}>
        <button className="login-qrcode-top">เข้าสู่ระบบด้วยคิวอาร์โค้ด</button>
        <div className="login-qrcode-bottom">
          <div style={{ transform: "translateY(16px)" }}>
            <svg viewBox="0 0 100 100" width="106px">
              <path d="M25,2 L2,2 L2,25" fill="none" stroke="#189aeb" strokeWidth="3" />
              <path d="M2,75 L2,98 L25,98" fill="none" stroke="#189aeb" strokeWidth="3" />
              <path d="M75,98 L98,98 L98,75" fill="none" stroke="#189aeb" strokeWidth="3" />
              <path d="M98,25 L98,2 L75,2" fill="none" stroke="#189aeb" strokeWidth="3" />
            </svg>
          </div>
          <Qrcode />
        </div>
      </div>
    </div>
  );
};

const mapStateToProps = state => {
  return {
    Page: state.Page
  };
};

export default withRouter(connect(mapStateToProps)(QrCodeView));
