import React from "react";
import { Row, Col, Button, Layout } from "antd";
import {updateExampleDownloadStatistic} from "../../Actions/Ebook"
export const TrynPrice = props => {
  let priceButton = ({
    isAddCart,
    priceTHB,
    addcartFunc,
    removecartFunc,
    isPurchase,
    isPromotions
  }) => {
    if (isPurchase) {
      return (
        <Button disabled className="purchasebtn">
          ซื้อแล้ว
        </Button>
      );
    } else {
      if (isAddCart) {
        return (
          <Button onClick={removecartFunc} className="clickpricebtn">
            เพิ่มในตะกร้า
          </Button>
        );
      } else {
        let btntitle = "";
        if (priceTHB === 0) {
          btntitle = "ฟรี";
        } else {
          btntitle = `${priceTHB} บาท`;
        }
        return (
          <Button
            onClick={addcartFunc}
            className={isPromotions ? "pricebtn-promotion" : "pricebtn"}
          >
            {priceTHB > -1 ? btntitle : ""}
          </Button>
        );
      }
    }
  };
  // return
  // props.screenWidth > 900 ?
  return (
    <div style={{ marginBottom: "24px" }}>
      <Row gutter={16} type="flex" justify="center">
        <Col span={12} align={props.isMobile ? "right" : "left"}>
          <a href={props.samplePath} download>
            <button className="trybtn" onClick={()=>{updateExampleDownloadStatistic(props.bookUID)}}>ทดลองอ่าน</button>
          </a>
        </Col>
        <Col span={12} align="left">
          {priceButton(props)}
        </Col>
      </Row>
    </div>
  );
  // : (
  //   <Layout.Header className="navbar-noshadow">
  //     <Row type="flex" justify="center">
  //       <Col span={12}>
  //         <a href={props.samplePath} download>
  //           <button className="trybtn">ทดลองอ่าน</button>
  //         </a>
  //       </Col>
  //       <Col span={12}>{priceButton(props)}</Col>
  //     </Row>
  //   </Layout.Header>
  // );
};
export default TrynPrice;
