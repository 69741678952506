//import {ADD_CART,REMOVE_CART} from '../Constants/actionTypes'

const INITIAL_STATE = {
  numCart:0
  };


const Ebook = (state = INITIAL_STATE, action) => {
    switch (action.type) {
      default:
        return state
    }
  }
  
  export default Ebook