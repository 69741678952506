import React, { useEffect } from "react";
import HomeList, { TagListComponent } from "../BookList/HomeList";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import PropTypes from "prop-types";
import BadgeList, { topList, topList_member, topList_creator, catList } from "../Collection/Badge";
import FlashSale from "../Collection/FlashSale";
import { navModal, navModalMode } from "../../Actions/Page";
import Helmet from "react-helmet";

import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);

const HOME = (props) => {
  let numCollection = getNumberCollection(props.Page.screensize.width);
  return (
    <div>
      <Helmet>
        <title>{`Hytexts คลัง E-Book (อีบุ๊ค) ไทย ที่ทุกคนเข้าถึงได้ รองรับ IOS, Android และ E-Reader`}</title>
      </Helmet>
      <BadgeList
        type="top"
        data={
          props.memberInfo.memberType === "creator"
            ? topList_creator
            : props.memberInfo.memberType === "member"
            ? topList_member
            : topList
        }
        showRegisterModal={() => {
          if (props.Page.screensize.width > 900) {
            props.dispatch(navModal(true));
            props.dispatch(
              navModalMode({
                loginModal: 0,
                registerModal: 1,
                registerEmailModal: 0,
              })
            );
          } else {
            props.history.push("/register");
          }
        }}
      />
      <FlashSale preloadNumCollection={6} numCollection={sixOrEight(props.Page.screensize.width)} />
      <HomeList
        title="มาใหม่"
        filter="newrelease"
        preloadNumCollection={6}
        numCollection={sixOrEight(props.Page.screensize.width)}
      />
      <BadgeList data={catList} title="หมวดหมู่แนะนำ" />
      <HomeList
        title="ขายดี"
        filter="bestseller"
        preloadNumCollection={6}
        numCollection={sixOrEight(props.Page.screensize.width)}
      />
      {/*<HomeList
        title="โปรโมชัน"
        filter="promotion"
        numCollection={numCollection}
      />*/}
      <HomeList
        title="ละครฮิต"
        filter="ontv"
        preloadNumCollection={6}
        numCollection={sixOrEight(props.Page.screensize.width)}
      />
      <HomeList
        title="ฟรี"
        filter="free"
        preloadNumCollection={6}
        numCollection={sixOrEight(props.Page.screensize.width)}
      />
      {props.Page.screensize.width > 900 ? (
        <TagListComponent title="กำลังเป็นที่นิยมใน Hytexts" />
      ) : (
        ""
      )}
    </div>
  );
};

const sixOrEight = (width) => {
  if (width <= 1020) {
    return 8;
  } else {
    return 6;
  }
};

const getNumberCollection = (width) => {
  if (width >= 1440) {
    return 8;
  } else if (width <= 768 && width > 440) {
    return 4;
  } else if (width <= 440) {
    return 3;
  } else {
    let ratio = Math.ceil(((width - 768) / (1440 - 768)) * 4);
    return 4 + (ratio % 2 ? ratio + 1 : ratio);
  }
};

const mapStateToProps = (state) => {
  return {
    Page: state.Page,
    authentication: state.Authentication,
    memberInfo: state.Member.memberInfo,
  };
};
HOME.propTypes = {
  Authentication: PropTypes.object,
  dispatch: PropTypes.func,
  Page: PropTypes.object,
  history: PropTypes.object,
};
export default withRouter(connect(mapStateToProps)(HOME));
