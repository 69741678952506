import React from "react";
import { Row, Col } from "antd";
import { millisecsToDateTime } from "../../Utilitys/changeFormatDateTime";
export const BookDetails = props => {
  let discount;
  if (props.discount) {
    discount = props.discount;
  }
  return (
    <Row>
      <Col span={8}>
        <p style={{ color: "black" }}>
          {/* ซีรี่ส์
          <br /> */}
          ขนาดไฟล์
          <br />
          {props.priceCover ? "ราคาปก" : "ราคา"}
          <br />
          จำนวนหน้า
          <br />
          วันที่วางจำหน่าย
          <br />
          เวลาหมดอายุ
        </p>
      </Col>
      <Col span={16} align="right">
        <p style={{ color: "black" }}>
          {/* ซีรี่ส์
          <br /> */}
          {dash(capitalizeType(props.fileType)) + " " + dash(props.fileSize) + " MB"}
          <br />
          {props.priceTHB > -1
            ? (props.priceCover
                ? priceLabel(props.priceCover ? props.priceCover : props.oldPriceTHB)
                : priceLabel(props.oldPriceTHB ? props.oldPriceTHB : props.priceTHB)) +
              (discount ? " (ประหยัด " + Math.round(discount) + " %)" : "")
            : ""}
          <br />
          {dash(props.pageCount) + " หน้า"}
          <br />
          {dash(millisecsToDateTime(props.saleDate))}
          <br />
          ไม่จำกัด
        </p>
      </Col>
    </Row>
  );
};
const dash = i => {
  if (i) return i;
  else return "-";
};

const priceLabel = i => {
  if (i === 0) return "ฟรี";
  else return `${i} บาท`;
};

const capitalizeType = i => {
  if (i === "epub") {
    return "ePub";
  } else if (i === "pdf") {
    return "PDF";
  }
};

export default BookDetails;
