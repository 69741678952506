import React, { useEffect, useState } from "react";
import PropTypes from "prop-types";
import { Col } from "antd";
import { connect } from "react-redux";
import { showLoading } from "../../../../Actions/Page";
import { fetch } from "../../../../Utilitys/ApiRequest";

const LinePay = ({ setSelectedForm, contentType, shoppingCart, showLoading, coinID }) => {
  const [payload, setPayload] = useState({
    returnUrl: window.location.href,
    cancelUrl: window.location.href,
    contentType,
    paymentType: "LP",
    contentData: null
  });

  const handleFormSubmit = async e => {
    e.preventDefault();
    showLoading(true);
    await fetch("post", "payment/line", payload);
    // const { data } = await fetch("post", "payment/line", payload);
    // if (data.paypal) {
    //   console.log({ data });
    // }
  };

  const setContentData = async () => {
    const data = await shoppingCart.map(book => book.bookUID);
    if (contentType === "coin") {
      setPayload({ ...payload, contentData: [coinID] });
    } else if (contentType === "ebook") {
      setPayload({ ...payload, contentData: data });
    }
  };

  useEffect(() => {
    setSelectedForm("linepay_form");
    setContentData();
  }, []);

  return (
    <Col span={24}>
      <form onSubmit={e => handleFormSubmit(e)} id="linepay_form"></form>
    </Col>
  );
};

LinePay.propTypes = {
  shoppingCart: PropTypes.array.isRequired,
  setSelectedForm: PropTypes.func.isRequired,
  contentType: PropTypes.string.isRequired,
  shoppingCart: PropTypes.array.isRequired,
  showLoading: PropTypes.func.isRequired
};

const mapStateToProps = state => ({
  shoppingCart: state.Payment.shoppingCart
});

export default connect(mapStateToProps, { showLoading })(LinePay);
