import React from "react";
import { Col, Form, Input, Button, Row } from "antd";
import Address from "./Address";
import validator from "validator";
class CompanyInfo extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      companynameError: "",
      companynameErrorMessage: "",
      publishernameError: "",
      publishernameErrorMessage: "",
      taxpayeridError: "",
      taxpayeridErrorMessage: "",
      contactnameError: "",
      contactnameErrorMessage: "",
      emailforreportError: "",
      emailforreportErrorMessage: "",
      addressComplete: false,
      addressFormData: {}
    };
  }
  componentDidMount() {
    this.props.form.validateFields();
  }

  errorHandleCheck = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };
  fieldOnchangehandleError = e => {
    this.props.form.validateFields();
    switch (e.target.name) {
      case "companyname":
        if (e.target.value === "") {
          this.setState({
            companynameError: "error",
            companynameErrorMessage: "กรุณากรอกชื่อนิติบุคคล/บริษัท"
          });
        } else {
          this.setState({ companynameError: "", companynameErrorMessage: "" });
        }
        break;
      case "publishername":
        if (e.target.value === "") {
          this.setState({
            publishernameError: "error",
            publishernameErrorMessage: "กรุณากรอกชื่อสำนักพิมพ์"
          });
        } else {
          this.setState({ publishernameError: "", publishernameErrorMessage: "" });
        }
        break;
      case "taxpayerid":
        if (
          /^[0-9]*$/.test(e.target.value) &&
          e.target.value.replace(/[^0-9]/g, "").length >= 10
          //  || e.target.value.replace(/[^0-9]/g, "").length === 13)
        ) {
          this.setState({ taxpayeridError: "", taxpayeridErrorMessage: "" });
        } else {
          this.setState({
            taxpayeridError: "error",
            taxpayeridErrorMessage: "กรุณาให้ข้อมูลเลขประจำตัวผู้เสียภาษี"
          });
        }
        break;
      case "contactname":
        if (e.target.value === "" || /\d/.test(e.target.value)) {
          this.setState({
            contactnameError: "error",
            contactnameErrorMessage: "กรุณากรอกชื่อให้ถูกต้อง"
          });
        } else {
          this.setState({ contactnameError: "", contactnameErrorMessage: "" });
        }
        break;
      case "emailforreport":
        if (e.target.value !== "" && validator.isEmail(e.target.value)) {
          this.setState({ emailforreportError: "", emailforreportErrorMessage: "" });
        } else {
          this.setState({
            emailforreportError: "error",
            emailforreportErrorMessage: "กรุณากรอกอีเมลให้ถูกต้อง"
          });
        }
        break;
      default:
        break;
    }
  };
  makeAddressComplete = (boo, values) => {
    this.setState({ addressComplete: boo, addressFormData: values });
  };
  handlePrev = e => {
    e.preventDefault();
    const values = this.props.form.getFieldsValue();
    this.props.onPrevHandle("CompanyInfo", {
      ...values,
      address: this.state.addressFormData
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmitHandle("CompanyInfo", {
          ...values,
          address: this.state.addressFormData
        });
      }
    });
  };
  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    return (
      <Form name="creator_companyInfo" className="companyInfo" onSubmit={this.handleSubmit}>
        <label>ชื่อนิติบุคคล/บริษัท</label>
        <Form.Item
          validateStatus={this.state.companynameError ? "error" : ""}
          help={this.state.companynameErrorMessage || ""}
        >
          {getFieldDecorator("companyname", {
            id: "companyname",
            rules: [
              {
                required: true,
                message: "companyname is null"
              }
            ]
          })(
            <Input
              name="companyname"
              placeholder="กรุณากรอกชื่อ"
              onChange={this.fieldOnchangehandleError}
            />
          )}
        </Form.Item>
        <label>ชื่อสำนักพิมพ์</label>
        <Form.Item
          validateStatus={this.state.publishernameError ? "error" : ""}
          help={this.state.publishernameErrorMessage || ""}
        >
          {getFieldDecorator("publishername", {
            id: "publishername",
            rules: [
              {
                required: true,
                message: "publishername is null"
              }
            ]
          })(
            <Input
              name="publishername"
              placeholder="กรุณากรอกชื่อ"
              onChange={this.fieldOnchangehandleError}
            />
          )}
        </Form.Item>
        <label>เลขประจำตัวผู้เสียภาษี</label>
        <Form.Item
          validateStatus={this.state.taxpayeridError ? "error" : ""}
          help={this.state.taxpayeridErrorMessage || ""}
        >
          {getFieldDecorator("taxpayerid", {
            id: "taxpayerid",
            rules: [
              {
                required: true,
                message: "taxpayerid is null"
              }
            ]
          })(
            <Input
              name="taxpayerid"
              placeholder="กรุณากรอกเลขประจำตัวผู้เสียภาษี"
              onChange={this.fieldOnchangehandleError}
            />
          )}
        </Form.Item>
        <label>ชื่อ-นามสกุล ผู้ติดต่อ</label>
        <Form.Item
          validateStatus={this.state.contactnameError ? "error" : ""}
          help={this.state.contactnameErrorMessage || ""}
        >
          {getFieldDecorator("contactname", {
            id: "contactname",
            rules: [
              {
                required: true,
                message: "contactname is null"
              }
            ]
          })(
            <Input
              name="contactname"
              placeholder="กรุณากรอกชื่อ-นามสกุล ผู้ติดต่อ"
              onChange={this.fieldOnchangehandleError}
            />
          )}
        </Form.Item>
        <label>อีเมลรับแจ้งรายงานยอดขาย</label>
        <Form.Item
          validateStatus={this.state.emailforreportError ? "error" : ""}
          help={this.state.emailforreportErrorMessage || ""}
        >
          {getFieldDecorator("emailforreport", {
            id: "emailforreport",
            rules: [
              {
                required: true,
                message: "emailforreport is null"
              }
            ]
          })(
            <Input
              name="emailforreport"
              placeholder="กรุณากรอกอีเมล"
              onChange={this.fieldOnchangehandleError}
            />
          )}
        </Form.Item>

        <Address
          type="company"
          addressComplete={this.makeAddressComplete}
          valuesAddress={this.props.values.address}
        />

        <Row type="flex" justify="space-between">
          <Col span={12}>
            {this.props.userHasEmail ? (
              ""
            ) : (
              <Form.Item>
                <Button type="primary" onClick={this.handlePrev} className="prevbtn">
                  ย้อนกลับ
                </Button>
              </Form.Item>
            )}
          </Col>
          <Col span={12} align="right">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="nextbtn"
                disabled={
                  this.errorHandleCheck(getFieldsError()) ||
                  this.state.companynameErrorMessage !== "" ||
                  this.state.publishernameErrorMessage !== "" ||
                  this.state.taxpayeridErrorMessage !== "" ||
                  this.state.contactnameErrorMessage !== "" ||
                  this.state.emailforreportErrorMessage !== "" ||
                  !this.state.addressComplete
                }
              >
                ถัดไป
              </Button>
            </Form.Item>
            {/* {this.errorHandleCheck(getFieldsError()) ? <div>disabled</div> : <div>eiei</div>}
            {this.state.companynameErrorMessage !== "" ? <div>disabled</div> : <div>eiei</div>}
            {this.state.publishernameErrorMessage !== "" ? <div>disabled</div> : <div>eiei</div>}
            {this.state.taxpayeridErrorMessage !== "" ? <div>disabled</div> : <div>eiei</div>}
            {this.state.contactnameErrorMessage !== "" ? <div>disabled</div> : <div>eiei</div>}
            {this.state.emailforreportErrorMessage !== "" ? <div>disabled</div> : <div>eiei</div>}
            {!this.state.addressComplete ? <div>disabled</div> : <div>eiei</div>} */}
          </Col>
        </Row>
      </Form>
    );
  }
}
export default Form.create({
  name: "creator_companyInfo",
  mapPropsToFields(props) {
    const values = props.values;
    return {
      companyname: Form.createFormField({
        value: values.companyname
      }),
      publishername: Form.createFormField({
        value: values.publishername
      }),
      contactname: Form.createFormField({
        value: values.contactname
      }),
      emailforreport: Form.createFormField({
        value: values.emailforreport
      })
    };
  }
})(CompanyInfo);
