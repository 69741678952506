import React, { useState, useEffect } from "react";
import { Input, Form, Button, message, Row, Col } from "antd";
import validator from "validator";
import { DownloadApp } from "./Login-children";
import { resetPassword } from "../../../Actions/Authentication";
import ObjectUitil from "../../../Utilitys/Object";
import { connect } from "react-redux";
const ForgetPasswordBox = (props) => {
  const [forgetPasswordEmailError, setForgetPasswordEmailError] = useState("");
  const [forgetPasswordEmailerrormessage, setForgetPasswordEmailerrormessage] = useState("");
  message.config({
    top: "15%",
    duration: 4,
    maxCount: 1,
  });

  useEffect(() => {
    props.form.validateFields();
  }, []);

  const fieldOnchangehandle = (e) => {
    props.form.validateFields();
    switch (e.target.name) {
      case "username":
        if (
          e.target.value !== "" &&
          !validator.isEmail(e.target.value) &&
          !ObjectUitil.isPhoneNumber(e.target.value)
        ) {
          setForgetPasswordEmailError("error");
          setForgetPasswordEmailerrormessage("อีเมลหรือเบอร์โทรศัพท์ไม่ถูกต้อง");
        } else {
          setForgetPasswordEmailError("");
          setForgetPasswordEmailerrormessage("");
        }
        break;
      default:
        break;
    }
  };
  const errorHandleCheck = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };
  const submitEmail_ForgetPassword = (e) => {
    e.preventDefault();
    props.form.validateFields(async (err, values) => {
      if (!err) {
        if (validator.isEmail(values.username) || ObjectUitil.isPhoneNumber(values.username)) {
          const res = await resetPassword({ userID: values.username });
          if (res.status) {
            props.endingForgetPassword(true);
            message.success(
              <span style={{ fontFamily: "Prompt", fontSize: "16px" }}>
                ส่งรหัสผ่านชั่วคราวแล้ว กรุณาตรวจสอบอีเมลหรือข้อความ SMS
              </span>
            );
          } else {
            if (res.message === "No User Found")
              message.error(
                <span style={{ fontFamily: "Prompt", fontSize: "16px" }}>ไม่พบผู้ใช้งานในระบบ</span>
              );
          }
        }
      }
    });
  };
  return (
    <div className="forget-password-box">
      <Row>
        <Col
          span={6}
          align="left"
          className="backfromForgetPW"
          onClick={() => {
            if (props.Page.screensize.width > 900) props.toForgetPassword(false);
          }}
        >
          {props.Page.screensize.width > 900 ? <span>{"<"}</span> : ""}
        </Col>
        <Col span={12}>
          <h1>ลืมรหัสผ่าน ?</h1>
        </Col>
        <Col span={6} />
      </Row>
      <div className="forget-password-box-container">
        <Form name="forget_password" className="login-form" onSubmit={submitEmail_ForgetPassword}>
          <h4>อีเมลหรือเบอร์โทรศัพท์</h4>
          <Form.Item
            validateStatus={forgetPasswordEmailError ? "error" : ""}
            help={forgetPasswordEmailerrormessage || ""}
            style={{ marginBottom: "16px" }}
          >
            {props.form.getFieldDecorator("username", {
              id: "username",
              rules: [
                {
                  required: true,
                  message: "Please input your email or phone number",
                },
              ],
            })(
              <Input
                name="username"
                placeholder="อีเมลหรือเบอร์โทรศัพท์"
                onChange={fieldOnchangehandle}
              />
            )}
          </Form.Item>
          <div style={{ marginBottom: "16px" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={
                errorHandleCheck(props.form.getFieldsError()) || forgetPasswordEmailError !== ""
              }
            >
              รับรหัสผ่านชั่วคราว
            </Button>
          </div>
        </Form>
      </div>
      <DownloadApp />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { Page: state.Page };
};

export default connect(mapStateToProps)(
  Form.create({ name: "forget_password" })(ForgetPasswordBox)
);
