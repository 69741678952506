import React, { useState, useEffect } from "react";
import { Input, Checkbox, Button, Modal, message } from "antd";
import PDPAUpload from "./PDPAUpload";
import { useFormik } from "formik";
import * as yup from "yup";
import { fetchPDPAPresigned, submitPDPAForm } from "../../../Actions/PDPA";
import Axios from "axios";

const PDPAForm = () => {
  const [successModal, setSuccessModal] = useState(false);
  const [loading, setLoading] = useState(false);
  const schema = yup.object().shape({
    email: yup.string().email("อีเมลไม่ตรงรูปแบบ").required("กรุณากรอกอีเมล"),
    phoneNumber: yup
      .string()
      .required("กรุณากรอกเบอร์โทรศัพท์")
      .matches(
        /^(0[689]{1})+([0-9]{8})+$|^(02)+([0-9]{7})+$/g,
        "เบอร์โทรไม่ตรงรูปแบบ"
      ),

    dataSubjectRight: yup
      .array()
      .min(1, "กรุณาเลือกความประสงค์")
      .required("กรุณาเลือกความประสงค์"),
    detail: yup.string().required("กรุณากรอกรายละเอียด "),
    files: yup.array().min(1, "กรุณาอัปโหลดไฟล์").required("กรุณาอัปโหลดไฟล์"),
  });

  const formik = useFormik({
    initialValues: {
      email: undefined,
      phoneNumber: undefined,
      dataSubjectRight: undefined,
      detail: undefined,
      files: [],
    },
    validationSchema: schema,
    onSubmit: (values) => {
      setLoading(true);
      onGetPresign(values);
    },
  });

  useEffect(() => {
    formik.setFieldValue("files", []);
  }, []);

  const onGetPresign = async (values) => {
    try {
      const fileType = formik.values.files.map((el) => {
        return el.name.split(".").pop();
      });
      const res = await fetchPDPAPresigned(fileType);
      if (res.status) {
        console.log(res);
        uploadImgToServer(res.data, formik.values.files, values);
      }
    } catch (error) {}
  };

  const uploadImgToServer = async (s3PresignArr, fileArr, values) => {
    const files = [];
    Promise.all(
      s3PresignArr.map((el, index) => {
        let formData = new FormData();
        for (let [key, val] of Object.entries(el.fields)) {
          formData.append(key, val);
          if (key === "key") {
            files.push(val.split("/").pop());
          }
        }
        formData.append("file", fileArr[index].originFileObj);
        return Axios.post(el.url, formData);
      })
    )
      .then((resArr) => {
        if (resArr?.length === s3PresignArr?.length) {
          onSubmitForm(values, files);
        }
      })
      .catch((error) => {
        message.error(error.response.data.message);
        setLoading(false);
      });
  };

  const onSubmitForm = async (values, files) => {
    try {
      const payload = { ...values, files };
      const res = await submitPDPAForm(payload);
      if (res.status) {
        setSuccessModal(true);
      }
    } catch (error) {
      message.error(error.response.data.message);
      setLoading(false);
    }
  };

  const options = [
    { label: "สิทธิขอถอนความยินยอม", value: "RightToWithdrawConsent" },
    { label: "สิทธิขอเข้าถึงข้อมูลส่วนบุคคล", value: "RightOfAccess" },
    {
      label: "สิทธิขอโอนย้ายข้อมูลส่วนบุคคลสมบูรณ์",
      value: "RightToDataPortability",
    },
    {
      label: "สิทธิขอคัดค้านการเก็บรวบรวม ใช้ เปิดเผยข้อมูลส่วนบุคคล",
      value: "RightToObject",
    },
    { label: "สิทธิขอลบ หรือทำลายข้อมูลส่วนบุคคล", value: "RightToErasure" },
    {
      label: "สิทธิขอให้ระงับการใช้ข้อมูลส่วนบุคคล",
      value: "RightToRestrictProcessing",
    },
    {
      label: "สิทธิขอให้แก้ไขข้อมูลส่วนบุคคลให้ถูกต้อง",
      value: "RightOfRectification",
    },
  ];
  return (
    <div className="privacy-content">
      <div className="PDPA-form">
        <h2 className="PDPA-title">ขอใช้สิทธิสำหรับเจ้าของข้อมูลส่วนบุคคล</h2>
        <div className="input-section">
          <h5 className="PDPA-subTitle">
            ข้อมูลติดต่อของเจ้าของข้อมูลส่วนบุคคล{" "}
            <span className="require">*</span>
          </h5>

          <div className="input-group">
            <Input
              className="PDPA-input"
              placeholder="กรอกอีเมล"
              size="large"
              onChange={formik.handleChange}
              name="email"
            />
            {formik.errors.email && formik.touched.email ? (
              <div className="error-detail">{formik.errors.email}</div>
            ) : null}
          </div>
          <div className="input-group">
            <Input
              className="PDPA-input"
              placeholder="กรอกเบอร์โทรศัพท์"
              size="large"
              onChange={formik.handleChange}
              name="phoneNumber"
            />
            {formik.errors.phoneNumber && formik.touched.phoneNumber ? (
              <div className="error-detail">{formik.errors.phoneNumber}</div>
            ) : null}
          </div>
        </div>

        <div className="input-section">
          <h5 className="PDPA-subTitle">
            มีความประสงค์จะขอใช้สิทธิดังต่อไปนี้{" "}
            <span className="require">*</span>
          </h5>
          <div className="input-group">
            <Checkbox.Group
              options={options}
              values={formik.values}
              onChange={(checkedValues) => {
                formik.setFieldValue("dataSubjectRight", checkedValues);
              }}
            />
            {formik.errors.requestType && formik.touched.requestType ? (
              <div className="error-detail">{formik.errors.requestType}</div>
            ) : null}
          </div>
        </div>

        <div className="input-section">
          <h5 className="PDPA-subTitle">
            รายละเอียด <span className="require">*</span>
          </h5>
          <div className="input-group">
            {" "}
            <Input.TextArea
              rows={5}
              onChange={formik.handleChange}
              name="detail"
            />
            {formik.errors.detail && formik.touched.detail ? (
              <div className="error-detail">{formik.errors.detail}</div>
            ) : null}
          </div>
        </div>

        <div className="input-group">
          <PDPAUpload
            onChangeFileList={(fileList) => {
              formik.setFieldValue("files", fileList);
            }}
            formik={formik}
          />
        </div>

        <Button
          type="primary"
          className="submit-btn"
          shape="round"
          size="large"
          onClick={formik.handleSubmit}
          loading={loading}
        >
          ส่งข้อมูล
        </Button>

        <div className="pdpa-note">
          หมายเหตุ : บริษัทอาจปฏิเสธคำร้องของท่าน หากพบว่า <br />
          <br />
          <br /> (1) คำขอดังกล่าวไม่สมเหตุสมผล <br /> (2) คำขอฟุ่มเฟือย <br />{" "}
          (3) จำเป็นต่อการปฏิบัติตามสัญญาหรือการเข้าสัญญา <br /> (4)
          จำเป็นต่อการปฏิบัติตามกฏหมาย หรือคำสั่งศาล <br /> (5)
          การประมวลผลข้อมูลส่วนบุคคลดังกล่าว
          มีความจำเป็นสำหรับผลประโยชน์ที่ชอบด้วยกฏหมาย <br /> (6)
          คำร้องขออาจส่งผลเสียและเป็นอันตรายต่อสิทธิและเสรีภาพของผู้อื่น <br />{" "}
          (7) จำเป็นต่อการก่อตั้ง ใช้ หรือป้องกันสิทธิทางกฏหมาย <br /> ทั้งนี้
          เมื่อบริษัทได้รับแบบฟอร์มและเอกสารหลักฐานที่เกี่ยวข้อง <br />{" "}
          เมื่อได้ตรวจสอบเอกสารแล้ว บริษัท <br />{" "}
          จะดำเนินการแจ้งให้บุคคลที่เกี่ยวข้องซึ่งอยู่ในความควบคุมดูแลของบริษัททราบโดย
          <br /> ทันที หาก
          ไม่มีเหตุแห่งการปฏิเสธคำร้องขอบริษัทจะดำเนินการตามคำขอของท่านภายใน{" "}
          <br /> 30 วันนับแต่วันที่ ได้รับคำขอและเอกสารประกอบโดยครบถ้วน
        </div>
      </div>

      <Modal
        className="pdpa-success__modal"
        visible={successModal}
        footer={null}
        onCancel={() => {
          setSuccessModal(false);
          window.location.reload();
        }}
        closeIcon={
          <img src="/images/faq/pdpa/CloseIcon.svg" className="close-icon" />
        }
        width={300}
      >
        <div className="pdpa-success-body__modal">
          <img src="/images/faq/pdpa/Correct.svg" />
          <h2>คุณส่งข้อมูลเรียบร้อย!</h2>
        </div>
      </Modal>
    </div>
  );
};

export default PDPAForm;
