import React, { useEffect, useState } from "react";
import { Col } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { fetch } from "../../../../Utilitys/ApiRequest";
import { showLoading } from "../../../../Actions/Page";
import queryString from "query-string";

const PayPal = ({
  shoppingCart,
  setSelectedForm,
  contentType,
  showLoading,
  history,
  coinID,
  coinAmount,
}) => {
  const [payload, setPayload] = useState({
    returnUrl: window.location.href,
    cancelUrl: window.location.href,
    contentType,
    paymentType: "PP",
  });

  const [responseUrl, setResponseUrl] = useState(null);

  const [isSuccessPaypal, setIsSuccessPaypal] = useState(false);

  const [paypalPayload, setPaypalPayload] = useState({
    orderCode: "",
    PayerID: "",
    paymentId: "",
  });

  useEffect(() => {
    if (paypalPayload.paymentId && paypalPayload.PayerID) {
      showLoading(true);
      if (!isSuccessPaypal) {
        successPaypal();
      }
    }
  }, [paypalPayload, setPaypalPayload, isSuccessPaypal]);

  useEffect(() => {
    const searchText = window.location.search;
    let amount = 0;
    if (shoppingCart) {
      for (var item of shoppingCart) {
        amount += item.priceTHB;
      }
      if (amount >= 300) {
        setSelectedForm("paypal_form");
        setContentData();
      } else {
        setSelectedForm("");
      }
    }
    if (coinAmount) {
      amount = coinAmount;
      if (coinAmount >= 300) {
        setSelectedForm("paypal_form");
        setContentData();
      } else {
        setSelectedForm("");
      }
    }
    if (searchText.trim().length !== 0) {
      const { PayerID, paymentId } = queryString.parse(searchText);
      const orderCode = localStorage.getItem("orderCode");
      setPaypalPayload({ orderCode, PayerID, paymentId });
    }
  }, [shoppingCart, coinAmount]);

  useEffect(() => {
    if (responseUrl !== null) {
      window.location.href = responseUrl;
    }
  }, [responseUrl, setResponseUrl]);

  const setContentData = async () => {
    const data = await shoppingCart.map((book) => book.bookUID);
    if (contentType === "coin") {
      setPayload({ ...payload, contentData: [coinID] });
    } else if (contentType === "ebook") {
      setPayload({ ...payload, contentData: data });
    }
  };

  const handleFormSubmit = async (e) => {
    e.preventDefault();
    showLoading(true);
    const { data } = await fetch("post", "payment/paypal", payload);
    if (data.paypal) {
      localStorage.setItem("orderCode", data.orderCode);
      setResponseUrl(data.paypal.redirectUrl);
    }
  };

  const successPaypal = async () => {
    setIsSuccessPaypal(true);
    const { data } = await fetch("post", "payment/paypal/successfully", paypalPayload);
    showLoading(false);
    if (data && data.paypal && data.paypal.httpStatusCode === 200) {
      history.push(`/payment/success/${data.orderCode}`);
    }
  };
  return (
    <Col span={24} className="creditcard-active-inside creditcard-active-below">
      <br />
      <p>
        * PAYPAL สำหรับการชำระเงินตั้งแต่{" "}
        <span style={{ color: "red", fontWeight: "bold" }}>300 บาทขึ้นไป</span>
        <br />* สำหรับผู้ถือบัตร VISA/MASTER CARD ธนาคารต่างประเทศ สามารถใช้ PAYPAL
        ชำระเงินได้อีกหนึ่งช่องทาง{coinAmount}
      </p>
      <form onSubmit={(e) => handleFormSubmit(e)} id="paypal_form"></form>
    </Col>
  );
};

PayPal.propTypes = {
  shoppingCart: PropTypes.array.isRequired,
  setSelectedForm: PropTypes.func.isRequired,
  contentType: PropTypes.string.isRequired,
  showLoading: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired,
  coinID: PropTypes.string,
  coinAmount: PropTypes.number,
};

const mapStateToProps = (state) => ({
  shoppingCart: state.Payment.shoppingCart,
});

export default connect(mapStateToProps, { showLoading })(PayPal);
