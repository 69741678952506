import Cookies from "universal-cookie";
import {
  osVersion,
  osName,
  mobileModel,
  browserName,
} from "react-device-detect";
import publicIp from "public-ip";

const cookies = new Cookies();
export const ip = publicIp.v4();

export const getHeader = () => ({
  appversion: "1.0",
  deviceuid: localStorage.getItem("deviceuid"),
  devicemodel: mobileModel === "none" ? browserName : mobileModel,
  authorization: "",
  devicename: osName,
  osversion: osVersion,
  appname: "Hytexts",
  appid: "Hytexts.WEB",
  os: osName,
});
export const getHeaderWithToken = () => ({
  appversion: "1.0",
  deviceuid: localStorage.getItem("deviceuid"),
  devicemodel: mobileModel === "none" ? browserName : mobileModel,
  devicename: osName,
  osversion: osVersion,
  appname: "Hytexts",
  appid: "Hytexts.WEB",
  os: osName,
  authorization: `Bearer ${cookies.get("userToken") || ""}`,
});
export const BaseURL = () => {
  if (process.env.REACT_APP_ENV == "production") {
    return `https://api-ebook-prod.hytexts.com`;
  } else if (process.env.REACT_APP_ENV == "staging") {
    return `https://api-ebook-staging.hytexts.com`;
  } else {
    return `https://api-ebook-dev.hytexts.com`;
  }
};
export const oldWebURL = "https://legacy.hytexts.com";
