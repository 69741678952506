import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col } from "antd";
import PaymentContainer from "./PaymentContainer";
import authenUtility from "../../../Utilitys/Authentication";
import { navModal, navModalMode } from "../../../Actions/Page";
import { connect } from "react-redux";

const PaymentMethod = ({
  index,
  paymentTitle,
  paymentType,
  paymentLogo,
  activeIndex,
  setActiveIndex,
  setSelectedForm,
  contentType,
  history,
  coinID,
  coinAmount,
  Page,
  dispatch
}) => {
  const [elemInside, setElemInside] = useState();
  const [title, setTitle] = useState("");

  useEffect(() => {
    setElemInside(
      <PaymentContainer
        type={paymentType}
        setSelectedForm={setSelectedForm}
        contentType={contentType}
        coinID={coinID}
        coinAmount={coinAmount}
        history={history}
      />
    );
    switch (paymentTitle) {
      case "creditcard":
        setTitle("บัตรเครดิต/เดบิต");
        break;
      case "PayPal":
        setTitle("PAYPAL");
        break;
      case "BankTranfer":
        setTitle("โอนเงินเข้าบัญชีธนาคาร/พร้อมเพย์");
        break;
      case "Hytexts Coin":
        setTitle("HYTEXTS COIN");
        break;
      case "LinePay":
        setTitle("Rabbit LINE Pay");
        break;
      default:
        break;
    }
  }, [coinAmount]);

  return (
    <div
      key={index}
      className={
        activeIndex === -1
          ? paymentTitle
          : activeIndex === index
          ? (paymentTitle === "Hytexts Coin" ? "HytextsCoin" : paymentTitle) + "-active"
          : (paymentTitle === "Hytexts Coin" ? "HytextsCoin" : paymentTitle) + "-inactive"
      }
    >
      <button
        disabled={contentType === "coin" && coinAmount === 0}
        type="button"
        className={
          activeIndex === -1
            ? contentType === "coin" && coinAmount === 0
              ? "paymentChannel-inactive"
              : "paymentChannel"
            : activeIndex === index
            ? "paymentChannel-active"
            : "paymentChannel-inactive"
        }
        value={index}
        onClick={e => {
          if (authenUtility.isLogin()) {
            setActiveIndex(parseInt(e.currentTarget.value, 10));
          } else {
            if (Page.screensize.width > 900) {
              dispatch(navModal(true));
              dispatch(navModalMode({ loginModal: 1, registerModal: 0, registerEmailModal: 0 }));
            } else {
              history.push("/login");
            }
          }
        }}
      >
        <Row type="flex" align="middle" gutter={[0, 8]} justify="space-between">
          <Col xs={Page.screensize.width > 360 ? 12 : Page.screensize.width > 320 ? 10 : 8} md={14}>
            <h2>{title}</h2>
          </Col>
          <Col
            xs={Page.screensize.width > 360 ? 12 : Page.screensize.width > 320 ? 14 : 16}
            md={10}
            align="right"
          >
            <img className={paymentType} src={paymentLogo} alt="payment" />
          </Col>
        </Row>
        {paymentType !== "CC" && activeIndex !== -1 && activeIndex === index && elemInside}
      </button>
      {paymentType === "CC" && activeIndex !== -1 && activeIndex === index && elemInside}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    Page: state.Page
  };
};

PaymentMethod.propTypes = {
  index: PropTypes.number.isRequired,
  paymentTitle: PropTypes.string.isRequired,
  paymentType: PropTypes.string.isRequired,
  paymentLogo: PropTypes.string.isRequired,
  activeIndex: PropTypes.number.isRequired,
  setActiveIndex: PropTypes.func.isRequired,
  setSelectedForm: PropTypes.func.isRequired,
  contentType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired
};

export default connect(mapStateToProps)(PaymentMethod);
