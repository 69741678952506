import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Upload, Menu } from "antd";
import { Link } from "react-router-dom";
import { fetchLogOut } from "../../Actions/Authentication";
import { updateProfileImage } from "../../Actions/Member";
import authenUtility from "../../Utilitys/Authentication";
import {
  belowForReader,
  readerLabel,
  readerSubLabel
} from "../Views/FAQ/forReader";
import { belowForWriter, writerLabel } from "../Views/FAQ/forWriter";
import { belowForEreader, ereaderLabel } from "../Views/FAQ/forEreader";
import { connect } from "react-redux";
import { oldWebURL } from "../../Constants/appInfo";
import ReactGA from "react-ga";
import { isIOS13, isAndroid, isIOS } from "react-device-detect";

ReactGA.pageview(window.location.pathname + window.location.search);
const { SubMenu } = Menu;

const MenuView = props => {
  const [profileImage, setProfileImage] = useState("");
  const [faqUpArrowDirection, setFaqUpArrowDirection] = useState(true);

  const [arrowDirection, setArrowDirection] = useState(["up", "up", "up"]);
  const [readerActiveIndex, setReaderActiveIndex] = useState(-1);
  const [writerActiveIndex, setWriterActiveIndex] = useState(-1);
  const [ereaderActiveIndex, setEreaderActiveIndex] = useState(-1);

  const [readerSubActiveIndex, setReaderSubActiveIndex] = useState(-1);
  const [afterFirstLoad, setAfterFirstLoad] = useState(false);

  const toggleFaq = node => {
    if (node === 0) {
      setFaqUpArrowDirection(!faqUpArrowDirection);
    }
  };

  useEffect(() => {
    if (
      localStorage.getItem("readerActive") &&
      localStorage.getItem("readerSubActive") &&
      localStorage.getItem("writerActive") &&
      localStorage.getItem("ereaderActive")
    ) {
      const reader = parseInt(localStorage.getItem("readerActive"), 10);
      const readerSub = parseInt(localStorage.getItem("readerSubActive"), 10);
      const writer = parseInt(localStorage.getItem("writerActive"), 10);
      const ereader = parseInt(localStorage.getItem("ereaderActive"), 10);
      // if (reader > -1 && readerSub > -1) {
      //   setReaderActiveIndex(reader);
      //   setReaderSubActiveIndex(readerSub);
      // } else if (writer > -1) {
      //   setWriterActiveIndex(writer);
      // } else if (ereader > -1) {
      //   setEreaderActiveIndex(ereader);
      // }
    }
  }, []);

  useEffect(() => {
    if (readerActiveIndex >= 0) {
      setReaderActiveIndex(readerActiveIndex);
      if (readerSubActiveIndex >= 0 && readerActiveIndex < 7) {
        props.history.push(
          `/faq/reader/${readerLabel[readerActiveIndex]}/${readerSubLabel[readerSubActiveIndex]}`
        );
        localStorage.setItem("readerActive", readerActiveIndex);
        localStorage.setItem("readerSubActive", readerSubActiveIndex);
        localStorage.setItem("writerActive", -1);
        localStorage.setItem("ereaderActive", -1);
        setAfterFirstLoad(true);
      }
    }
    if (writerActiveIndex >= 0) {
      props.history.push(`/faq/writer/${writerLabel[writerActiveIndex]}`);
      localStorage.setItem("readerActive", -1);
      localStorage.setItem("readerSubActive", -1);
      localStorage.setItem("writerActive", writerActiveIndex);
      localStorage.setItem("ereaderActive", -1);
      setWriterActiveIndex(writerActiveIndex);
    }
    if (ereaderActiveIndex >= 0) {
      props.history.push(`/faq/ereader/${ereaderLabel[ereaderActiveIndex]}`);
      localStorage.setItem("readerActive", -1);
      localStorage.setItem("readerSubActive", -1);
      localStorage.setItem("writerActive", -1);
      localStorage.setItem("ereaderActive", ereaderActiveIndex);
      setEreaderActiveIndex(ereaderActiveIndex);
    }
  }, [
    readerActiveIndex,
    writerActiveIndex,
    ereaderActiveIndex,
    readerSubActiveIndex
  ]);

  useEffect(() => {
    if (afterFirstLoad) {
      setReaderSubActiveIndex(0);
    }
  }, [readerActiveIndex]);

  const downArrow15 = (
    <img
      src="/images/logos/downArrow.png"
      style={{ width: "12px", height: "7px", marginRight: "15px" }}
    />
  );

  const upArrow15 = (
    <img
      src="/images/logos/upArrow.png"
      style={{ width: "12px", height: "7px", marginRight: "15px" }}
    />
  );

  const downArrow = (
    <img
      src="/images/logos/downArrow.png"
      style={{ width: "12px", height: "7px" }}
    />
  );

  const upArrow = (
    <img
      src="/images/logos/upArrow.png"
      style={{ width: "12px", height: "7px" }}
    />
  );

  const subjectRow = (direction, node) => {
    var temp_arrowDirection = [...arrowDirection];
    if (direction === "down") {
      temp_arrowDirection[node] = "up";
      return (
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="subjectRow-blue"
          onClick={() => {
            setArrowDirection(temp_arrowDirection);
          }}
        >
          <Col span={props.Page.screensize.width > 440 ? 2 : 3}></Col>
          <Col span={props.Page.screensize.width > 440 ? 20 : 19}>
            <p className="subject">{subjectTitle[node]}</p>
          </Col>
          <Col span={2} align="right">
            {upArrow15}
          </Col>
        </Row>
      );
    } else if (direction === "up") {
      temp_arrowDirection[node] = "down";
      switch (node) {
        case 0:
          temp_arrowDirection = ["down", "up", "up"];
          break;
        case 1:
          temp_arrowDirection = ["up", "down", "up"];
          break;
        case 2:
          temp_arrowDirection = ["up", "up", "down"];
          break;
        default:
          break;
      }
      return (
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="subjectRow"
          onClick={() => {
            setArrowDirection(temp_arrowDirection);
          }}
        >
          <Col span={props.Page.screensize.width > 440 ? 2 : 3}></Col>
          <Col span={props.Page.screensize.width > 440 ? 20 : 19}>
            <p className="subject">{subjectTitle[node]}</p>
          </Col>
          <Col span={2} align="right" className="arrow">
            {downArrow15}
          </Col>
        </Row>
      );
    }
  };
  const subjectTitle = [
    "สำหรับนักอ่าน",
    "สำหรับนักเขียน/สำนักพิมพ์",
    "E-READER"
  ];

  const setActiveFunc = (type, index) => {
    if (type === "reader") {
      setReaderActiveIndex(index);
    }
    if (type === "writer") {
      setWriterActiveIndex(index);
    }
    if (type === "ereader") {
      setEreaderActiveIndex(index);
    }
  };

  const setSubActiveFunc = (type, index, iindex) => {
    if (type === "reader") {
      setReaderActiveIndex(index);
      setReaderSubActiveIndex(iindex);
    }
    if (type === "writer") {
      setWriterActiveIndex(index);
    }
    if (type === "ereader") {
      setEreaderActiveIndex(index);
    }
  };

  const convertTobase64 = imgfile => {
    return new Promise(resolve => {
      var file = imgfile;
      var reader = new FileReader();
      reader.onload = function(event) {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const customRequest = req => {
    convertTobase64(req.file).then(async filedata => {
      let newpic = await updateProfileImage(filedata, req.file.type);
      if (newpic.status) {
        setProfileImage(newpic.data.image);
      }
    });
  };

  const FAQ = () => {
    return (
      <>
        <Link
          to="#"
          onClick={() => {
            toggleFaq(0);
          }}
        >
          <Row>
            <Col span={props.Page.screensize.width > 440 ? 2 : 3}>
              <img
                style={{ width: "25px", height: "25px" }}
                src="/images/logos/faq.svg"
                alt=""
              />
            </Col>
            <Col span={props.Page.screensize.width > 440 ? 20 : 19}>FAQ</Col>
            <Col span={2} align="right">
              {faqUpArrowDirection ? downArrow : upArrow}
            </Col>
          </Row>
        </Link>
      </>
    );
  };

  const BelowFAQ = () => {
    return (
      <div className="faqMenu">
        <Divider />
        {subjectRow(arrowDirection[0], 0)}
        {arrowDirection[0] === "down" && (
          <Row className="belowSubjectRow">
            <Col span={props.Page.screensize.width > 440 ? 2 : 3}></Col>
            <Col span={props.Page.screensize.width > 440 ? 22 : 21}>
              <Menu
                onClick={e => {
                  if (e.keyPath.length === 2) {
                    setActiveFunc(
                      "reader",
                      parseInt(e.keyPath[1].slice(e.keyPath[1].length - 1), 10)
                    );
                    setSubActiveFunc(
                      "reader",
                      parseInt(e.keyPath[1].slice(e.keyPath[1].length - 1), 10),
                      parseInt(e.keyPath[0].slice(e.keyPath[0].length - 1), 10)
                    );
                  } else if (e.keyPath.length === 1) {
                    setActiveFunc(
                      "reader",
                      parseInt(e.keyPath[0].slice(e.keyPath[0].length - 1), 10)
                    );
                  }
                }}
                style={{ fontFamily: "Prompt", borderRight: "none" }}
                mode="inline"
              >
                {belowForReader.map((e, index) => {
                  if (e.subHead) {
                    return (
                      <SubMenu
                        key={"sub" + index}
                        onTitleClick={e => {
                          const key = parseInt(
                            e.key.slice(e.key.length - 1),
                            10
                          );
                          if (readerActiveIndex === key) {
                            setActiveFunc("reader", -1);
                          } else {
                            setActiveFunc("reader", key);
                          }
                        }}
                        style={{ fontSize: "14px" }}
                        title={e.head}
                      >
                        {e.subHead &&
                          e.subHead.map((ee, iindex) => (
                            <Menu.Item key={"sub" + index + "-" + iindex}>
                              {ee}
                            </Menu.Item>
                          ))}
                      </SubMenu>
                    );
                  }
                })}
                <Menu.Item key={"sub7"}>อุปกรณ์ที่รองรับ</Menu.Item>
              </Menu>
            </Col>
          </Row>
        )}
        <Divider />
        {subjectRow(arrowDirection[1], 1)}
        {arrowDirection[1] === "down" && (
          <Row className="belowSubjectRow">
            <Col span={props.Page.screensize.width > 440 ? 2 : 3}></Col>
            <Col span={props.Page.screensize.width > 440 ? 22 : 21}>
              <Menu
                onClick={e => {
                  setActiveFunc(
                    "writer",
                    parseInt(e.keyPath[0].slice(e.keyPath[0].length - 1), 10)
                  );
                }}
                style={{ fontFamily: "Prompt" }}
                mode="inline"
              >
                {belowForWriter.map((e, index) => {
                  return <Menu.Item key={"sub" + index}>{e}</Menu.Item>;
                })}
              </Menu>
            </Col>
          </Row>
        )}
        <Divider />
        {subjectRow(arrowDirection[2], 2)}
        {arrowDirection[2] === "down" && (
          <Row className="belowSubjectRow">
            <Col span={props.Page.screensize.width > 440 ? 2 : 3}></Col>
            <Col span={props.Page.screensize.width > 440 ? 22 : 21}>
              <Menu
                onClick={e => {
                  setActiveFunc(
                    "ereader",
                    parseInt(e.keyPath[0].split("sub")[1], 10)
                  );
                }}
                style={{ fontFamily: "Prompt" }}
                mode="inline"
              >
                {belowForEreader.map((e, index) => {
                  return <Menu.Item key={"sub" + index}>{e.title}</Menu.Item>;
                })}
              </Menu>
            </Col>
          </Row>
        )}
      </div>
    );
  };

  const ContactUs = () => {
    return (
      <Link to="/contactus">
        <Row>
          <Col span={props.Page.screensize.width > 440 ? 2 : 3}>
            <img
              style={{ width: "25px", height: "25px" }}
              src="/images/nav/contactus.svg"
              alt=""
            />
          </Col>
          <Col span={10}>ติดต่อเรา</Col>
        </Row>
      </Link>
    );
  };

  const BuyHytextsCoin = () => {
    return (
      <Link to="/hytextscoin">
        <Row>
          <Col span={props.Page.screensize.width > 440 ? 2 : 3}>
            <img
              style={{ width: "25px", height: "25px" }}
              src="/images/nav/yellowcoin.png"
              alt=""
            />
          </Col>
          <Col span={10}>ซื้อ Hytexts Coin</Col>
        </Row>
      </Link>
    );
  };

  return (
    <div className="menuView">
      {!authenUtility.isLogin() ? (
        <>
          <Link to="/login">
            <Row>
              <Col span={props.Page.screensize.width > 440 ? 2 : 3}>
                <img
                  style={{ width: "25px", height: "25px" }}
                  src="/images/nav/login.svg"
                  alt=""
                />
              </Col>
              <Col span={10}>เข้าสู่ระบบ</Col>
            </Row>
          </Link>
          <Divider />
          {BuyHytextsCoin()}
          <Divider />
          {ContactUs()}
          <Divider />
          {FAQ()}
          {faqUpArrowDirection ? "" : BelowFAQ()}
        </>
      ) : (
        <>
          <Row style={{ marginBottom: "24px" }}>
            <Col span={9} align="center">
              <a
                href={`${oldWebURL}/webservice/loginwithtoken.php?type=profile&token=${props.authentication.userToken}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img
                  src={
                    profileImage ||
                    (props.memberInfo.profileImage
                      ? props.memberInfo.profileImage.image
                      : "/images/book/worm.png")
                  }
                  alt=""
                  style={{
                    width: "79px",
                    height: "79px",
                    marginTop: "4px",
                    objectFit: "cover",
                    borderRadius: "50%"
                  }}
                />
              </a>
              <Upload
                accept="image/*"
                customRequest={req => customRequest(req)}
              >
                <img
                  src="/images/nav/camera.svg"
                  alt=""
                  style={{
                    borderRadius: "25%",
                    backgroundColor: "white",
                    paddingLeft: "2px",
                    width: "21px",
                    height: "18px",
                    transform: "translate(-22px, 29px)"
                  }}
                  className="camera"
                />
              </Upload>
            </Col>
            <Col span={15}>
              <Row>
                <Col span={1}></Col>
                <Col span={22}>
                  <a
                    href={`${oldWebURL}/webservice/loginwithtoken.php?type=profile&token=${props.authentication.userToken}`}
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    <p className="displayname">
                      {props.memberInfo.displayName}
                    </p>
                  </a>
                </Col>
              </Row>
              <Row>
                <Col span={4} align="center">
                  <p className="labeltop">{props.memberInfo.ebooks || 0}</p>
                  <p className="labelunder">อีบุ๊ค</p>
                </Col>
                <Col span={10} align="center">
                  <p className="labeltop">{props.memberInfo.following || 0}</p>
                  <p className="labelunder">กำลังติดตาม</p>
                </Col>
              </Row>
            </Col>
          </Row>
          <Divider />
          {BuyHytextsCoin()}
          <Divider />
          <Link to="/wishlist">
            <Row>
              <Col span={props.Page.screensize.width > 440 ? 2 : 3}>
                <img
                  style={{ width: "28px", height: "25px" }}
                  src="/images/book/wishlist.svg"
                  alt=""
                />
              </Col>
              <Col span={10}> Wishlist</Col>
            </Row>
          </Link>
          <Divider />
          <a
            href={
              isAndroid
                ? "https://play.google.com/store/apps/details?id=com.IPJ1988.hytexts.ebookstore&hl=th"
                : isIOS || isIOS13
                ? "https://apps.apple.com/app/hytexts-ebook-store/id908903530"
                : `${oldWebURL}/webservice/loginwithtoken.php?type=library&token=${props.authentication.userToken}`
            }
            target="_blank"
            rel="noopener noreferrer"
          >
            <Row>
              <Col span={props.Page.screensize.width > 440 ? 2 : 3}>
                <img
                  style={{ width: "25px", height: "25px" }}
                  src="/images/nav/library.svg"
                  alt=""
                />
              </Col>
              <Col span={10}>ชั้นหนังสือ</Col>
            </Row>
          </a>
          {props.memberInfo.memberType === "creator" ? (
            <>
              <Divider />
              <a
                href={`${oldWebURL}/webservice/loginwithtoken.php?type=upload&token=${props.authentication.userToken}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Row>
                  <Col span={props.Page.screensize.width > 440 ? 2 : 3}>
                    <img
                      style={{ width: "25px", height: "25px" }}
                      src={
                        props.memberInfo.email === "paymenthytexts@gmail.com"
                          ? "/images/nav/buildbook.svg"
                          : "/images/nav/uploadbook.svg"
                      }
                      alt=""
                    />
                  </Col>
                  <Col span={10}>
                    {props.memberInfo.email === "paymenthytexts@gmail.com"
                      ? "สร้างอีบุ๊ค"
                      : "อัพโหลดหนังสือ"}
                  </Col>
                </Row>
              </a>
              <Divider />
              <a
                href={`${oldWebURL}/webservice/loginwithtoken.php?type=ebook&token=${props.authentication.userToken}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Row>
                  <Col span={props.Page.screensize.width > 440 ? 2 : 3}>
                    <img
                      style={{ width: "25px", height: "25px" }}
                      src="/images/nav/myebook.svg"
                      alt=""
                    />
                  </Col>
                  <Col span={10}>My e-book</Col>
                </Row>
              </a>
              <Divider />
              <a
                href={`${oldWebURL}/webservice/loginwithtoken.php?type=report&token=${props.authentication.userToken}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Row>
                  <Col span={props.Page.screensize.width > 440 ? 2 : 3}>
                    <img
                      style={{ width: "25px", height: "25px" }}
                      src="/images/nav/report.svg"
                      alt=""
                    />
                  </Col>
                  <Col span={10}>ยอดขาย</Col>
                </Row>
              </a>
              <Divider />
              <a
                href={`${oldWebURL}/webservice/loginwithtoken.php?type=writer&token=${props.authentication.userToken}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <Row>
                  <Col span={props.Page.screensize.width > 440 ? 2 : 3}>
                    <img
                      style={{ width: "25px", height: "25px" }}
                      src="/images/nav/search-author.svg"
                      alt=""
                    />
                  </Col>
                  <Col span={10}>นามปากกา</Col>
                </Row>
              </a>
            </>
          ) : (
            ""
          )}
          <Divider />
          {/* <a
            href={`${oldWebURL}/webservice/loginwithtoken.php?type=purchased&token=${props.authentication.userToken}`}
            target="_blank"
            rel="noopener noreferrer"
          > */}
          <Link to="/payment/history">
            <Row>
              <Col span={props.Page.screensize.width > 440 ? 2 : 3}>
                <img
                  style={{ width: "25px", height: "25px" }}
                  src="/images/nav/history.svg"
                  alt=""
                />
              </Col>
              <Col span={10}>ประวัติการซื้อ</Col>
            </Row>
          </Link>
          {/* </a> */}
          {/* <Divider />
          {FAQ()} */}
          <Divider />
          {ContactUs()}
          <Divider />
          <Link
            to="#"
            onClick={() => {
              props.dispatch(fetchLogOut());
            }}
          >
            <Row>
              <Col span={props.Page.screensize.width > 440 ? 2 : 3}>
                <img
                  style={{ width: "25px", height: "25px" }}
                  src="/images/nav/logout.svg"
                  alt=""
                />
              </Col>
              <Col span={10}>ออกจากระบบ</Col>
            </Row>
          </Link>
        </>
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    authentication: state.Authentication,
    Page: state.Page,
    memberInfo: state.Member.memberInfo
  };
};

export default connect(mapStateToProps)(MenuView);
