import { SHOW_MESSAGE,DISMISS_MESSAGE,MESSAGE_TYPE_ERROR } from '../Constants/actionTypes'

export const showError = (response) => ({
    type: SHOW_MESSAGE,
    errorcode: response.errorcode,
    message: response.message,
    messageType:MESSAGE_TYPE_ERROR
})


export const dimissError = () => ({
    type: DISMISS_MESSAGE
})

