import React, { useState, useEffect } from "react";
import EbookCollection from "../Collection/EbookCollection";
import PropTypes from "prop-types";
import { withRouter, Link } from "react-router-dom";
import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import {
  fetchEbookData,
  fetchSubCatEbook,
  fetchWishlist,
  fetchEbookByPublisherUID,
  fetchEbookByAuthorName,
  fetchRelatedEbook,
  fetchEbookByKeyword,
  fetchEbookByTag
} from "../../Actions/Ebook";
import Helmet from "react-helmet";
import { Col, Row, Select } from "antd";
import {
  fetchMemberLibrary,
  fetchMemberLibrary_Remove
} from "../../Actions/Member";
const { Option } = Select;
const TopicList = props => {
  const [isEmpty, setEmpty] = useState(false);
  const [ebooks, setEbooks] = useState([]);
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState({});
  const [loadingBottom, setLoadingBottom] = useState(false);
  const [properTitle, setProperTitle] = useState(props.title);
  const [sortBy, setSortBy] = useState(
    props.title === "ขายดี"
      ? "sale"
      : props.title === "อีบุ๊คที่เกี่ยวข้อง"
      ? "priority"
      : "date"
  );
  const [isAsc, setIsAsc] = useState(
    props.title === "โปรโมชัน" || props.title === "อีบุ๊คที่เกี่ยวข้อง"
      ? "true"
      : "false"
  );
  const [firstTime, setFirstTime] = useState(true);
  const loaderHeight = 50;
  const footerHeight = 300;

  const isBottom = () => {
    let scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
    return (
      window.pageYOffset + window.innerHeight >=
      scrollHeight - 3 * loaderHeight - footerHeight
    );
  };

  const trackScrolling = () => {
    if (isBottom()) {
      setLoadingBottom(true);
    }
  };

  const getEbookData = async (mode, sortBy = "date", isAsc = "false") => {
    if (mode === "new") {
      setProperTitle("");
      let result = props.filter
        ? await fetchEbookData(props.filter, sortBy, isAsc)
        : props.title === "Wishlist"
        ? await fetchWishlist()
        : props.title === "ชั้นอีบุ๊ค"
        ? await fetchMemberLibrary()
        : props.title === "อีบุ๊คนอกชั้น"
        ? await fetchMemberLibrary_Remove()
        : props.title === "อีบุ๊คที่เกี่ยวข้อง"
        ? await fetchRelatedEbook(props.bookUID, sortBy, isAsc, false, 24)
        : props.keyword
        ? await fetchEbookByKeyword(props.keyword, sortBy, isAsc)
        : props.tagName
        ? await fetchEbookByTag(props.tagName, sortBy, isAsc)
        : props.publisherUID
        ? await fetchEbookByPublisherUID(props.publisherUID, sortBy, isAsc)
        : props.authorName
        ? await fetchEbookByAuthorName(props.authorName, sortBy, isAsc)
        : await fetchSubCatEbook(props.catId, props.subcatId, sortBy, isAsc);
      if (props.relatedTitle) {
        setProperTitle(props.title + "กับ" + ` "${props.relatedTitle}"`);
      } else {
        setProperTitle(props.title);
      }
      if (result.status) {
        if (result.data && result.data.publisher) {
          setProperTitle(result.data.publisher.publisherName);
        }
        if (result.data && result.data.ebooks.length) {
          setEmpty(false);
          setEbooks(result.data.ebooks);
          setLastEvaluatedKey(
            result.data.lastEvaluatedKey || result.data.LastEvaluatedKey,
            setLoadingBottom(false)
          );
        } else {
          setEmpty(true);
        }
      } else {
        if (result.message === "No Data found") {
          setEmpty(true);
        }
      }
      window.scrollTo(0, 0);
    } else if (
      mode === "append" &&
      ebooks.length &&
      lastEvaluatedKey !== undefined
    ) {
      let result = props.filter
        ? await fetchEbookData(props.filter, sortBy, isAsc, lastEvaluatedKey)
        : props.title === "Wishlist"
        ? await fetchWishlist()
        : props.title === "ชั้นอีบุ๊ค"
        ? await fetchMemberLibrary(lastEvaluatedKey)
        : props.title === "อีบุ๊คนอกชั้น"
        ? await fetchMemberLibrary_Remove(lastEvaluatedKey)
        : props.title === "อีบุ๊คที่เกี่ยวข้อง"
        ? await fetchRelatedEbook(
            props.bookUID,
            sortBy,
            isAsc,
            lastEvaluatedKey,
            24
          )
        : props.keyword
        ? await fetchEbookByKeyword(
            props.keyword,
            sortBy,
            isAsc,
            lastEvaluatedKey
          )
        : props.tagName
        ? await fetchEbookByTag(props.tagName, sortBy, isAsc, lastEvaluatedKey)
        : props.publisherUID
        ? await fetchEbookByPublisherUID(
            props.publisherUID,
            sortBy,
            isAsc,
            lastEvaluatedKey
          )
        : props.authorName
        ? await fetchEbookByAuthorName(
            props.authorName,
            sortBy,
            isAsc,
            lastEvaluatedKey
          )
        : await fetchSubCatEbook(
            props.catId,
            props.subcatId,
            sortBy,
            isAsc,
            lastEvaluatedKey
          );
      if (result.status) {
        if (result.data && result.data.lastEvaluatedKey !== undefined) {
          setEbooks([...ebooks, ...result.data.ebooks]);
          setLastEvaluatedKey(
            result.data.lastEvaluatedKey,
            setLoadingBottom(false)
          );
        } else {
          setEbooks([...ebooks, ...result.data.ebooks]);
          setLastEvaluatedKey(undefined, setLoadingBottom(false));
        }
      } else {
        setLastEvaluatedKey(undefined);
      }
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", trackScrolling);
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setFirstTime(true);
    setEbooks([]);
    switch (props.title) {
      case "ขายดี":
        setSortBy("sale");
        setIsAsc("false");
        getEbookData("new", "sale", "false");
        break;
      case "โปรโมชัน":
        setSortBy("date");
        setIsAsc("true");
        getEbookData("new", "date", "true");
        break;
      case "อีบุ๊คที่เกี่ยวข้อง":
        setSortBy("priority");
        setIsAsc("true");
        getEbookData("new", "priority", "true");
        break;
      default:
        setSortBy("date");
        setIsAsc("false");
        getEbookData("new", "date", "false");
        break;
    }
    // eslint-disable-next-line
  }, [props.filter, props.title, props.publisherUID]);

  useEffect(() => {
    if (!firstTime) {
      setEbooks([]);
      getEbookData("new", sortBy, isAsc);
    } else setFirstTime(false);
    // eslint-disable-next-line
  }, [sortBy, isAsc]);

  useEffect(() => {
    if (loadingBottom) {
      getEbookData("append", sortBy, isAsc);
    }
    // eslint-disable-next-line
  }, [loadingBottom]);

  useEffect(() => {
    return () => {
      document.removeEventListener("scroll", trackScrolling);
    };
    // eslint-disable-next-line
  }, []);

  const onChangeSortBy = e => {
    switch (e) {
      case "วันที่เก่า - ใหม่":
        setSortBy("date");
        setIsAsc("true");
        break;
      case "ใกล้หมดโปรโมชัน":
        setSortBy("date");
        setIsAsc("true");
        break;
      case "วันที่ใหม่ - เก่า":
        setSortBy("date");
        setIsAsc("false");
        break;
      case "วันเริ่มโปรโมชัน":
        setSortBy("date");
        setIsAsc("false");
        break;
      case "ราคาน้อย - มาก":
        setSortBy("price");
        setIsAsc("true");
        break;
      case "ราคามาก - น้อย":
        setSortBy("price");
        setIsAsc("false");
        break;
      case "ขายดี":
        setSortBy("sale");
        setIsAsc("false");
        break;
      case "ความเกี่ยวข้อง":
        setSortBy("priority");
        setIsAsc("true");
        break;
      case "A > Z":
        setSortBy("name");
        setIsAsc("true");
        break;
      case "Z > A":
        setSortBy("name");
        setIsAsc("false");
        break;
      default:
        break;
    }
  };

  const valueOfOption = (sortBy, isAsc) => {
    switch (sortBy) {
      case "date":
        if (props.title === "โปรโมชัน") {
          if (isAsc === "true") {
            return "ใกล้หมดโปรโมชัน";
          } else if (isAsc === "false") {
            return "วันเริ่มโปรโมชัน";
          }
          break;
        } else {
          if (isAsc === "true") {
            return "วันที่เก่า - ใหม่";
          } else if (isAsc === "false") {
            return "วันที่ใหม่ - เก่า";
          }
        }
        break;
      case "price":
        if (isAsc === "true") {
          return "ราคาน้อย - มาก";
        } else if (isAsc === "false") {
          return "ราคามาก - น้อย";
        }
        break;
      case "sale":
        if (isAsc === "false") {
          return "ขายดี";
        }
      case "priority":
        if (isAsc === "true") {
          return "ความเกี่ยวข้อง";
        }
        break;
      case "name":
        if (isAsc === "true") {
          return "A > Z";
        } else if (isAsc === "false") {
          return "Z > A";
        }
        break;
      default:
        break;
    }
  };

  const helmetTitle = title => {
    switch (title) {
      case "โปรโมชัน":
        return "โปรโมชั่น E-Book ราคาพิเศษ Flash Sale นิยายสนุก ลดสูงสุดถึง 80%";
      case "มาใหม่":
        return "E-Book ออกใหม่ อัพเดททุกวัน โหลดอ่านได้ทุกวัน อ่านไม่มีเบื่อ";
      case "ขายดี":
        return "E-Book ขายดี นิยายฮิตติดเทรน จากละครดัง ซีรีย์ฮอต โหลดไว้ไม่ตกเทรน";
      case "ละครฮิต":
        return "E-Book ละครดัง ซีรี่ย์ยอดฮิต ซีรี่ย์วาย ซีรี่ย์จีน ซีรี่ย์เกาหลี อีบุ๊คการ์ตูน มีครบจบในเว็บเดียว อ่านก่อนละครจบกันเลย";
      case "ฟรี":
        return "E-Book ฟรี ทั้งนิยาย การ์ตูน นิตยสาร มีให้เลือกโหลดอ่านเพียบ";
      default:
        return `Hytexts: ${title}`;
    }
  };

  return (
    <div
      className="homelist"
      style={{ padding: props.isOneSubcat ? "8px 24px 8px 24px" : "0" }}
    >
      <Helmet>
        <title>{properTitle ? helmetTitle(properTitle) : ""}</title>
      </Helmet>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ minWidth: "250px", margin: "0 0 16px 0" }}
        gutter={[0, 8]}
      >
        <Col
          xs={24}
          sm={
            props.authorName
              ? 24
              : 9 + Math.floor(props.Page.screensize.width / 100) > 16
              ? 16
              : 9 + Math.floor(props.Page.screensize.width / 100)
          }
          align="left"
          style={
            props.authorName
              ? { marginBottom: "-8px" }
              : { marginBottom: "0px" }
          }
        >
          <p className="topiclist">
            {properTitle
              ? properTitle
              : // "สำนักงานมาตรฐานสินค้าเกษตรและอาหารแห่งชาติ (มกอช.)"
                ""}
          </p>
        </Col>
        {props.title === "ชั้นอีบุ๊ค" && (
          <Col xs={24} sm={7} align="right">
            <Link to={"/library/archive"}>
              <p className="liblink">ไปหาอีบุ๊คนอกชั้น</p>
            </Link>
          </Col>
        )}
        {props.title === "อีบุ๊คนอกชั้น" && (
          <Col xs={24} sm={7} align="right">
            <Link to={"/library"}>
              <p className="liblink">ย้อนกลับสู่ชั้นอีบุ๊ค</p>
            </Link>
          </Col>
        )}
        {props.title !== "ชั้นอีบุ๊ค" &&
          props.title !== "อีบุ๊คนอกชั้น" &&
          props.title !== "Wishlist" && (
            <>
              {props.authorName && ebooks.length > 0 ? (
                <Col span={12} align="left">
                  <span className="booknumber">
                    {" (" + ebooks.length + " เล่ม)"}
                  </span>
                </Col>
              ) : (
                ""
              )}
              <Col
                xs={12}
                sm={6}
                align={
                  props.Page.screensize.width > 576 ||
                  (props.authorName && ebooks.length > 0)
                    ? "right"
                    : "left"
                }
                style={{ fontFamily: "Prompt" }}
              >
                {properTitle && !isEmpty ? (
                  <Select
                    style={
                      props.Page.screensize.width > 576
                        ? { width: 137 }
                        : { width: 130, fontSize: "13px" }
                    }
                    dropdownMenuStyle={{ fontFamily: "Prompt" }}
                    name="sortBy"
                    optionFilterProp="children"
                    onChange={onChangeSortBy}
                    onFocus={() => {}}
                    onSearch={() => {}}
                    value={valueOfOption(sortBy, isAsc)}
                    filterOption={(input, option) =>
                      option.props.children
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {options(props.title)}
                  </Select>
                ) : (
                  ""
                )}
              </Col>
            </>
          )}
      </Row>
      <EbookCollection
        ebooks={ebooks || []}
        numCollection={props.numCollection}
        flashSale={props.flashSale}
        rowOnLoading={2}
        marginBottom={30}
        isEmpty={isEmpty}
        isWishlist={props.title === "Wishlist"}
        isLib={
          props.title === "ชั้นอีบุ๊ค"
            ? 1
            : props.title === "อีบุ๊คนอกชั้น"
            ? 2
            : undefined
        }
        isTopic={true}
        screenWidth={props.Page.screensize.width}
      />
      {lastEvaluatedKey !== undefined && !isEmpty ? (
        <div
          style={{
            minHeight: `${loaderHeight}px`,
            textAlign: "center",
            padding: "20px 0 0 0"
          }}
        >
          <ClipLoader size={loaderHeight} color={"#123abc"} loading={true} />
        </div>
      ) : (
        <div style={{ minHeight: `${loaderHeight}px` }} />
      )}
    </div>
  );
};

const options = title => {
  if (title === "ทั้งหมด") {
    return [
      <Option key={"วันที่เก่า - ใหม่"}>วันที่เก่า - ใหม่</Option>,
      <Option key={"วันที่ใหม่ - เก่า"}>วันที่ใหม่ - เก่า</Option>
    ];
  } else if (title === "โปรโมชัน") {
    return [
      <Option key={"ใกล้หมดโปรโมชัน"}>ใกล้หมดโปรโมชัน</Option>,
      // <Option key={"วันเริ่มโปรโมชัน"}>วันเริ่มโปรโมชัน</Option>,
      <Option key={"ราคามาก - น้อย"}>ราคามาก - น้อย</Option>,
      <Option key={"ราคาน้อย - มาก"}>ราคาน้อย - มาก</Option>,
      <Option key={"A > Z"}>A > Z</Option>,
      <Option key={"Z > A"}>Z > A</Option>,
      <Option key={"ขายดี"}>ขายดี</Option>
    ];
  } else if (title === "ฟรี") {
    return [
      <Option key={"วันที่เก่า - ใหม่"}>วันที่เก่า - ใหม่</Option>,
      <Option key={"วันที่ใหม่ - เก่า"}>วันที่ใหม่ - เก่า</Option>,
      <Option key={"A > Z"}>A > Z</Option>,
      <Option key={"Z > A"}>Z > A</Option>,
      <Option key={"ขายดี"}>ขายดี</Option>
    ];
  } else if (title === "อีบุ๊คที่เกี่ยวข้อง") {
    return [
      <Option key={"ความเกี่ยวข้อง"}>ความเกี่ยวข้อง</Option>,
      <Option key={"วันที่เก่า - ใหม่"}>วันที่เก่า - ใหม่</Option>,
      <Option key={"วันที่ใหม่ - เก่า"}>วันที่ใหม่ - เก่า</Option>,
      <Option key={"ราคามาก - น้อย"}>ราคามาก - น้อย</Option>,
      <Option key={"ราคาน้อย - มาก"}>ราคาน้อย - มาก</Option>,
      <Option key={"A > Z"}>A > Z</Option>,
      <Option key={"Z > A"}>Z > A</Option>,
      <Option key={"ขายดี"}>ขายดี</Option>
    ];
  } else {
    return [
      <Option key={"วันที่เก่า - ใหม่"}>วันที่เก่า - ใหม่</Option>,
      <Option key={"วันที่ใหม่ - เก่า"}>วันที่ใหม่ - เก่า</Option>,
      <Option key={"ราคามาก - น้อย"}>ราคามาก - น้อย</Option>,
      <Option key={"ราคาน้อย - มาก"}>ราคาน้อย - มาก</Option>,
      <Option key={"A > Z"}>A > Z</Option>,
      <Option key={"Z > A"}>Z > A</Option>,
      <Option key={"ขายดี"}>ขายดี</Option>
    ];
  }
};

const mapStateToProps = state => {
  return { Page: state.Page, authentication: state.Authentication };
};

TopicList.propTypes = {
  numCollection: PropTypes.number
};
export default withRouter(connect(mapStateToProps)(TopicList));
