import React, { useState, useEffect, useRef, useMemo } from "react";
import { connect } from "react-redux";
import {
  fetchAddCart,
  fetchRemoveCart,
  removeCartItemFromLocalStorage,
} from "../../Actions/Payment";
import PropTypes from "prop-types";
import BuyButton from "./BuyButton";
import { Link, withRouter } from "react-router-dom";
import { EbookViewLoader } from "../ContentLoader";
import { Modal, Tooltip, Popconfirm } from "antd";
import { deleteWishlist } from "../../Actions/Ebook";
import { moveEbookToLibrary, moveEbookToLibrary_Remove } from "../../Actions/Member";

const { confirm } = Modal;

export const EbookTag = ({ type, discount = 0, zIndex, isOffset = false }) => {
  let className = "";
  if (isOffset) {
    className = "tag offset";
  } else {
    className = "tag";
  }
  switch (type) {
    case "new":
      return (
        <div className={className} style={{ zIndex: zIndex }}>
          <div className="imageTag">
            <img src="/images/tags/new.svg" alt="new" />
          </div>
          <div className="tagTitle">New</div>
        </div>
      );
    case "bestseller":
      return (
        <div className={className} style={{ zIndex: zIndex }}>
          <img src="/images/tags/bestseller.svg" alt="bestseller" />
          <div className="tagTitle">
            ขาย
            <br />
            ดี
          </div>
        </div>
      );
    case "tv":
      return (
        <div className={className} style={{ zIndex: zIndex }}>
          <img src="/images/tags/tv.svg" alt="tv" />
          <div className="tagTitle">TV</div>
        </div>
      );
    case "promotion":
      return (
        <div className={className} style={{ zIndex: zIndex }}>
          <div className="imageTag">
            <img src="/images/tags/promotion.svg" alt="promotion" />
          </div>
          <div className="tagTitle-discount">
            <p className="red">ลด</p>
            <span>{discount}%</span>
          </div>
        </div>
      );
    default:
      return <div></div>;
  }
};

EbookTag.propTypes = {
  type: PropTypes.string,
  zIndex: PropTypes.number,
  isOffset: PropTypes.bool,
  discount: PropTypes.number,
};

const EbookView = (props) => {
  const [isAddCart, setIsAddCart] = useState(false);
  const [tagArray, setTagArray] = useState([]);
  const [isImgError, setIsImgError] = useState(false);
  const [twoLinesTitle, setTwoLinesTitle] = useState(false);
  const titleRef = useRef(null);
  const addcart = () => {
    setIsAddCart(true);
    props.dispatch(fetchAddCart(props.ebook));
  };
  const removecart = () => {
    setIsAddCart(false);
    removeCartItemFromLocalStorage(props.ebook.bookUID);
    props.dispatch(fetchRemoveCart(props.ebook));
  };
  useEffect(() => {
    let tagArray_temp = [];
    if (props.ebook.isPromotions) {
      tagArray_temp.push({
        type: "promotion",
        discount: props.ebook.discount,
      });
    }
    if (props.ebook.isNew) {
      tagArray_temp.push({
        type: "new",
      });
    }

    if (props.ebook.isTV) {
      tagArray_temp.push({
        type: "tv",
      });
    }

    if (props.ebook.isBestSeller) {
      tagArray_temp.push({
        type: "bestseller",
      });
    }
    setTagArray(tagArray_temp);
  }, []);

  useEffect(() => {
    if (titleRef) {
      var el = titleRef.current;
      // var wordArray = el.innerHTML.split(" ");
      if (
        (props.Page.screensize.width <= 900 && el.scrollHeight > 19) ||
        (props.Page.screensize.width > 900 && el.scrollHeight > 24)
      ) {
        setTwoLinesTitle(true);
      }
      // while (el.scrollHeight > 48) {
      //   wordArray.pop();
      //   el.innerHTML = wordArray.join(" ") + "...";
      // }
    }
  }, [titleRef]);

  useEffect(() => {
    // if (props.match.path === "/") {
    setIsAddCart(
      props.Payment.shoppingCart.filter((el) => {
        if (props.ebook) {
          return el.bookUID === props.ebook.bookUID;
        }
      }).length > 0
    );
    // }
  }, [props.Payment]);

  const imgError = (image) => {
    setIsImgError(true);
    image.onError = "";
    return true;
  };

  const imgErrorSrc = "/images/book/book-placeholder.jpg";

  const onOkWishlistFunc = async () => {
    props.setEbookDeleted(props.ebook.bookUID);
    let wl_delete = await deleteWishlist(props.ebook.bookUID);
    if (wl_delete.status) {
    }
  };

  const onOkLibraryFunc = async (isLib) => {
    if (isLib === 1) {
      props.setEbookDeleted(props.ebook);
      let move = await moveEbookToLibrary_Remove(props.ebook.bookUID);
      if (move.status) {
      }
    } else if (isLib === 2) {
      props.setEbookDeleted(props.ebook);
      let move = await moveEbookToLibrary(props.ebook.bookUID);
      if (move.status) {
      }
    }
  };

  const isLibConfirm = () => {
    return confirm({
      title: (
        <p style={{ fontFamily: "Prompt" }}>{`ย้ายอีบุ๊ค "${props.ebook.title}" ไปอยู่${
          props.isLib === 1 ? "นอกชั้น" : props.isLib === 2 ? "ชั้นอีบุ๊ค" : ""
        }?`}</p>
      ),
      okText: <p style={{ fontFamily: "Prompt", marginTop: "0.33em" }}>{`ตกลง`}</p>,
      cancelText: <p style={{ fontFamily: "Prompt", marginTop: "0.33em" }}>{`ยกเลิก`}</p>,
      onOk() {
        onOkLibraryFunc(props.isLib);
      },
      onCancel() {
        console.log("");
      },
    });
  };

  const buyButton = useMemo(
    () => (
      <BuyButton
        priceCover={props.ebook.priceCover}
        oldPriceTHB={props.ebook.oldPriceTHB}
        isPurchase={props.isLib ? true : props.ebook.isPurchase}
        isPromotions={props.ebook.isPromotions}
        isAddCart={isAddCart}
        priceTHB={props.ebook.priceTHB}
        addcartFunc={addcart}
        removecartFunc={removecart}
        flashSale={props.flashSale}
      />
    ),
    [isAddCart]
  );

  return (
    <div
      className={
        props.isHomePage && props.Page.screensize.width <= 1020 ? "ebook-view-home" : "ebook-view"
      }
      style={props.index === 0 ? { marginLeft: "16px" } : { marginLeft: "0px" }}
    >
      <div
        className={
          props.isTopic
            ? "cover-container-topic"
            : props.isRelatedBook
            ? "cover-container-related"
            : "cover-container"
        }
        style={{ marginBottom: "4px" }}
      >
        <div className="coverImage">
          <Link to={"/ebook/" + props.ebook.bookUID} target="_blank">
            {props.ebook.coverImage ? (
              <img
                src={
                  isImgError
                    ? imgErrorSrc
                    : props.Page.OSName.match(/(iOS|Mac OS)/i)
                    ? props.ebook.coverImage
                    : props.Page.browserName.match(/(Safari|Firefox)/i)
                    ? props.ebook.coverImage
                    : props.ebook.coverImageWebp
                }
                onError={imgError}
                alt={`Cover ${props.ebook.title}`}
              />
            ) : (
              <EbookViewLoader />
            )}
          </Link>
          <div className={`filetype ${props.ebook.fileType}`}>
            {props.ebook.fileType ? props.ebook.fileType.toUpperCase() : ""}
          </div>
          <div className="tagList">
            {tagArray.map((el, index) => {
              let offset = false;
              if (index > 0) {
                offset = true;
              }
              return (
                <EbookTag
                  type={el.type}
                  key={index}
                  zIndex={tagArray.length - index}
                  isOffset={offset}
                  discount={Math.round(el.discount)}
                />
              );
            })}
          </div>
          {props.isWishlist && (
            // <Popconfirm
            //   title={
            //     <p
            //       style={{ fontFamily: "Prompt" }}
            //     >{`ลบอีบุ๊ค "${props.ebook.title}" ออกจาก Wishlist?`}</p>
            //   }
            //   onConfirm={onOkWishlistFunc}
            //   onCancel={() => console.log("")}
            //   okText={<p style={{ fontFamily: "Prompt", marginTop: "1px" }}>{`ตกลง`}</p>}
            //   cancelText={<p style={{ fontFamily: "Prompt", marginTop: "1px" }}>{`ยกเลิก`}</p>}
            // >
            <img
              className={"isWishlistClass"}
              src={"/images/book/wishlist-full.svg"}
              alt={`wishlist`}
              onClick={onOkWishlistFunc}
            />
          )}
          {props.isLib && (
            <img
              onClick={isLibConfirm}
              className={"isLibClass-" + `${props.isLib}`}
              src={"/images/nav/library.svg"}
              alt={`isLib`}
            />
          )}
        </div>
      </div>
      <Link to={"/ebook/" + props.ebook.bookUID} target="_blank">
        {/* <Tooltip
          placement="top"
          title={<p style={{ fontFamily: "Prompt", margin: "0 0" }}>{props.ebook.title}</p>}
        > */}
        <h3
          className="title"
          style={{
            height: twoLinesTitle
              ? props.Page.screensize.width <= 900
                ? "32px"
                : "38px"
              : props.Page.screensize.width <= 900
              ? "22px"
              : "24px",
          }}
          ref={titleRef}
          title={props.ebook.title}
        >
          {props.ebook.title}
        </h3>
        {/* </Tooltip> */}
      </Link>
      <Link to={"/author/" + props.ebook.authorName}>
        <h4
          className="author"
          style={{
            minHeight: "15px",
            marginBottom: twoLinesTitle
              ? "3px"
              : props.Page.screensize.width <= 900
              ? "17px"
              : "19px",
          }}
          title={props.ebook.authorName}
        >
          {props.ebook.authorName}
        </h4>
      </Link>
      {buyButton}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Payment: state.Payment,
    Page: state.Page,
  };
};
EbookView.propTypes = {
  ebook: PropTypes.object,
  dispatch: PropTypes.func,
  Payment: PropTypes.object,
};

export default withRouter(connect(mapStateToProps)(EbookView));
