import React from "react";
import { EbookTag } from "../Collection/EbookView";
import { EbookFullViewLoader } from "../ContentLoader";
export const CoverFiletype = props => {
  var tagArray = [];
  if (props.ebook.isPromotions) {
    tagArray.push({
      type: "promotion",
      discount: props.ebook.discount
    });
  }
  if (props.ebook.isNew) {
    tagArray.push({ type: "new" });
  }

  if (props.ebook.isTV) {
    tagArray.push({ type: "tv" });
  }

  if (props.ebook.isBestSeller) {
    tagArray.push({ type: "bestseller" });
  }
  return props.ebook.coverImage ? (
    <div className="coverImage-ebook">
      {props.ebook.coverImage ? <img src={props.ebook.coverImage} alt={`cover`} /> : ""}
      <div className={`filetype ${props.ebook.fileType}`}>
        {props.ebook.fileType ? props.ebook.fileType.toUpperCase() : ""}
      </div>
      <div className={tagArray.length > 1 ? "tagList-twoTags" : "tagList"}>
        {tagArray.map((el, index) => {
          let offset = false;
          if (index > 0) {
            offset = true;
          }
          return (
            <EbookTag
              type={el.type}
              key={index}
              zIndex={tagArray.length - index}
              isOffset={offset}
              discount={Math.round(el.discount)}
            />
          );
        })}
      </div>
    </div>
  ) : (
    <EbookFullViewLoader />
  );
};
export default CoverFiletype;
