import React, { useState, useEffect } from "react";
import { Row, Col, Input, Divider } from "antd";
import { fetchCoinData } from "../../../Actions/Payment";
import authenUtility from "../../../Utilitys/Authentication";
import { navModal, navModalMode } from "../../../Actions/Page";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const PaymentHytextsCoinList = ({
  coinAmount,
  setCoinAmount,
  setCoinID,
  screenWidth,
  dispatch,
  authentication,
  setLoading_PaymentHytextsCoinList,
  memberInfo,
  history,
  Page,
}) => {
  const [coinData, setCoinData] = useState([]);
  const [activeCoinBtn, setActiveCoinBtn] = useState(-1);

  useEffect(() => {
    const fetchCoin = async () => {
      let coinData = await fetchCoinData();
      if (coinData.status) {
        setCoinData(coinData.data.coinData);
        setLoading_PaymentHytextsCoinList(false);
      }
    };
    fetchCoin();
  }, []);

  const selectCoin = (e, coin) => {
    e.preventDefault();
    setActiveCoinBtn(parseInt(e.currentTarget.value, 10));
    setCoinAmount(coin.creditAmount);
    setCoinID(coin.coinID);
  };

  const renderBuyCoinBtn = () => {
    if (coinData) {
      var fiveCoinBtn = coinData.map((c, index) => {
        return (
          <Col
            xs={screenWidth > 412 ? 8 : 20}
            md={7}
            lg={4}
            key={index}
            style={{ maxWidth: screenWidth > 900 ? "160px" : "109px" }}
          >
            <button
              onClick={(e) => {
                if (authenUtility.isLogin()) {
                  selectCoin(e, c);
                } else {
                  if (screenWidth > 900) {
                    dispatch(navModal(true));
                    dispatch(
                      navModalMode({ loginModal: 1, registerModal: 0, registerEmailModal: 0 })
                    );
                  } else {
                    history.push("/login");
                  }
                }
              }}
              value={index}
              className={
                activeCoinBtn === -1
                  ? "buyCoinBtn"
                  : activeCoinBtn === index
                  ? "buyCoinBtn-active"
                  : "buyCoinBtn-inactive"
              }
              style={{ paddingLeft: "0px", paddingRight: "0px" }}
            >
              <div className="coinpic">
                <img
                  src={`/images/payment/coin${c.creditAmount}.svg`}
                  alt=""
                  style={{ height: "90px" }}
                ></img>
                <p
                  className="creditAmount"
                  style={{ fontSize: screenWidth <= 900 ? "18px" : "26px" }}
                >
                  {c.creditAmount} C
                </p>
                <p className="thbAmount" style={{ fontSize: screenWidth <= 900 ? "16px" : "20px" }}>
                  {c.creditAmount} บาท
                </p>
                <div className="bonusAmount">
                  <p
                    style={{ marginBottom: "0px", paddingTop: screenWidth <= 900 ? "5px" : "6px" }}
                  >
                    FREE
                  </p>
                  <p
                    style={{
                      transform: screenWidth <= 900 ? "translateY(-5px)" : "translateY(-6px)",
                    }}
                  >
                    {c.bonusAmount}
                  </p>
                </div>
              </div>
            </button>
          </Col>
        );
      });
      fiveCoinBtn.push(
        <Col
          span={18}
          md={0}
          key={5}
          style={
            screenWidth < 484
              ? { width: "109px" }
              : { maxWidth: screenWidth > 900 ? "160px" : "327px" }
          }
        ></Col>
      );
      return fiveCoinBtn;
    }
  };

  return (
    <div>
      <Row>
        {Page.screensize.width <= 900 ? (
          <Col span={24} align="center">
            <p className="coltitle" style={{ marginLeft: "8px" }}>
              Coin ที่มีอยู่ : {memberInfo.coinData.remainCoin} Coin
            </p>
          </Col>
        ) : (
          ""
        )}
      </Row>
      <Row style={{ margin: "0px 0px 28px 0px" }} type="flex" gutter={[16, 16]} justify={"center"}>
        {renderBuyCoinBtn()}
        <Col span={Page.screensize.width < 768 ? 24 : 20}>
          <Divider style={{ margin: "10px 0", width: "100%" }} />
        </Col>
      </Row>

      {/* <div style={{ textAlign: "center" }}>
        <p className="price-label">
          {activeCoinBtn === -1 ? "จำนวน Coin ที่มีอยู่" : "ยอดที่ต้องชำระ"}
        </p>
        <Input
          className="inputDisabled"
          disabled
          value={activeCoinBtn === -1 ? memberInfo.coinData.remainCoin : `฿${coinAmount}`}
        />
        <br />
      </div> */}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    authentication: state.Authentication,
    memberInfo: state.Member.memberInfo,
    Page: state.Page,
  };
};

export default withRouter(connect(mapStateToProps)(PaymentHytextsCoinList));
