import queryString from "query-string";
const ValidateParam = (obj = {}) => {
  for (let a in obj) {
    if (typeof obj[a] == "string") {
      obj[a].trim();
    }
    if (obj[a] === "" || obj[a] === null || obj[a] === undefined) {
      delete obj[a];
    }
  }
  return obj;
};
const getQueryStringFormURL = searchParam => {
  return queryString.parse(searchParam);
};
const isPhoneNumber = (number = "") => {
  if (number.match(/^[0][0-9]{9}$/)) {
    return true;
  } else {
    return false;
  }
};

const isPasswordValid = (pw = "") => {
  if (pw.match(/^[0-9a-zA-Z!#@$%^&*()-_+=/\\'":;.`~]{6,}$/)) {
    return true;
  } else {
    return false;
  }
};

const removeExistArray = (basearray = [], removeValue = [], key = "") => {
  let removeArray = basearray;
  basearray.forEach((value, index) => {
    if (key === "") {
      if (removeValue.includes(value)) {
        removeArray.splice(index, 1);
      }
    } else {
      if (Array.isArray(removeValue)) {
        if (removeValue.filter(value => removeValue[key] === value[key]).length) {
          removeArray.splice(index, 1);
        }
      } else {
        if (value[key] === removeValue[key]) {
          removeArray.splice(index, 1);
        }
      }
    }
  });

  return removeArray;
};
export default {
  ValidateParam,
  getQueryStringFormURL,
  isPhoneNumber,
  isPasswordValid,
  removeExistArray
};
