import React from "react";
import { Layout } from "antd";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

const Footer = (props) => {
  const rightStyle = { textAlign: "right", color: "rgba(0, 0, 0, 0.7)" };
  const centerStyle = { textAlign: "center", color: "rgba(0, 0, 0, 0.7)" };
  const leftStyle = { textAlign: "left", color: "rgba(0, 0, 0, 0.7)" };
  const rightStyleB = { textAlign: "right", color: "black" };
  const centerStyleB = { textAlign: "center", color: "black" };
  const leftStyleB = { textAlign: "left", color: "black" };
  const leftStyle_underline = {
    textAlign: "left",
    textDecoration: "underline",
  };
  const leftStyle_underline_marginTop = {
    textAlign: "left",
    textDecoration: "underline",
    marginTop: "8px",
  };
  const footerImg_email = {
    width: "20px",
    height: "18px",
    marginBottom: "1px",
  };
  return (
    <Layout.Footer
      style={{
        textAlign: "center",
        position: "relative",
        background: "white",
        borderTop: "1px solid #dfdfdf",
      }}
    >
      <Row
        type="flex"
        justify="center"
        gutter={16}
        className="footer-Row"
        style={{ fontFamily: "Bai JamJuree" }}
      >
        <Col xs={24} sm={8} align="left">
          <h4
            style={
              props.Page.screensize.width >= 576
                ? leftStyleB
                : leftStyle_underline
            }
          >
            ติดต่อเรา
          </h4>
          <h5>
            <a href="#">
              <img
                src="/images/logos/email.png"
                style={footerImg_email}
                alt=""
              />{" "}
              : <span className="footer-link">contact@hytexts.com</span>
            </a>
          </h5>
          <h5>
            <a
              href={`https://www.facebook.com/hytexts`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/logos/facebook.png" style={footerImg} alt="" />{" "}
              : <span className="footer-link">@hytexts</span>
            </a>
          </h5>
          <h5>
            <a
              href={`https://twitter.com/hytexts`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/logos/twitter.png" style={footerImg} alt="" /> :{" "}
              <span className="footer-link">@hytexts</span>
            </a>
          </h5>
          <h5>
            <a
              href={`http://line.me/ti/p/%40hytexts`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                src="/images/logos/lineCircle.png"
                style={footerImg}
                alt=""
              />{" "}
              : <span className="footer-link">@hytexts</span>
            </a>
          </h5>
          <h5>
            <a
              href={`https://www.instagram.com/hytexts/`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src="/images/logos/instagram.png" style={footerImg} alt="" />{" "}
              : <span className="footer-link">hytexts</span>
            </a>
          </h5>
        </Col>
        <Col xs={24} sm={8} align="left">
          <h4
            style={
              props.Page.screensize.width >= 576
                ? centerStyleB
                : leftStyle_underline_marginTop
            }
          >
            บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด
          </h4>
          <h5
            style={props.Page.screensize.width >= 576 ? centerStyle : leftStyle}
          >
            เลขที่ 3 อาคารพร้อมพันธุ์ 3 ชั้นที่ 7 ห้องเลขที่ 701-702 ซอยลาดพร้าว
            3 ถนนลาดพร้าว แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900
          </h5>
          <h5
            style={props.Page.screensize.width >= 576 ? centerStyle : leftStyle}
          >
            โทร. 02-024-6690
          </h5>
          <h5
            style={props.Page.screensize.width >= 576 ? centerStyle : leftStyle}
          >
            (จันทร์-ศุกร์ 9.00-18.00 น. เว้นวันหยุดราชการ)
          </h5>
          <h5
            style={props.Page.screensize.width >= 576 ? centerStyle : leftStyle}
          >
            Customer Service : 089-134-7470
          </h5>
          <br />
          <h5
            style={props.Page.screensize.width >= 576 ? centerStyle : leftStyle}
          >
            Copyright ©{moment().year()} All Right Reserved.
          </h5>
        </Col>
        <Col xs={0} lg={4}></Col>
        <Col
          xs={24}
          sm={8}
          lg={4}
          align={props.Page.screensize.width >= 576 ? "right" : "left"}
        >
          <h4
            style={
              props.Page.screensize.width >= 576
                ? rightStyleB
                : leftStyle_underline_marginTop
            }
          >
            BECOME A PARTNER
          </h4>
          <h5>
            <a
              target="_blank"
              href="https://www.hibrary.me"
              className="faq-link"
            >
              ห้องสมุดออนไลน์(E-library)สำหรับองค์กร
            </a>
          </h5>
          <h5>
            <Link to="/faq/reader" className="faq-link">
              สำหรับผู้อ่าน
            </Link>
          </h5>
          <h5>
            <Link to="/faq/writer" className="faq-link">
              สำหรับผู้ขาย
            </Link>
          </h5>
          <h5>
            <Link to="/privacy/term" className="faq-link">
              เงื่อนไขการใช้บริการ
            </Link>
          </h5>
          <h5>
            <Link to="/privacy/policy" className="faq-link">
              นโยบายความเป็นส่วนตัว
            </Link>
          </h5>
          <h5>
            <Link to="/faq/reader" className="faq-link">
              FAQ
            </Link>
          </h5>
        </Col>
      </Row>
    </Layout.Footer>
  );
};

export const footerImg = { width: "20px", height: "20px", marginBottom: "4px" };

const mapStateToProps = (state) => {
  return {
    Page: state.Page,
  };
};

export default connect(mapStateToProps)(Footer);
