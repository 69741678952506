import React from "react";
import { Form, Input, Icon, Button, Modal } from "antd";
import { getQrCodeData } from "../../../Actions/Authentication";
import validator from "validator";
import PropTypes from "prop-types";
import { FacebookLogin } from "../../Login";
import { connect } from "react-redux";
import { showLoading } from "../../../Actions/Page";
import { showError } from "../../../Actions/Message";
import { fetchLogin } from "../../../Actions/Authentication";
import ObjectUitil from "../../../Utilitys/Object";
import authenObj from "../../../Utilitys/Authentication";
import firebase from "../../../Service/Firebase";
import { Redirect, Link } from "react-router-dom";
import Qrcode from "../qrcode";
import { DownloadApp } from "./Login-children";
import { navModal, navModalMode } from "../../../Actions/Page";
import { fetch } from "../../../Utilitys/ApiRequest";

class Login extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      isShowqrModal: false,
      qrImage: "",
      usernameError: "",
      usernameerrormessage: "",
      passwordError: "",
      passworderrormessage: ""
    };
    this.dispatch = this.props.dispatch;
    this.queryParam = ObjectUitil.getQueryStringFormURL(this.props.location.search || "");
    this.checkLineLoginCallback();
  }

  checkLineLoginCallback = async () => {
    if (this.queryParam.state === "LineCallBack") {
      if (this.queryParam.code) {
        var channelid = "1586815286";
        var callbackurl = authenObj.URL + "/login";
        var state = "LineCallBack";
        let params = {
          grant_type: "authorization_code",
          code: this.queryParam.code,
          redirect_uri: authenObj.URL + "/login",
          client_id: "1586815286",
          client_secret: ""
        };
        let reqLineToken = await fetch("post", `https://api.line.me/oauth2/v2.1/token`, params);
      } else if (this.queryParam.error) {
        this.dispatch(showError({ message: `Line Login Error ${this.queryParam.error}` }));
      }
    }
  };
  render() {
    return <div></div>;
  }
}
const mapStateToProps = state => {
  return { Authentication: state.Authentication };
};
Login.propTypes = {
  form: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  Authentication: PropTypes.object,
  location: PropTypes.object,
  isModal: PropTypes.bool
};
export default connect(mapStateToProps)(Form.create({ name: "normal_login" })(Login));
