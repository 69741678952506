import React from "react";
import TweenOne from "rc-tween-one";
import PropTypes from "prop-types";
const Demo = props => (
  <TweenOne
    animation={{
      x: 0,
      scale: 0.95,
      rotate: 0,
      yoyo: true,
      repeat: -1,
      duration: 500
    }}
    style={{
      width: "98px",
      height: "98px",
      margin: "auto",
      backgroundImage: 'url("/images/logos/qrcode-animate.svg")',
      transform: "translateY(-92px)"
    }}
  />
);
Demo.propTypes = {
  children: PropTypes.any,
  className: PropTypes.string,
  paused: PropTypes.bool
};

export default Demo;
