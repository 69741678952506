import React from "react";
import { connect } from "react-redux";
import { fetchLogin } from "../../../Actions/Authentication";
import authen from "../../../Utilitys/Authentication";
import { showLoading } from "../../../Actions/Page";
import firebase from "../../../Service/Firebase";
import { DownloadApp } from "../Login/Login-children";
import { showError } from "../../../Actions/Message";
import { FacebookLogin } from "../../Login";
import { Link } from "react-router-dom";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);

class Register extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentStep: 0 };
    this.dispatch = this.props.dispatch;
  }
  onSubmitHandle = () => {
    if (this.state.currentStep < this.content.length) {
      this.setState({ currentStep: this.state.currentStep + 1 });
    }
  };
  onFacebookLoginHandle = (result, data) => {
    if (result) {
      this.dispatch(fetchLogin("facebook", data.user, this.props.history));
    }
    this.dispatch(showLoading(false));
  };

  onLineLoginHandle = () => {
    var channelid = "1586815286";
    var callbackurl = authen.URL + "/login";
    var state = "LineCallBack";
    var url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${channelid}&redirect_uri=${callbackurl}&scope=profile&state=${state}`;
    window.location.href = url;
  };

  onGmailLoginHandle = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().languageCode = "th";
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        var user = result.additionalUserInfo.profile;
        let payload = {
          id: user.id,
          email: user.email,
          name: user.name,
          picture: user.picture,
          given_name: user.given_name,
          family_name: user.family_name
        };
        this.dispatch(fetchLogin("gmail", payload));
      })
      .catch(error => {
        this.dispatch(showError({ message: `Gmail Login Error ${error.message}` }));
      });
  };
  render() {
    return (
      <div className="register-container">
        <h1>สมัครสมาชิก</h1>
        <div className="register-channel-container">
          <div className="loginIconSet">
            <Link
              to={this.props.Page.screensize.width > 900 ? "#" : "/register/email"}
              onClick={this.props.toRegisterEmail}
            >
              <button className="login-email">Register with email</button>
            </Link>
            <FacebookLogin onLogin={this.onFacebookLoginHandle}>
              <button className="login-facebook">Register with Facebook</button>
            </FacebookLogin>
            {/* <button className="login-line" onClick={this.onLineLoginHandle}>
              Register with Line
            </button> */}
            <button className="login-google" onClick={this.onGmailLoginHandle}>
              Register with Google
            </button>
          </div>
        </div>
        <p className="noaccountyet">
          คุณมีบัญชีอยู่แล้ว ?{" "}
          <Link
            className="login"
            to={this.props.Page.screensize.width > 900 ? "#" : "/login"}
            onClick={this.props.toLogin}
          >
            เข้าสู่ระบบ
          </Link>
        </p>
        <DownloadApp />
      </div>
    );
  }
}
const mapStateToProps = state => {
  return { Authentication: state.Authentication, Page: state.Page };
};

export default connect(mapStateToProps)(Register);
