import {
  REQUEST_GUEST_TOKEN,
  RECEIVE_GUEST_TOKEN,
  RECEIVE_LOGIN_TOKEN,
  REQUEST_EXIST_ACCOUNT,
  LOG_OUT
} from "../Constants/actionTypes";
import Cookies from "universal-cookie";
import moment from "moment";
const cookies = new Cookies();

const INITIAL_STATE = {
  userToken: cookies.get("userToken") || "",
  status: false,
  errorcode: "",
  message: "",
  isLoading: false,
  email: ""
};
const Authentication = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case REQUEST_GUEST_TOKEN:
      return Object.assign({}, state, {
        isLoading: action.isLoading
      });
    case RECEIVE_GUEST_TOKEN:
      cookies.set("userToken", action.userToken, {
        expires: moment()
          .add(1, "d")
          .toDate(),
        path: "/"
      });
      if (state.userToken === action.userToken) {
        return state;
      } else {
        return {
          ...state,
          userToken: action.userToken,
          status: false
        };
      }
    case RECEIVE_LOGIN_TOKEN:
      cookies.set("userToken", action.userToken, {
        expires: moment()
          .add(1, "d")
          .toDate(),
        path: "/"
      });
      if (state.userToken === action.userToken) {
        return state;
      } else {
        return {
          ...state,
          userToken: action.userToken,
          status: true
        };
      }
    case LOG_OUT:
      return {
        ...state,
        userToken: action.userToken,
        status: false
      };
    case REQUEST_EXIST_ACCOUNT:
      return Object.assign({}, state, {
        email: action.email
      });
    default:
      return state;
  }
};
export default Authentication;
