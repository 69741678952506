import React from "react";
import HomeList from "../BookList/HomeList";
import moment from "moment";
import {
  fetchAddCart,
  fetchRemoveCart,
  removeCartItemFromLocalStorage,
} from "../../Actions/Payment";
import { isAddCart } from "../../Actions/Page";
import { updateExampleDownloadStatistic } from "../../Actions/Ebook";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Button } from "antd";

class FlashSale extends React.Component {
  constructor(props) {
    super(props);
    console.log(props);
    this.state = { isbtnShow: false };
    let filter = this.props.Payment.shoppingCart.filter((el) => {
      if (this.props.ebook) {
        return el.bookUID === this.props.ebook.bookUID;
      }
    });
    if (filter.length) {
      this.props.dispatch(isAddCart(true));
    }
  }
  componentDidMount() {
    document.addEventListener("scroll", this.trackScrolling);
  }
  componentWillUnmount() {
    document.removeEventListener("scroll", this.trackScrolling);
  }
  isArea = () => {
    return window.pageYOffset >= this.props.offsetTop;
  };
  trackScrolling = () => {
    if (this.isArea()) {
      this.setState({ isbtnShow: true });
    } else {
      this.setState({ isbtnShow: false });
    }
  };

  addcart = () => {
    this.props.dispatch(isAddCart(true));
    this.props.dispatch(fetchAddCart(this.props.ebook));
  };
  removecart = () => {
    this.props.dispatch(isAddCart(false));
    this.props.dispatch(fetchRemoveCart(this.props.ebook));
    removeCartItemFromLocalStorage(this.props.ebook.bookUID);
  };

  render() {
    const path = this.props.location.pathname;
    return (
      <div
        className={path.includes("/ebook/") ? "flash-sale-ebook" : "flash-sale"}
      >
        <div className="title">
          <div className="flash-icon">
            <img src="/images/tags/flash-sale.svg" alt="Flash Sale" />
          </div>
          {this.props.discount && this.props.Page.screensize.width > 712 ? (
            <div className="discount">{this.props.discount + "%"}</div>
          ) : (
            ""
          )}
          <CountDownTimer
            isbtnShow={this.state.isbtnShow}
            flashSaleExp={this.props.Page.flashSaleExp}
          />
          {this.state.isbtnShow && this.props.Page.screensize.width > 712 ? (
            <span className="btn-flash">
              <a href={this.props.ebook.samplePath} download>
                <Button
                  className="trybtn-flash"
                  onClick={() => {
                    updateExampleDownloadStatistic(this.props.ebook.bookUID);
                  }}
                >
                  ทดลองอ่าน
                </Button>
              </a>
              {this.props.isAddCart ? (
                <Button onClick={this.removecart} className="addcartbtn-flash">
                  เพิ่มในตะกร้าแล้ว
                </Button>
              ) : (
                <Button onClick={this.addcart} className="pricebtn-flash">
                  {this.props.ebook.priceTHB} บาท
                </Button>
              )}
            </span>
          ) : (
            ""
          )}
        </div>

        {this.props.showListBelow === false ? (
          ""
        ) : (
          <HomeList
            filter={"promotion"}
            numCollection={this.props.numCollection}
            flashSale={true}
          />
        )}
      </div>
    );
  }
}

class CountDownTimer extends React.Component {
  constructor(props) {
    super(props);
    this.state = { currentTimeLeft: 1 };
    this.timerID = setInterval(this.setTimeCount, 1000);
  }
  setTimeCount = () => {
    console.log("time left", this.props.flashSaleExp);

    let timeleft = this.props.flashSaleExp - moment().unix();
    this.setState((prevState) => ({
      currentTimeLeft: timeleft < 1 ? 0 : timeleft,
    }));
  };

  componentWillUnmount() {
    clearInterval(this.timerID);
  }
  render() {
    let currenttimeleft = this.state.currentTimeLeft;
    let totaltimeleft = currenttimeleft / (3600 * 24);
    let totalmodtime = currenttimeleft % (3600 * 24);
    let day = Math.floor(totaltimeleft);
    var hour = Math.floor(totalmodtime / 3600);
    var minutes = Math.floor((totalmodtime % 3600) / 60);
    var second = Math.floor((totalmodtime % 3600) % 60);
    hour = hour < 10 ? "0" + hour : hour;
    minutes = minutes < 10 ? "0" + minutes : minutes;
    second = second < 10 ? "0" + second : second;
    return (
      <div
        className="count-down-timer marginLeft16"
        style={{ marginRight: this.props.isbtnShow ? "0px" : "16px" }}
      >
        <div className="cout-time">{day}</div>
        <div className="daylabel">วัน </div>
        <div className="cout-timee">{hour}</div>
        <div className="cout-timee">{minutes}</div>
        <div className="cout-timee">{second}</div>
      </div>
    );
  }
}

const mapStateToProps = (state) => {
  return {
    Payment: state.Payment,
    offsetTop: state.Page.showFlashSale.offsetTop,
    ebook: state.Page.showFlashSale.ebook,
    discount: state.Page.showFlashSale.discount,
    isAddCart: state.Page.isAddCart,
    Page: state.Page,
  };
};

export default withRouter(connect(mapStateToProps)(FlashSale));
