import React from "react";
import { Layout } from "antd";
import { Row, Col } from "antd";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
import moment from "moment";

const ContactUs = (props) => {
  const leftStyle = { textAlign: "left" };
  const leftStyle_B8px = { textAlign: "left", marginBottom: "8px" };
  const leftStyle_underline_marginTop = {
    textAlign: "left",
    textDecoration: "underline",
    marginTop: "8px",
  };
  const copyRightStyle = { width: "100%", position: "fixed", bottom: "82px", textAlign: "center" };
  const footerImg = { width: "20px", height: "20px", marginBottom: "4px" };
  const footerImg_email = { width: "20px", height: "18px", marginBottom: "1px" };
  return (
    <Row
      type="flex"
      justify="center"
      gutter={16}
      style={{ fontFamily: "Bai JamJuree" }}
      className="contactUs"
    >
      <Col xs={24} align="left">
        <h2 style={leftStyle_B8px}>ติดต่อเรา</h2>
        <h5>
          <a href="#">
            <img src="/images/logos/email.png" style={footerImg_email} alt="" /> :{" "}
            <span className="footer-link">contact@hytexts.com</span>
          </a>
        </h5>
        <h5>
          <a href={`https://www.facebook.com/hytexts`} target="_blank" rel="noopener noreferrer">
            <img src="/images/logos/facebook.png" style={footerImg} alt="" /> :{" "}
            <span className="footer-link">@hytexts</span>
          </a>
        </h5>
        <h5>
          <a href={`https://twitter.com/hytexts`} target="_blank" rel="noopener noreferrer">
            <img src="/images/logos/twitter.png" style={footerImg} alt="" /> :{" "}
            <span className="footer-link">@hytexts</span>
          </a>
        </h5>
        <h5>
          <a href={`http://line.me/ti/p/%40hytexts`} target="_blank" rel="noopener noreferrer">
            <img src="/images/logos/lineCircle.png" style={footerImg} alt="" /> :{" "}
            <span className="footer-link">@hytexts</span>
          </a>
        </h5>
        <h5>
          <a href={`https://www.instagram.com/hytexts/`} target="_blank" rel="noopener noreferrer">
            <img src="/images/logos/instagram.png" style={footerImg} alt="" /> :{" "}
            <span className="footer-link">hytexts</span>
          </a>
        </h5>
      </Col>
      <Col xs={24} align="left">
        <h4 style={leftStyle_underline_marginTop}>บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด</h4>
        <h5 style={leftStyle}>
          เลขที่ 3 อาคารพร้อมพันธุ์ 3 ชั้นที่ 7 ห้องเลขที่ 701-702 ซอยลาดพร้าว 3 ถนนลาดพร้าว
          แขวงจอมพล เขตจตุจักร กทม 10900
        </h5>
        <h5 style={leftStyle}>โทร. 02-024-6690</h5>
        <h5 style={leftStyle}>(จันทร์-ศุกร์ 9.00-18.00 น. เว้นวันหยุดราชการ)</h5>
        <h5 style={leftStyle}>Customer Service : 089-134-7470</h5>
        <br />
        <div style={copyRightStyle}>Copyright ©{moment().year()} All Right Reserved.</div>
      </Col>
      {/* <Col xs={24} align={"left"}>
        <h4 style={leftStyle_underline_marginTop}>BECOME A PARTNER</h4>
          <h5>
            <Link to="/faq?for=reader" className="faq-link">
              สำหรับผู้อ่าน
            </Link>
          </h5>
          <h5>
            <Link to="/faq?for=writer" className="faq-link">
              สำหรับผู้ขาย
            </Link>
          </h5>
          <h5>
            <Link to="/privacy" className="faq-link">
              เงื่อนไขการใช้บริการ
            </Link>
          </h5>
          <h5>
            <Link to="/faq" className="faq-link">
              FAQ
            </Link>
          </h5>
      </Col> */}
    </Row>
  );
};

const mapStateToProps = (state) => {
  return {
    Page: state.Page,
  };
};

export default connect(mapStateToProps)(ContactUs);
