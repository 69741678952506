import React from "react";
import { Row, Col } from "antd";
export const BuyButton = ({
  isPromotions,
  isAddCart,
  priceTHB,
  priceCover,
  oldPriceTHB,
  addcartFunc,
  removecartFunc,
  flashSale,
  isPurchase,
}) => {
  if (isPurchase) {
    return (
      <button disabled className="purchasebtn">
        ซื้อแล้ว
      </button>
    );
  } else {
    if (isPromotions) {
      if (isAddCart) {
        return (
          <Row gutter={4}>
            {/* {flashSale ? (
              <div>
                <Col span={6}>
                  <div className="discount">{priceCover ? priceCover : oldPriceTHB}</div>
                </Col>
                <Col span={18}>
                  <button type="primary" onClick={removecartFunc} className="incartbtn">
                    เพิ่มในตะกร้าแล้ว
                  </button>
                </Col>
              </div>
            ) : ( */}
            <button type="primary" onClick={removecartFunc} className="incartbtn">
              เพิ่มในตะกร้าแล้ว
            </button>
            {/* )} */}
          </Row>
        );
      } else {
        let btntitle = "";
        if (priceTHB === 0) {
          btntitle = "ฟรี";
        } else {
          btntitle = `฿${priceTHB}`;
        }
        return (
          <Row gutter={8}>
            <Col xs={8} md={6}>
              <div className="discount">{priceCover ? `฿${priceCover}` : `฿${oldPriceTHB}`}</div>
            </Col>
            <Col xs={16} md={18}>
              <button type="primary" onClick={addcartFunc} className="buybtn flashsale-btn">
                {btntitle}
              </button>
            </Col>
          </Row>
        );
      }
    } else {
      if (isAddCart) {
        return (
          <button type="primary" onClick={removecartFunc} className="incartbtn">
            เพิ่มในตะกร้าแล้ว
          </button>
        );
      } else {
        let btntitle = "";
        if (priceTHB === 0) {
          btntitle = "ฟรี";
        } else {
          btntitle = `฿${priceTHB}`;
        }
        return flashSale ? (
          <button type="primary" onClick={addcartFunc} className="buybtn flashsale-btn">
            <span className="title">{btntitle}</span>
          </button>
        ) : (
          <button type="primary" onClick={addcartFunc} className="buybtn">
            <span className="title">{btntitle}</span>
          </button>
        );
      }
    }
  }
};
export default BuyButton;
