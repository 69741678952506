import React, { useEffect, useState } from "react";
import { Row, Col, Divider } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { footerImg } from "../../Footer/Footer";
import { millisecsToDateTime } from "../../../Utilitys/changeFormatDateTime";
import moment from "moment";
import QRCode from "qrcode.react";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);

const PaymentSuccessTF = (props) => {
  let screenWidth = props.Page.screensize.width;
  let orderCode = props.match.params.orderCode;
  const qrCodeString =
    props.location.state && props.location.state.qrCode && decodeURI(props.location.state.qrCode);
  const [paymentData, setPaymentData] = useState(null);
  const [isHytextsCoin, setIsHytextsCoin] = useState(null);
  useEffect(() => {
    let btf_local = JSON.parse(localStorage.getItem("banktransfer"));
    if (btf_local.orderCode === orderCode) {
      setPaymentData(btf_local);
      if (orderCode.substring(0, 1).toLowerCase() === "c") {
        setIsHytextsCoin(true);
      } else {
        setIsHytextsCoin(false);
      }
    }
  }, []);
  return (
    paymentData && (
      <div className="hytextsBankAccount">
        <h3 className="prompt26">การชำระเงิน</h3>
        <h3 className="prompt20" style={{ margin: "38px 0px 16px 0px" }}>
          ยอดเงินที่ต้องชำระ
        </h3>
        <div className="priceBox">
          <h3 className="prompt30" style={{ fontWeight: "600", margin: "8px 0px 0px 0px" }}>
            {paymentData.amount} บาท
          </h3>
          <h3 className="BaiJamJuree16" style={{ color: "#a20006", margin: "0px 0px 8px 0px" }}>
            กรุณาโอนเงินให้ตรงกับยอดนี้
          </h3>
        </div>
        <Row className="orderNumberBox" type="flex" justify="center">
          <Col span={24} align="center">
            <h3 className="BaiJamJuree16" style={{ margin: "0px 0px" }}>
              เลขที่คำสั่งซื้อ: {paymentData.orderCode}
            </h3>
          </Col>
          {/* <Col span={12} align="right">
          <h3 className="BaiJamJuree16" style={{ margin: "0px 0px" }}>
            E-mail: supattra.ardhaw@gmail.com
          </h3>
        </Col> */}
        </Row>
        <Divider
          style={{
            margin: "0 0 16px 0",
            width: screenWidth > 750 ? "692px" : "100%",
            background: "#dbdbdb",
          }}
        />
        <h3 className="prompt18" style={{ textAlign: "center" }}>
          รายละเอียดการโอนเงิน
        </h3>
        <span className="BaiJamJuree16">บัญชีพร้อมเพย์</span>
        <span>
          <img
            src="/images/payment/promptpay.png"
            style={{ width: "102px", height: "32px", marginLeft: "8px" }}
          />
        </span>
        <Row type="flex" justify="center" style={{ margin: "13px 0px 32px 0px" }}>
          <Col xs={24} md={11}>
            <div className="promptpayBox">
              {qrCodeString ? (
                <QRCode value={qrCodeString} size={234} />
              ) : (
                <img
                  src={paymentData.qrcode.qrCodeImage}
                  style={{ width: "234px", height: "234px" }}
                />
              )}
              <h3 className="BaiJamJuree12">*กรุณาใช้แอปพลิเคชันของธนาคารในการสแกน QR code</h3>
            </div>
          </Col>
          <Col xs={24} md={0}>
            <Divider
              style={{
                marginTop: "10px",
                marginBottom: "0px",
                width: "100%",
                background: "#dbdbdb",
              }}
            />
          </Col>
          {paymentData.promptpayID || paymentData.qrcode.promptpayID ? (
            <>
              <Col xs={0} md={2}></Col>
              <Col
                xs={{ span: 24, offset: 0 }}
                md={{ span: 11, offset: 0 }}
                align={screenWidth > 768 ? "left" : "center"}
              >
                <div className="paymentDetailBox">
                  <div className="bankBox">
                    <h3
                      className="BaiJamJuree16"
                      style={{ margin: "8px 0px", textAlign: "center" }}
                    >
                      บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด
                    </h3>
                    <Row type="flex" justify="center" align="middle">
                      <Col span={5}>
                        <img
                          src="/images/logos/hytexts.png"
                          style={{ width: "52px", height: "52px" }}
                          alt=""
                        />
                      </Col>
                      <Col span={14}>
                        <h3 className="BaiJamJuree16" style={{ margin: "0px 0px" }}>
                          เลขประจำตัวนิติบุคคล
                        </h3>
                        <h3 className="prompt20" style={{ fontWeight: "bold", margin: "0px 0px" }}>
                          {paymentData.promptpayID || paymentData.qrcode.promptpayID}
                          {/* {props.qrPayment.promptpayID.slice(0, 3) +
                      "-" +
                      props.qrPayment.promptpayID.slice(3, 7) +
                      "-" +
                      props.qrPayment.promptpayID.slice(7, 11) +
                      "-" +
                      props.qrPayment.promptpayID.slice(11, props.qrPayment.promptpayID.length)} */}
                        </h3>
                      </Col>
                    </Row>
                  </div>
                </div>
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
        <h3 className="BaiJamJuree14" style={{ color: "red" }}>
          *กรุณาโอนเงินภายในวันที่{" "}
          {millisecsToDateTime(moment().unix() + 2 * 86400, "hr-min", "dd/mm/yyyy").split(" ")[0]}{" "}
          หลังจากโอนเงินแล้วกรุณาแจ้งโอนเงินเพื่อยืนยันการโอนเงิน
        </h3>
        <Divider
          style={{
            margin: "16px 0",
            width: screenWidth > 750 ? "692px" : "100%",
            background: "#dbdbdb",
          }}
        />
        {paymentData.qrcode ? (
          <div className="paymentChannelBox">
            <h3 className="prompt20">ช่องทางการแจ้งการโอนเงิน</h3>
            <div className="channels">
              <h3 className="prompt20 margin8_0">
                <span style={{ color: "#4f4f4f" }}>1. Line ID : </span>@hytexts (มี @ นำหน้า)
              </h3>
              <h3 className="prompt20 margin8_0">
                <span style={{ color: "#4f4f4f" }}>2. Facebook : </span>@hytexts
              </h3>
              <h3 className="prompt20 margin8_0">
                <span style={{ color: "#4f4f4f" }}>3. Email : </span>payment@hytexts.com
              </h3>
            </div>
          </div>
        ) : (
          ""
        )}
        <div className="contactInfo">
          {paymentData.qrcode ? (
            <h3 className="BaiJamJuree14">
              หลังจากแจ้งโอนเงินแล้ว e-book จะเข้าชั้นหนังสือบน App ของคุณภายใน 15 นาที (จ.-ศ. เวลา
              9.00-18.00 น.)
            </h3>
          ) : (
            <h3 className="BaiJamJuree14">
              {isHytextsCoin
                ? "หลังจากโอนเงินแล้ว Hytexts coin จะเข้าบัญชีของคุณโดยอัตโนมัติ"
                : "หลังจากโอนเงินแล้ว e-book จะเข้าชั้นหนังสือบน App ของคุณโดยอัตโนมัติ"}
            </h3>
          )}
          <br />
          <h3 className="BaiJamJuree14">
            {isHytextsCoin
              ? "หากไม่ได้รับ Hytexts Coin หรือมีข้อสงสัยใดๆ กรุณาติดต่อสอบถามเราได้ที่"
              : "หากไม่ได้รับ e-book หรือมีข้อสงสัยใดๆ กรุณาติดต่อสอบถามเราได้ที่"}
            <br />
            <a href={`http://line.me/ti/p/%40hytexts`} target="_blank" rel="noopener noreferrer">
              <img src="/images/logos/lineCircle.png" style={footerImg} alt="" /> :{" "}
              <span className="footer-link">@hytexts</span> (มี @ นำหน้า)
            </a>
            <br />
            <a href={`https://www.facebook.com/hytexts`} target="_blank" rel="noopener noreferrer">
              <img src="/images/logos/facebook.png" style={footerImg} alt="" /> :{" "}
              <span className="footer-link">hytexts</span>
            </a>
          </h3>
        </div>
      </div>
    )
  );
};

const mapStateToProps = (state) => {
  return {
    Page: state.Page,
  };
};

export default withRouter(connect(mapStateToProps)(PaymentSuccessTF));
