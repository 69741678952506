import React from "react";
const contents_termOfUse = [
  "โปรดอ่านทำความเข้าใจตามเงื่อนไขและข้อตกลงก่อนทำการลงทะเบียน หลังจากลงทะเบียนเสร็จสมบูรณ์แล้ว ถือว่าท่านยอมรับเงื่อนไขและข้อตกลงระหว่างนักเขียน กับ บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด หรือ www.hytexts.com",
  "โดยทั้งสองฝ่ายตกลงทำข้อตกลงกัน ซึ่งมีข้อความดังต่อไปนี้",
];
const contents_ebook = [
  "1. หนังสืออิเล็กทรอนิกส์ที่ถูกตั้งราคาขายใน www.hytexts.com จะถูกเข้ารหัสเพื่อจัดการลิขสิทธ์ (Digital Right Management) ยกเว้นหนังสืออิเล็กทรอนิกส์ที่จัดจำหน่ายแบบ DRM-Free",
  "2. สมาชิกจะต้องสมัคร Adobe ID ก่อนที่จะดาวน์โหลดหนังสืออิเล็กทรอนิกส์ เพื่อที่จะสามารถนำไปอ่านบนอุปกรณ์ในระบบต่าง ๆ เช่น iOS, Android, Window, Mac OS และเครื่อง E-Reader",
  "3. สมาชิกสามารถ Authorize อุปกรณ์พกพาได้สูงสุด 5 อุปกรณ์ ต่อ 1 Adobe ID",
  "4. หนังสืออิเล็กทรอนิกส์ และตัวอย่างหนังสืออิเล็กทรอนิกส์ ที่ถูกจัดจำหน่ายโดย www.hytexts.com ห้ามทำซ้ำ ดัดแปลง แก้ไข เผยแพร่ จัดจำหน่าย โดยไม่ได้รับอนุญาตจาก www.hytexts.com",
];
const contents_writer =
  "นักเขียนจะต้องมีอายุมากกว่า 18 ปีบริบูรณ์ จึงจะสามารถวางขาย E-Book กับ www.hytexts.com";
const contents_1 =
  "นักเขียนตกลงว่าข้อมูลอิเล็กทรอนิกส์ หรืองานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ (E-Book) ของนักเขียนที่ส่งให้ www.hytexts.com จำหน่ายให้แก่ลูกค้านั้น เป็นข้อมูลที่สามารถเข้าถึงและนำกลับมาใช้ใหม่ได้ โดยความหมายไม่เปลี่ยนแปลง และในกรณีที่ต้องมีบุคคลลงชื่อในหนังสือ ให้ถือว่าข้อมูลทางอิเล็กทรอนิกส์นั้น ได้มีการลงลายมือชื่อแล้ว และนักเขียนได้รับอนุญาตให้จัดทำ จำหน่าย โฆษณา เผยแพร่ จากเจ้าของลิขสิทธิ์ที่แท้จริงถูกต้องตามกฎหมายแล้ว และเผยแพร่งานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ของนักเขียนบน www.hytexts.com ได้ โดยไม่ขัดต่อข้อตกลงกับบุคคลอื่น";
const contents_2 = [
  "นักเขียนตกลงให้ www.hytexts.com มีอำนาจกระทำการแทนดังต่อไปนี้",
  "Hytexts.com มีอำนาจจัดทำ จำหน่าย โฆษณา งานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ และการทำธุรกรรมทางพาณิชย์อิเล็กทรอนิกส์ผ่านระบบเครือข่ายอินเตอร์เน็ตที่เกี่ยวกับกิจการในเชิงพาณิชย์ ด้วยการทำซ้ำงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ และนำส่งข้อมูลงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ให้แก่ลูกค้าบน www.hytexts.com เพื่อวัตถุประสงค์ในการจัดจำหน่ายในรูปแบบหนังสืออิเล็กทรอนิกส์ในประเทศไทยและในต่างประเทศ โดยผ่านทาง www.hytexts.com หรือ ผ่านทางแอปพลิชั่นในแพลตฟอร์มอื่นๆ ของทาง www.hytexts.com",
];
const contents_3 = [
  "1. นักเขียนตกลงนำส่งข้อมูลงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ที่ชอบด้วยกฎหมาย เข้าสู่ระบบ (Upload) ผ่านทางบัญชีผู้ใช้งานบน www.hytexts.com โดยผู้ส่งจะต้องลงลายมือชื่อหรือยอมรับเงื่อนไขของทาง www.hytexts.com เพื่อให้เราจัดจำหน่าย โฆษณา เผยแพร่ งานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ได้",
  "2. www.hytexts.com ตกลงว่าจะจัดจำหน่ายโดยวิธีโฆษณาและเผยแพร่งานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ ภายในเวลา 5 วัน นับตั้งแต่วันที่ได้รับข้อมูลหนังสืออิเล็กทรอนิกส์ และจะไม่แก้ไขเปลี่ยนแปลงหรือละเมิดลิขสิทธิ์ของเจ้าของเดิม ยกเว้นการแก้ไขในรูปแบบการจัดวางตัวหนังสือ เพื่อความเหมาะสมในการจัดจำหน่ายในรูปแบบหนังสืออิเล็กทรอนิกส์",
  "3. ในกรณีที่นักเขียนส่งต้นฉบับงานวรรณกรรมให้ที่เป็นไฟล์ Microsoft Words หรือ ไฟล์อื่นๆ ให้ www.hytexts.com จัดทำหนังสืออิเล็กทรอนิกส์เป็นไฟล์ ePub เพื่อจัดจำหน่ายใน www.hytexts.com ให้ถือว่าข้อมูลหนังสืออิเล็กทรอนิกส์นั้น เป็นงานของ www.hytexts.com ห้ามไม่ผู้ใดจัดจำหน่าย เว้นแต่จะได้รับอนุญาตจาก www.hytexts.com",
  "4. www.hytexts.com ตกลงว่าจะทำการเข้ารหัสหนังสืออิเล็กทรอนิกส์ เพื่อป้องกันการละเมิดลิขสิทธิ์ด้วยระบบของ Adobe Content Server 4 หรือเทียบเท่า เฉพาะหนังสืออิเล็กทรอนิกส์ที่ถูกตั้งราคาขาย แต่ยกเว้นหนังสืออิเล็กทรอนิกส์ที่จัดจำหน่ายสำหรับอุปกรณ์ Kindle",
  "5. นักเขียนตกลงว่าจะส่งตัวอย่างงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์โดยจะต้องมีเนื้อหาไม่น้อยกว่า 3 บท หรือ 10% ของจำนวนหน้าทั้งหมดของงานวรรณกรรม ซึ่งตัวอย่างงานวรรณกรรมนี้จะไม่ถูกเข้ารหัสหนังสืออิเล็กทรอนิกส์เพื่อจัดการลิขสิทธิ์ และจะถูกใช้เพื่อประชาสัมพันธ์ หรือ โฆษณางานวรรณกรรมเท่านั้น",
  "6. การจัดหน้า พิสูจน์อักษร ออกแบบปก และภาพประกอบ นักเขียนต้องเป็นผู้จัดทำด้วยตนเอง",
];
const contents_4 = [
  "www.hytexts.com มีหน้าที่ตรวจรูปแบบ การจัดวางปก และข้อมูลหนังสืออิเล็กทรอนิกส์ให้เหมาะสมกับการจัดจำหน่ายเท่านั้น โดยไม่มีหน้าที่ตรวจสอบ เนื้อหา เนื้อเรื่อง ภาพประกอบ ลิขสิทธิ์ หรือส่วนหนึ่งส่วนใด ของหนังสืออิเล็กทรอนิกส์ที่นักเขียนนำส่งข้อมูล ดังนั้นนักเขียนตกลงว่าจะไม่นำงานวรรณกรรมที่มีเนื้อหา เนื้อเรื่อง ภาพประกอบหรือส่วนหนึ่งส่วนใดดังต่อไปนี้ให้ www.hytexts.com จัดจำหน่าย โฆษณาและเผยแพร่ ใน www.hytexts.com",
  "1. งานวรรณกรรมที่มีเนื้อหาหมิ่นประมาท หรือแสดงความอาฆาตมาดร้าย พาดพิงสถาบันพระมหากษัตริย์ รัชทายาทราชวงศ์ หรือผู้สำเร็จราชการแทนพระองค์ เป็นอันขาด",
  "2. งานวรรณกรรมที่กล่าวโจมตี หรือวิพากษ์วิจารณ์ในทางเสียหายต่อศาสนา หรือคำสอนของศาสนาใดๆ ทุกศาสนา",
  "3. งานวรรณกรรมที่มีเนื้อหาหมิ่นประมาทหรือละเมิดสิทธิ์ส่วนบุคคลที่มีต่อบุคคลอื่นหรือหน่วยงานใด",
  "4. งานวรรณกรรมที่คัดลอกผลงาน หรือบทความบนเว็บไซต์ของบุคคลอื่น",
  "5. งานวรรณกรรมที่มีเนื้อหาละเมิดกฎหมาย หรือสนับสนุนกิจกรรมที่ผิดกฎหมาย",
  "6. งานวรรณกรรมที่มีเนื้อหามีภาพประกอบหรือภาพบุคคลอื่นที่มีลักษณะหมิ่นประมาทหรือละเมิดสิทธิ์ส่วนบุคคล",
  "7. งานวรรณกรรมที่มีเนื้อหามีการโฆษณาในวัตถุประสงค์ เพื่อใช้ในการโฆษณางานวรรณกรรมเดียวกันที่จัดจำหน่ายในที่อื่นนอกเหนือจาก www.hytexts.com",
  "8. งานวรรณกรรมที่มี ตัวอักษร หน้าปก หรือ ภาพประกอบที่ละเมิดลิขสิทธิ์ของบุคคลอื่น",
];
const contents_51 =
  "www.hytexts.com ตกลงจัดส่งค่าตอบแทนการขายหนังสืออิเล็กทรอนิกส์ให้แก่นักเขียน ในอัตราร้อยละ 65 ของราคาขายหนังสืออิเล็กทรอนิกส์ คูณจำนวนหนังสืออิเล็กทรอนิกส์ที่ลูกค้าชำระเงินเสร็จสมบูรณ์บน www.hytexts.com ทั้งหมด";
const contents_52 = [
  "1. นักเขียนเป็นผู้กำหนดราคาขายหนังสืออิเล็กทรอนิกส์เป็นสกุลเงิน US Dollar",
  "2. www.hytexts.com ตกลงจัดส่งค่าตอบแทนการขายหนังสืออิเล็กทรอนิกส์ให้แก่นักเขียน ในอัตราร้อยละ 45 ของราคาขายหนังสืออิเล็กทรอนิกส์ คูณจำนวนหนังสืออิเล็กทรอนิกส์ที่ลูกค้าชำระเงินเสร็จสมบูรณ์ผ่านทางระบบ In-app Purchased ของบริษัท Apple Inc โดยอ้างอิงจากอัตราแลกเปลี่ยนสกุลเงินจากทางบริษัท Apple Inc (บริษัท Apple Inc คิดค่าธรรมเนียมระบบ In-app Purchased 30% ของราคาขายหนังสืออิเล็กทรอนิกส์)",
];
const contents_53 = [
  "1. www.hytexts.com ตกลงจะแจ้งให้นักเขียนทราบผลการจำหน่ายหนังสืออิเล็กทรอนิกส์ทุกครั้งเมื่อมีการซื้อขายกับบุคคลภายนอกสำเร็จ โดยแจ้งผ่านทางชื่อบัญชีผู้ใช้งานของนักเขียนที่ลงทะเบียนไว้ที่ www.hytexts.com",
  "2. นักเขียนสามารถตรวจสอบรายละเอียดและยอดขายงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ ผ่านชื่อบัญชีผู้ใช้งานของนักเขียนที่ลงทะเบียนไว้ในระบบ",
  "3. สำหรับการขายผ่าน Apple In-app Purchased จะมีการนำส่งเงินค่าตอบแทนภายใน ระยะเวลา 60 วันหลังจากได้ทำการส่งรายงานยอดขายที่เกิดขึ้นให้กับนักเขียน",
  "4. www.hytexts.com จะนำส่งเงินค่าตอบแทนทุกวันที่ 20 ของแต่ละเดือน หรือวันทำการถัดไปหากติดวันหยุด โดยเข้าบัญชีข้อมูลธนาคารที่ลงทะเบียนไว้ในระบบ",
  "5. นักเขียนยินยอมให้หักภาษี ณ ที่จ่าย ตามอัตราที่ระบุในประมวลรัษฎากรจากค่าตอบแทนที่จ่ายทุกครั้ง โดย www.hytexts.com จะออกหนังสือรับรองการหักภาษี ณ ที่จ่าย และรวบรวมส่งมอบให้แก่นักเขียนในเดือนกรกฎาคม และมกราคมของทุกปี",
  "6. ในการชำระค่าตอบแทน นักเขียนจะรับผิดชอบค่าธรรมเนียมในการโอนเงินผ่านธนาคาร โดยwww.hytexts.com จะหักค่าธรรมเนียมในการโอนเงินผ่านธนาคาร ที่จ่ายตามอัตราที่ธนาคารระบุไว้จากค่าตอบแทนที่จ่ายทุกครั้ง",
];
const contents_6 =
  "ภายใต้ข้อบังคับแห่งข้อตกลงฉบับนี้ นักเขียนยังคงเป็นเจ้าของลิขสิทธิ์ในงานวรรณกรรมตามข้อตกลงนี้ทุกประการ";
const contents_7 =
  "ในการจัดทำงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ทุกครั้ง นักเขียนจะต้องระบุชื่อผู้สร้างสรรค์งานวรรณกรรม ชื่อเจ้าของลิขสิทธิ์ สำนักพิมพ์ วัน เดือน ปี ที่พิมพ์ ตราสัญลักษณ์ของ www.hytexts.com และโฆษณางานครั้งแรก  วันเดือนปีที่พิมพ์ และโฆษณางานครั้งปัจจุบันเป็นอย่างน้อย โดยระบุถ้อยคำว่า “ได้รับอนุญาตจากเจ้าของลิขสิทธิ์” และ “สงวนลิขสิทธิ์ พ.ศ. .....” และ “ อนุญาตให้จัดจำหน่ายใน www.hytexts.com เท่านั้น” ให้ปรากฏในส่วนที่สังเกตเห็นได้ชัดเจน";
const contents_8 = [
  "www.hytexts.com ตกลงว่าจะพยายามถึงที่สุดที่จะป้องกันการละเมิดลิขสิทธิ์งานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ โดย www.hytexts.com จะไม่รับผิดชอบต่อความเสียหายจากการละเมิดลิขสิทธิ์ของงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ที่เกิดจากกรณีดังต่อไปนี้",
  "1. การละเมิดลิขสิทธิ์ตัวอย่างงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ที่ถูกใช้เพื่อการประชาสัมพันธ์หรือไม่ถูกใช้ในเชิงพาณิชย์",
  "2. การทำลายการเข้ารหัสและระบบป้องกันการละเมิดลิขสิทธิ์ของงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์จากบุคคลอื่น",
  "3. การจับภาพหน้าจอแสดงผลจากคอมพิวเตอร์ส่วนบุคคล คอมพิวเตอร์แบบพกพา โทรศัพท์มือถือ และอุปกรณ์อ่านหนังสืออิเล็กทรอนิกส์ ที่แสดงผลหนังสืออิเล็กทรอนิกส์ของนักเขียน",
  "4. การคัดลอกข้อความ เนื้อหา หน้าปก และรูปภาพ หรือส่วนใดส่วนหนึ่ง ในงานวรรณกรรมรูปแบบหนังสืออิเล็กทรอนิกส์จากบุคคลอื่น",
  "5. การเจาะฐานข้อมูลหนังสืออิเล็กทรอนิกส์ของ www.hytexts.com จากบุคคลผู้ไม่หวังดี",
  "6. www.hytexts.com ขอสงวนสิทธิการรับผิดชอบต่อความเสียหายในกรณีที่ข้อมูลภายในเว็บสูญหายหรือข้อมูลผิดพลาดไม่ครบถ้วน อันเกิดจากความผิดพลาดของอุปกรณ์ของผู้ให้บริการ Web Hosting หรือจากเหตุสุดวิสัยอื่นใด แม้ www.hytexts.com มีการสำรองข้อมูลไว้",
];
const contents_9 = [
  "1. นักเขียนตกลงว่าจะใช้ความระมัดระวังในการนำส่งข้อมูลหนังสืออิเล็กทรอนิกส์ ในกรณีที่เกิดพิพาทหรือในการใช้สิทธิ์ทางศาลกับบุคคลที่สาม เนื่องจากข้อมูลต่างๆ ไปละเมิดลิขสิทธิ์ หมิ่นประมาท หรือมีเนื้อหา ดังที่ได้ระบุใน ข้อ (4) เป็นเหตุให้ www.hytexts.com ได้รับความเสียหาย หรือ www.hytexts.com ต้องชำระค่าเสียหายให้แก่บุคคลที่สาม นักเขียนยินยอมชำระค่าเสียหายให้แก่ www.hytexts.com ตามความเป็นจริง",
  "2. ในกรณีที่มีบุคคลอื่นละเมิดลิขสิทธิ์ในงานวรรณกรรมภายใต้ข้อตกลงนี้ นักเขียนตกลงมอบอำนาจให้ www.hytexts.com ในการร้องทุกข์ ฟ้องร้อง และดำเนินคดี โดยจะจัดทำหนังสือมอบอำนาจให้แก่ตัวแทนทันที",
];
const contents_10 =
  "ข้อตกลงฉบับนี้ให้อยู่ภายใต้บังคับแห่งกฎหมายไทย นักเขียนได้อ่านและเข้าใจข้อความในข้อตกลงโดยตลอดดีแล้ว";

export const contents_condition = [
  "โปรดอ่านทำความเข้าใจตามเงื่อนไขและข้อตกลงก่อนทำการลงทะเบียน หลังจากที่การลงทะเบียนเสร็จสมบูรณ์แล้ว ถือว่าท่านยอมรับเงื่อนไขและข้อตกลงระหว่างสมาชิก กับ บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด หรือ www.hytexts.com",
  "ทั้งสองฝ่ายตกลงทำข้อตกลงกันดังมีข้อความดังต่อไปนี้",
];
export const contents_ebook_2 = [
  "1. หนังสืออิเล็กทรอนิกส์ที่ถูกตั้งราคาขายใน www.hytexts.com จะถูกเข้ารหัสเพื่อจัดการลิขสิทธ์ (Digital Right Management) ยกเว้น หนังสืออิเล็กทรอนิกส์ที่จัดจำหน่ายแบบ DRM-Free",
  "2. สมาชิกหรือนักเขียนจะต้องสมัคร Adobe ID ก่อนที่จะดาวน์โหลดหนังสืออิเล็กทรอนิกส์ เพื่อที่จะสามารถนำไปอ่านในอุปกรณ์พกพาอื่นๆได้ เช่น iPad iPhone Android e-Reader Pc และ Mac",
  "3. สมาชิกหรือนักเขียนสามารถ Authorize อุปกรณ์พกพาได้สูงสุด 5 อุปกรณ์ ต่อ 1 Adobe ID",
  "4. หนังสืออิเล็กทรอนิกส์ และ ตัวอย่างหนังสืออิเล็กทรอนิกส์ ที่ถูกจัดจำหน่ายโดย www.hytexts.com ห้ามทำซ้ำ ดัดแปลง แก้ไข เผยแพร่ จัดจำหน่าย โดยไม่ได้รับอนุญาตจาก www.hytexts.com",
];
export const contents_hytextsCredit = [
  "1. Hytexts Credit เป็นสื่อกลางสำหรับใช้ชำระค่าสินค้าแทนเงินสดใน www.hytexts.com และแอปพลิเคชัน Hytexts เท่านั้น",
  "2. Hytexts Credit ที่สมาชิกสั่งซื้อไม่มีวันหมดอายุ",
  "3. Hytexts Credit ที่สมาชิกได้รับตอนสมัครสมาชิกใหม่หรือจากโปรโมชันอื่นๆ ไม่มีวันหมดอายุ และสามารถนำมาใช้ชำระค่าสินค้าใน www.hytexts.com และแอปพลิเคชัน Hytexts ได้",
  "4. Hytexts Credit เมื่อสั่งซื้อและได้รับเข้าบัญชีของสมาชิกหรือนักเขียนแล้ว ไม่สามารถแลกเป็นเงินสดหรือโอนให้สมาชิกท่านอื่นได้",
  "5. ในการชำระเงินด้วย Hytexts Credit จะมีการตรวจสอบรหัสผ่าน (Password) ทุกครั้ง หากสมาชิกตกลงให้ถือว่าการกระทำนั้นมีผลสมบูรณ์ ยินยอมว่าได้ตกลงชำระค่าสินค้าดังกล่าวด้วย Hytexts Credit โดยชอบด้วยกฎหมายแล้ว",
  "6. www.hytexts.com จะเติม Hytexts Credit เข้าบัญชีของสมาชิกหลังจากได้รับแจ้งผลการโอนเงินภายใน 2 ชั่วโมง เฉพาะเวลา 8.00-16.45น. (จันทร์ - เสาร์) ถ้านอกเหนือเวลาดังกล่าว Hytexts Credit จะเข้าบัญชีของสมาชิกในวันทำการถัดไป",
  "7. เงื่อนไขของ Hytexts Credit สามารถเปลี่ยนแปลงไปตามที่ www.hytexts.com กำหนด และ www.hytexts.com ขอสงวนสิทธิ์ในการเปลี่ยนแปลงเงื่อนไขได้ตามที่เห็นสมควร โดยจะแจ้งให้ทราบเมื่อมีการเปลี่ยนแปลง",
];
export const contents_member = [
  "1. การสมัครสมาชิกกับ www.hytexts.com ไม่ต้องเสียค่าใช้จ่ายใดๆทั้งสิ้น",
  "2. ผู้ที่ต้องการสมัครสมาชิก ต้องกรอกข้อมูลของตนเองที่เป็นจริงให้แก่ www.hytexts.com ให้ครบทุกข้อ เพื่อสิทธิประโยชน์ของท่านในการเข้าร่วมกิจกรรมของ www.hytexts.com หาก www.hytexts.com ตรวจสอบพบว่าท่านให้ข้อมูลเท็จ ทีมงานมีสิทธิ์ไม่ให้สิทธิ์การเป็นสมาชิกได้หรือยกเลิกการเป็นสมาชิกได้",
  "3. ผู้ใดแอบอ้าง หรือกระทำการใดๆ อันเป็นการละเมิดสิทธิ์ส่วนบุคคล โดยใช้ข้อมูลของผู้อื่นมาแอบอ้างสมัครสมาชิกเพื่อให้ได้สิทธิ์การสมาชิก ถือเป็นความผิด ต้องรับโทษตามที่กฎหมายกำหนดไว้",
  "4. เพื่อความเป็นส่วนตัวและความปลอดภัยในข้อมูลของท่านเอง www.hytexts.com ขอแจ้งให้ทราบว่า เป็นหน้าที่ของสมาชิกในการรักษาข้อมูลชื่อ Login และ Password โดยไม่บอกให้ผู้อื่นทราบ",
  "5. สมาชิกสามารถเปลี่ยนแปลงรหัสผ่าน (Password) ด้วยตนเองได้ตลอดระยะเวลาการใช้งาน โดยไม่ต้องแจ้งให้ www.hytexts.com ทราบ ทั้งนี้สมาชิกตกลงให้รหัสผ่าน (Password) ที่เปลี่ยนแปลงไปในแต่ละขณะดังกล่าว เป็นรหัสผ่านการใช้บริการของ www.hytexts.com ที่มีผลผูกพันสมาชิก ตามข้อกำหนดและเงื่อนไขการใช้บริการนี้ทุกประการ",
  "6. การกระทำการใดๆ โดยผ่านบริการ ไม่ว่ากรณีใดๆก็ตาม หากกระทำโดยใช้รหัสสมาชิกและรหัสผ่านของสมาชิกและ/หรือตรงตามวิธีการหรือเงื่อนไขที่บริษัทกำหนดไว้เกี่ยวกับการใช้บริการ www.hytexts.com แล้ว สมาชิกตกลงให้ถือว่าการกระทำนั้นมีผลสมบูรณ์ โดยสมาชิกไม่ต้องลงลายมือชื่อเป็นหลักฐานในเอกสารใดๆ อีก และยินยอมรับผิดชอบในการกระทำดังกล่าวเสมือนหนึ่งว่าสมาชิกเป็นผู้กระทำด้วยตนเอง ทั้งนี้ไม่ว่าจะเกิดขึ้นด้วยความทุจริตของบุคคลภายนอกหรือไม่ก็ตาม สมาชิกจะไม่เรียกร้องให้บริษัทต้องรับผิดชอบแต่อย่างใด",
  "7. รหัสผ่าน (Password) ถือเป็นความลับเฉพาะตัวของสมาชิกที่ต้องเก็บรักษาและปกปิดมิให้ผู้อื่นล่วงรู้ สมาชิกต้องเก็บรักษาไว้ด้วยตนเอง หากมีการใช้บริการรายการใดๆโดยใช้รหัสผ่าน (Password) ของสมาชิกแล้ว ให้ถือว่าถูกต้องสมบูรณ์มีผลผูกพันสมาชิกเสมือนสมาชิกใช้บริการในครั้งนั้นๆ ด้วยตนเอง ทั้งนี้ บริษัทไม่สามารถล่วงรู้รหัสผ่าน (Password) ของสมาชิกได้ ดังนั้น บริษัทจึงไม่ต้องรับผิดชอบในความเสียหายใดๆที่เกิดขึ้นในการใช้บริการ www.hytexts.com โดยรหัสผ่าน (Password) ของสมาชิก",
  "8. สมาชิกจำเป็นต้องตรวจสอบความถูกต้องในการทำรายการสั่งซื้อหนังสืออิเล็กทรอนิกส์ว่าถูกต้องหรือไม่ ก่อนที่จะทำการตกลงชำระเงิน ซึ่งถ้าหากเกิดข้อผิดพลาดจากการทำรายการสั่งซื้อหนังสืออิเล็กทรอนิกส์อันเนื่องมาจากความไม่ถูกต้องของรายการเนื่องมาจากความผิดพลาดของสมาชิกเอง ทาง www.hytexts.com จะไม่รับผิดชอบทั้งสิ้น",
  "9. หนังสืออิเล็กทรอนิกส์เมื่อชำระเงินแล้ว สมาชิกไม่สามารถขอคืนเงินได้",
  "10. หากมีความผิดพลาดของข้อมูลหรือเนื้อหาของหนังสืออิเล็กทรอนิกส์ที่สมาชิกได้สั่งซื้อแล้วนั้น หลังจากที่ได้ทำการแก้ไขเนื้อหาหรือข้อมูลแล้ว สมาชิกสามารถลบหนังสืออิเล็กทรอนิกส์เล่มนั้นๆ ที่หน้า My Library ของสมาชิก และดาวน์โหลดหนังสืออิเล็กทรอนิกส์เล่มนั้นๆ ใหม่อีกครั้ง",
  "11. ถ้า www.hytexts.com พบว่าสมาชิกทำรายการสั่งซื้อหนังสืออิเล็กทรอนิกส์หรือ Hytexts Credit โดยชำระเงินผ่านบัญชีธนาคารหรือบัตรเครดิต โดยไม่มีการชำระเงินจริงเกิดขึ้นหลายครั้ง เพื่อป้องกันการสร้างปัญหาให้กับระบบของ www.hytexts.com ทีมงานมีสิทธิ์ยกเลิกความเป็นสมาชิกได้ทันที",
  "12. ถ้า www.hytexts.com พบว่าสมาชิกนำหนังสืออิเล็กทรอนิกส์และตัวอย่างหนังสืออิเล็กทรอนิกส์ของ www.hytexts.com ไปทำซ้ำ ดัดแปลง แก้ไข เผยแพร่ หรือจัดจำหน่าย โดยไม่ได้รับอนุญาตจาก www.hytexts.com ทีมงานมีสิทธิ์ยกเลิกความเป็นสมาชิกได้โดยทันที",
  "13. หนังสืออิเล็กทรอนิกส์ที่สมาชิกสั่งซื้อไป ห้ามทำซ้ำหรือดัดแปลง ห้ามให้เช่าต้นฉบับหรือสำเนางาน ห้ามเผยแพร่งานต่อสาธารณชน หากผู้ใดกระทำการดังกล่าว ถือเป็นการละเมิดลิขสิทธิ์ ต้องรับโทษอาญาและจ่ายค่าเสียหายแก่เจ้าของลิขสิทธิ์และ www.hytexts.com ด้วย หากผู้ใดรู้อยู่แล้วหรือมีเหตุอันควรรู้ว่างานใดได้ทำขึ้นโดยละเมิดลิขสิทธิ์ของผู้อื่น กระทำการต่อไปนี้แก่งานนั้นเพื่อหากำไร ให้ถือว่าผู้นั้นกระทำละเมิดลิขสิทธิ์ด้วย ได้แก่",
  "13.1 ขาย มีไว้เพื่อขาย เสนอขาย ให้เช่า เสนอให้เช่า ให้เช่าซื้อ หรือเสนอให้เช่าซื้อ",
  "13.2 เผยแพร่ต่อสาธารณชน",
  "13.3 แจกจ่ายในลักษณะที่อาจก่อให้เกิดความเสียหายแก่เจ้าของลิขสิทธิ์",
  "14. สมาชิกจะต้องไม่โพสต์ข้อความที่มีเนื้อหาดังต่อไปนี้ที่ www.hytexts.com",
  "14.1 ไม่โพสต์ข้อความซึ่งพาดพิงหรือลบหลู่ สถาบันพระมหากษัตริย์",
  "14.2. ไม่โพสต์ข้อมูลหยาบคาบ หมิ่นประมาณ หรือสร้างความเสียหายต่อบุคคลหนึ่งบุคคลใด",
  "15. www.hytexts.com จะไม่รับผิดชอบต่อความสูญเสียในด้านข้อมูลสมาชิก ข้อมูลการสั่งซื้อ และข้อมูลทุกๆอย่างภายในเว็บไซต์หากมีการสูญหาย จากการถูกจารกรรมความผิดพลาดจากระบบของเครื่องแม่ข่ายที่เก็บข้อมูลหรือความผิดพลาดที่เกิดจากระบบเว็บไซต์ทั้งสิ้น",
  "16. หากพบว่าสมาชิกกระทำการที่ผิดเงื่อนไขและข้อตกลงกับ www.hytexts.com ทีมงานมีสิทธิ์ที่จะยกเลิกความเป็นสมาชิกได้โดยทันที",
  "17. ในกรณีที่ดาวน์โหลดหนังสืออิเล็กทรอนิกส์แบบ DRM-free สมาชิกตกลงให้ฝังข้อมูล ชื่อ-นามสกุล อีเมล์ รหัสสั่งซื้อ และข้อมูล IP ลงไปในข้อมูลหนังสืออิเล็กทรอนิกส์",
];

export const breakTheLaw = [
  "หากพบว่ามีผลงานที่เข้าข่ายผิดกฎหมาย สำนักงานจะดำเนินการระงับการเผยแพร่และแจ้งให้เจ้าของผลงานทราบ เพื่อ",
  "ดำเนินการแก้ไขต่อไป และหากพบว่าเจ้าของผลงานมีการส่งผลงานที่ผิดกฎหมายเข้ามาซ้ำซาก สำนักงานจะดำเนิน",
  "การระงับบัญชีผู้ใช้ของเจ้าของผลงานต่อไป ผู้ที่ประสงค์จะให้สำนักงานในฐานะผู้ให้บริการดำเนินการต่อเนื้อหาที่เข้า",
  "ข่ายผิดกฎหมาย สามารถดำเนินการแจ้งสำนักงานได้พร้อมเอกสารหลักฐานประกอบแล้วส่งมาที่",
];

export const content_contact = [
  "เลขที่ 3 อาคารพร้อมพันธุ์ 3 ชั้นที่ 7 ห้องเลขที่ 701-702 ซอยลาดพร้าว 3 ถนนลาดพร้าว",
  "แขวงจอมพล เขตจตุจักร กทม 10900",
  "โทร. 02-024-6690",
  "Customer Service : 089-134-7470",
  "E-mail : contact@hytexts.com",
  "ช่วงเวลาทำการ: จันทร์-ศุกร์ 09:00-18:00 น.",
  "",
  "บริษัทจะดำเนินการและตอบกลับภายใน 7 วันทำการ",
];

export const content_rate = [
  "บริษัทฯ มีหลักเกณฑ์ในการจัดระดับการนำเสนอเนื้อหาที่เหมาะสมต่อผู้ใช้บริการแต่ละกลุ่ม โดยคำนึงถึง ปัจจัยหลักใน",
  "การจัดอันดับขายดี คือ ยอดขาย และ ปัจจัยหลักในการจัดระดับหนังสือที่คุณน่าจะสนใจ ขึ้นกับความเกี่ยวข้องของ",
  "หนังสือที่ผู้ใช้บริการมี ซึ่งแพลตฟอร์มจะแบ่งเนื้อหาออกเป็น 3 ประเภท โดย",
  "- ประเภทแรก คือ เนื้อหาทั่วไปที่สามารถเข้าถึงได้บนหน้าเว็บไซต์",
  "- ประเภทสอง คือ เนื้อหาที่เหมาะกับผู้ใช้งานที่มีอายุ 18 ปีขึ้นไป โดยจะมีสัญลักษณ์ 18+ หรือ 18 ปีขึ้นไป",
  "- ประเภทสาม คือ เนื้อหาที่เหมาะกับผู้ใช้งานบางกลุ่ม ซึ่งจะจำกัดการแสดงผลให้เฉพาะผู้ใช้บางกลุ่มเท่านั้น เช่น  หนังสือรุ่น เป็นต้น",
  "ทั้งนี้ ผลงานใดจะอยู่ในระดับ/ประเภทไหน ขึ้นกับการตรวจสอบและพิจารณาของเจ้าหน้าที่ โดยจะพิจารณาจากเนื้อหาของผลงานนั้นๆ",
];

const termOfUse = () => {
  return (
    <div className="side">
      <p className="side-title-c">เงื่อนไขการใช้บริการ</p>
      {contents_termOfUse.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <p className="side-title">หนังสืออิเล็กทรอนิกส์ (E-BOOK)</p>
      {contents_ebook.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <p className="side-title">นักเขียน</p>
      <p className="side-content-lessBottom">
        {contents_writer}
        <br />
      </p>
      <p className="side-title">1. ผลงานลิขสิทธิ์</p>
      <p className="side-content-lessBottom">
        {contents_1}
        <br />
      </p>
      <p className="side-title">2. เงื่อนไขการอนุญาต</p>
      {contents_2.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <p className="side-title">3. การจัดทำหนังสืออิเล็กทรอนิกส์</p>
      {contents_3.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <p className="side-title">4. ข้อกำหนดเนื้อหาของงานวรรณกรรม</p>
      {contents_4.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <p className="side-title">5. ค่าตอบแทน</p>
      <p className="side-title" style={{ marginLeft: "43px" }}>
        5.1 ค่าตอบแทนการขายผ่าน www.hytexts.com
      </p>
      <p className="side-content-lessBottom">
        {contents_51}
        <br />
      </p>
      <p className="side-title" style={{ marginLeft: "43px" }}>
        5.2 ค่าตอบแทนการขายผ่าน Apple In-app Purchased
      </p>
      {contents_52.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <p className="side-title" style={{ marginLeft: "43px" }}>
        5.3 การจ่ายค่าตอบแทน
      </p>
      {contents_53.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <p className="side-title">6. สิทธิ์เจ้าของลิขสิทธิ์</p>
      <p className="side-content-lessBottom">
        {contents_6}
        <br />
      </p>
      <p className="side-title">
        7. การระบุชื่อผู้สร้างสรรค์ เจ้าของลิขสิทธิ์ และข้อมูลอื่นๆ
      </p>
      <p className="side-content-lessBottom">
        {contents_7}
        <br />
      </p>
      <p className="side-title">8. ขอบเขตการรับผิดชอบต่อความเสียหาย</p>
      {contents_8.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <p className="side-title">9. การต่อสู้คดี</p>
      {contents_9.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <p className="side-title">10. กฎหมายที่ใช้บังคับ</p>
      <p className="side-content-lessBottom">
        {contents_10}
        <br />
      </p>
      <p className="side-title-cc">
        เงื่อนไขและข้อตกลงในการสมัครเป็นสมาชิกกับ www.hytexts.com
      </p>
      {contents_condition.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <p className="side-title">หนังสืออิเล็กทรอนิกส์ (E-BOOK)</p>
      {contents_ebook_2.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <p className="side-title">HYTEXTS CREDIT</p>
      {contents_hytextsCredit.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <p className="side-title">สมาชิกทั่วไป</p>
      {contents_member.map((e, index) =>
        index === 13 ||
        index === 14 ||
        index === 15 ||
        index === 17 ||
        index === 18 ? (
          <p className="side-content-lessBottom" style={{ marginLeft: "59px" }}>
            {e}
            <br />
          </p>
        ) : (
          <p className="side-content-lessBottom">
            {e}
            <br />
          </p>
        )
      )}

      <p className="side-title">
        ขั้นตอนการดำเนินการต่อเนื้อหาที่เข้าข่ายผิดกฎหมาย
      </p>
      {breakTheLaw.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      <br />

      <p className="side-content-lessBottom" style={{ fontWeight: 600 }}>
        บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด
      </p>
      {content_contact.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}

      <p className="side-title">
        การจัดระดับการนำเสนอเนื้อหาที่เหมาะสมต่อผู้ใช้บริการแต่ละกลุ่ม
      </p>
      {content_rate.map((e) => (
        <p className="side-content-lessBottom">
          {e}
          <br />
        </p>
      ))}
      {/* // */}

      <p className="side-title">
        การจำกัดพื้นที่ข้อมูลและการละเมิดสิทธิส่วนบุคคล
      </p>
      <p className="side-content-lessBottom">
        การพยายามลักลอบเข้าไปยังพื้นที่ข้อมูลที่ไม่ได้รับอนุญาต
        ถือเป็นการละเมิดสิทธิส่วนบุคคล
        และหากพิสูจน์ได้ว่ามีบุคคลใดนำข้อมูลส่วนบุคคลของผู้อื่นไปใช้โดยมิชอบ
        หรือกระทำการใดๆ กับข้อมูลทำให้ เกิดความเสียหายตามมา
        บุคคลผู้นั้นจะถูกดำเนินคดีตามกฎหมายถึงที่สุด
      </p>

      <p className="side-title">การให้ข้อมูลและการใช้ภาษา</p>
      <p className="side-content-lessBottom">
        บางครั้งผู้ใช้บริการอาจมีการให้ข้อมูล ส่งต่อ หรือสอบถาม
        โดยส่งมายังเจ้าหน้าที่ของบริษัทฯ เขียนข้อวิจารณ์เพื่อขึ้นเว็บไซต์
        หรือส่งอีเมล์ต่อไปยังผู้อื่น
        ทั้งนี้ผู้ใช้บริการยืนยันว่าจะไม่ใช้ภาษาที่ไม่สุภาพ ส่อเสียด ข่มขู่
        ทำให้เกิดจดหมายลูกโซ่ หรือการใช้ที่ผิดวัตถุประสงค์
        และไม่ส่งข้อมูลที่มีโปแกรมไวรัส นอกจากนี้
        ผู้ใช้บริการยืนยันที่จะให้ข้อมูลที่เป็นจริง ไม่มีการแอบอ้างชื่อบุคคล
        องค์กร หรือข้อมูลใดที่จะทำให้เกิดความเข้าใจผิดกับผู้อ่าน
        ในกรณีที่ข้อมูลผ่านการตรวจสอบจากเจ้าหน้าที่ของบริษัทฯ
        ให้ปรากฎอยู่ในเว็บไซต์มิได้หมายความว่า บริษัทฯ
        ยืนยันความถูกต้องของเนื้อหาหรือข้อมูล
        แต่หากผ่านการพิจารณาว่ามีการใช้ภาษาที่เหมาะสมในระดับหนึ่งเท่านั้น
      </p>
      <p className="side-title">มาตรการดูแลผู้ใช้บริการ</p>
      <p className="side-content-lessBottom">
        มาตรการดูแลผู้ใช้บริการซึ่งได้รับความเสียหายจากการใช้บริการเป็นไปตามนโยบายของบริษัทฯ
      </p>

    
    </div>
  );
};

const TermOfUse = () => {
  return <>{termOfUse()}</>;
};

export default TermOfUse;
