export const APP_LOAD = "APP_LOAD";
export const REDIRECT = "REDIRECT";
export const SCREEN_SIZE_CHANGE = "SCREEN_SIZE_CHANGE";
export const SHOW_FLASHSALE = "SHOW_FLASHSALE";
export const IS_ADD_CART = "IS_ADD_CART";
export const NAV_MODAL = "NAV_MODAL";
export const NAV_MODAL_MODE = "NAV_MODAL_MODE";
export const FLASH_SALE_EXP = "FLASH_SALE_EXP";
export const COVER_ARR = "COVER_ARR";

export const ADD_CART = "ADD_CART";
export const REMOVE_CART = "REMOVE_CART";
export const RECEIVE_MEMBER_CART = "RECEIVE_MEMBER_CART";
export const REMOVE_ALL_CART = "REMOVE_ALL_CART";

export const RECEIVE_OS_NAME = "RECEIVE_OS_NAME";
export const RECEIVE_BROWSER_NAME = "RECEIVE_BROWSER_NAME";
export const SHOW_LOADING = "SHOW_LOADING";

export const REQUEST_GUEST_TOKEN = "REQUEST_GUEST_TOKEN";
export const RECEIVE_GUEST_TOKEN = "RECEIVE_GUEST_TOKEN";

export const RECEIVE_ERROR = "RECEIVE_ERROR_RESPONSE";
export const RECEIVE_EBOOK_DATA = "RECEIVE_EBOOK_DATA";
export const REQUEST_EBOOK_DATA = "REQUEST_EBOOK_DATA";

export const REQUEST_LOGIN = "REQUEST_LOGIN";
export const REQUEST_EXIST_ACCOUNT = "REQUEST_EXIST_ACCOUNT";
export const RECEIVE_LOGIN_TOKEN = "RECEIVE_LOGIN_TOKEN";
export const LOG_OUT = "LOG_OUT";

export const MESSAGE_TYPE_ERROR = "MESSAGE_TYPE_ERROR";
export const MESSAGE_TYPE_WARNING = "MESSAGE_TYPE_WARNING";
export const MESSAGE_TYPE_INFO = "MESSAGE_TYPE_INFO";

export const SHOW_MESSAGE = "SHOW_MESSAGE";
export const DISMISS_MESSAGE = "DISMISS_MESSAGE";

export const RECEIVE_MEMBER_INFO = "RECEIVE_MEMBER_INFO";
