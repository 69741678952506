import {
  SHOW_LOADING,
  RECEIVE_ERROR,
  REDIRECT,
  SCREEN_SIZE_CHANGE,
  SHOW_FLASHSALE,
  IS_ADD_CART,
  NAV_MODAL,
  NAV_MODAL_MODE,
  FLASH_SALE_EXP,
  COVER_ARR,
  RECEIVE_BROWSER_NAME,
  RECEIVE_OS_NAME,
} from "../Constants/actionTypes";
import moment from "moment";

const INITIAL_STATE = {
  browserName: "",
  OSName: "",
  isLoading: false,
  redirectURL: "",
  screensize: { width: 0, height: 0 },
  showFlashSale: {
    isShow: false,
    discount: 0,
    offsetTop: 900,
    ebook: {},
  },
  isAddCart: false, //addCartBtn on FlashSale bar
  navModal: false,
  navModalMode: {
    loginModal: 0,
    registerModal: 0,
    registerEmailModal: 0,
  },
  flashSaleExp: moment().unix() + 1000,
  coverArr: [],
};
const Page = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_BROWSER_NAME:
      return Object.assign({}, state, {
        browserName: action.browserName,
      });
    case RECEIVE_OS_NAME:
      return Object.assign({}, state, {
        OSName: action.OSName,
      });
    case SHOW_LOADING:
      return Object.assign({}, state, {
        isLoading: action.isLoading,
      });
    case RECEIVE_ERROR:
      return Object.assign({}, state, {
        isShowAlert: action.isShowAlert,
        errorcode: action.errorcode,
        message: action.message,
      });
    case REDIRECT:
      return Object.assign({}, state, {
        redirectURL: action.redirectURL,
      });
    case SCREEN_SIZE_CHANGE:
      return Object.assign({}, state, {
        screensize: action.screensize,
      });
    case SHOW_FLASHSALE:
      return Object.assign({}, state, {
        showFlashSale: action.showFlashSale,
      });
    case IS_ADD_CART:
      return Object.assign({}, state, {
        isAddCart: action.isAddCart,
      });
    case NAV_MODAL:
      return Object.assign({}, state, {
        navModal: action.navModal,
      });
    case NAV_MODAL_MODE:
      return Object.assign({}, state, {
        navModalMode: action.navModalMode,
      });
    case FLASH_SALE_EXP:
      return Object.assign({}, state, {
        flashSaleExp: action.flashSaleExp,
      });
    case COVER_ARR:
      return Object.assign({}, state, {
        coverArr: action.coverArr,
      });
    default:
      return state;
  }
};
export default Page;
