import React, { useRef, useState, useEffect } from "react";
import { Row, Col, Modal, Input, message } from "antd";
import { addWishlist, fetchWishlist, deleteWishlist } from "../../Actions/Ebook";
import authen from "../../Utilitys/Authentication";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import * as Scroll from "react-scroll";
var scroller = Scroll.scroller;
export const ReviewWishlistShareGift = props => {
  const [isWishlist, setIsWishlist] = useState(false);
  const [isSharing, setIsSharing] = useState(false);
  const textAreaRef = useRef(null);
  message.config({
    top: "15%",
    duration: 2,
    maxCount: 1
  });

  const handleReview = () => {
    scroller.scrollTo("reviewElement", {
      duration: 1000,
      delay: 10,
      smooth: true,
      offset: 50
    });
  };

  const handleWishlist = async () => {
    if (!isWishlist) {
      let wl_post = await addWishlist(props.bookUID);
      if (wl_post.status) {
        message.success(
          <span className="spanSubcatFollow">เพิ่ม "{props.bookTitle}" ลงใน Wishlist</span>
        );
      }
    } else {
      let wl_delete = await deleteWishlist(props.bookUID);
      if (wl_delete.status) {
        message.error(
          <span className="spanSubcatFollow">ลบ "{props.bookTitle}" ออกจาก Wishlist</span>
        );
      }
    }
    setIsWishlist(!isWishlist);
  };

  const handleShare = () => {
    setIsSharing(true);
  };

  const copyToClipboard = () => {
    textAreaRef.current.select();
    textAreaRef.current.input.setSelectionRange(0, textAreaRef.current.input.value.length);
    document.execCommand("copy");
    message.success(
      <span style={{ fontFamily: "Prompt", fontSize: "16px" }}>คัดลอกลิงก์แล้ว</span>
    );
  };

  useEffect(() => {
    const getWishlist = async () => {
      if (props.memberUID !== "") {
        let wl = await fetchWishlist();
        if (wl.status) {
          let filter_wl =
            wl.data && wl.data.ebooks
              ? wl.data.ebooks.filter(el => el.bookUID === props.bookUID)
              : [];
          let isWl = false;
          if (filter_wl.length) {
            isWl = true;
          }
          setIsWishlist(isWl);
        }
      }
    };
    getWishlist();
    // eslint-disable-next-line
  }, [props.bookUID]);
  // const url = `${authen.URL}/ebook/${props.bookUID}`;
  const url = `https://www.hytexts.com/shareproduct.php?code=${props.bookUID}`;
  const fb_app_id = "676572462682428";
  return (
    <div>
      <Row type="flex" justify="center">
        <Col span={5} align="center">
          {/* <a href="#review"> */}
          <button
            className="setbtn"
            onClick={() => {
              handleReview();
            }}
          >
            <img
              style={{ width: "27px", height: "24px", imageRendering: "-webkit-optimize-contrast" }}
              src="/images/book/review.png"
              alt={`review`}
            />
            <p>Review</p>
          </button>
          {/* </a> */}
        </Col>
        {!props.isPurchase && (
          <Col span={5} align="center">
            <button
              className="setbtn"
              onClick={() => {
                if (props.memberUID !== "") handleWishlist();
                else props.showLoginModal();
              }}
            >
              <img
                style={
                  isWishlist
                    ? { width: "26px", height: "24px", imageRendering: "-webkit-optimize-contrast" }
                    : {
                        width: "26px",
                        height: "24px",
                        imageRendering: "-webkit-optimize-contrast"
                      }
                }
                src={isWishlist ? "/images/book/wishlist-full.png" : "/images/book/wishlist.png"}
                alt={`wishlist`}
              />
              <p>Wishlist</p>
            </button>
          </Col>
        )}
        <Col span={5} align="center">
          <button
            className="setbtn"
            onClick={() => {
              handleShare();
            }}
          >
            <img
              style={{ width: "24px", height: "24px", imageRendering: "-webkit-optimize-contrast" }}
              src="/images/book/share.png"
              alt={`share`}
            />
            <p>Share</p>
          </button>
        </Col>
        {/* <Col span={5} align="center">
          <button className="setbtn">
            <img
              style={{ width: "35px", height: "24px" }}
              src="/images/book/gift.svg"
              alt={`gift`}
            />
            <p>Gift</p>
          </button>
        </Col> */}
      </Row>
      <Modal
        visible={isSharing}
        style={{ top: window.innerHeight / 3 }}
        footer={null}
        maskClosable={true}
        onCancel={() => {
          setIsSharing(false);
        }}
        width={488}
      >
        <div className="shareBox">
          <h3 className="BaiJamJuree14" style={marginBottom10}>
            แชร์
          </h3>
          <Row>
            <Col xs={8} sm={5} align="center">
              <a
                href={`https://www.facebook.com/dialog/share?app_id=${fb_app_id}&href=${url}%26feature%3Dshare&display=popup`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/logos/facebook.png" style={shareImg} alt="" />
                <h3 className="BaiJamJuree14">Facebook</h3>
              </a>
            </Col>
            <Col xs={8} sm={4} align="center">
              <a
                href={`https://social-plugins.line.me/lineit/share?url=${url}%26feature%3Dshare`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/logos/lineCircle.png" style={shareImg} alt="" />
                <h3 className="BaiJamJuree14">LINE</h3>
              </a>
            </Col>
            <Col xs={8} sm={4} align="center">
              <a
                href={`https://twitter.com/intent/tweet?url=${url}`}
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src="/images/logos/twitter.png" style={shareImg} alt="" />
                <h3 className="BaiJamJuree14" style={marginBottom16}>
                  Twitter
                </h3>
              </a>
            </Col>
          </Row>
          <Row type="flex" align="middle">
            <Col xs={18} sm={20}>
              {document.queryCommandSupported("copy") && (
                <Input
                  ref={textAreaRef}
                  style={{ color: "black", backgroundColor: "white", cursor: "text" }}
                  value={`${authen.URL}${props.location.pathname}`}
                  // value={`${url}`}
                />
              )}
            </Col>
            <Col xs={6} sm={4} align="center" style={moveDown}>
              <button style={blueBtn} onClick={copyToClipboard}>
                คัดลอก
              </button>
            </Col>
          </Row>
        </div>
      </Modal>
    </div>
  );
};
const moveDown = { transform: "translate(0px, 2px)" };
const blueBtn = {
  color: "#0770ff",
  backgroundColor: "white",
  border: "none",
  fontFamily: "Bai JamJuree",
  fontSize: "14px",
  fontWeight: "600"
};
const marginBottom10 = { marginBottom: "10px" };
const marginBottom16 = { marginBottom: "16px" };
const shareImg = { width: "42px", height: "42px", marginBottom: "4px" };

const mapStateToProps = state => {
  return {
    Authentication: state.Authentication
  };
};

export default withRouter(connect(mapStateToProps)(ReviewWishlistShareGift));
