import React from "react";
import { Link } from "react-router-dom";

export const Tags = props => {
  if (props.tags && props.tags.length) {
    return (
      <div className="tags">
        {props.tags.map((tag, index) => {
          return (
            <Link to={`/tag/${tag}`} target="_blank">
              <p className="tagBox">{"#" + tag + " "}</p>
            </Link>
          );
        })}
      </div>
    );
  } else {
    return "";
  }
};
export default Tags;
