import React, { useState, useRef } from "react";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { useSelector } from "react-redux";
import { Button, Modal } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";

const PaymentButton = ({ selectedForm, memberInfo, Page, history }) => {
  const payment = useSelector(state => state.Payment);
  const [notEnoughCoinModal, setNotEnoughCoinModal] = useState(false);
  const [confirmBefore, setConfirmBefore] = useState(false);
  const notEnoughCoin = () => {
    setNotEnoughCoinModal(true);
  };
  const onClickCancel = () => {
    setNotEnoughCoinModal(false);
  };

  const onClickConfirmCancel = () => {
    setConfirmBefore(false);
  };

  return (
    <div style={{ textAlign: "center" }}>
      <p className="price-label">ยอดที่ต้องชำระ</p>
      {selectedForm !== "hticoin_form" ? (
        <Button
          className="price-total"
          type="primary"
          htmlType="submit"
          form={selectedForm}
          disabled={selectedForm.trim() !== "" ? false : true}
        >
          ฿{payment.totalValue}
        </Button>
      ) : memberInfo.coinData.remainCoin >= payment.totalValue ? (
        <button
          className="price-total"
          type="primary"
          disabled={selectedForm.trim() !== "" ? false : true}
          onClick={() => {
            setConfirmBefore(true);
          }}
        >
          ฿{payment.totalValue}
        </button>
      ) : (
        // <Button
        //   className="price-total"
        //   type="primary"
        //   htmlType="submit"
        //   form={selectedForm}
        //   disabled={selectedForm.trim() !== "" ? false : true}
        // >
        //   ฿{payment.totalValue}
        // </Button>
        <button
          className="price-total"
          onClick={() => {
            notEnoughCoin();
          }}
          disabled={selectedForm.trim() !== "" ? false : true}
        >
          ฿{payment.totalValue}
        </button>
      )}
      <br />
      <Link to={"/"} className="continue-btn">
        เลือกซื้อเพิ่ม
      </Link>
      {notEnoughCoinModal ? (
        <Modal
          visible={notEnoughCoinModal}
          footer={null}
          style={{ top: "15%" }}
          maskClosable={false}
          onCancel={() => {
            onClickCancel();
          }}
          width={Page.screensize.width < 370 ? "95%" : 370}
        >
          <div className="notEnoughCoin">
            <p className="title">ไม่สามารถชำระเงินได้</p>
            <p className="subtitle">เนื่องจาก Hytexts Coin ไม่เพียงพอ</p>
            <div className="imgAndBtn">
              <img
                src="/images/payment/coin5000.svg"
                style={{ width: "133px", height: "103px", marginBottom: "32px" }}
                alt=""
              />
              <br />
              <button
                onClick={() => {
                  onClickCancel();
                }}
                className="cancelButton"
              >
                ยกเลิก
              </button>
              <button
                onClick={() => {
                  history.push("/hytextscoin");
                }}
                className="okButton"
              >
                ซื้อ Coin
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
      {confirmBefore ? (
        <Modal
          visible={confirmBefore}
          footer={null}
          style={{ top: "15%" }}
          maskClosable={false}
          onCancel={() => {
            onClickConfirmCancel();
          }}
          width={Page.screensize.width < 370 ? "95%" : 370}
        >
          <div className="notEnoughCoin">
            <p className="title">ยืนยันการสั่งซื้ออีบุ๊ค</p>
            <p className="subtitle">ด้วย Hytexts Coin จำนวน {payment.totalValue} เหรียญ</p>
            <div className="imgAndBtn">
              <img
                src="/images/payment/coin5000.svg"
                style={{ width: "133px", height: "103px", marginBottom: "32px" }}
                alt=""
              />
              <br />
              <button
                onClick={() => {
                  onClickConfirmCancel();
                }}
                className="cancelButton"
              >
                ยกเลิก
              </button>
              <button htmlType="submit" form="hticoin_form" className="okButton">
                ซื้ออีบุ๊ค
              </button>
            </div>
          </div>
        </Modal>
      ) : (
        ""
      )}
    </div>
  );
};

const mapStateToProps = state => {
  return {
    memberInfo: state.Member.memberInfo,
    Page: state.Page
  };
};

PaymentButton.propTypes = {
  selectedForm: PropTypes.string.isRequired
};

export default withRouter(connect(mapStateToProps)(PaymentButton));
