import {
  ADD_CART,
  REMOVE_CART,
  RECEIVE_MEMBER_CART,
  REMOVE_ALL_CART
} from "../Constants/actionTypes";
import { ACTION_SHOW_LOADING } from "../Actions/Page";
import { fetch } from "../Utilitys/ApiRequest";
import { shoppingCartItem } from "../Constants/Model";
import { showError } from "./Message";
import authenUtility from "../Utilitys/Authentication";

export const actionAddCart = cartItem => ({
  type: ADD_CART,
  cartItem: cartItem
});
export const actionRemoveCart = cartItem => ({
  type: REMOVE_CART,
  cartItem: cartItem
});
export const actionReceiveMemberCart = cartItem => ({
  type: RECEIVE_MEMBER_CART,
  cartItem: cartItem
});

export const actionRemoveAllCart = allCart => {
  return async dispatch => {
    let response = await fetch("patch", `cart`, { ebook: allCart });
    if (response.status) {
      dispatch({
        type: REMOVE_ALL_CART
      });
      dispatch(fetchShoppingCart());
    }
  };
};

export const makeHCpurchase = async contentData => {
  return await fetch("post", "payment/hticoin", {
    contentData: contentData,
    contentType: "ebook",
    paymentType: "HC"
  });
};

export const fetchShoppingCart = () => {
  return async dispatch => {
    if (authenUtility.isLogin()) {
      let response = await fetch("get", "cart");
      if (response.status) {
        if (response.data && response.data.carts) {
          fetchAddCartFromCookie(dispatch, response.data.carts);
          dispatch(actionReceiveMemberCart(createModelItem(response.data.carts, shoppingCartItem)));
        }
      } else {
        if (response.message !== "No Data found") {
          dispatch(showError(response));
        } else {
          fetchAddCartFromCookie(dispatch);
        }
      }
    } else {
      let cart = JSON.parse(localStorage.getItem("shoppingCart"));
      if (cart) dispatch(actionReceiveMemberCart(createModelItem(cart, shoppingCartItem)));
    }
    dispatch(ACTION_SHOW_LOADING(false));
  };
};

export const fetchAddCartFromCookie = async (dispatch, cartOrigin = []) => {
  let cartItems = JSON.parse(localStorage.getItem("shoppingCart"));
  if (cartItems !== undefined && cartItems && cartItems.length) {
    // cartItems.forEach(async (el, index) => {
    //   let cartUnique = cartOrigin.filter(b => {
    //     return b.bookUID !== el.bookUID;
    //   });
    //   let sameBook = cartUnique.length + 1 === cartOrigin.length;
    //   if (!sameBook) {
    //     let response = await fetch("post", "cart", {
    //       ebook: el.bookUID
    //     });
    //     if (response.status) {
    //       dispatch(actionAddCart(el));
    //     } else if (!response.status) {
    //       dispatch(showError(response));
    //     }
    //   }
    //   if (index === cartItems.length - 1) {
    //     localStorage.removeItem("shoppingCart");
    //   }
    // });
    var cartOriginUIDS = cartOrigin.map(el => el.bookUID);
    var contentUIDS = cartItems
      .map(el => el.bookUID)
      .filter(item => {
        return !cartOriginUIDS.includes(item);
      });
    let response = await fetch("post", "cart", {
      ebook: contentUIDS
    });
    if (response.status) {
      cartItems.forEach(cartItem => {
        let cc = cartOrigin.filter(c => {
          return c.bookUID !== cartItem.bookUID;
        });
        let sameBook = cc.length + 1 === cartOrigin.length;
        if (!sameBook) {
          dispatch(actionAddCart(cartItem));
        }
      });
      localStorage.removeItem("shoppingCart");
    } else {
      dispatch(showError(response));
    }
  }
};

export const fetchAddCart = (ebook = {}) => {
  return async (dispatch, getState) => {
    let cartItem = createModelItem(ebook, shoppingCartItem);
    let cartOrigin = getState().Payment.shoppingCart;
    let cartUnique = cartOrigin.filter(b => {
      return b.bookUID !== ebook.bookUID;
    });
    let sameBook = cartUnique.length + 1 === cartOrigin.length;
    if (!sameBook) {
      if (authenUtility.isLogin()) {
        let response = await fetch("post", "cart", {
          ebook: cartItem.bookUID
        });
        if (response.status) {
          dispatch(actionAddCart(cartItem));
        } else {
          dispatch(showError(response));
        }
      } else {
        dispatch(actionAddCart(cartItem));
      }
    }
  };
};

export const addCartWithOutLogin = (ebook = {}) => {
  return async dispatch => {
    let cartItem = createModelItem(ebook, shoppingCartItem);
    dispatch(actionAddCart(cartItem));
  };
};

export const removeCartItemFromLocalStorage = async bookUID => {
  let cartItems = JSON.parse(localStorage.getItem("shoppingCart"));
  if (cartItems) {
    localStorage.setItem(
      "shoppingCart",
      JSON.stringify(
        cartItems.filter(book => {
          return book.bookUID !== bookUID;
        })
      )
    );
  }
};

export const fetchRemoveCart = (ebook = {}, complete = () => {}) => {
  return async dispatch => {
    let cartItem = createModelItem(ebook, shoppingCartItem);
    if (authenUtility.isLogin()) {
      let response = await fetch("patch", "cart", {
        ebook: cartItem.bookUID
      });
      if (response.status) {
        dispatch(actionRemoveCart(cartItem));
        complete();
      } else {
        if (
          JSON.stringify(response) !==
          JSON.stringify({ status: false, errorCode: "021", message: "Insert to DB error" })
        )
          dispatch(showError(response));
      }
    } else {
      dispatch(actionRemoveCart(cartItem));
    }
  };
};

export const createModelItem = (data = {}, model = {}) => {
  if (Array.isArray(data)) {
    let modelItem = [];
    data.forEach(el => {
      let convertValue = {};
      for (let key in model) {
        if (el[key] !== undefined) {
          convertValue[key] = el[key];
        }
      }
      modelItem.push(convertValue);
    });
    return modelItem.slice();
  } else {
    let modelItem = model;
    for (let key in modelItem) {
      if (data[key] !== undefined) {
        modelItem[key] = data[key];
      }
    }
    return Object.assign({}, modelItem);
  }
};

export const fetchListPayment = async () => {
  const result = await fetch("get", "payment/listPaymentChannel");
  return result;
};

export const fetchCoinData = async () => {
  return await fetch("post", "payment/getCoinData", { isIAP: false });
};

export const payWithCreditCard = payload => async dispatch => {
  const { data } = await fetch("post", "payment/cc", payload);
  return await data;
};

export const payWithBankTransfer = payload => async dispatch => {
  const { data } = await fetch("post", "payment/promptpay", payload);
  return await data;
};

export const payWithHTICoin = payload => async dispatch => {
  const { data } = await fetch("post", "payment/hticoin", payload);
  return await data;
};

export const paypalRequest = payload => async dispatch => {
  const { data } = await fetch("post", "payment/paypal", payload);
  return await data;
};

export const payWithPayPal = payload => async dispatch => {
  const { data } = await fetch("post", "payment/paypal/successfully", payload);
  return await data;
};

export const fetchOrderDetail = async orderCode => {
  return await fetch("get", `orders/${orderCode}`);
};

export const fetchPaymentHistory = async (contentType, lastEvaluatedKey) => {
  var lastKey = "";
  if (lastEvaluatedKey) {
    lastKey = "&lastEvaluatedKey=" + encodeURIComponent(JSON.stringify(lastEvaluatedKey));
  }
  return await fetch("get", `orders/histories?contentType=${contentType}${lastKey}&isAsc=false`);
};
