import Ebook from "./Reducers/Ebook";
import Authentication from "./Reducers/Authentication";
import Page from "./Reducers/Page";
import Member from "./Reducers/Member";
import Message from "./Reducers/Message";
import Payment from "./Reducers/Payment";
import { combineReducers } from "redux";
export default combineReducers({
  Ebook,
  Authentication,
  Page,
  Member,
  Message,
  Payment
});
