import { Modal, Button } from "antd";
import React from "react";
import "./AlertReviewStyle.scss";

const AlertWriteReview = (props) => {
  const { visible, onAccept } = props;
  return (
    <Modal
      visible={visible}
      className="alertReviewModal"
      footer={null}
      closeIcon={<img src='/images/faq/pdpa/CloseIcon.svg' className="close-icon"/>}
      onCancel={() => {
        onAccept(false);
      }}
    >
      <div className="review-cover--container">
        <img src="/images/faq/pdpa/reviewModalCover.png" />
      </div>

      <div className="alert-review-content">
        <h2 className="alert-review-title">เขียนรีวิว</h2>
        <p className="danger-text">(โปรดอ่านเงื่อนไขสำคัญ)</p>
        <p className="content-text">
          ผู้ใช้งานยืนยันว่าจะไม่ใช่ภาษาที่ไม่สุภาพ <br />
          ส่อเสียด ข่มขู่ ทำให้เกิดจดหมายลูกโซ่ หรือ <br />
          การใช้ที่ผิดวัตถุประสงค์ และไม่ส่งข้อมูลที่มีโปรแกรมไวรัส
          <br />
          นอกจากนี้ ผู้ใช้บริการยืนยันที่จะให้ข้อมูลที่เป็นจริง <br />
          ไม่มีการแอบอ้างชื่อ บุคคล องค์กร <br />
          หรือข้อมูลใดที่จะทำให้เกิดความเข้าใจผิด
          <br />
        </p>

        <Button
          type="primary"
          className="submit-btn"
          shape="round"
          size="large"
          onClick={() => {
            onAccept(true);
          }}
        >
          ยอมรับ
        </Button>
      </div>
    </Modal>
  );
};

export default AlertWriteReview;
