import React, { useState, useEffect, useRef } from "react";
import { Row, Col, Button, message, Divider } from "antd";
import { addFavorite, deleteFavorite, getFavorite } from "../../Actions/Member";
import { Link } from "react-router-dom";
import { connect } from "react-redux";
export const AuthorPublisher = (props) => {
  const [following, setFollowing] = useState(false);
  const [isImgError, setIsImgError] = useState(false);
  const publisherDiv = useRef(null);
  const authorDiv = useRef(null);
  const [publisherHeight, setPublisherHeight] = useState(36);
  const [authorHeight, setAuthorHeight] = useState(36);
  const imgError = (image) => {
    setIsImgError(true);
    image.onError = "";
    return true;
  };
  message.config({
    top: "15%",
    duration: 2,
    maxCount: 1,
  });

  const imgErrorSrc = "/images/book/worm.png";
  const handleFollowing = async (type) => {
    if (!following) {
      let add_fav =
        type === "author"
          ? await addFavorite("Author", props.name)
          : await addFavorite("Publisher", props.creatorUID);
      if (add_fav.status) {
        setFollowing(true);
        message.success(<span className="spanSubcatFollow">ติดตาม "{props.name}" แล้ว</span>);
      }
    } else {
      let delete_fav =
        type === "author"
          ? await deleteFavorite("Author", props.name)
          : await deleteFavorite("Publisher", props.creatorUID);
      if (delete_fav.status) {
        setFollowing(false);
        message.error(<span className="spanSubcatFollow">ยกเลิกการติดตาม "{props.name}"</span>);
      }
    }
  };
  useEffect(() => {
    const fetchFav = async (type) => {
      if (props.memberUID !== "") {
        let fav = type === "author" ? await getFavorite("Author") : await getFavorite("Publisher");
        if (fav.status) {
          let filter_fav =
            fav.data && fav.data.favorites
              ? fav.data.favorites.filter((el) => {
                  return type === "author"
                    ? el.contentID === props.name
                    : el.contentID === props.creatorUID;
                })
              : [];
          let isfav = false;
          if (filter_fav.length) {
            isfav = true;
          }
          setFollowing(isfav);
        }
      }
    };
    fetchFav(props.type);
    // eslint-disable-next-line
  }, [props.name]);
  const followBtn = () => {
    if (following)
      return (
        <p
          className="followingbtn"
          onClick={() => {
            if (props.memberUID !== "") handleFollowing(props.type);
            else props.showLoginModal();
          }}
        >
          <img alt="" src="/images/book/checktrue.png" style={{ width: "10px", height: "8px" }} />
        </p>
      );
    else
      return (
        <p
          className="followbtn"
          onClick={() => {
            if (props.memberUID !== "") handleFollowing(props.type);
            else props.showLoginModal();
          }}
        >
          +
        </p>
      );
  };
  return (
    <div
      style={
        props.isMobile
          ? props.type === "publisher"
            ? { marginBottom: "16px", marginTop: "0px" }
            : { marginBottom: "0px", marginTop: "8px" }
          : props.isSmallMobile
          ? { marginTop: "8px" }
          : { marginBottom: "16px" }
      }
    >
      {props.type === "publisher" ? (
        <div className="authorAndPublisher" ref={publisherDiv}>
          <Link to={`/publisher/${props.creatorUID}`} target="_blank">
            <img
              className="profile"
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                marginRight: "4px",
                // transform: props.Page.browserName.match(/(Safari)/i)
                //   ? "translateY(1px)"
                //   : "translateY(-5px)",
              }}
              src={isImgError ? imgErrorSrc : props.imgSrc}
              onError={imgError}
              alt={props.type}
              onLoad={() => {
                if (publisherDiv.current) {
                  setPublisherHeight(publisherDiv.current.clientHeight);
                }
              }}
            />
            <div
              className="publisher"
              // style={{
              //   transform: props.Page.browserName.match(/(Safari)/i)
              //     ? "translateY(2px)"
              //     : "translateY(-4px)",
              // }}
            >
              สำนักพิมพ์ :{" "}
            </div>
            <h4
              className="publisherName"
              // style={{
              //   transform: props.Page.browserName.match(/(Safari)/i)
              //     ? "translateY(2px)"
              //     : props.Page.browserName.match(/(Firefox)/i)
              //     ? "translateY(9px)"
              //     : "translateY(10px)",
              // }}
            >
              {props.name}
            </h4>
          </Link>
          <Divider
            type="vertical"
            style={{
              height: `${publisherHeight}px`,
              background: "#c8c8c8",
              transform: "translateY(1px)",
              marginRight: "0px",
            }}
          />
          {followBtn()}
        </div>
      ) : (
        <div className="authorAndPublisher" ref={authorDiv}>
          <Link to={`/author/${props.name}`} target="_blank">
            <img
              className="profile"
              style={{
                height: "30px",
                width: "30px",
                borderRadius: "50%",
                marginRight: "4px",
                // transform: props.Page.browserName.match(/(Safari)/i)
                //   ? "translateY(1px)"
                //   : "translateY(-5px)",
                verticalAlign: "middle",
              }}
              src={isImgError ? imgErrorSrc : props.imgSrc}
              onError={imgError}
              alt={props.type}
              onLoad={() => {
                if (authorDiv.current) {
                  setAuthorHeight(authorDiv.current.clientHeight);
                }
              }}
            />
            <div
              className="author"
              // style={{
              //   transform: props.Page.browserName.match(/(Safari)/i)
              //     ? "translateY(2px)"
              //     : "translateY(-4px)",
              // }}
            >
              นักเขียน :{" "}
            </div>
            <h4
              className="authorName"
              // style={{
              //   transform: props.Page.browserName.match(/(Safari)/i)
              //     ? "translateY(2px)"
              //     : props.Page.browserName.match(/(Firefox)/i)
              //     ? "translateY(9px)"
              //     : "translateY(10px)",
              // }}
            >
              {props.name}
            </h4>
          </Link>
          <Divider
            type="vertical"
            style={{
              height: `${authorHeight}px`,
              background: "#c8c8c8",
              transform: "translateY(1px)",
              marginRight: "0px",
            }}
          />
          {followBtn()}
        </div>
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Authentication: state.Authentication,
    Page: state.Page,
  };
};
export default connect(mapStateToProps)(AuthorPublisher);
