import React from "react";
import { Form, Input, Button, Row, Col, Select, Checkbox } from "antd";
import Address from "./Address";
import { fetch } from "../../../Utilitys/ApiRequest";
const { Option } = Select;
class PaymentInfo extends React.Component {
  state = {
    banklist: [],
    bankAccountNameError: "",
    bankAccountNameErrorMessage: "",
    bankAccountNumberError: "",
    bankAccountNumberErrorMessage: "",
    sameaddress: false,
    addressComplete: false,
    addressFormData: {}
  };
  async componentDidMount() {
    this.props.form.validateFields();
    let response = await fetch("get", "banks");
    if (response.status) {
      this.setState({ banklist: response.data.banks });
    }
  }
  fieldOnchangehandle = e => {
    this.props.form.validateFields();
    switch (e.target.name) {
      case "bankaccountname":
        if (e.target.value === "" || /\d/.test(e.target.value)) {
          this.setState({
            bankAccountNameError: "error",
            bankAccountNameErrorMessage: "กรุณากรอกชื่อบัญชีให้ถูกต้อง"
          });
        } else {
          this.setState({ bankAccountNameError: "", bankAccountNameErrorMessage: "" });
        }
        break;
      case "bankaccountnumber":
        if (/^[0-9]*$/.test(e.target.value) && e.target.value.replace(/[^0-9]/g, "").length >= 10) {
          this.setState({ bankAccountNumberError: "", bankAccountNumberErrorMessage: "" });
        } else {
          this.setState({
            bankAccountNumberError: "error",
            bankAccountNumberErrorMessage: "กรุณาให้ข้อมูลเลขบัญชีที่ถูกต้อง"
          });
        }
        break;
      case "sameaddress":
        this.setState({ sameaddress: e.target.checked });
        break;
      default:
        break;
    }
  };
  makeAddressComplete = (boo, values) => {
    this.setState({ addressComplete: boo, addressFormData: values });
  };
  errorHandleCheck = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };
  handlePrev = e => {
    e.preventDefault();
    const values = this.props.form.getFieldsValue();
    this.props.onPrevHandle("PaymentInfo", {
      ...values,
      address: this.state.addressFormData
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmitHandle("PaymentInfo", {
          ...values,
          address: this.state.addressFormData
        });
      }
    });
  };
  render() {
    const { banklist } = this.state;
    const { getFieldDecorator, getFieldsError } = this.props.form;
    return (
      <Form name="creator_paymentInfo" className="paymentInfo" onSubmit={this.handleSubmit}>
        <p>ข้อมูลการจ่ายเงิน</p>
        <label>เลือกธนาคาร</label>
        <Form.Item>
          {getFieldDecorator("bankname", {
            id: "bankname",
            rules: [
              {
                required: true,
                message: "กรุณาเลือกธนาคาร"
              }
            ]
          })(
            <Select
              showSearch
              name="banklist"
              placeholder="กรุณาเลือกธนาคาร"
              optionFilterProp="children"
              dropdownMenuStyle={{ fontFamily: "Prompt" }}
              onChange={() => {}}
              onFocus={() => {}}
              onBlur={() => {}}
              onSearch={() => {}}
              filterOption={(input, option) =>
                option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
              }
            >
              {banklist && banklist.length
                ? banklist.map(b => {
                    return (
                      <Option key={b.bank_id} value={b.bank_id}>
                        {b.bank_name_th}
                      </Option>
                    );
                  })
                : ""}
            </Select>
          )}
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <label>ชื่อบัญชี</label>
            <Form.Item
              validateStatus={this.state.bankAccountNameError ? "error" : ""}
              help={this.state.bankAccountNameErrorMessage}
            >
              {getFieldDecorator("bankaccountname", {
                id: "bankaccountname",
                rules: [
                  {
                    required: true,
                    message: "กรุณาเลือกธนาคาร"
                  }
                ]
              })(
                <Input
                  name="bankaccountname"
                  placeholder="กรุณากรอกชื่อ"
                  onChange={this.fieldOnchangehandle}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <label>เลขบัญชีธนาคาร</label>
            <Form.Item
              validateStatus={this.state.bankAccountNumberError ? "error" : ""}
              help={this.state.bankAccountNumberErrorMessage}
            >
              {getFieldDecorator("bankaccountnumber", {
                id: "bankaccountnumber",
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกเลขบัญชี"
                  }
                ]
              })(
                <Input
                  name="bankaccountnumber"
                  placeholder="กรุณากรอกเลขบัญชี"
                  onChange={this.fieldOnchangehandle}
                />
              )}
            </Form.Item>
          </Col>
        </Row>
        <p style={{ marginBottom: "8px" }}>ที่อยู่จัดส่งเอกสาร</p>
        {this.props.isCreator ? (
          ""
        ) : (
          <Checkbox
            name="sameaddress"
            className="sameaddress"
            onChange={this.fieldOnchangehandle}
            checked={this.state.sameaddress}
          >
            <span>ที่อยู่เดียวกับที่อยู่ปัจจุบัน</span>
          </Checkbox>
        )}

        <Address
          type={"shippingdoc"}
          sameaddress={this.state.sameaddress}
          addressComplete={this.makeAddressComplete}
          addressStepTwo={this.props.addressStepTwo}
          valuesAddress={this.props.values.address}
        />
        {/*<Address
          type={"shippingdoc"}
          sameaddress={this.state.sameaddress}
          addressComplete={this.makeAddressComplete}
          addressStepTwo={{
            address: "555",
            postcode: "10530",
            province: "กรุงเทพอะ",
            district: "เขตหนองจอก",
            subdistrict: "ได้หมดอะคับ"
          }}
        />*/}

        <Row type="flex" justify="space-between">
          <Col span={12}>
            <Form.Item>
              <Button type="primary" onClick={this.handlePrev} className="prevbtn" disabled={false}>
                ย้อนกลับ
              </Button>
            </Form.Item>
          </Col>
          <Col span={12} align="right">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="nextbtn"
                disabled={
                  this.errorHandleCheck(getFieldsError()) ||
                  this.state.bankAccountNameError !== "" ||
                  this.state.bankAccountNameErrorMessage !== "" ||
                  this.state.bankAccountNumberError !== "" ||
                  this.state.bankAccountNumberErrorMessage !== "" ||
                  !this.state.addressComplete
                }
              >
                สมัคร
              </Button>
            </Form.Item>
          </Col>
        </Row>
      </Form>
    );
  }
}
export default Form.create({
  name: "creator_paymentInfo",
  mapPropsToFields(props) {
    const values = props.values;
    return {
      bankname: Form.createFormField({
        value: values.bankname
      }),
      bankaccountname: Form.createFormField({
        value: values.bankaccountname
      }),
      bankaccountnumber: Form.createFormField({
        value: values.bankaccountnumber
      })
    };
  }
})(PaymentInfo);
