import React, { useState, useEffect } from "react";
import PropTypes from "prop-types";
import { Row, Col, Divider, Button, Modal } from "antd";
import PaymentMethodList from "./PaymentMethodList";
import PaymentList from "./PaymentList";
import PaymentButton from "./PaymentButton";
import { imageAsLink } from "../../Views/ShoppingCart";
import { connect } from "react-redux";
import Helmet from "react-helmet";
import PaymentHytextsCoinList from "./PaymentHytextsCoinList";
import { policyContent } from "../RegisterCreator/AccountInfo";
import { ACTION_SHOW_LOADING } from "../../../Actions/Page";
import {
  contents_condition,
  contents_ebook_2,
  contents_hytextsCredit,
  contents_member,
} from "../Privacy/TermOfUse";

const PaymentAll = ({ contentType, history, hytextsCoin, Page, dispatch }) => {
  const [selectedForm, setSelectedForm] = useState("");
  const [coinAmount, setCoinAmount] = useState(0);
  const [coinID, setCoinID] = useState("");
  const [isShowpolicy, setIsShowpolicy] = useState(false);
  const [loading_PaymentHytextsCoinList, setLoading_PaymentHytextsCoinList] =
    useState(true);
  const [loading_PaymentMethodList, setLoading_PaymentMethodList] =
    useState(true);

  const descriptions = () => {
    return (
      <div className="total-price">
        <p className="cart-bottom-left">
          ท่านสามารถอ่าน e-book ที่ท่านซื้อไว้ผ่าน แอปพลิเคชันและเว็บไซต์
          Hytexts eBook Store บนอุปกรณ์เหล่านี้เท่านั้น
        </p>
        <Row className="cart-bottom-label">
          <Col xs={24} sm={8} align="center">
            1. iOS/iPadOS (11 ขึ้นไป)
            {Page.screensize.width <= 576 && (
              <>
                <br />
                {imageAsLink[0]}
              </>
            )}
          </Col>
          <Col xs={24} sm={8} align="center">
            2. Android (5.0 ขึ้นไป)
            {Page.screensize.width <= 576 && (
              <>
                <br />
                {imageAsLink[1]}
              </>
            )}
          </Col>
          <Col xs={24} sm={8} align="center">
            3. Boox Ereader (Android 6.0 ขึ้นไป)
            {Page.screensize.width <= 576 && (
              <>
                <br />
                {imageAsLink[2]}
              </>
            )}
          </Col>
        </Row>
        {Page.screensize.width > 576 && (
          <Row style={{ marginBottom: "32px" }}>
            <Col span={8}>{imageAsLink[0]}</Col>
            <Col span={8}>{imageAsLink[1]}</Col>
            <Col span={8}>{imageAsLink[2]}</Col>
          </Row>
        )}
        <p className="cart-bottom-left">
          หากต้องการอ่าน e-book ที่วางจำหน่ายบน Books หรือ Google Play Book
          จะต้องซื้อผ่าน Application ของ Books และ Google Play Book เท่านั้น
        </p>
        <Row className="cart-bottom-label" justify="center">
          <Col xs={24} sm={8} align="center">
            1. Books
            {Page.screensize.width <= 576 && (
              <>
                <br />
                {imageAsLink[3]}
              </>
            )}
          </Col>
          <Col xs={24} sm={8} align="center">
            2. Google Play Book
            {Page.screensize.width <= 576 && (
              <>
                <br />
                {imageAsLink[4]}
              </>
            )}
          </Col>
        </Row>
        {Page.screensize.width > 576 && (
          <Row style={{ marginBottom: "32px" }} justify="center">
            <Col xs={24} sm={8}>
              {imageAsLink[3]}
            </Col>
            <Col xs={24} sm={8}>
              {imageAsLink[4]}
            </Col>
          </Row>
        )}
        <p className="cart-bottom-left">
          *ไม่สามารถอ่านบนเว็บไซต์หรือดาวน์โหลดไฟล์มาอ่านด้วยโปรแกรมอื่น ๆ ได้
        </p>
        <p className="cart-bottom-left-red">
          *ไม่มีการจัดส่งหนังสือเป็นรูปเล่มให้ทางไปรษณีย์
        </p>
        <p className="cart-bottom-left">
          *E-BOOK ที่ซื้อบนเว็บ Hytexts หรือบน App Hytexts{" "}
          <span className="cart-bottom-left-red">ไม่สามารถ Print ได้</span>
        </p>
        <p className="cart-bottom-left">
          *หากต้องการซื้อเป็น{" "}
          <span className="cart-bottom-left-red">“รูปเล่ม”</span> กรุณาติดต่อ
          <span className="cart-bottom-left-red">สำนักพิมพ์หรือนักเขียน</span>
          โดยตรง
        </p>
        <p className="cart-bottom-left">
          *E-BOOK ที่ซื้อบนเว็บ Hytexts หรือบน App Hytexts{" "}
          <span className="cart-bottom-left-red">
            ไม่สามารถอ่านบน Kindle ได้
          </span>
        </p>
      </div>
    );
  };

  const policyclickHandle = () => {
    setIsShowpolicy(!isShowpolicy);
  };

  useEffect(() => {
    if (hytextsCoin) {
      if (!loading_PaymentHytextsCoinList && !loading_PaymentMethodList) {
        dispatch(ACTION_SHOW_LOADING(false));
      } else {
        dispatch(ACTION_SHOW_LOADING(true));
      }
    } else {
      if (!loading_PaymentMethodList) {
        dispatch(ACTION_SHOW_LOADING(false));
      } else {
        dispatch(ACTION_SHOW_LOADING(true));
      }
    }
  }, [loading_PaymentHytextsCoinList, loading_PaymentMethodList]);

  return (
    <div>
      {hytextsCoin ? (
        <div className="payment-container">
          <Helmet>
            <title>{`Hytexts: HYTEXTS COIN`}</title>
          </Helmet>
          <h1 style={{ marginBottom: "0px" }}>HYTEXTS COIN</h1>
          {/* <h1 style={{ marginBottom: "0px" }}>{Page.screensize.width}</h1> */}
          <Row type="flex" justify="center">
            <Col span={24}>
              <PaymentHytextsCoinList
                coinAmount={coinAmount}
                setCoinID={setCoinID}
                setCoinAmount={setCoinAmount}
                screenWidth={Page.screensize.width}
                setLoading_PaymentHytextsCoinList={
                  setLoading_PaymentHytextsCoinList
                }
              />
            </Col>
            <Col xs={24} sm={18} md={15} lg={12}>
              <p className="coltitle">เลือกช่องทางการชำระเงิน</p>
              <PaymentMethodList
                noHytextsCoin={{
                  boo: true,
                  coinID: coinID,
                  coinAmount: coinAmount,
                }}
                setSelectedForm={setSelectedForm}
                contentType={contentType}
                history={history}
                setLoading_PaymentMethodList={setLoading_PaymentMethodList}
              />
              <span className="policy" onClick={policyclickHandle}>
                เงื่อนไขและข้อกำหนด
              </span>
            </Col>
          </Row>
          <div className="confirm">
            <Button
              className="confirmBtn"
              type="primary"
              htmlType="submit"
              form={selectedForm}
              disabled={
                selectedForm.trim() !== "" && coinAmount > 0 ? false : true
              }
            >
              ยืนยัน
            </Button>
          </div>
        </div>
      ) : (
        <div className="payment-container">
          <Helmet>
            <title>{`Hytexts: การชำระเงิน`}</title>
          </Helmet>
          <h1>การชำระเงิน</h1>
          <Row type="flex" justify="center">
            <Col xs={24} lg={0}>
              <PaymentList selectedForm={selectedForm} />
            </Col>
            <Col
              xs={24}
              sm={18}
              md={15}
              lg={11}
              style={
                Page.screensize.width < 992
                  ? { marginBottom: "16px" }
                  : { marginBottom: "0" }
              }
            >
              <p className="coltitle">เลือกช่องทางการชำระเงิน</p>
              <PaymentMethodList
                setSelectedForm={setSelectedForm}
                contentType={contentType}
                history={history}
                setLoading_PaymentMethodList={setLoading_PaymentMethodList}
              />
              <span className="policy" onClick={policyclickHandle}>
                เงื่อนไขและข้อกำหนด
              </span>
            </Col>
            <Col xs={0} lg={4} align="center">
              <Divider
                type="vertical"
                style={{ height: "550px", background: "#bdbdbd" }}
              />
            </Col>
            <Col xs={0} lg={9}>
              <PaymentList selectedForm={selectedForm} />
            </Col>
            <Col xs={24} lg={0}>
              <PaymentButton selectedForm={selectedForm} />
            </Col>
          </Row>
          {descriptions()}
        </div>
      )}
      <Modal
        visible={isShowpolicy}
        footer={null}
        maskClosable={true}
        onCancel={policyclickHandle}
        width={Page.screensize.width < 1100 ? "95%" : 1100}
      >
        <div className="policy-content">
          <p className="title" style={{ marginTop: "16px" }}>
            ข้อตกลงและเงื่อนไขในการใช้บริการ
          </p>
          {contents_condition.map((e) => (
            <p className="content">
              {e}
              <br />
            </p>
          ))}
          <p className="content-head" style={{ fontWeight: "700" }}>
            หนังสืออิเล็กทรอนิกส์ (E-BOOK)
          </p>
          {contents_ebook_2.map((e) => (
            <p className="content">
              {e}
              <br />
            </p>
          ))}
          <p className="content-head" style={{ fontWeight: "700" }}>
            HYTEXTS CREDIT
          </p>
          {contents_hytextsCredit.map((e) => (
            <p className="content">
              {e}
              <br />
            </p>
          ))}
          <p className="content-head" style={{ fontWeight: "700" }}>
            สมาชิกทั่วไป
          </p>
          {contents_member.map((e, index) =>
            index === 13 ||
            index === 14 ||
            index === 15 ||
            index === 17 ||
            index === 18 ? (
              <p className="content" style={{ marginLeft: "24px" }}>
                {e}
                <br />
              </p>
            ) : (
              <p className="content">
                {e}
                <br />
              </p>
            )
          )}
        </div>
      </Modal>
    </div>
  );
};

PaymentAll.propTypes = {
  contentType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return {
    Page: state.Page,
  };
};

export default connect(mapStateToProps)(PaymentAll);
