import React from "react";

export const writerLabel = [
  "registration",
  "registration_third_store",
  "share",
];

export const belowForWriter = [
  // "การสมัครสมาชิกลงขาย",
  "การสมัครลงขายบน Third Store",
  "ส่วนแบ่งและค่าตอบแทน",
];

export const SideForWriter = (props) => {
  const sideForWriter0 = (c) => {
    const url_common = "/images/faq/forWriter/0/";
    const url_person = "/images/faq/forWriter/0/person/";
    const url_company = "/images/faq/forWriter/0/company/";
    const contents_person = [
      "1. เข้าไปที่เว็บไซต์ www.hytexts.com",
      "2. เลือกเข้าสู่ระบบด้วยช่องทางต่าง ๆ",
      "3. เลือก สมัครขายอีบุ๊ค",
      "4. เลือก บุคคล ",
      "5. กรอกรายละเอียดต่าง ๆ ให้ครบถ้วน > กดถัดไป",
      "6. กรอกรายละเอียดต่าง ๆ ให้ครบถ้วน > กดสมัคร",
      "7. เมื่อเสร็จเรียบร้อย > กดตกลง",
    ];
    const contents_company = [
      "1. เข้าไปที่เว็บไซต์ www.hytexts.com",
      "2. เลือกเข้าสู่ระบบด้วยช่องทางต่าง ๆ",
      "3. เลือก สมัครขายอีบุ๊ค",
      "4. เลือก นิติบุคคล ",
      "5. กรอกรายละเอียดต่าง ๆ ให้ครบถ้วน > กดถัดไป",
      "6. กรอกรายละเอียดต่าง ๆ ให้ครบถ้วน > กดสมัคร",
      "7. เมื่อเสร็จเรียบร้อย > กดตกลง",
    ];
    return (
      <div className="side">
        <p className={c ? "side-title-c" : "side-title"}>
          การสมัครสมาชิกสำหรับลงขาย (ทั้งนักเขียนและสำนักพิมพ์)
        </p>
        <p className="side-content-lessBottom">
          * ต้องสมัคร Hytexts account ก่อนจึงจะสามารถเป็นนักเขียน/สำนักพิมพ์ได้
        </p>
        <p className="side-title">การสมัครสมาชิกสำหรับขายอีบุ๊คของนักเขียน</p>
        {contents_person.map((e, index) => {
          if (index < 3 || index > 5)
            return (
              <p className="side-content" key={index}>
                {e}
                <br />
                <img
                  src={`${url_common + (index + 1)}.jpg`}
                  onLoad={props.handleImageChange}
                  onError={props.handleImageChange}
                />
              </p>
            );
          if (index >= 3 && index <= 5) {
            return (
              <p className="side-content" key={index}>
                {e}
                <br />
                <img
                  src={`${url_person + (index + 1)}.jpg`}
                  onLoad={props.handleImageChange}
                  onError={props.handleImageChange}
                />
              </p>
            );
          }
        })}
        <p className="side-title">การสมัครสมาชิกสำหรับขายอีบุ๊คของสำนักพิมพ์</p>
        {contents_company.map((e, index) => {
          if (index < 3 || index > 5)
            return (
              <p className="side-content" key={index}>
                {e}
                <br />
                <img
                  src={`${url_common + (index + 1)}.jpg`}
                  onLoad={props.handleImageChange}
                  onError={props.handleImageChange}
                />
              </p>
            );
          if (index >= 3 && index <= 5) {
            return (
              <p className="side-content" key={index}>
                {e}
                <br />
                <img
                  src={`${url_company + (index + 1)}.jpg`}
                  onLoad={props.handleImageChange}
                  onError={props.handleImageChange}
                />
              </p>
            );
          }
        })}
      </div>
    );
  };

  const sideForWriter1 = (c) => {
    const contents_1 = [
      "ก่อนสมัครลงขายบน Third Store นักเขียนและสำนักพิมพ์จะต้องสมัครสมาชิกกับ Hytexts.com ก่อน",
      '1. กด "สมัครขายอีบุ๊คบน Google Play Books"',
      "2. กรอกอีเมลแล้วกด สมัคร > กด สมัคร",
    ];
    const contents_2 = [
      "Third Store คือ บริการเสริมที่ทาง Hytexts ให้บริการลงขายบน Third Store เช่น Google Play Book (ซึ่งมีมากกว่า 10,xxx titles) และ iBook Store",
      "- Third Store ช่วยเพิ่มช่องทางการขาย E-Book บนร้านค้าระดับสากล Google Play Bookและ iBook Store",
      "- Third Store ช่วยเพิ่มรายได้ เพิ่มโอกาสสร้างรายได้ เพิ่มยอดขาย E-Book ให้นักเขียน",
      "- Third Store ช่วยเพิ่มฐานลูกค้า ให้เข้าถึง E-Book ได้มากขึ้น ทั้งในประเทศและต่างประเทศ",
    ];
    return (
      <>
        <div className="side">
          <p className={c ? "side-title-cc" : "side-title"}>
            การสมัครลงขายบน Third Store
          </p>
          {contents_1.map((e, index) =>
            index === 1 || index === 2 ? (
              <>
                <p className="side-content" key={index}>
                  {e}
                  <br />
                  <img
                    src={
                      index === 1
                        ? `/images/faq/forWriter/1/thirdStore.jpg`
                        : `/images/faq/forWriter/1/inputEmail.jpg`
                    }
                    onLoad={props.handleImageChange}
                    onError={props.handleImageChange}
                  />
                </p>
              </>
            ) : (
              <p className="side-content-lessBottom" key={index}>
                {e}
              </p>
            )
          )}
        </div>
        <div className="side">
          <p className="side-title">Third Store คืออะไร</p>
          {contents_2.map((e, index) => (
            <p className="side-content-lessBottom" key={index}>
              {e}
            </p>
          ))}
        </div>
      </>
    );
  };

  const sideForWriter2 = (c) => {
    const contents_1 = [
      "hytexts.com เป็น eBook store ที่เปิดโอกาสให้นักเขียนอิสระสามารถวางขายผลงานการในรูปแบบหนังสืออิเล็กทรอนิกส์ (eBook) และสามารถตรวจสอบยอดขายได้เองผ่านชื่อบัญชีผู้ใช้งาน ซึ่ง hytexts.com จะมีการเข้ารหัสระบบจัดการลิขสิทธิ์ (Digital right management) ด้วยระบบ Adobe DRM สำหรับหนังสืออิเล็กทรอนิกส์ (eBook) ที่กำหนดราคาขายไว้",
      "eBook จาก hytexts.com สามารถอ่านได้กับอุปกรณ์ในระบบต่างๆ เช่น iOS, Android, Window, Mac OS และเครื่อง eReader อีกทั้ง hytexts.com ยังสนันสนุน eBook ในรูปแบบ ePUB ซึ่งทำให้ผู้อ่านสามารถอ่าน eBook ได้อย่างสะดวกสบาย โดยไม่ต้องคำนึงถึงขนาดหน้าจอของอุปกรณ์ที่ใช้งาน",
      "โปรดอ่านเงื่อนไขและข้อตกลงสำหรับการวางขาย eBook กับ hytexts.com",
    ];
    const contents_2 = [
      "1. งานเขียนต้องไม่ละเมิดลิขสิทธิ์ของบุคคลอื่น ทั้งเนื้อหา ข้อความ และรูปประกอบ",
      "2. งานเขียนต้องไม่ขัดกับข้อตกลงระหว่างนักเขียนกับบุคคลอื่น ๆ",
      "3. งานเขียนจะต้องมีเนื้อหาครบถ้วนสมบูรณ์",
      "4. งานเขียนจะต้องผ่านการพิสูจน์อักษร",
      "5. งานเขียนจะต้องมีการจัดทำหน้าปกทุกครั้ง",
    ];
    const contents_3 = [
      "1. กำหนดขนาดกระดาษเป็น A5 หรือ A4",
      "2. แยกหน้าลิขสิทธิ์, คำนำนักเขียน และบทต่าง ๆ ออกอย่างชัดเจน  (สามารถดูตัวอย่างหน้าลิขสิทธิ์ได้จากหนังสือตัวอย่างของ hytexts.com)",
      "3. ใช้คำสั่ง page brake ในการขึ้นหน้าใหม่สำหรับการแยกบทหรือหัวข้อต่าง ๆ",
      "4. ใช้ enter เฉพาะการขึ้นย่อหน้าใหม่เท่านั้น",
      "5. สามารถกำหนดราคาขายได้ตั้งแต่ 0 บาทขึ้นไป หรืออ้างอิงจากราคาหน้าปกจริง",
      "6. แนบรายละเอียดหนังสือ, จำนวนบทของตัวอย่างหนังสือ, หมวดหมู่หนังสือ, ราคาขาย, สารบัญ, แหล่งที่มาของรูปหน้าปก และคำโปรยมาที่หน้าแรกของต้นฉบับ",
      "7. หน้าปกต้องเป็นไฟล์ JPG ขนาด 768*1111 pixel",
      "8. ตัวอย่างหนังสือต้องมีเนื้อหาอยู่ที่ 1-3 บท หรือ 10% ของจำนวนหน้าจากเนื้อหาทั้งหมด",
    ];
    const contents_4 = [
      "1. หลังจากจัดทำไฟล์ต้นฉบับตามที่กำหนดแล้ว ให้นักเขียนเข้าไปที่เมนู My account > Upload eBook",
      "หรือหากต้องการสร้างไฟล์ epub ด้วยตัวเองให้ไปที่ create eBook",
      "2. ทำการใส่ข้อมูลต่างๆ เช่น ชื่อหนังสือ, หมวดหมู่หนังสือ, เรื่องย่อหรือโปรย และราคาขายให้ครบถ้วน",
      "3. ทำการอัปโหลดไฟล์ eBook (สำหรับนวนิยาย แนะนำเป็นไฟล์ Word ดีที่สุด), ไฟล์ตัวอย่าง และไฟล์หน้าปก เสร็จแล้วกด Submit",
      "หลังจากได้รับไฟล์ต้นฉบับแล้ว ทาง hytexts.com จะสร้างไฟล์ ePUB ให้กับนักเขียนโดยไม่มีค่าใช้จ่ายเพิ่มเติม ซึ่งใช้เวลา 3-7 วันทำการ จากนั้นจะทำการเข้ารหัสจัดการลิขสิทธิ์ Adobe DRM และอัปโหลดไฟล์ eBook เข้าสู่ระบบการซื้อขายของ hytexts.com โดยทันที ทีมงานมีสิทธิ์ไม่รับพิจารณต้นฉบับที่ไม่ได้ทำตามข้อกำหนดของ hytexts.com",
      "นักเขียนสามารถประชาสัมพันธ์ eBook ของตัวเองในช่องทางต่าง ๆ เพื่อเพิ่มยอดขายได้  ทางเจ้าหน้าที่จะทำการโอนค่าตอบแทนให้กับนักเขียน เมื่อมียอดค่าตอบแทนเกิน 1,000 บาท ผ่านทางบัญชีธนาคารที่นักเขียนลงทะเบียนไว้",
    ];
    return (
      <>
        <div className="side">
          <p className={c ? "side-title-cc" : "side-title"}>
            วิธีการขาย E-Book สำหรับนักเขียน
          </p>
          {contents_1.map((e, index) => (
            <p className="side-content-lessBottom" key={index}>
              {e}
              <br />
            </p>
          ))}
        </div>
        <div className="side">
          <p className="side-title">
            hytexts.com มีข้อกำหนดของงานเขียนเบื้องต้นดังนี้
          </p>
          {contents_2.map((e, index) => (
            <p className="side-content-lessBottom" key={index}>
              {e}
              <br />
            </p>
          ))}
        </div>
        <div className="side">
          <p className="side-title">ข้อกำหนดของไฟล์ต้นฉบับ</p>
          {contents_3.map((e, index) => (
            <p className="side-content-lessBottom" key={index}>
              {e}
              <br />
            </p>
          ))}
        </div>
        <div className="side">
          <p className="side-title">ขั้นตอนการวางขาย</p>
          {contents_4.map((e, index) => (
            <p className="side-content-lessBottom" key={index}>
              {e}
              <br />
            </p>
          ))}
        </div>
      </>
    );
  };

  const sideForWriter3 = (c) => {
    const contents_1 = [
      "* นักเขียน/สำนักพิมพ์ จะเป็นผู้รับผิดชอบ ภาษีหัก ณ ที่จ่าย และ  ค่าธรรมเนียมการโอนเงินผ่านธนาคาร",
      "- นักเขียนสามารถตรวจสอบยอดขายแบบ real time จากบัญชีผู้ใช้งานได้",
      "- นักเขียนที่วางขาย eBook กับ hytexts.com แล้ว สามารถส่งต้นฉบับไปวางขายกับที่อื่น ๆ ได้",
      "- หลังจากลงขายแล้ว 6 เดือน นักเขียน/สำนักพิมพ์สามารถยกเลิกการขายกับ hytexts.com ได้",
      "ทาง hytexts.com ยินดีให้คำปรึกษาและจัดทำไฟล์ eBook ในรูปแบบ ePUB ให้นักเขียน โดยไม่มีค่าใช้จ่ายเพิ่มเติม  สอบถามรายละเอียดเพิ่มเติมได้ที่ contact@hytexts.com หรือโทร 089-134-7470",
    ];
    const contents_2 =
      "www.hytexts.com ตกลงจัดส่งค่าตอบแทนการขายหนังสืออิเล็กทรอนิกส์ให้แก่นักเขียน ในอัตราร้อยละ 65 ของราคาขายหนังสืออิเล็กทรอนิกส์ คูณจำนวนหนังสืออิเล็กทรอนิกส์ที่ลูกค้าชำระเงินเสร็จสมบูรณ์บน www.hytexts.com ทั้งหมด";
    const contents_3 = [
      "1. นักเขียนเป็นผู้กำหนดราคาขายหนังสืออิเล็กทรอนิกส์เป็นสกุลเงิน US Dollar",
      "2. www.hytexts.com ตกลงจัดส่งค่าตอบแทนการขายหนังสืออิเล็กทรอนิกส์ให้แก่นักเขียน ในอัตราร้อยละ 45 ของราคาขายหนังสืออิเล็กทรอนิกส์ คูณจำนวนหนังสืออิเล็กทรอนิกส์ที่ลูกค้าชำระเงินเสร็จสมบูรณ์ผ่านทางระบบ In-app Purchased ของบริษัท Apple Inc โดยอ้างอิงจากอัตราแลกเปลี่ยนสกุลเงินจากทางบริษัท Apple Inc (บริษัท Apple Inc คิดค่าธรรมเนียมระบบ In-app Purchased 30% ของราคาขายหนังสืออิเล็กทรอนิกส์)",
    ];

    const contents_4 = [
      "1. www.hytexts.com ตกลงจะแจ้งให้นักเขียนทราบผลการจำหน่ายหนังสืออิเล็กทรอนิกส์ทุกครั้งเมื่อมีการซื้อขายกับบุคคลภายนอกสำเร็จ โดยแจ้งผ่านทางชื่อบัญชีผู้ใช้งานของนักเขียนที่ลงทะเบียนไว้ที่ www.hytexts.com",
      "2. นักเขียนสามารถตรวจสอบรายละเอียดและยอดขายงานวรรณกรรมในรูปแบบหนังสืออิเล็กทรอนิกส์ ผ่านชื่อบัญชีผู้ใช้งานของนักเขียนที่ลงทะเบียนไว้ในระบบ",
      "3. สำหรับการขายผ่าน Apple In-app Purchased จะมีการนำส่งเงินค่าตอบแทนภายใน ระยะเวลา 60 วันหลังจากได้ทำการส่งรายงานยอดขายที่เกิดขึ้นให้กับนักเขียน",
      "4. www.hytexts.com จะนำส่งเงินค่าตอบแทนทุกวันที่ 20 ของแต่ละเดือน หรือวันทำการถัดไปหากติดวันหยุด โดยเข้าบัญชีข้อมูลธนาคารที่ลงทะเบียนไว้ในระบบ",
      "5. นักเขียนยินยอมให้หักภาษี ณ ที่จ่าย ตามอัตราที่ระบุในประมวลรัษฎากรจากค่าตอบแทนที่จ่ายทุกครั้ง โดย www.hytexts.com จะออกหนังสือรับรองการหักภาษี ณ ที่จ่าย และรวบรวมส่งมอบให้แก่นักเขียนในเดือนกรกฎาคม และมกราคมของทุกปี",
      "6. ในการชำระค่าตอบแทน นักเขียนจะรับผิดชอบค่าธรรมเนียมในการโอนเงินผ่านธนาคาร โดยwww.hytexts.com จะหักค่าธรรมเนียมในการโอนเงินผ่านธนาคาร ที่จ่ายตามอัตราที่ธนาคารระบุไว้จากค่าตอบแทนที่จ่ายทุกครั้ง",
    ];

    return (
      <>
        <div className="side">
          <p className={c ? "side-title-cc" : "side-title"}>
            รายละเอียดส่วนแบ่งการขาย
          </p>
          {contents_1.map((e, index) => (
            <p className="side-content-lessBottom" key={index}>
              {e}
              <br />
            </p>
          ))}
        </div>
        <div className="side">
          <p className="side-title">ค่าตอบแทนการขายผ่าน www.hytexts.com</p>
          <p className="side-content-lessBottom">
            {contents_2}
            <br />
          </p>
        </div>
        <div className="side">
          <p className="side-title">
            ค่าตอบแทนการขายผ่าน Apple In-app Purchased
          </p>
          {contents_3.map((e, index) => (
            <p className="side-content-lessBottom" key={index}>
              {e}
              <br />
            </p>
          ))}
        </div>
        <div className="side">
          <p className={c ? "side-title-cc" : "side-title"}>การจ่ายค่าตอบแทน</p>
          {contents_4.map((e, index) => (
            <p className="side-content-lessBottom" key={index}>
              {e}
              <br />
            </p>
          ))}
        </div>
      </>
    );
  };
  //sideForWriter0(),
  const s = [sideForWriter1(), sideForWriter3()];
  return s[props.index];
};

// const ForWriter = () => {
//   return (
//     <>
//       {sideForWriter0(1)}
//       {sideForWriter1(1)}
//       {sideForWriter3(1)}
//     </>
//   );
// };

// export default ForWriter;
