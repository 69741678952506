import React from "react";
import { Col, Form, Input, Button, Radio, DatePicker, Row } from "antd";
import Address from "./Address";
import { ACTION_SHOW_LOADING } from "../../../Actions/Page";
import moment from "moment";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
class PersonalInfo extends React.Component {
  constructor(props) {
    super(props);
    this.citizenid = React.createRef();
    this.phone = React.createRef();
    this.state = {
      prefix: "mr",
      firstnameError: "",
      firstnameErrorMessage: "",
      lastnameError: "",
      lastnameErrorMessage: "",
      displaynameError: "",
      displaynameErrorMessage: "",
      birthdateError: "",
      birthdateErrorMessage: "",
      phoneError: "",
      phoneErrorMessage: "",
      citizenidError: "",
      citizenidErrorMessage: "",
      citizenid: "",
      prevLength_citizen: [0],
      prevPos_citizen: [0],
      phone: "",
      prevLength_phone: [0],
      prevPos_phone: [0],
      addressComplete: false,
      addressFormData: {},
      hasDisplayName: 1
    };
  }

  componentDidMount() {
    // this.props.dispatch(ACTION_SHOW_LOADING(false));
    this.props.form.validateFields();
    let hasDisplayName =
      this.props.match.path === "/register/creator"
        ? decodeURIComponent(this.props.history.location.search.split("=")[1])
        : "";
    if (hasDisplayName === "false") {
      this.setState({ hasDisplayName: 0 });
    }
  }

  fieldOnchangehandle = e => {
    this.props.form.validateFields();
    switch (e.target.name) {
      case "phone":
        this.setState({ phone: e.target.value });
        //   let newphone = e.target.value
        //     .match(/\d*/g)
        //     .join("")
        //     .match(/(\d{0,3})(\d{0,3})(\d{0,4})/)
        //     .slice(1)
        //     .join("-")
        //     .replace(/-*$/g, "");
        //   if (newphone.length <= 12) {
        //     let newPos;
        //     let prevLength_phone = this.state.prevLength_phone[1];
        //     let prevPos_phone = this.state.prevPos_phone[1];
        //     let curPos = e.target.selectionStart;
        //     e.persist();
        //     if (this.state.phone.length < 17) {
        //       this.setState(
        //         {
        //           phone: newphone,
        //           prevLength_phone: [prevLength_phone, newphone.length],
        //           prevPos_phone: [prevPos_phone, curPos]
        //         },
        //         console.log(), //can't be deleted because this is a callback func
        //         (prevLength_phone = this.state.prevLength_phone[
        //           this.state.prevLength_phone.length - 1
        //         ]),
        //         (newPos = e.target.selectionStart)
        //       );
        //     } else if (this.state.phone.length === 12 && newphone.length < 12) {
        //       this.setState(
        //         {
        //           phone: newphone,
        //           prevLength_phone: [prevLength_phone, newphone.length],
        //           prevPos_phone: [prevPos_phone, curPos]
        //         },
        //         console.log(),
        //         (prevLength_phone = this.state.prevLength_phone[
        //           this.state.prevLength_phone.length - 1
        //         ]),
        //         (newPos = e.target.selectionStart)
        //       );
        //     } else {
        //       this.setState(
        //         { prevPos_phone: [prevPos_phone, curPos] },
        //         console.log(),
        //         (prevPos_phone = this.state.prevPos_phone[1])
        //       );
        //     }
        //     setTimeout(() => {
        //       if (newphone.length < prevLength_phone) {
        //         if (newPos < newphone.length) {
        //           this.phone.current.input.setSelectionRange(newPos, newPos);
        //         } else {
        //           this.phone.current.input.setSelectionRange(newphone.length, newphone.length);
        //         }
        //       } else if (newphone.length > prevLength_phone) {
        //         if (newPos < newphone.length - 1) {
        //           if (newphone.charAt(newPos - 1) === "-") {
        //             this.phone.current.input.setSelectionRange(newPos + 1, newPos + 1);
        //           } else {
        //             this.phone.current.input.setSelectionRange(newPos, newPos);
        //           }
        //         } else if (newPos === newphone.length) {
        //           this.phone.current.input.setSelectionRange(newPos, newPos);
        //         }
        //       } else if (prevLength_phone === newphone.length) {
        //         if (newphone.length === 12)
        //           this.phone.current.input.setSelectionRange(curPos, curPos);
        //         else {
        //           this.phone.current.input.setSelectionRange(newPos, newPos);
        //         }
        //       }
        //     }, 1);
        //   }
        break;
      case "citizenid":
        let newid = e.target.value
          .match(/\d*/g)
          .join("")
          .match(/(\d{0,1})(\d{0,4})(\d{0,5})(\d{0,2})(\d{0,1})/)
          .slice(1)
          .join("-")
          .replace(/-*$/g, "");
        if (newid.length <= 17) {
          let newPos;
          let prevLength_citizen = this.state.prevLength_citizen[1];
          let prevPos_citizen = this.state.prevPos_citizen[1];
          let curPos = e.target.selectionStart;
          e.persist();
          if (this.state.citizenid.length < 17) {
            this.setState(
              {
                citizenid: newid,
                prevLength_citizen: [prevLength_citizen, newid.length],
                prevPos_citizen: [prevPos_citizen, curPos]
              },
              console.log(), //can't be deleted because this is a callback func
              (prevLength_citizen = this.state.prevLength_citizen[
                this.state.prevLength_citizen.length - 1
              ]),
              (newPos = e.target.selectionStart)
            );
          } else if (this.state.citizenid.length === 17 && newid.length < 17) {
            this.setState(
              {
                citizenid: newid,
                prevLength_citizen: [prevLength_citizen, newid.length],
                prevPos_citizen: [prevPos_citizen, curPos]
              },
              console.log(),
              (prevLength_citizen = this.state.prevLength_citizen[
                this.state.prevLength_citizen.length - 1
              ]),
              (newPos = e.target.selectionStart)
            );
          } else {
            this.setState(
              { prevPos_citizen: [prevPos_citizen, curPos] },
              console.log(),
              (prevPos_citizen = this.state.prevPos_citizen[1])
            );
          }
          setTimeout(() => {
            if (newid.length < prevLength_citizen) {
              if (newPos < newid.length) {
                this.citizenid.current.input.setSelectionRange(newPos, newPos);
              } else {
                this.citizenid.current.input.setSelectionRange(newid.length, newid.length);
              }
            } else if (newid.length > prevLength_citizen) {
              if (newPos < newid.length - 1) {
                if (newid.charAt(newPos - 1) === "-") {
                  this.citizenid.current.input.setSelectionRange(newPos + 1, newPos + 1);
                } else {
                  this.citizenid.current.input.setSelectionRange(newPos, newPos);
                }
              } else if (newPos === newid.length) {
                this.citizenid.current.input.setSelectionRange(newPos, newPos);
              }
            } else if (prevLength_citizen === newid.length) {
              if (newid.length === 17)
                this.citizenid.current.input.setSelectionRange(curPos, curPos);
              else {
                this.citizenid.current.input.setSelectionRange(newPos, newPos);
              }
            }
          }, 1);
        }
        break;
      default:
        break;
    }
  };
  fieldOnchangehandleError = e => {
    this.props.form.validateFields();
    if (e && e.target) {
      switch (e.target.name) {
        case "firstname":
          if (e.target.value === "" || /\d/.test(e.target.value)) {
            this.setState({
              firstnameError: "error",
              firstnameErrorMessage: "กรุณากรอกชื่อให้ถูกต้อง"
            });
          } else {
            this.setState({ firstnameError: "", firstnameErrorMessage: "" });
          }
          break;
        case "lastname":
          if (e.target.value === "" || /\d/.test(e.target.value)) {
            this.setState({
              lastnameError: "error",
              lastnameErrorMessage: "กรุณากรอกนามสกุลให้ถูกต้อง"
            });
          } else {
            this.setState({ lastnameError: "", lastnameErrorMessage: "" });
          }
          break;
        case "displayname":
          if (e.target.value === "") {
            this.setState({
              displaynameError: "error",
              displaynameErrorMessage: "กรุณากรอกชื่อ"
            });
          } else {
            this.setState({ displaynameError: "", displaynameErrorMessage: "" });
          }
          break;
        case "phone":
          if (
            e.target.value !== ""
            // e.target.value.replace(/[^0-9]/g, "").length === 10 ||
            // e.target.value.replace(/[^0-9]/g, "").length === 11
          ) {
            this.setState({ phoneError: "", phoneErrorMessage: "" });
          } else {
            this.setState({
              phoneError: "error",
              phoneErrorMessage: "กรุณาให้ข้อมูลเบอร์โทรศัพท์"
            });
          }
          break;
        case "citizenid":
          if (
            e.target.value.replace(/[^0-9]/g, "").length === 13 ||
            e.target.value.replace(/[^0-9]/g, "").length === 14
          ) {
            this.setState({ citizenidError: "", citizenidErrorMessage: "" });
          } else {
            this.setState({
              citizenidError: "error",
              citizenidErrorMessage: "กรุณาให้ข้อมูลเป็นตัวเลข 13 หลัก"
            });
          }
          break;
        case "birthdate":
          if (e.target.value === "") {
            this.setState({
              birthdateError: "error",
              birthdateErrorMessage: "กรุณาให้ข้อมูลวันเกิด"
            });
          } else {
            this.setState({ birthdateError: "", birthdateErrorMessage: "" });
          }
          break;
        default:
          break;
      }
    }
  };
  errorHandleCheck = fieldsError => {
    // console.log("P errorHandleCheck", fieldsError);
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };
  disabledDate = current => {
    return current > moment().subtract(18, "years");
  };
  handlePrev = e => {
    e.preventDefault();
    const values = this.props.form.getFieldsValue();
    this.props.onPrevHandle("PersonalInfo", {
      prefix: this.state.prefix,
      ...values,
      citizenid: this.state.citizenid,
      phone: this.state.phone,
      address: this.state.addressFormData
    });
  };
  handleSubmit = e => {
    e.preventDefault();
    this.props.form.validateFields((err, values) => {
      if (!err) {
        this.props.onSubmitHandle("PersonalInfo", {
          prefix: this.state.prefix,
          ...values,
          citizenid: this.state.citizenid,
          phone: this.state.phone,
          address: this.state.addressFormData
        });
      }
    });
  };
  makeAddressComplete = (boo, values) => {
    this.setState({ addressComplete: boo, addressFormData: values });
  };
  render() {
    const { getFieldDecorator, getFieldsError } = this.props.form;
    return (
      <Form name="creator_personalInfo" className="personalInfo" onSubmit={this.handleSubmit}>
        <label>คำนำหน้า</label>
        <Form.Item>
          {getFieldDecorator("prefix", {
            id: "prefix",
            rules: [
              {
                required: true,
                message: "กำหนดคำนำหน้าชื่อ"
              }
            ]
          })(
            <Radio.Group>
              <Radio value={"mr"}>นาย</Radio>
              <Radio value={"mrs"}>นาง</Radio>
              <Radio value={"miss"}>นางสาว</Radio>
            </Radio.Group>
          )}
        </Form.Item>
        <Row gutter={16}>
          <Col span={12}>
            <label>ชื่อ</label>
            <Form.Item
              validateStatus={this.state.firstnameError ? "error" : ""}
              help={this.state.firstnameErrorMessage}
            >
              {getFieldDecorator("firstname", {
                id: "firstname",
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกชื่อ"
                  }
                ]
              })(
                <Input
                  name="firstname"
                  placeholder="กรุณากรอกชื่อ"
                  onChange={this.fieldOnchangehandleError}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <label>นามสกุล</label>
            <Form.Item
              validateStatus={this.state.lastnameError ? "error" : ""}
              help={this.state.lastnameErrorMessage}
            >
              {getFieldDecorator("lastname", {
                id: "lastname",
                rules: [
                  {
                    required: true,
                    message: "กรุณากรอกนามสกุล"
                  }
                ]
              })(
                <Input
                  name="lastname"
                  placeholder="กรุณากรอกนามสกุล"
                  onChange={this.fieldOnchangehandleError}
                />
              )}
            </Form.Item>
          </Col>
          {this.state.hasDisplayName && this.props.userHasEmail ? (
            <Col span={24}>
              <label>ชื่อโปรไฟล์</label>
              <Form.Item
                validateStatus={this.state.displaynameError ? "error" : ""}
                help={this.state.displaynameErrorMessage}
              >
                {getFieldDecorator("displayname", {
                  id: "displayname",
                  rules: [
                    {
                      required: true,
                      message: "กรุณากรอกชื่อ"
                    }
                  ]
                })(
                  <Input
                    name="displayname"
                    placeholder="กรุณากรอกชื่อ"
                    onChange={this.fieldOnchangehandleError}
                  />
                )}
              </Form.Item>
            </Col>
          ) : (
            ""
          )}
        </Row>
        <Row gutter={16}>
          <Col span={12}>
            <label>วันเกิด</label>
            <Form.Item
              validateStatus={this.state.birthdateError ? "error" : ""}
              help={this.state.birthdateErrorMessage}
            >
              {getFieldDecorator("birthdate", {
                id: "birthdate",
                rules: [
                  {
                    required: true,
                    message: "กรุณาให้ข้อมูลวันเกิด"
                  }
                ]
              })(
                <DatePicker
                  onChange={this.fieldOnchangehandleError}
                  name="birthdate"
                  defaultPickerValue={moment().subtract(18, "years")}
                  disabledDate={this.disabledDate}
                  placeholder={"วัน เดือน ปี"}
                  format={"DD/MM/YYYY"}
                  showToday={false}
                />
              )}
            </Form.Item>
          </Col>
          <Col span={12}>
            <label>เบอร์โทรศัพท์</label>
            <Form.Item
              validateStatus={this.state.phoneError ? "error" : ""}
              help={this.state.phoneErrorMessage}
            >
              <Input
                name="phone"
                ref={this.phone}
                placeholder="กรุณากรอกเบอร์โทรศัพท์"
                value={this.state.phone}
                onChange={e => {
                  this.fieldOnchangehandle(e);
                  this.fieldOnchangehandleError(e);
                }}
              />
            </Form.Item>
          </Col>
          <Col span={24}>
            <label>เลขบัตรประจำตัวประชาชน</label>
            <Form.Item
              validateStatus={this.state.citizenidError ? "error" : ""}
              help={this.state.citizenidErrorMessage}
            >
              <Input
                name="citizenid"
                ref={this.citizenid}
                placeholder="0-0000-00000-00-0"
                value={this.state.citizenid}
                onChange={e => {
                  this.fieldOnchangehandle(e);
                  this.fieldOnchangehandleError(e);
                }}
              />
            </Form.Item>
          </Col>
        </Row>

        <Address
          type="personal"
          addressComplete={this.makeAddressComplete}
          valuesAddress={this.props.values.address}
        />

        <Row type="flex" justify="space-between">
          <Col span={12}>
            {this.props.userHasEmail ? (
              ""
            ) : (
              <Form.Item>
                <Button type="primary" onClick={this.handlePrev} className="prevbtn">
                  ย้อนกลับ
                </Button>
              </Form.Item>
            )}
          </Col>
          <Col span={12} align="right">
            <Form.Item>
              <Button
                type="primary"
                htmlType="submit"
                className="nextbtn"
                disabled={
                  this.errorHandleCheck(getFieldsError()) ||
                  this.state.firstnameErrorMessage !== "" ||
                  this.state.lastnameErrorMessage !== "" ||
                  this.state.displaynameErrorMessage !== "" ||
                  this.state.birthdateErrorMessage !== "" ||
                  !this.state.phone ||
                  this.state.phoneErrorMessage !== "" ||
                  !this.state.citizenid ||
                  this.state.citizenidErrorMessage !== "" ||
                  !this.state.addressComplete
                }
              >
                ถัดไป
              </Button>
            </Form.Item>
            {/* {this.errorHandleCheck(getFieldsError()) ? <div>disabled</div> : <div>eiei</div>}
            {this.state.firstnameErrorMessage !== "" ? <div>disabled</div> : <div>eiei</div>}
            {this.state.lastnameErrorMessage !== "" ? <div>disabled</div> : <div>eiei</div>}
            {this.state.birthdateErrorMessage !== "" ? <div>disabled</div> : <div>eiei</div>}
            {!this.state.phone ? <div>disabled</div> : <div>eiei</div>}
            {this.state.phoneErrorMessage !== "" ? <div>disabled</div> : <div>eiei</div>}
            {!this.state.citizenid ? <div>disabled</div> : <div>eiei</div>}
            {this.state.citizenidErrorMessage !== "" ? <div>disabled</div> : <div>eiei</div>}
            {!this.state.addressComplete ? <div>disabled</div> : <div>eiei</div>} */}
          </Col>
        </Row>
      </Form>
    );
  }
}
export default withRouter(
  connect(null)(
    Form.create({
      name: "creator_personalInfo",
      mapPropsToFields(props) {
        const values = props.values;
        return {
          prefix: Form.createFormField({
            value: values.prefix
          }),
          firstname: Form.createFormField({
            value: values.firstname
          }),
          lastname: Form.createFormField({
            value: values.lastname
          }),
          displayname: Form.createFormField({
            value: values.displayname
          }),
          birthdate: Form.createFormField({
            value: values.birthdate
          })
        };
      }
    })(PersonalInfo)
  )
);
