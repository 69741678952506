import React, { Component } from "react";
import PropTypes from "prop-types";
import { connect } from "react-redux";
import { showLoading } from "../../Actions/Page";

class FacebookLogin extends Component {
  componentDidMount() {
    // this.initFacebookSDK();
    window.fbAsyncInit = function () {
      window.FB.init({
        appId: "676572462682428",
        cookie: true,
        autoLogAppEvents: true,
        xfbml: true,
        version: "v6.0",
        status: true,
      });
      window.FB.AppEvents.logPageView();
    };
  }
  componentWillUnmount() {}
  initializeFacebookLogin() {}
  facebookLogin = () => {
    this.props.dispatch(showLoading(true));
    if (!window.FB) return;
    window.FB.getLoginStatus((response) => {
      if (response.status === "connected") {
        this.facebookLoginHandler(response);
      } else {
        window.FB.login(this.facebookLoginHandler, {
          scope: "email,public_profile",
        });
      }
    });
  };
  facebookLoginHandler = (response) => {
    console.log(response);
    if (response.status === "connected") {
      window.FB.api("/me", { fields: "id,first_name,picture,email,last_name" }, (userData) => {
        let result = {
          ...response,
          user: userData,
        };
        this.props.onLogin(true, result);
      });
    } else {
      this.props.onLogin(false);
    }
  };
  render() {
    let { children } = this.props;
    return (
      <div
        className={this.props.className}
        onClick={this.facebookLogin}
        style={{ display: "initial" }}
      >
        {children}
      </div>
    );
  }
}

FacebookLogin.propTypes = {
  children: PropTypes.element,
  onLogin: PropTypes.func,
  className: PropTypes.string,
  dispatch: PropTypes.func,
};
export default connect()(FacebookLogin);
