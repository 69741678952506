import React, { useState, useEffect } from "react";
import EbookCollection from "../Collection/EbookCollection";
import { Button, Tag } from "antd";
import PropTypes from "prop-types";
import { fetchEbookData } from "../../Actions/Ebook";
import VisibilitySensor from "react-visibility-sensor";
import { fetchRecommendKeyword } from "../../Actions/Ebook";
import { flashSaleExp } from "../../Actions/Page";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { Link } from "react-router-dom";
import moment from "moment";

const HomeList = (props) => {
  const [ebooks, setEbooks] = useState([]);

  const onChange = (isVisible) => {
    if (isVisible) {
      if (ebooks.length === 0) {
        getEbookData();
      }
    }
  };

  useEffect(() => {
    if (ebooks.length === 0) {
      getEbookData();
    }
  }, []);

  useEffect(() => {
    if (props.relatedEbook) {
      if (ebooks.length === 0) {
        getEbookData();
      } else {
        setEbooks(props.relatedEbook);
      }
    }
  }, [props.relatedEbook]);

  const getPromotionActiveList = (promotion) => {
    return promotion.filter((el) => {
      return el.endSaleDate > moment().unix();
    });
  };

  const getEbookData = async () => {
    if (filter) {
      let result =
        filter === "promotion"
          ? await fetchEbookData(filter, "date", "true", false, 9)
          : filter === "bestseller"
          ? await fetchEbookData(filter, "sale", "false", false, 9)
          : await fetchEbookData(filter, "date", "false", false, 9);
      if (result.status && result.data && result.data.ebooks) {
        if (filter === "promotion") {
          console.log(result.data);
          let filterPromotions = getPromotionActiveList(result.data.ebooks[0].promotions);
          if (result.data.ebooks[0].promotions ) {
            if(filterPromotions?.length > 0){
              props.dispatch(
                flashSaleExp(filterPromotions[0].endSaleDate)
              );
            }
            
          } else if (result.data.ebooks[1].promotions) {
            props.dispatch(
              flashSaleExp(result.data.ebooks[1].promotions[0].endSaleDate)
            );
          }
        }
        setEbooks(result.data.ebooks.slice(0, 9));
      }
    }
    if (props.subcatEbook && props.subcatEbook.length) {
      setEbooks(props.subcatEbook.slice(0, 9));
    }
    if (props.relatedEbook && props.relatedEbook.length) {
      setEbooks(props.relatedEbook.slice(0, 9));
    }
  };

  const { history, match, filter, subcatId } = props;
  return props.relatedEbook ? (
    props.relatedEbook.length > 0 && (
      <div
        className="homelist"
        style={{ marginLeft: "0px", marginRight: "0px" }}
      >
        <h2 className="homelist-related" style={{ fontSize: "16px" }}>
          {props.title}
        </h2>
        {ebooks.length > props.numCollection && (
          <Button
            type="link"
            className="viewmore-related"
            onClick={() =>
              history.push(
                `/relatedebooks/${props.bookUID}?title=${props.relatedTitle}`
              )
            }
          >
            ดูทั้งหมด >
          </Button>
        )}
        <EbookCollection
          ebooks={ebooks.slice(0, props.numCollection) || []}
          numCollection={props.numCollection}
          flashSale={props.flashSale}
          rowOnLoading={1}
          isRelatedBook={true}
          screenWidth={props.Page.screensize.width}
        />
      </div>
    )
  ) : (
    // <VisibilitySensor onChange={onChange} offset={{ top: 0 }} partialVisibility={true}>
    <div className="homelist">
      <h2 className={"homelist"}>{props.title}</h2>
      {ebooks.length > props.numCollection && (
        <Button
          type="link"
          className={"viewmore"}
          onClick={() =>
            subcatId
              ? history.push(
                  `${match.url}/subcategories/${subcatId}?title=${props.title}`
                )
              : history.push(`/all/${filter}`)
          }
        >
          ดูทั้งหมด >
        </Button>
      )}
      <EbookCollection
        ebooks={ebooks.slice(0, props.numCollection) || []}
        numCollection={props.numCollection}
        preloadNumCollection={props.preloadNumCollection}
        flashSale={props.flashSale}
        rowOnLoading={1}
        isHomePage={true}
        screenWidth={props.Page.screensize.width}
      />
    </div>
    // </VisibilitySensor>
  );
};

const TagList = (props) => {
  const [tags, setTags] = useState([]);

  // const onChange = async (isVisible) => {
  //   if (isVisible && tags.length === 0) {
  //     let keyword = await fetchRecommendKeyword();
  //     if (keyword.status && keyword.data) {
  //       setTags(keyword.data.keywords);
  //     }
  //   }
  // };

  useEffect(() => {
    const fetchTags = async () => {
      if (tags.length === 0) {
        let keyword = await fetchRecommendKeyword();
        if (keyword.status && keyword.data) {
          setTags(keyword.data.keywords);
        }
      }
    };
    fetchTags();
  }, []);

  return props.isSearchView ? (
    <div className="homelist">
      <h2 className="homelist-searchview" style={{ textAlign: "center" }}>
        {tags.length > 0 ? props.title : ""}
      </h2>
      <div className="tag-list-searchview">
        {tags.map((el, index) => {
          return (
            <Link to={`/keyword/${el.keyword}`}>
              <div key={index} style={{ textAlign: "center" }}>
                {el}
              </div>
            </Link>
          );
        })}
      </div>
    </div>
  ) : (
    // <VisibilitySensor onChange={onChange} offset={{ top: 0 }} partialVisibility={true}>
    <div className="homelist">
      <h2 className="homelist">{props.title}</h2>
      <div className="tag-list">
        {tags.map((el, index) => {
          return (
            <Tag key={index}>
              <Link to={`/keyword/${el.keyword || el}`}>
                {el.keyword || el}
              </Link>
            </Tag>
          );
        })}
      </div>
    </div>
  );
  // </VisibilitySensor>
};

const mapStateToProps = (state) => {
  return {
    Payment: state.Payment,
    authentication: state.Authentication,
    Page: state.Page,
  };
};

TagList.propTypes = {
  title: PropTypes.string,
};

HomeList.propTypes = {
  title: PropTypes.string,
  filter: PropTypes.string,
  numCollection: PropTypes.number,
};

export const TagListComponent = TagList;
export default withRouter(connect(mapStateToProps)(HomeList));
