import React, { useEffect, useState } from "react";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import { navModal } from "../../../Actions/Page";
import {
  registerAuthor,
  registerPublisher,
  registerPayment,
  requestMemberInfo,
  receiveMemberInfo
} from "../../../Actions/Member";
import authenUtility from "../../../Utilitys/Authentication";
import { Steps, Modal } from "antd";
import AccountInfo from "./AccountInfo";
import PersonalInfo from "./PersonalInfo";
import PaymentInfo from "./PaymentInfo";
import CompanyInfo from "./CompanyInfo";
import { fetchLogOut } from "../../../Actions/Authentication";
const { Step } = Steps;
// const { Option } = Select
const steps = [
  {
    title: "สมัครสมาชิก",
    content: "First-content"
  },
  {
    title: "ข้อมูลส่วนตัว",
    content: "Second-content"
  },
  {
    title: "ข้อมูลการจ่ายเงิน",
    content: "Second-content"
  }
];

const RegisterCreator = props => {
  const memberUID = authenUtility.getMemberUID(props.authentication.userToken || "");
  const [currentStep, setCurrentStep] = useState(-1); //default = -1
  const [personOrcompany, setPersonOrcompany] = useState("p");
  const [accountInfo, setAccountInfo] = useState({});
  const [personalInfo, setPersonalInfo] = useState({ prefix: "mr" });
  const [companyInfo, setCompanyInfo] = useState({});
  const [paymentInfo, setPaymentInfo] = useState({ bankname: "กรุณาเลือกธนาคาร" });
  const [showSuccessModal, setShowSuccessModal] = useState(false);
  const [userHasEmail, setUserHasEmail] = useState(false);

  useEffect(() => {
    props.dispatch(navModal(false));
  }, []);

  useEffect(() => {
    if (props.memberInfo.email && props.memberInfo.email !== "") {
      if (props.memberInfo.memberType === "creator") {
        setCurrentStep(4);
      } else {
        setCurrentStep(1);
        setUserHasEmail(true);
        setPersonalInfo({ prefix: "mr", displayname: props.memberInfo.displayName });
      }
    } else {
      setCurrentStep(0);
    }
  }, [props.memberInfo]);

  useEffect(() => {
    if (currentStep === 1 && (!authenUtility.isLogin() || !props.memberInfo.email)) {
      if (accountInfo.username && authenUtility.isLogin()) {
        setCurrentStep(1);
      } else {
        setCurrentStep(0);
      }
    }
  }, [currentStep]);

  useEffect(() => {
    if (memberUID === "") {
      setCurrentStep(0);
    } else {
      if (props.memberInfo.memberType === "creator") {
        setCurrentStep(4);
      } else {
        setCurrentStep(1);
      }
    }
    // if (memberUID === "") {
    //   setCurrentStep(0);
    // } else {
    //   if (props.memberInfo.email && props.memberInfo.email !== "") {
    //     setCurrentStep(1);
    //     setUserHasEmail(true);
    //     setPersonalInfo({ prefix: "mr", displayname: props.memberInfo.displayName });
    //   } else {
    //     setCurrentStep(0);
    //   }
    // }
  }, [memberUID]);

  const successRegister = async data => {
    const res = await registerPayment(data);
    if (res.status) {
      setCurrentStep(3);
      window.scrollTo(0, 0);
      setShowSuccessModal(true);
    }
  };

  const onSubmitHandle = async (formName, values) => {
    if (formName === "AccountInfo") {
      // console.log("AccountInfo", values);
      setAccountInfo(values);
      setCurrentStep(1);
      window.scrollTo(0, 0);
    }
    if (formName === "PersonalInfo") {
      // console.log("PersonalInfo", values);
      setPersonalInfo(values);
      setCurrentStep(2);
      window.scrollTo(0, 0);
    }
    if (formName === "CompanyInfo") {
      // console.log("CompanyInfo", values);
      setCompanyInfo(values);
      setCurrentStep(2);
      window.scrollTo(0, 0);
    }
    if (formName === "PaymentInfo") {
      if (personOrcompany === "p") {
        //person
        var email = "";
        var displayname = "";
        if (accountInfo && accountInfo.username && accountInfo.displayname) {
          email = accountInfo.username;
          displayname = accountInfo.displayname;
        } else {
          email = props.memberInfo.email;
          displayname = personalInfo.displayname;
        }
        var title = "none";
        switch (personalInfo.prefix) {
          case "mr":
            title = "นาย";
            break;
          case "mrs":
            title = "นาง";
            break;
          case "miss":
            title = "นางสาว";
            break;
          default:
            title = "none";
            break;
        }
        var birthday =
          personalInfo.birthdate.date() +
          1 +
          "-" +
          (personalInfo.birthdate.month() + 1) +
          "-" +
          personalInfo.birthdate.year();
        var idCard = personalInfo.citizenid.replace(/[^0-9]/g, "");
        var address = personalInfo.address;

        const authorObj = {
          title: title,
          name: personalInfo.firstname,
          surname: personalInfo.lastname,
          birthday: birthday,
          email: email,
          displayName: displayname,
          phoneNumber: personalInfo.phone,
          idCard: idCard,
          zipcode: address.postcode,
          address: address.address,
          district: address.district,
          subDistrict: address.subdistrict,
          province: address.province
        };
        const res = await registerAuthor(authorObj);
        if (res.status) {
          sendPaymentObj(values);
        }
      } else if (personOrcompany === "c") {
        //company
        var address = companyInfo.address;
        const publisherObj = {
          publisherName: companyInfo.publishername,
          zipcode: address.postcode,
          address: address.address,
          district: address.district,
          subDistrict: address.subdistrict,
          province: address.province,
          corporateName: companyInfo.companyname,
          taxId: companyInfo.taxpayerid,
          registratorName: companyInfo.contactname,
          registratorEmail: companyInfo.emailforreport
        };
        const res = await registerPublisher(publisherObj);
        if (res.status) {
          sendPaymentObj(values);
        }
      }
    }
  };
  const sendPaymentObj = v => {
    var address = v.address;
    const paymentObj = {
      zipcode: address.postcode,
      address: address.address,
      district: address.district,
      subDistrict: address.subdistrict,
      province: address.province,
      bankId: v.bankname,
      accountId: v.bankaccountnumber,
      accountName: v.bankaccountname
    };
    // console.log("PaymentInfo", paymentObj, personOrcompany);
    setPaymentInfo(v);
    successRegister(paymentObj);
  };
  const onPrevHandle = (formName, values) => {
    if (currentStep > 0) {
      if (formName === "AccountInfo") {
        setAccountInfo("AccountInfo", values);
      }
      if (formName === "PersonalInfo") {
        // console.log("PersonalInfo", values);
        setPersonalInfo(values);
      }
      if (formName === "CompanyInfo") {
        // console.log("CompanyInfo", values);
        setCompanyInfo(values);
      }
      if (formName === "PaymentInfo") {
        // console.log("PaymentInfo", values);
        setPaymentInfo(values);
      }
      setCurrentStep(currentStep - 1);
      window.scrollTo(0, 0);
    }
  };
  return (
    <>
      {currentStep === 4 ? (
        <div className="register-creator-container-isAuthor">
          <p className="isAuthor">เป็นบัญชีนักเขียน/สำนักพิมพ์เรียบร้อยแล้ว</p>
          <img
            src="/images/badges/alreadyCreator.png"
            style={{
              width: props.Page.screensize.width < 370 ? props.Page.screensize.width - 80 : "100%",
              height: "100%"
            }}
            alt=""
          />
        </div>
      ) : (
        <div className="register-creator-container">
          {currentStep > -1 && currentStep < 4 ? (
            authenUtility.isLogin() ? (
              <h1>นักเขียน/สำนักพิมพ์</h1>
            ) : (
              <h1>สมัครนักเขียน/สำนักพิมพ์</h1>
            )
          ) : (
            ""
          )}
          {currentStep > -1 && currentStep < 4 ? (
            <Steps current={currentStep}>
              {steps.map(item => (
                <Step
                  key={item.title}
                  title={item.title}
                  // onClick={() => this.setState({ currentStep: 0 })}
                />
              ))}
            </Steps>
          ) : (
            ""
          )}
          {currentStep === 1 && (
            <div style={{ marginTop: "32px", marginBottom: "32px" }}>
              <button
                onClick={() => {
                  setPersonOrcompany("p");
                }}
                style={{ borderRight: "1px solid #189aeb" }}
                className={personOrcompany === "p" ? "pOrc-active" : "pOrc"}
              >
                บุคคล
              </button>
              <button
                onClick={() => {
                  setPersonOrcompany("c");
                }}
                style={{ borderLeft: "none" }}
                className={personOrcompany === "c" ? "pOrc-active" : "pOrc"}
              >
                นิติบุคคล
              </button>
            </div>
          )}
          <div style={{ marginTop: "40px" }}>
            {currentStep === -1 ? (
              ""
            ) : currentStep === 0 ? (
              <AccountInfo
                key={1}
                onSubmitHandle={onSubmitHandle}
                values={accountInfo}
                isLogin={authenUtility.isLogin()}
              />
            ) : currentStep === 1 ? (
              personOrcompany === "p" ? (
                <PersonalInfo
                  key={2}
                  userHasEmail={userHasEmail}
                  onSubmitHandle={onSubmitHandle}
                  values={personalInfo}
                  onPrevHandle={onPrevHandle}
                />
              ) : (
                <CompanyInfo
                  key={2}
                  userHasEmail={userHasEmail}
                  onSubmitHandle={onSubmitHandle}
                  values={companyInfo}
                  onPrevHandle={onPrevHandle}
                />
              )
            ) : currentStep === 2 ? (
              personOrcompany === "p" ? (
                <PaymentInfo
                  key={3}
                  onSubmitHandle={onSubmitHandle}
                  addressStepTwo={personalInfo.address}
                  values={paymentInfo}
                  onPrevHandle={onPrevHandle}
                />
              ) : (
                personOrcompany === "c" && (
                  <PaymentInfo
                    key={3}
                    onSubmitHandle={onSubmitHandle}
                    addressStepTwo={companyInfo.address}
                    values={paymentInfo}
                    onPrevHandle={onPrevHandle}
                  />
                )
              )
            ) : currentStep === 3 ? (
              <div></div>
            ) : (
              ""
            )}
          </div>
        </div>
      )}
      <Modal
        visible={showSuccessModal}
        footer={null}
        style={{ top: "35%" }}
        maskClosable={false}
        onCancel={() => {
          props.history.push("/");
        }}
        width={props.Page.screensize.width < 338 ? "95%" : 338}
      >
        <div className="policy-content">
          <p className="title" style={{ marginBottom: "8px" }}>
            ลงทะเบียนเรียบร้อยแล้ว
          </p>
          <div className="imgSuccessRegister">
            <img
              src="/images/logos/successRegister.png"
              style={{ width: "142px", height: "142px", marginBottom: "32px" }}
              alt=""
            />
            <br />
            <button
              onClick={() => {
                // window.location = authenUtility.URL;
                props.history.push("/");
                const fetchMemberInfo = async () => {
                  let memberInfo = await requestMemberInfo();
                  if (memberInfo.status) {
                    props.dispatch(receiveMemberInfo(memberInfo.data));
                  } else {
                    props.dispatch(fetchLogOut());
                  }
                };
                if (memberUID !== "") {
                  fetchMemberInfo();
                }
              }}
              className="okButton"
            >
              ตกลง
            </button>
          </div>
        </div>
      </Modal>
    </>
  );
};

const mapStateToProps = state => {
  return {
    authentication: state.Authentication,
    Page: state.Page,
    memberInfo: state.Member.memberInfo
  };
};
export default withRouter(connect(mapStateToProps)(RegisterCreator));
