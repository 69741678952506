import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Button, Radio, Modal, Select } from "antd";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { fetchPaymentHistory, fetchOrderDetail } from "../../../Actions/Payment";
import { ACTION_SHOW_LOADING } from "../../../Actions/Page";
import { ClipLoader } from "react-spinners";
import { connect } from "react-redux";
import { millisecsToDateTime } from "../../../Utilitys/changeFormatDateTime";
import Helmet from "react-helmet";

const { Option } = Select;
const PaymentHistory = (props) => {
  const [history, setHistory] = useState(undefined);
  const [contentType, setContentType] = useState("ebook");
  const [lastEvaluatedKey, setLastEvaluatedKey] = useState({});
  const [isEmpty, setIsEmpty] = useState(false);
  const [orderOnClick, setOrderOnClick] = useState(undefined);
  const [isOrderOnClick, setIsOrderOnClick] = useState(false);
  const [loadingBottom, setLoadingBottom] = useState(false);
  const loaderHeight = 50;

  const actionPaymentHistory = async (mode, contentType) => {
    if (mode === "new") {
      const res = await fetchPaymentHistory(contentType);
      if (res.status) {
        if (res.data.histories.length > 0) {
          setIsEmpty(false);
          setHistory(res.data.histories);
          setLastEvaluatedKey(
            res.data.lastEvaluatedKey || res.data.LastEvaluatedKey,
            setLoadingBottom(false)
          );
        } else {
          setIsEmpty(true);
        }
        props.dispatch(ACTION_SHOW_LOADING(false));
      } else {
        if (res.message === "No Data found") {
          setIsEmpty(true);
          props.dispatch(ACTION_SHOW_LOADING(false));
        }
      }
    } else if (mode === "append" && history.length && lastEvaluatedKey !== undefined) {
      const res = await fetchPaymentHistory(contentType, lastEvaluatedKey);
      if (res.status) {
        if (res.data.lastEvaluatedKey !== undefined || res.data.LastEvaluatedKey !== undefined) {
          setHistory([...history, ...res.data.histories]);
          setLastEvaluatedKey(
            res.data.lastEvaluatedKey || res.data.LastEvaluatedKey,
            setLoadingBottom(false)
          );
        } else {
          setLastEvaluatedKey(undefined);
        }
      } else {
        setLastEvaluatedKey(undefined);
      }
    }
  };

  useEffect(() => {
    props.dispatch(ACTION_SHOW_LOADING(true));
    document.addEventListener("scroll", trackScrolling);
    // eslint-disable-next-line
  }, []);

  const isBottom = () => {
    let scrollHeight = Math.max(
      document.body.scrollHeight,
      document.documentElement.scrollHeight,
      document.body.offsetHeight,
      document.documentElement.offsetHeight,
      document.body.clientHeight,
      document.documentElement.clientHeight
    );
    return window.pageYOffset + window.innerHeight >= scrollHeight - 3 * loaderHeight;
  };

  const trackScrolling = () => {
    if (isBottom()) {
      setLoadingBottom(true);
    }
  };

  useEffect(() => {
    if (loadingBottom) {
      actionPaymentHistory("append", contentType);
    }
    // eslint-disable-next-line
  }, [loadingBottom]);

  useEffect(() => {
    return () => {
      document.removeEventListener("scroll", trackScrolling);
    };
    // eslint-disable-next-line
  }, []);

  useEffect(() => {
    setHistory([]);
    if (contentType === "coin") {
      actionPaymentHistory("new", contentType);
    } else if (contentType === "ebook") {
      actionPaymentHistory("new", contentType);
    }
    // eslint-disable-next-line
  }, [contentType]);

  const onChangeContentType = (e) => {
    setContentType(e);
  };

  const showOrderDetail = async (orderCode) => {
    const res = await fetchOrderDetail(orderCode);
    if (res.status) {
      setIsOrderOnClick(true);
      setOrderOnClick(res.data);
    }
  };

  const paymentName = (type) => {
    switch (type) {
      case "CC":
        return "Credit Card";
      case "PP":
        return "PayPal";
      case "TF":
        return "โอนเงินธนาคาร";
      case "HC":
        return "Hytexts Coin";
      case "LP":
        return "Line Pay";
      case "IAP":
        return "In-App Purchase";
      case "RD":
        return "Redeem";
      default:
        return type;
    }
  };
  return (
    <div className="paymentHistory">
      <Helmet>
        <title>{`Hytexts: ประวัติการซื้อ`}</title>
      </Helmet>
      <Row
        className="contentTypeGroup"
        type="flex"
        align="middle"
        justify={props.Page.screensize.width >= 470 ? "space-between" : "start"}
        gutter={[0, 8]}
        style={{ margin: "16px 0 16px 0" }}
      >
        <Col
          span={props.Page.screensize.width >= 440 ? 12 : 24}
          align={props.Page.screensize.width >= 440 ? "left" : "center"}
        >
          <span className="prompt26">ประวัติการซื้อ</span>
        </Col>
        <Col
          span={props.Page.screensize.width >= 440 ? 12 : 24}
          align={props.Page.screensize.width >= 440 ? "right" : "center"}
        >
          <Select
            style={{ width: 137 }}
            dropdownMenuStyle={{ fontFamily: "Prompt" }}
            name="sortBy"
            optionFilterProp="children"
            onChange={onChangeContentType}
            onFocus={() => {}}
            onSearch={() => {}}
            value={contentType}
          >
            <Option key={"ebook"}>อีบุ๊ค</Option>
            <Option key={"coin"}>Hytexts Coin</Option>
          </Select>
          {/* <Radio.Button
            onClick={onChangeContentType}
            className={contentType === "ebook" ? "active" : "inactive"}
            style={
              contentType === "ebook"
                ? {
                    backgroundColor: "#189aeb",
                    color: "white"
                  }
                : { borderRight: "none" }
            }
            value={"ebook"}
          >
            อีบุ๊ค
          </Radio.Button>
          <Radio.Button
            onClick={onChangeContentType}
            className={contentType === "coin" ? "active" : "inactive"}
            style={
              contentType === "coin"
                ? {
                    backgroundColor: "#189aeb",
                    color: "white",
                    borderLeft: "none"
                  }
                : { borderLeft: "none" }
            }
            value={"coin"}
          >
            Hytexts Coin
          </Radio.Button> */}
        </Col>
      </Row>
      <Divider
        style={{
          margin: "0 0 16px 0",
          width: props.Page.screensize.width > 750 ? "692px" : "100%",
          background: "#dbdbdb",
        }}
      />

      {props.Page.screensize.width >= 576 ? (
        <Row type="flex" justify="space-between" style={{ margin: "16px 0 12px 0" }}>
          <Col span={7} align="left">
            <h3 className="BaiJamJuree16">เลขที่การสั่งซื้อ</h3>
          </Col>
          <Col span={7} align="left">
            <h3 className="BaiJamJuree16">วันที่สั่งซื้อ</h3>
          </Col>
          <Col span={7} align="left">
            <h3 className="BaiJamJuree16">ช่องทางการชำระเงิน</h3>
          </Col>
          <Col span={3} align="left">
            <h3 className="BaiJamJuree16">ราคา</h3>
          </Col>
        </Row>
      ) : (
        <Row type="flex" justify="space-between" style={{ margin: "16px 0 12px 0" }}>
          <Col span={10} align="left">
            <h3 className="BaiJamJuree16">เลขที่การสั่งซื้อ/</h3>
            <h3 className="BaiJamJuree16">วันที่สั่งซื้อ</h3>
          </Col>
          <Col span={7} align="left">
            <h3 className="BaiJamJuree16">ช่องทาง</h3>
          </Col>
          <Col span={3} align="left">
            <h3 className="BaiJamJuree16">ราคา</h3>
          </Col>
        </Row>
      )}

      <Divider
        style={{
          margin: "0 0 16px 0",
          width: props.Page.screensize.width > 750 ? "692px" : "100%",
          background: "#dbdbdb",
        }}
      />
      <div style={{ minHeight: "200px" }}>
        {!isEmpty && history ? (
          history.map((h) => {
            return props.Page.screensize.width >= 576 ? (
              <Row
                key={h.orderCode}
                type="flex"
                justify="space-between"
                style={{ margin: "16px 0 24px 0" }}
              >
                <Col span={7} align="left" className="orderCodeCol">
                  <h3
                    onClick={() => {
                      showOrderDetail(h.orderCode);
                    }}
                    className={
                      props.Page.screensize.width >= 768 ? "BaiJamJuree16" : "BaiJamJuree14"
                    }
                  >
                    {h.orderCode}
                  </h3>
                </Col>
                <Col span={7} align="left">
                  {/* <h3 className="BaiJamJuree16">22/01/2018 16:16 น.</h3> */}
                  <h3
                    className={
                      props.Page.screensize.width >= 768 ? "BaiJamJuree16" : "BaiJamJuree14"
                    }
                  >
                    {h.date && millisecsToDateTime(h.date, "hr-min", "dd/mm/yyyy") + " น."}
                  </h3>
                </Col>
                <Col span={7} align="left">
                  <h3
                    className={
                      props.Page.screensize.width >= 768 ? "BaiJamJuree16" : "BaiJamJuree14"
                    }
                  >
                    {paymentName(h.paymentType)}
                  </h3>
                </Col>
                <Col span={3} align="left">
                  <h3
                    className={
                      props.Page.screensize.width >= 768 ? "BaiJamJuree16" : "BaiJamJuree14"
                    }
                  >
                    {h.amount > 0 ? `฿${h.amount}` : "ฟรี"}
                  </h3>
                </Col>
              </Row>
            ) : (
              <Row
                key={h.orderCode}
                type="flex"
                justify="space-between"
                style={{ margin: "16px 0 24px 0" }}
              >
                <Col span={10} align="left" className="orderCodeCol">
                  <h3
                    onClick={() => {
                      showOrderDetail(h.orderCode);
                    }}
                    className="BaiJamJuree14"
                  >
                    {h.orderCode}
                  </h3>
                  <h3 className="BaiJamJuree14">
                    {h.date && millisecsToDateTime(h.date, "hr-min", "dd/mm/yyyy") + " น."}
                  </h3>
                </Col>
                <Col span={7} align="left">
                  <h3 className="BaiJamJuree14">{paymentName(h.paymentType)}</h3>
                </Col>
                <Col span={3} align="left">
                  <h3 className="BaiJamJuree14">{h.amount > 0 ? `฿${h.amount}` : "ฟรี"}</h3>
                </Col>
              </Row>
            );
          })
        ) : (
          <h3 className={props.Page.screensize.width >= 768 ? "BaiJamJuree16" : "BaiJamJuree14"}>
            ไม่พบประวัติการซื้อ
          </h3>
        )}
      </div>
      {lastEvaluatedKey !== undefined && !isEmpty ? (
        <div
          style={{
            minHeight: `${loaderHeight}px`,
            textAlign: "center",
            padding: "20px 0 20px 0",
          }}
        >
          <ClipLoader size={loaderHeight} color={"#123abc"} loading={true} />
        </div>
      ) : (
        <div style={{ minHeight: `${loaderHeight}px` }} />
      )}
      <Button
        className="toHome"
        onClick={() => {
          props.history.push("/");
        }}
      >
        กลับไปหน้าหลัก
      </Button>
      <Modal
        visible={isOrderOnClick}
        style={{ top: "15%" }}
        footer={null}
        maskClosable={true}
        onCancel={() => {
          setIsOrderOnClick(false);
        }}
        width={488}
      >
        <div className="orderDetailBox">
          {orderOnClick && (
            <h3 className="BaiJamJuree14" style={marginBottom24}>
              เลขที่การสั่งซื้อ: {orderOnClick.orderCode}
            </h3>
          )}
          {orderOnClick &&
            (contentType === "ebook"
              ? orderOnClick.orderDetail.map((el) => {
                  return (
                    <Row
                      key={el.bookUID}
                      type="flex"
                      justify="start"
                      align="top"
                      style={{ margin: "0 0 16px 0" }}
                    >
                      <Col span={props.Page.screensize.width >= 440 ? 4 : 6} align="center">
                        <img
                          src={el.coverImage}
                          style={{ height: "auto", width: "100%", marginRight: "16px" }}
                          alt=""
                        />
                      </Col>
                      <Col
                        span={props.Page.screensize.width >= 440 ? 12 : 10}
                        align="left"
                        className="centerDiv"
                      >
                        <Link to={"/ebook/" + el.bookUID}>
                          <p className="title">{el.title}</p>
                        </Link>
                        <Link to={"/author/" + el.authorName}>
                          <p className="author">ผู้เขียน: {el.authorName}</p>
                        </Link>
                      </Col>
                      <Col span={6} align="right" className="price">
                        {el.price > 0 ? `฿${el.price}` : `ฟรี`}
                      </Col>
                    </Row>
                  );
                })
              : contentType === "coin"
              ? orderOnClick.orderDetail.map((el) => {
                  return (
                    <Row
                      key={el.orderCode}
                      type="flex"
                      justify="space-between"
                      align="top"
                      style={{ margin: "-4px 0 4px 0" }}
                    >
                      <Col span={12} align="left">
                        <p className="coinReceive" style={{ marginBottom: "2px" }}>
                          {el.coinReceive || el.coinRecieve} C
                        </p>
                        <p className="coinBonus">+Free {el.coinBonus} C</p>
                      </Col>
                      <Col span={8} align="right" className="price">
                        ฿{orderOnClick.paidAmount}
                      </Col>
                    </Row>
                  );
                })
              : "")}
        </div>
      </Modal>
    </div>
  );
};

const marginBottom24 = { marginBottom: "24px" };

const mapStateToProps = (state) => {
  return { Page: state.Page, authentication: state.Authentication };
};

export default withRouter(connect(mapStateToProps)(PaymentHistory));
