import React, { useState, useEffect } from "react";
import { Row, Col, Select } from "antd";
import { connect } from "react-redux";
import AuthorPublisherBox from "../Collection/AuthorPublisherBox";
import { fetchAllPublishers, ACTION_SHOW_LOADING } from "../../Actions/Page";
import authen from "../../Utilitys/Authentication";
import { withRouter } from "react-router-dom";
import { getFavorite } from "../../Actions/Member";
import Helmet from "react-helmet";
const { Option } = Select;
const AuthorPublisherAll = props => {
  let numCollection = getNumberCollection(props.Page.screensize.width);
  const [publisherList, setPublisherList] = useState([]);
  const [authorList, setAuthorList] = useState([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);
  const [myFav, setMyFav] = useState([]);
  const [isAsc, setIsAsc] = useState("false");
  const [sortBy, setSortBy] = useState("ebook");
  const type = props.match.path === "/publisher/all" ? "publisher" : "author";

  useEffect(() => {
    props.dispatch(ACTION_SHOW_LOADING(true));
  }, []);

  const fetchFav = async (t, sort, asc) => {
    const fetchPublisherList = async () => {
      let publisherList = await fetchAllPublishers(sort, asc);
      if (publisherList.status) {
        setPublisherList(publisherList.data.publishers);
      }
    };
    const fetchAuthorList = async () => {
      setAuthorList([1, 1, 1, 1, 1, 1, 1, 1, 1, 1, 1]);
    };
    if (authen.isLogin()) {
      let fav = t === "author" ? await getFavorite("Author") : await getFavorite("Publisher");
      if (fav.status) {
        setMyFav(fav.data.favorites);
        if (t === "publisher") fetchPublisherList();
        if (t === "author") fetchAuthorList();
      }
    } else {
      if (t === "publisher") fetchPublisherList();
      if (t === "author") fetchAuthorList();
    }
    props.dispatch(ACTION_SHOW_LOADING(false));
  };

  useEffect(() => {
    setSortBy("ebook");
    setIsAsc("false");
    fetchFav(type, "ebook", "false");
    // eslint-disable-next-line
  }, [type]);

  useEffect(() => {
    if (type === "publisher") {
      setPublisherList([]);
      fetchFav("publisher", sortBy, isAsc);
    } else if (type === "author") {
      setAuthorList([]);
      fetchFav("author", sortBy, isAsc);
    }
    // eslint-disable-next-line
  }, [sortBy, isAsc]);

  const onChangeSortBy = e => {
    switch (e) {
      case "อีบุ๊คน้อย - มาก":
        setSortBy("ebook");
        setIsAsc("true");
        break;
      case "อีบุ๊คมาก - น้อย":
        setSortBy("ebook");
        setIsAsc("false");
        break;
      case "ผู้ติดตามน้อย - มาก":
        setSortBy("follower");
        setIsAsc("true");
        break;
      case "ผู้ติดตามมาก - น้อย":
        setSortBy("follower");
        setIsAsc("false");
        break;
      case "A > Z":
        setSortBy("name");
        setIsAsc("true");
        break;
      case "Z > A":
        setSortBy("name");
        setIsAsc("false");
        break;
      case "นักเขียนน้อย - มาก":
        setSortBy("writer");
        setIsAsc("true");
        break;
      case "นักเขียนมาก - น้อย":
        setSortBy("writer");
        setIsAsc("false");
        break;
      default:
        break;
    }
  };

  const valueOfOption = (sortBy, isAsc) => {
    switch (sortBy) {
      case "ebook":
        if (isAsc === "true") {
          return "อีบุ๊คน้อย - มาก";
        } else if (isAsc === "false") {
          return "อีบุ๊คมาก - น้อย";
        }
      case "follower":
        if (isAsc === "true") {
          return "ผู้ติดตามน้อย - มาก";
        } else if (isAsc === "false") {
          return "ผู้ติดตามมาก - น้อย";
        }
      case "name":
        if (isAsc === "true") {
          return "A > Z";
        } else if (isAsc === "false") {
          return "Z > A";
        }
      case "writer":
        if (isAsc === "true") {
          return "นักเขียนน้อย - มาก";
        } else if (isAsc === "false") {
          return "นักเขียนมาก - น้อย";
        }
      default:
        break;
    }
  };

  return (
    <div className="authorpublisherAll">
      <Helmet>
        <title>{`Hytexts: ${type === "publisher" ? "รวมสำนักพิมพ์" : "รวมนักเขียน"}`}</title>
      </Helmet>
      <Row
        type="flex"
        justify="space-between"
        align="middle"
        style={{ minWidth: "290px", margin: "0 0 8px 0" }}
        gutter={[0, 8]}
      >
        <Col xs={24} sm={9} align={props.Page.screensize.width >= 576 ? "left" : "center"}>
          <p>{type === "author" ? "นักเขียน" : "สำนักพิมพ์"}</p>
        </Col>
        <Col xs={24} sm={6} align="right" style={{ fontFamily: "Prompt" }}>
          <Select
            style={{ width: 160 }}
            dropdownMenuStyle={{ fontFamily: "Prompt" }}
            name="sortBy"
            optionFilterProp="children"
            onChange={onChangeSortBy}
            onFocus={() => {}}
            onSearch={() => {}}
            value={valueOfOption(sortBy, isAsc)}
            filterOption={(input, option) =>
              option.props.children.toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {options(type)}
          </Select>
        </Col>
      </Row>
      <Row
        gutter={[16, props.Page.screensize.width > 375 ? 38 : 4]}
        type="flex"
        justify={numCollection === 1 ? "center" : "start"}
      >
        {type === "author"
          ? authorList.map((e, index) => {
              return (
                <AuthorPublisherBox
                  key={index}
                  publisherName={"นักเขียน"}
                  ebooks={"1"}
                  followers={"1"}
                  numCollection={numCollection}
                  type={"author"}
                />
              );
            })
          : publisherList.map((e, index) => {
              let filter_fav = myFav.filter(el => el.contentID === e.uid);
              let isfav = false;
              if (filter_fav.length) {
                isfav = true;
              }
              return (
                <AuthorPublisherBox
                  key={e.uid}
                  profileImage={e.profileImage}
                  creatorUID={e.uid}
                  publisherName={e.publisherName}
                  ebooks={e.ebooks}
                  followers={e.followers}
                  writers={e.writers}
                  numCollection={numCollection}
                  type={"publisher"}
                  isfav={isfav}
                />
              );
            })}
      </Row>
    </div>
  );
};

const getNumberCollection = width => {
  if (width >= 1440) {
    return 4;
  } else if (width > 1100) {
    return 3;
  } else if (width > 750) {
    return 2;
  } else {
    return 1;
  }
};

const options = type => {
  if (type === "publisher") {
    return [
      <Option key={"อีบุ๊คมาก - น้อย"}>อีบุ๊คมาก - น้อย</Option>,
      <Option key={"อีบุ๊คน้อย - มาก"}>อีบุ๊คน้อย - มาก</Option>,
      <Option key={"ผู้ติดตามมาก - น้อย"}>ผู้ติดตามมาก - น้อย</Option>,
      <Option key={"ผู้ติดตามน้อย - มาก"}>ผู้ติดตามน้อย - มาก</Option>,
      <Option key={"A > Z"}>A > Z</Option>,
      <Option key={"Z > A"}>Z > A</Option>,
      <Option key={"นักเขียนมาก - น้อย"}>นักเขียนมาก - น้อย</Option>,
      <Option key={"นักเขียนน้อย - มาก"}>นักเขียนน้อย - มาก</Option>
    ];
  } else if (type === "author") {
    return [
      <Option key={"อีบุ๊คมาก - น้อย"}>อีบุ๊คมาก - น้อย</Option>,
      <Option key={"อีบุ๊คน้อย - มาก"}>อีบุ๊คน้อย - มาก</Option>,
      <Option key={"ผู้ติดตามมาก - น้อย"}>ผู้ติดตามมาก - น้อย</Option>,
      <Option key={"ผู้ติดตามน้อย - มาก"}>ผู้ติดตามน้อย - มาก</Option>,
      <Option key={"A > Z"}>A > Z</Option>,
      <Option key={"Z > A"}>Z > A</Option>
    ];
  }
};

const mapStateToProps = state => {
  return { Page: state.Page, authentication: state.Authentication };
};

export default withRouter(connect(mapStateToProps)(AuthorPublisherAll));
