import React, { Fragment, useState } from "react";
import PropTypes from "prop-types";
import PaymentButton from "./PaymentButton";
import { Row, Col, Divider, Tooltip } from "antd";
import { useSelector } from "react-redux";
import { connect } from "react-redux";

const PaymentList = ({ Page, selectedForm }) => {
  const payment = useSelector(state => state.Payment);
  const [arrowDirection, setArrowDirection] = useState("down");

  const downArrow = (
    <img
      onClick={() => setArrowDirection("up")}
      src="/images/logos/downArrow.png"
      className="arrow"
      style={{ width: "12px", height: "7px" }}
    />
  );
  const upArrow = (
    <img
      onClick={() => setArrowDirection("down")}
      src="/images/logos/upArrow.png"
      className="arrow"
      style={{ width: "12px", height: "7px" }}
    />
  );

  return (
    <Fragment>
      <p className="coltitle">ยอดรวมคำสั่งซื้อ</p>
      <Divider
        style={{
          margin: "8px 0",
          width: Page.screensize.width > 430 ? "350px" : "100%",
          background: "black"
        }}
      />
      {payment.shoppingCart.length > 0 ? (
        <Row type="flex" align="middle" justify="space-between">
          <Col span={12}>
            <p className="toplabel">{payment.shoppingCart.length} รายการ</p>
          </Col>
          <Col span={1} align="center">
            {arrowDirection === "down" ? downArrow : upArrow}
          </Col>
        </Row>
      ) : (
        ""
      )}
      {arrowDirection === "down" && (
        <div className="shoppingcart" id="shoppingcart">
          {payment.shoppingCart.map((el, index) => {
            return (
              <Row
                key={index}
                gutter={[15, 0]}
                type="flex"
                align="top"
                justify="start"
                style={{ margin: "0 0 16px 0" }}
              >
                <Col xs={2} sm={1}>
                  <p style={{ marginRight: "8px" }}>{index + 1}.</p>
                </Col>
                <Col xs={Page.screensize.width > 370 ? 5 : 6} md={3} lg={4}>
                  <img
                    src={el.coverImage}
                    style={{
                      height: "auto",
                      width: "100%",
                      marginRight: "16px"
                    }}
                    alt=""
                  />
                </Col>
                <Col xs={Page.screensize.width > 375 ? 13 : 12} md={18} lg={14}>
                  <p className="title">
                    {/* <Tooltip
                      placement="top"
                      title={<p style={{ fontFamily: "Prompt", margin: "0 0" }}>{el.title}</p>}
                    > */}
                    {el.title}
                    {/* </Tooltip> */}
                  </p>
                  <p className="author">
                    ผู้เขียน:{" "}
                    {/* <Tooltip
                      placement="top"
                      title={<p style={{ fontFamily: "Prompt", margin: "0 0" }}>{el.authorName}</p>}
                    >  */}
                    {el.authorName}
                    {/* </Tooltip> */}
                  </p>
                  {el.publisherName ? (
                    <p className="author">
                      สำนักพิมพ์:{" "}
                      {/* <Tooltip
                      placement="top"
                      title={<p style={{ fontFamily: "Prompt", margin: "0 0" }}>{el.authorName}</p>}
                    >  */}
                      {el.publisherName}
                      {/* </Tooltip> */}
                    </p>
                  ) : (
                    ""
                  )}
                </Col>
                <Col xs={0} lg={1} xl={2}></Col>
                <Col span={1} align="right" className="price">
                  {el.priceTHB > 0 ? `฿${el.priceTHB}` : "ฟรี"}
                </Col>
              </Row>
            );
          })}
        </div>
      )}
      {/* <Row type="flex">
        <Col span={12}>
          <p className="bottomlabel">ยอดรวม</p>
        </Col>
        <Col span={12} align="right">
          <p className="price">฿{payment.totalValue}</p>
        </Col>
      </Row> */}
      <Divider
        style={{
          margin: "0 0 16px 0",
          width: Page.screensize.width > 430 ? "350px" : "100%",
          background: "#bdbdbd"
        }}
      />
      {Page.screensize.width >= 992 ? <PaymentButton selectedForm={selectedForm} /> : ""}
    </Fragment>
  );
};

const mapStateToProps = state => {
  return {
    Page: state.Page
  };
};

PaymentList.propTypes = {
  selectedForm: PropTypes.string.isRequired
};

export default connect(mapStateToProps)(PaymentList);
