import {
  ADD_CART,
  REMOVE_CART,
  RECEIVE_MEMBER_CART,
  REMOVE_ALL_CART
} from "../Constants/actionTypes";
import ObjectUitil from "../Utilitys/Object";
const INITIAL_STATE = {
  shoppingCart: [],
  numCart: 0,
  totalValue: 0,
  bookUID: ""
};

const Payment = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case ADD_CART:
      let newarray = state.shoppingCart.slice();
      newarray.splice(newarray.length - 1, 0, action.cartItem);
      state.shoppingCart = newarray;
      state.numCart = state.shoppingCart.length;
      state.bookUID = action.cartItem.bookUID;
      state.totalValue = state.shoppingCart.reduce(
        (pre, current) => pre + current.priceTHB,
        0
      );
      return Object.assign({}, state);
    case REMOVE_CART:
      state.shoppingCart = ObjectUitil.removeExistArray(
        state.shoppingCart,
        action.cartItem,
        "bookUID"
      );
      state.numCart = state.shoppingCart.length;
      state.bookUID = action.cartItem.bookUID;
      state.totalValue = state.shoppingCart.reduce(
        (pre, current) => pre + current.priceTHB,
        0
      );
      return Object.assign({}, state);
    case REMOVE_ALL_CART:
      state.shoppingCart = [];
      state.numCart = 0;
      state.bookUID = "";
      state.totalValue = state.shoppingCart.reduce(
        (pre, current) => pre + current.priceTHB,
        0
      );
      return Object.assign({}, state);
    case RECEIVE_MEMBER_CART:
      let mergeArray = action.cartItem;
      state.shoppingCart = mergeArray;
      state.totalValue = state.shoppingCart.reduce(
        (pre, current) => pre + current.priceTHB,
        0
      );
      state.numCart = state.shoppingCart.length;

      return Object.assign({}, state);
    default:
      return state;
  }
};
export default Payment;
