import React from "react";

export const ereaderLabel = [
  "initialize",
  "update_firmware",
  "update_firmware_from_BOOX_website",
  "registration_play_store",
  "install_dictionary",
  "add_keyboard",
  "factory_reset",
  "change_language",
  "login_hytexts_ereader_via_qrcode",
  "neo_reader",
  "open_ebook_from_library",
  "setting_pdf_epub_firsttime",
  "remove_ebook_from_library",
  "refresh_mode",
  "multi_tasking_switching",
  "lock_screen_password_setting",
  "change_saver_screen",
];

export const belowForEreader = [
  { title: "การตั้งค่าเมื่อเปิดใช้งานเครื่อง", pics: 1 },
  { title: "การอัพเดท Firmware (OTA)", pics: 2 },
  { title: "การอัพเดท Firmware จากเว็บไซต์ BOOX", pics: 1, usemap: 2 },
  { title: "การลงทะเบียนและตั้งค่า Play Store", pics: 1 },
  { title: "การติดตั้ง Dictionary Eng - Thai", pics: 2, usemap: 1 },
  { title: "การเพิ่มคีย์บอร์ดภาษาไทย", pics: 1 },
  { title: "การ Reset เครื่อง (Factory Reset)", pics: 1 },
  { title: "การเปลี่ยนภาษาเครื่อง", pics: 1 },
  { title: "การล็อกอินแอป Hytexts E-Reader ผ่าน QR Code", pics: 1 },
  { title: "การใช้งานเบื้องต้นของโปรแกรม Neo Reader v3.0", pics: 1 },
  { title: "การเปิดอ่าน E-Book จากแอป Hytexts ในหน้า Library", pics: 1 },
  { title: "การตั้งค่าการเปิดอ่านไฟล์ครั้งแรกจากหน้า Library", pics: 1 },
  { title: "การลบ E-Book ออกจากหน้า Library", pics: 1 },
  { title: "การใช้งานโหมด Refresh Mode", pics: 1 },
  { title: "การใช้งานโหมด Multi-tasking switching", pics: 1 },
  { title: "การตั้งค่าและ Reset รหัสผ่านล็อคหน้าจอ", pics: 2 },
  { title: "การเปลี่ยนภาพพักหน้าจอ", pics: 1 },
];

export const SideForEreader = (props) => {
  const s = belowForEreader.map((e, index_e) => (
    <div className="side" key={index_e}>
      {[...Array(e.pics)].map((el, index_el) => (
        <>
          {e.usemap ? (
            <div
              style={{
                transform: "translateY(100px)",
                position: "absolute",
                width: "440px",
                height: "320px",
                cursor: "pointer",
              }}
              onClick={() =>
                window.open(
                  e.usemap === 1 ? "https://bit.ly/3lqYg0S" : "https://www.boox.com/downloads/",
                  "_blank"
                )
              }
            ></div>
          ) : (
            ""
          )}
          <img
            src={`/images/faq/forEreader/${index_e}/${index_el}.png`}
            onLoad={props.handleImageChange}
            onError={props.handleImageChange}
            style={{ marginTop: index_e === 15 && index_el === 1 ? "32px" : "0px" }}
          />
        </>
      ))}
    </div>
  ));
  return s[props.index];
};
