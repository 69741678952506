import React, { Fragment, useMemo } from "react";
import PaymentAll from "./PaymentAll";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";

import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);

const Payment = (props) => {
  useMemo(() => {
    const queryParams = props.history.location.search;
    queryParams.split("&").map((e) => {
      if (e.includes("qrData")) {
        const qrCodeString = e.split("=")[1];
        const orderDetail = JSON.parse(localStorage.getItem("banktransfer"));
        props.history.push({
          pathname: `/payment/banktransfer/${orderDetail.orderCode}`,
          state: { qrCode: qrCodeString },
        });
      }
    });
  }, []);
  return (
    <Fragment>
      {props.match.path === "/payment" ? (
        <PaymentAll contentType="ebook" history={props.history} />
      ) : (
        props.match.path === "/hytextscoin" && (
          <PaymentAll hytextsCoin={true} contentType="coin" history={props.history} />
        )
      )}
    </Fragment>
  );
};

export default withRouter(connect(null)(Payment));
