import {
  REQUEST_GUEST_TOKEN,
  RECEIVE_GUEST_TOKEN,
  REQUEST_LOGIN,
  RECEIVE_LOGIN_TOKEN,
  REQUEST_EXIST_ACCOUNT,
  LOG_OUT,
  REMOVE_ALL_CART,
} from "../Constants/actionTypes";
import { connect } from "react-redux";
import { fetch } from "../Utilitys/ApiRequest";
import authen from "../Utilitys/Authentication";
import Cookies from "universal-cookie";
import { ACTION_SHOW_LOADING } from "./Page";
import { showError } from "./Message";
import { fetchAddCartFromCookie } from "./Payment";
import ObjectUitil from "../Utilitys/Object";
import { ip } from "../Constants/appInfo";
import firebase from "../Service/Firebase";
const cookies = new Cookies();

export const requestGuestToken = () => ({
  type: REQUEST_GUEST_TOKEN,
});
export const receiveGuestToken = (response) => ({
  type: RECEIVE_GUEST_TOKEN,
  userToken: response.data.userToken || response.userToken,
});

export const receiveCookieGuestToken = (response) => ({
  type: RECEIVE_GUEST_TOKEN,
  userToken: response.userToken,
});
export const requestLogin = () => ({
  type: REQUEST_LOGIN,
});

export const requestExistAccount = (email) => ({
  type: REQUEST_EXIST_ACCOUNT,
  email: email,
});

export const requestLoginToken = (response) => ({
  type: RECEIVE_LOGIN_TOKEN,
  userToken: response.data.userToken || response.userToken,
});

export const requestLogOut = (response) => ({
  type: LOG_OUT,
  userToken: response.data.userToken,
});

export const requestLoginQRToken = (token) => ({
  type: RECEIVE_LOGIN_TOKEN,
  userToken: token,
});

export const fetchGuestToken = () => {
  if (cookies.get("userToken")) {
    return (dispatch) => {
      dispatch(receiveCookieGuestToken({ userToken: cookies.get("userToken") }));
    };
  } else {
    return async (dispatch) => {
      const ipValue = await ip;
      localStorage.setItem("deviceuid", ipValue);
      dispatch(requestGuestToken());
      let response = await getGuestToken();
      if (response.status) {
        dispatch(receiveGuestToken(response));
      } else {
        dispatch(showError(response));
      }
    };
  }
};
export const logOut = async () => {
  return await fetch("get", `signin/logout`);
};
export const getGuestToken = async () => {
  return await fetch("get", `authentication/requestToken/guest`);
};
export const getQrCodeData = async () => {
  return await fetch("get", `signin/qrcode`);
};
export const loginWithEmail = async (payload = { userID: "", userPassword: "" }) => {
  return await fetch("post", `signin/password`, payload);
};
export const loginWithGmail = async (payload = {}) => {
  return await fetch("post", `signin/google`, payload);
};

export const loginWithFacebook = async (payload = {}) => {
  return await fetch("post", `signin/facebook`, payload);
};

export const registerMember = (payload = {}, path) => {
  return async (dispatch) => {
    //if (path !== "/register/creator") {
    // dispatch(ACTION_SHOW_LOADING(true));
    // }
    const res = await fetch("post", `register`, payload);
    if (res.status) {
      if (res.data && res.data.userToken) {
        dispatch(requestLoginToken(res));
      }
      //dispatch(ACTION_SHOW_LOADING(false));
      if (path !== "/login") {
        if (path === "/register/creator") {
          window.location = authen.URL + path + "?displayname=false";
        } else {
          window.location = authen.URL + path;
        }
      } else {
        window.location = authen.URL;
      }
    } else {
      if (path !== "/register/creator") {
        dispatch(showError(res));
        //dispatch(ACTION_SHOW_LOADING(false));
      } else {
        if (ObjectUitil.isPhoneNumber(payload.userID)) {
          dispatch(
            showError({ status: false, errorcode: "038", message: "User Phone already Exist" })
          );
        } else {
          dispatch(
            showError({ status: false, errorcode: "038", message: "User Email already Exist" })
          );
        }
        //dispatch(ACTION_SHOW_LOADING(false));
      }
    }
  };
};

export const resetPassword = async (payload = {}) => {
  return await fetch("post", "signin/resetPassword", payload);
};

export const fetchLogOut = () => {
  return async (dispatch) => {
    window.FB.getLoginStatus((response) => {
      if (response.status === "connected") {
        window.FB.logout((response) => {
          console.log("Logout Facebook", response);
        });
      }
    });
    firebase
      .auth()
      .signOut()
      .then(() => {
        console.log("Logout Google");
      })
      .catch((error) => {
        console.log(error);
      });
    await logOut();
    dispatch({
      type: REMOVE_ALL_CART,
    });
    cookies.remove("userToken");
    localStorage.removeItem("shoppingCart");
    let responseToken = await getGuestToken();
    if (responseToken.status) {
      await dispatch(requestLogOut(responseToken));
      window.location = authen.URL;
    }
  };
};

export const fetchLogin = (type = "email", payload = {}, path) => {
  return async (dispatch) => {
    // dispatch(ACTION_SHOW_LOADING(true));
    var response;
    switch (type) {
      case "email":
        response = await loginWithEmail(payload);
        break;
      case "facebook":
        response = await loginWithFacebook(payload);
        break;
      case "gmail":
        response = await loginWithGmail(payload);
        break;
      case "line":
        break;
      case "qrcode":
        response = { status: true, data: { userToken: payload.userToken } };
        break;
      default:
        response = await loginWithEmail(payload);
        break;
    }
    // dispatch(ACTION_SHOW_LOADING(false));
    if (response.status) {
      if (response.data && response.data.userToken) {
        dispatch(requestLoginToken(response));
        // fetchAddCartFromCookie(dispatch);
        // add exist cart in cookie

        // dispatch(redirect('/home'))
      } else if (response.data && response.data.email) {
        dispatch(requestExistAccount(response.data.email));
      }
      if (path === "/login" || path === "/login/qrcode") {
        window.location = authen.URL;
      } else {
        window.location.reload();
      }
    } else {
      dispatch(showError(response));
    }
  };
};

const mapStateToProps = (state) => {
  return state;
};
connect(mapStateToProps)(getGuestToken);
