import React from "react";
import ContentLoader from "react-content-loader";

export const EbookViewLoader = (props) => {
  return (
    <ContentLoader
      className={props.isHomePage ? "ebookViewLoader-home" : "ebookViewLoader"}
      style={props.index === 0 ? { marginLeft: "16px" } : { marginLeft: "0px" }}
      height={280}
      width={200}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width="200" height="280" />
    </ContentLoader>
  );
};

export const EbookFullViewLoader = () => {
  return (
    <ContentLoader
      className="ebookViewLoader-full"
      height={480}
      width={338}
      speed={2}
      primaryColor="#f3f3f3"
      secondaryColor="#ecebeb"
    >
      <rect x="0" y="0" rx="0" ry="0" width="338" height="480" />
    </ContentLoader>
  );
};
