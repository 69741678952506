import { SHOW_MESSAGE, DISMISS_MESSAGE, MESSAGE_TYPE_ERROR } from '../Constants/actionTypes'
const INITIAL_STATE = {
    isShow: false,
    errorcode: '',
    message: '',
    title: '',
    messageType: MESSAGE_TYPE_ERROR
};

const Message = (state = INITIAL_STATE, action) => {
    switch (action.type) {
        case SHOW_MESSAGE:
            return Object.assign({}, state, {
                isShow: true, message: action.message
                , messageType: action.messageType
            })
        case DISMISS_MESSAGE:
            return Object.assign({}, state, {
                isShow: false
            })
        default:
            return state
    }
}
export default Message