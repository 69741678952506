import { getHeaderWithToken } from "../Constants/appInfo";
import { fetch } from "../Utilitys/ApiRequest";

const getPdpaPath = () => {
  if (process.env.REACT_APP_ENV == "production") {
    return `https://2ej53lptxi.execute-api.ap-southeast-1.amazonaws.com/prod`;
  } else if (process.env.REACT_APP_ENV == "staging") {
    return `https://2ej53lptxi.execute-api.ap-southeast-1.amazonaws.com/prod`;
  } else {
    return `https://2ej53lptxi.execute-api.ap-southeast-1.amazonaws.com/dev`;
  }
};

export const fetchPDPAPresigned = async (fileTypes) => {
  return await fetch(
    "post",
    `form/pdpa/presigned`,
    { fileTypes },
    getHeaderWithToken(),
    getPdpaPath()
  );
};

export const submitPDPAForm = async (pdpaPayload) => {
  return await fetch(
    "post",
    `form/pdpa/submit`,
    pdpaPayload,
    getHeaderWithToken(),
    getPdpaPath()
  );
};
