import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
// eslint-disable-next-line
import { DownloadApp } from "../Login/Login-children";
import { Link, withRouter } from "react-router-dom";
import { Form, Input, Icon, Button, Row, Col, Modal } from "antd";
import ObjectUitil from "../../../Utilitys/Object";
import validator from "validator";
import { registerMember } from "../../../Actions/Authentication";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);

const RegisterForm = props => {
  const [usernameError, setUsernameError] = useState("");
  const [usernameerrormessage, setUsernameerrormessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passworderrormessage, setPassworderrormessage] = useState("");
  const [displaynameError, setDisplaynameError] = useState("");
  const [displaynameerrormessage, setDisplaynameerrormessage] = useState("");
  const [isShowpolicy, setIsShowpolicy] = useState(false);

  const onSubmitHandle = async e => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        props.dispatch(
          registerMember(
            {
              email: values.username,
              password: values.password,
              displayName: values.displayname
            },
            props.location.pathname
          )
        );
      }
    });
  };
  const errorHandleCheck = fieldsError => {
    return Object.keys(fieldsError).some(field => fieldsError[field]);
  };
  const fieldOnchangehandle = e => {
    props.form.validateFields();
    switch (e.target.name) {
      case "username":
        if (
          e.target.value !== "" &&
          !validator.isEmail(e.target.value) &&
          !ObjectUitil.isPhoneNumber(e.target.value)
        ) {
          setUsernameError("error");
          setUsernameerrormessage("อีเมลหรือเบอร์โทรศัพท์ไม่ถูกต้อง");
        } else {
          setUsernameError("");
          setUsernameerrormessage("");
        }
        break;
      case "password":
        if (e.target.value !== "" && !ObjectUitil.isPasswordValid(e.target.value)) {
          if (/\s/g.test(e.target.value)) {
            setPasswordError("error");
            setPassworderrormessage("ห้ามเว้นวรรคในรหัสผ่าน");
          } else if (/[^a-zA-Z0-9]/g.test(e.target.value)) {
            setPasswordError("error");
            setPassworderrormessage("กรุณาใช้ตัวอักษรภาษาอังกฤษ");
          } else {
            setPasswordError("error");
            setPassworderrormessage("โปรดตั้งรหัสผ่าน 6 ตัวขึ้นไป");
          }
        } else {
          setPasswordError("");
          setPassworderrormessage("");
        }
        break;
      case "displayname":
        if (e.target.value === "") {
          setDisplaynameError("error");
          setDisplaynameerrormessage("โปรดตั้งชื่อโปรไฟล์");
        } else {
          setDisplaynameError("");
          setDisplaynameerrormessage("");
        }
        break;
      default:
        break;
    }
  };

  useEffect(() => {
    props.form.validateFields();
  }, []);

  const policyclickHandle = () => {
    setIsShowpolicy(!isShowpolicy);
  };
  const { getFieldDecorator, getFieldsError } = props.form;
  return (
    <div className="register-container">
      <Row>
        <Col
          span={6}
          align="left"
          className="backtoRegister"
          onClick={() => {
            if (props.Page.screensize.width > 900) props.toRegister_fromEmail();
          }}
        >
          {props.Page.screensize.width > 900 ? <span>{"<"}</span> : ""}
        </Col>
        <Col span={12}>
          <h1>สมัครสมาชิก</h1>
        </Col>
        <Col span={6} />
      </Row>
      <div className="register-channel-container">
        <Form name="form_accountInfo" className="register-form" onSubmit={onSubmitHandle}>
          <h4>อีเมลหรือเบอร์โทรศัพท์</h4>
          <Form.Item
            validateStatus={usernameError ? "error" : ""}
            help={usernameerrormessage}
            className="marginb16"
          >
            {getFieldDecorator("username", {
              id: "username",
              rules: [
                {
                  required: true,
                  message: "Please input your email or phone number"
                }
              ]
            })(
              <Input
                name="username"
                prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="อีเมลหรือเบอร์โทรศัพท์"
                onChange={fieldOnchangehandle}
              />
            )}
          </Form.Item>
          <h4>รหัสผ่าน</h4>
          <Form.Item
            validateStatus={passwordError ? "error" : ""}
            help={passworderrormessage || ""}
            className="marginb16"
          >
            {getFieldDecorator("password", {
              id: "password",
              rules: [{ required: true, message: "Please input your password!" }]
            })(
              <Input.Password
                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="รหัสผ่าน 6 ตัวขึ้นไป"
                name="password"
                onChange={fieldOnchangehandle}
              />
            )}
          </Form.Item>
          <h4>ชื่อโปรไฟล์</h4>
          <Form.Item
            validateStatus={displaynameError ? "error" : ""}
            help={displaynameerrormessage || ""}
            className="marginb16"
          >
            {getFieldDecorator("displayname", {
              id: "displayname",
              rules: [{ required: true, message: "Please input your display name!" }]
            })(
              <Input
                prefix={<Icon type="smile" style={{ color: "rgba(0,0,0,.25)" }} />}
                type="username"
                placeholder="ชื่อโปรไฟล์"
                name="displayname"
                onChange={fieldOnchangehandle}
              />
            )}
          </Form.Item>

          <Button
            type="primary"
            htmlType="submit"
            className="register-form-button"
            onClick={onSubmitHandle}
            disabled={
              errorHandleCheck(getFieldsError()) ||
              usernameError !== "" ||
              passwordError !== "" ||
              displaynameError !== ""
            }
          >
            สมัครสมาชิก
          </Button>
          <Link style={{ textDecoration: "underline" }} to="#" onClick={policyclickHandle}>
            <p className="policy">ข้อตกลงและเงื่อนไขในการใช้บริการ</p>
          </Link>
        </Form>
      </div>
      <p className="noaccountyet">
        คุณมีบัญชีอยู่แล้ว ?{" "}
        <Link
          className="login"
          to={props.Page.screensize.width > 900 ? "#" : "/login"}
          onClick={props.toLogin_email}
        >
          เข้าสู่ระบบ
        </Link>
      </p>
      <DownloadApp />
      <Modal
        visible={isShowpolicy}
        footer={null}
        maskClosable={true}
        onCancel={policyclickHandle}
        width={props.Page.screensize.width < 1100 ? "95%" : 1100}
      >
        <div className="policy-content">
          <p className="title">ข้อกำหนดการเป็นสมาชิก</p>
          {policyContent.map((e, index) => {
            return (
              <p key={index} className="content">
                {e}
              </p>
            );
          })}
        </div>
      </Modal>
    </div>
  );
};

const policyContent = [
  `1. การสมัครสมาชิกกับ www.hytexts.com ไม่เสียค่าใช้จ่ายใดๆทั้งสิ้น โดยผู้สมัครต้องกรอกข้อมูลของตนเองที่เป็นจริงให้ครบทุกข้อ เพื่อสิทธิประโยชน์ในการเข้าร่วมกิจกรรมของ www.hytexts.com หากตรวจสอบ
  พบว่าให้ข้อมูลเท็จ ทีมงานมีสิทธิ์ไม่ให้สิทธิ์การเป็นสมาชิกได้หรือยกเลิกการเป็นสมาชิกได้ ผู้ใดแอบอ้าง หรือกระทำการใดๆ อันเป็นการละเมิดสิทธิ์ส่วนบุคคล โดยใช้ข้อมูลของผู้อื่นมาแอบอ้างสมัครสมาชิกเพื่อให้ได้สิทธิ์การ
  เป็นสมาชิก ถือเป็นความผิด ต้องรับโทษตามที่กฎหมายกำหนดไว้`,
  `2. เพื่อความเป็นส่วนตัวและความปลอดภัยในข้อมูล เป็นหน้าที่ของสมาชิกในการเก็บรักษาข้อมูลชื่อ Login และ Password ถือเป็นความลับเฉพาะตัวของสมาชิก และสามารถเปลี่ยนรหัสผ่าน (Password) ด้วยตนเอง
ได้ตลอดระยะเวลาการใช้งาน ทั้งนี้หากมีการใช้บริการรายการใดๆโดยใช้รหัสผ่าน (Password) ของสมาชิกในแต่ละขณะดังกล่าวแล้ว ให้ถือว่าถูกต้องสมบูรณ์มีผลผูกพันสมาชิก ตามข้อกำหนดและเงื่อนไขการใช้บริการทุก
ประการ เสมือนสมาชิกใช้บริการในครั้งนั้นๆ ด้วยตนเอง ทั้งนี้ บริษัทไม่สามารถล่วงรู้รหัสผ่าน (Password) ของสมาชิกได้ ดังนั้น บริษัทจึงไม่รับผิดชอบในความเสียหายใดๆที่เกิดขึ้นในการใช้บริการ www.hytexts.com โดย
รหัสผ่าน (Password) ของสมาชิก`,
  `3. การกระทำการใดๆ โดยผ่านบริการ ไม่ว่ากรณีใดๆก็ตาม หากกระทำโดยใช้รหัสสมาชิกและรหัสผ่านของสมาชิกและ/หรือตรงตามวิธีการหรือเงื่อนไขที่บริษัทกำหนดไว้เกี่ยวกับการใช้บริการ www.hytexts.com แล้ว
สมาชิกตกลงให้ถือว่าการกระทำนั้นมีผลสมบูรณ์ โดยสมาชิกไม่ต้องลงลายมือชื่อเป็นหลักฐานในเอกสารใดๆ อีก และยินยอมรับผิดชอบในการกระทำดังกล่าวเสมือนหนึ่งว่าสมาชิกเป็นผู้กระทำด้วยตนเอง ทั้งนี้ไม่ว่าจะเกิดขึ้น
ด้วยความทุจริตของบุคคลภายนอกหรือไม่ก็ตาม สมาชิกจะไม่เรียกร้องให้บริษัทต้องรับผิดชอบแต่อย่างใด`,
  `4. สมาชิกจำเป็นต้องตรวจสอบความถูกต้องในการทำรายการสั่งซื้อหนังสืออิเล็กทรอนิกส์ว่าถูกต้องหรือไม่ ก่อนที่จะทำการตกลงชำระเงิน หากเกิดข้อผิดพลาดจากการทำรายการสั่งซื้อหนังสืออิเล็กทรอนิกส์อันเนื่อง
มาจากความไม่ถูกต้องของรายการเนื่องมาจากความผิดพลาดของสมาชิกเอง ทาง www.hytexts.com จะไม่รับผิดชอบทั้งสิ้น`,
  `5. หนังสืออิเล็กทรอนิกส์ ไม่มีการจัดส่งเป็นรูปเล่ม เมื่อชำระเงินแล้ว ไม่สามารถขอคืนเงินได้ในทุกกรณี`,
  `6. หนังสืออิเล็กทรอนิกส์ที่สมาชิกสั่งซื้อไป หากผู้ใดกระทำการดังกล่าวด้านล่างนี้ ถือเป็นการละเมิดลิขสิทธิ์ ต้องรับโทษอาญาและจ่ายค่าเสียหายแก่เจ้าของลิขสิทธิ์และ www.hytexts.com หากผู้ใดรู้อยู่แล้วหรือมี
เหตุอันควรรู้ว่างานใดได้ทำขึ้นโดยละเมิดลิขสิทธิ์ ให้ถือว่าผู้นั้นกระทำละเมิดลิขสิทธิ์ด้วยทำซ้ำหรือดัดแปลง ให้เช่าต้นฉบับหรือสำเนางาน ขาย เสนอขาย ให้เช่า เสนอให้เช่า ให้เช่าซื้อ หรือเสนอให้เช่าซื้อ เผยแพร่งานต่อ
สาธารณชน แจกจ่ายในลักษณะที่อาจก่อให้เกิดความเสียหายแก่เจ้าของลิขสิทธิ์`,
  `7. หากพบว่าสมาชิกกระทำการผิดเงื่อนไขและข้อตกลงกับ www.hytexts.com ทีมงานมีสิทธิ์ที่จะยกเลิกความเป็นสมาชิกได้โดยทันที`,
  `8. บริษัทขอสงวนสิทธิ์ในการปฏิเสธความรับผิดทั้งหมดในกรณีที่ข้อมูลสมาชิก ข้อมูลการสั่งซื้อ และข้อมูลทุกๆอย่างภายในเว็บไซต์เสียหายหรือสูญหาย เนื่องจากความผิดพลาดใดๆ ระหว่างส่งข้อมูลกับผู้ใช้บริการ
เหตุสุดวิสัยใดๆ การถูกจารกรรมข้อมูลคอมพิวเตอร์(Hack) และ/หรือการกระทำใดๆที่ขัดกฎหมาย ไม่ว่าการกระทำนั้นจะเกิดจากบุคคลใดๆ ก็ตาม`,
  `9. เมื่อมีการเปลี่ยนแปลงหรือเพิ่มเติมบริการหรือเมื่อได้รับข้อเสนอแนะจากสมาชิก การเปลี่ยนแปลงใดๆในข้อตกลงและเงื่อนไขการใช้บริการ จะมีการแจ้งถึงสมาชิกผ่านทางไปรษณีย์อิเล็กทรอนิกส์`
];

const mapStateToProps = state => {
  return { Authentication: state.Authentication, Page: state.Page };
};

export default withRouter(
  connect(mapStateToProps)(Form.create({ name: "form_register" })(RegisterForm))
);
