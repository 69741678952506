import { ADD_CART, REMOVE_CART } from "../Constants/actionTypes";
import { fetch } from "../Utilitys/ApiRequest";
import { ip } from "../Constants/appInfo";
export const ACTION_ADD_CART = (uid) => ({
  type: ADD_CART,
  uid: uid,
});
export const ACTION_REMOVE_CART = (uid) => ({
  type: REMOVE_CART,
  uid: uid,
});

export const fetchEbookData = async (
  type = "newrelease",
  order = "date",
  isAsc = "false",
  lastEvaluatedKey,
  limit = 48
) => {
  var lastKey = "";
  if (lastEvaluatedKey) {
    lastKey = "&lastEvaluatedKey=" + encodeURIComponent(JSON.stringify(lastEvaluatedKey));
  }
  switch (type) {
    case "ebooks":
      return await fetch(
        "get",
        `ebooks/all?isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
      );

    case "newrelease":
      return await fetch(
        "get",
        `ebooks/new?isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
      );

    case "bestseller":
      return await fetch(
        "get",
        `ebooks/bestseller?isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
      );

    case "promotion":
      return await fetch(
        "get",
        `ebooks/promotions?isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
      );

    case "ontv":
      return await fetch(
        "get",
        `ebooks/tvdrama?isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
      );

    case "free":
      return await fetch(
        "get",
        `ebooks/free?isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
      );

    default:
      // params["order"] = "date";
      return await fetch(
        "get",
        `ebooks/all?isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
      );
  }
};

export const fetchSubCatEbook = async (
  catId,
  subcatId,
  order,
  isAsc,
  lastEvaluatedKey,
  limit = 48
) => {
  var lastKey = "";
  if (lastEvaluatedKey) {
    lastKey = "&lastEvaluatedKey=" + encodeURIComponent(JSON.stringify(lastEvaluatedKey));
  }
  return await fetch(
    "get",
    `categories/${catId}/subcategories/${subcatId}?isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
  );
};

export const fetchSearchEbook = async (keyword) => {
  return await fetch("get", `ebooks/autoCompleteSearch?keyword=${encodeURIComponent(keyword)}`);
};

export const fetchEbookByKeyword = async (keyword, order, isAsc, lastEvaluatedKey, limit = 48) => {
  var lastKey = "";
  if (lastEvaluatedKey) {
    lastKey = "&lastEvaluatedKey=" + encodeURIComponent(JSON.stringify(lastEvaluatedKey));
  }
  return await fetch(
    "get",
    `ebooks/search?keyword=${encodeURIComponent(
      keyword
    )}&isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
  );
};

export const fetchEbookByPublisherUID = async (
  publisherUID,
  order,
  isAsc,
  lastEvaluatedKey,
  limit = 48
) => {
  var lastKey = "";
  if (lastEvaluatedKey) {
    lastKey = "&lastEvaluatedKey=" + encodeURIComponent(JSON.stringify(lastEvaluatedKey));
  }
  return await fetch(
    "get",
    `ebooks/publisher/${publisherUID}?isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
  );
};

export const fetchEbookByTag = async (tagName, order, isAsc, lastEvaluatedKey, limit = 48) => {
  var lastKey = "";
  if (lastEvaluatedKey) {
    lastKey = "&lastEvaluatedKey=" + encodeURIComponent(JSON.stringify(lastEvaluatedKey));
  }
  return await fetch(
    "get",
    `ebooks/tags?tagName=${tagName}&isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
  );
};

export const fetchEbookByAuthorName = async (
  authorName,
  order,
  isAsc,
  lastEvaluatedKey,
  limit = 48
) => {
  var lastKey = "";
  if (lastEvaluatedKey) {
    lastKey = "&lastEvaluatedKey=" + encodeURIComponent(JSON.stringify(lastEvaluatedKey));
  }
  return await fetch(
    "get",
    `ebooks/writers?writerName=${encodeURIComponent(
      authorName
    )}&isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
  );
};

export const fetchAllCategoriesName = async () => {
  return await fetch("get", `categories`);
};

export const fetchCategoryInfoById = async (catId, limit) => {
  return await fetch("get", `categories/${catId}?limit=${limit}`);
};

export const fetchEbookDetail = async (bookUID) => {
  return await fetch("get", "ebooks/" + bookUID + "?coverImageSize=large");
};

export const fetchRelatedEbook = async (bookUID, order, isAsc, lastEvaluatedKey, limit = 9) => {
  var lastKey = "";
  if (lastEvaluatedKey) {
    lastKey = "&lastEvaluatedKey=" + encodeURIComponent(JSON.stringify(lastEvaluatedKey));
  }
  return await fetch(
    "get",
    `ebooks/${bookUID}/related?isAsc=${isAsc}&order=${order}${lastKey}&limit=${limit}`
  );
};

export const fetchBanner = async () => {
  return await fetch("get", `banners?type=topBanner`);
};

export const fetchRecommendKeyword = async () => {
  return await fetch("get", `ebooks/keywords`);
};

export const addWishlist = async (bookUID) => {
  var params = {
    contentID: bookUID,
  };
  return await fetch("post", `ebooks/wishlists`, params);
};

export const deleteWishlist = async (bookUID) => {
  return await fetch("delete", `ebooks/wishlists/${bookUID}`);
};

export const fetchWishlist = async () => {
  return await fetch("get", `ebooks/wishlists`);
};

export const updateEbookViewStatistic = async (bookUID) => {
  return await fetch("patch", `statistics/ebooks`, { ebookUID: bookUID });
};

export const updateExampleDownloadStatistic = async (bookUID) => {
  return await fetch("patch", `statistics/examples`, { ebookUID: bookUID });
};
