import React, { useState, useRef, useEffect } from "react";
import { AutoComplete, Input, Icon } from "antd";
import { fetchSearchEbook } from "../../Actions/Ebook";
import { TagListComponent } from "../BookList/HomeList";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);
const { Option, OptGroup } = AutoComplete;
const { Search } = Input;

const SearchView = (props) => {
  const searchInput = useRef(null);
  const [dataSource, setDataSource] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchBoxValue, setSearchBoxValue] = useState("");

  useEffect(() => {
    setDataSource([]);
    setSearchBoxValue("");
  }, []);

  const onSelect = async (type, contentID) => {
    searchInput.current.blur();
    if (type) {
      props.history.push(`/${type === "KeyWord" ? "tag" : type.toLowerCase()}/${contentID}`);
      setDataSource([]);
      setSearchBoxValue("");
    }
  };

  const onSearch = async (text) => {
    setSearchBoxValue(text);
    var objOneKeyWord = { type: "keyword", children: [] };
    var objEbook = { type: "Ebook", children: [] };
    var objAuthor = { type: "Author", children: [] };
    var objPublisher = { type: "Publisher", children: [] };
    var objKeyWord = { type: "KeyWord", children: [] };
    if (text.length >= 3) {
      let result = await fetchSearchEbook(text);
      if (result.status && result.data) {
        objOneKeyWord.children.push({
          title: `ค้นหาจากคำว่า "${text}"`,
          contentID: text,
          type: "keyword",
        });
        // eslint-disable-next-line
        result.data.searchResults.map((el) => {
          if (el.type === "Ebook") {
            objEbook.children.push(el);
          } else if (el.type === "Author") {
            objAuthor.children.push(el);
          } else if (el.type === "Publisher") {
            objPublisher.children.push(el);
          } else if (el.type === "KeyWord") {
            objKeyWord.children.push(el);
          }
        });
        let dataSource = [objOneKeyWord];
        if (objAuthor.children.length) {
          dataSource.push(objAuthor);
          if (objPublisher.children.length) {
            dataSource.push(objPublisher);
            if (objEbook.children.length) {
              dataSource.push(objEbook);
              if (objKeyWord.children.length) {
                dataSource.push(objKeyWord);
              }
            }
          } else {
            if (objEbook.children.length) {
              dataSource.push(objEbook);
              if (objKeyWord.children.length) {
                dataSource.push(objKeyWord);
              }
            } else {
              if (objKeyWord.children.length) {
                dataSource.push(objKeyWord);
              }
            }
          }
        } else if (objPublisher.children.length) {
          dataSource.push(objPublisher);
          if (objEbook.children.length) {
            dataSource.push(objEbook);
            if (objKeyWord.children.length) {
              dataSource.push(objKeyWord);
            }
          } else {
            if (objKeyWord.children.length) {
              dataSource.push(objKeyWord);
            }
          }
        } else if (objEbook.children.length) {
          dataSource.push(objEbook);
          if (objKeyWord.children.length) {
            dataSource.push(objKeyWord);
          }
        } else if (objKeyWord.children.length) {
          dataSource.push(objKeyWord);
        }
        setDataSource(dataSource);
        setSearchResults([
          { title: text, contentID: "_" + text, type: "keyword" },
          ...result.data.searchResults,
        ]);
      }
    } else {
      setDataSource([]);
    }
  };
  const searchBar = () => {
    if (dataSource.length) {
      return dataSource.map((el) => {
        return (
          <>
            <div key={el.type === "keyword" ? "ค้นหาอีบุ๊ค" : el.type}>
              {el.type !== "keyword" ? (
                <span style={{ fontSize: "20px" }}>
                  <img
                    src={`/images/nav/search-${el.type.toLowerCase()}.svg`}
                    style={{
                      width: "24px",
                      height: "20px",
                      transform: "translateY(-2px)",
                    }}
                    alt=""
                  />
                  {" " + (el.type === "KeyWord" ? "Tag" : el.type)}
                </span>
              ) : (
                ""
              )}
            </div>
            {el.children.map((e, index) => (
              <div
                className="searchview-result"
                key={index}
                onClick={() => onSelect(el.type, e.contentID)}
                style={{
                  padding: "0 0",
                  clear: "both",
                  display: "block",
                  lineHeight: "2.5",
                }}
              >
                <p
                  style={{
                    width: "100%",
                    padding: "0 0 0 29px",
                    margin: "0 0",
                  }}
                >
                  {e.title}
                </p>
              </div>
            ))}
          </>
        );
      });
    }
  };

  return (
    <div className="searchBar-top">
      {/* <AutoComplete
        ref={searchInput}
        dropdownClassName="search-bar-dropdown"
        dataSource={searchBar()}
        style={{ width: "100%", marginBottom: "18px" }}
        onSelect={onSelect}
        onSearch={onSearch}
        value={searchBoxValue}
        optionLabelProp="value"
        placeholder="ค้นหาอีบุ๊ค นักเขียน สำนักพิมพ์ แฮชแท็ก #"
      > */}
      <Search
        ref={searchInput}
        onSearch={(e) => props.history.push(`/keyword/${e}`)}
        onChange={(e) => onSearch(e.target.value)}
        value={searchBoxValue}
        style={{ width: "100%", marginBottom: "18px" }}
        placeholder="ค้นหาอีบุ๊ค นักเขียน สำนักพิมพ์ แฮชแท็ก #"
      />
      {/* </AutoComplete> */}
      {dataSource.length > 0 ? (
        searchBar()
      ) : (
        <TagListComponent title="คำค้นหายอดนิยม" isSearchView={true} />
      )}
    </div>
  );
};

export default SearchView;
