import React, { useEffect, useState } from "react";
import { Col } from "antd";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import { showLoading } from "../../../../Actions/Page";
import { fetch } from "../../../../Utilitys/ApiRequest";

const HytextsCoin = ({ setSelectedForm, contentType, shoppingCart, showLoading, history }) => {
  const [payload, setPayload] = useState({
    returnUrl: window.location.href,
    cancelUrl: window.location.href,
    contentType,
    paymentType: "HC",
    contentData: null
  });

  useEffect(() => {
    setSelectedForm("hticoin_form");
    setContentData();
  }, []);

  const handleFormSubmit = async e => {
    e.preventDefault();
    showLoading(true);
    const { data } = await fetch("post", "payment/hticoin", payload);
    showLoading(false);
    if (data) {
      localStorage.setItem("orderCode", data.orderCode);
      history.push(`/payment/success/${data.orderCode}`);
    }
  };

  const setContentData = async () => {
    const data = await shoppingCart.map(book => book.bookUID);
    setPayload({ ...payload, contentData: data });
  };

  return (
    <Col span={24}>
      <form onSubmit={e => handleFormSubmit(e)} id="hticoin_form"></form>
    </Col>
  );
};

HytextsCoin.propTypes = {
  shoppingCart: PropTypes.array.isRequired,
  setSelectedForm: PropTypes.func.isRequired,
  contentType: PropTypes.string.isRequired,
  shoppingCart: PropTypes.array.isRequired,
  showLoading: PropTypes.func.isRequired,
  history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
  shoppingCart: state.Payment.shoppingCart
});

export default connect(mapStateToProps, { showLoading })(HytextsCoin);
