import React, { useState, useEffect } from "react";
import { Row, Col, Rate, Tooltip, Divider, Popconfirm } from "antd";
import { millisecsToDateTime } from "../../Utilitys/changeFormatDateTime";
import { fetchComments, deleteComment } from "../../Actions/Member";
import { connect } from "react-redux";

const Comments = (props) => {
  const [comments, setComments] = useState(props.initComments);
  const [commentsCount, setCommentsCount] = useState(null);
  const [ratingCommentsCount, setRatingCommentsCount] = useState(null);
  const [starCount, setStarCount] = useState({});
  const [activeBtnPos, setActiveBtnPos] = useState(0);
  const [haveComments, setHaveComments] = useState([]);
  const [onlyHaveRating, setOnlyHaveRating] = useState([]);
  // const [latest, setLatest] = useState(true);

  const setAllCount = (c) => {
    if (c.comments) {
      setCommentsCount(c.comments.count);
    }
    if (c.rating) {
      setRatingCommentsCount(c.rating.count);
    }
    if (c.star) {
      setStarCount(c.star);
    }
  };

  const mergedComments = (c) => {
    const comments = c.comments;
    const ratingC = c.rating;
    var haveComments_temp = [];
    var onlyHaveRating_temp = [];
    if (comments) {
      if (comments.fiveStar && comments.fiveStar.content) {
        haveComments_temp.push(...comments.fiveStar.content);
      }
      if (comments.fourStar && comments.fourStar.content) {
        haveComments_temp.push(...comments.fourStar.content);
      }
      if (comments.threeStar && comments.threeStar.content) {
        haveComments_temp.push(...comments.threeStar.content);
      }
      if (comments.twoStar && comments.twoStar.content) {
        haveComments_temp.push(...comments.twoStar.content);
      }
      if (comments.oneStar && comments.oneStar.content) {
        haveComments_temp.push(...comments.oneStar.content);
      }
      if (comments.noStar && comments.noStar.content) {
        haveComments_temp.push(...comments.noStar.content);
      }
    }
    if (ratingC) {
      if (ratingC.fiveStar && ratingC.fiveStar.content) {
        onlyHaveRating_temp.push(...ratingC.fiveStar.content);
      }
      if (ratingC.fourStar && ratingC.fourStar.content) {
        onlyHaveRating_temp.push(...ratingC.fourStar.content);
      }
      if (ratingC.threeStar && ratingC.threeStar.content) {
        onlyHaveRating_temp.push(...ratingC.threeStar.content);
      }
      if (ratingC.twoStar && ratingC.twoStar.content) {
        onlyHaveRating_temp.push(...ratingC.twoStar.content);
      }
      if (ratingC.oneStar && ratingC.oneStar.content) {
        onlyHaveRating_temp.push(...ratingC.oneStar.content);
      }
    }
    setHaveComments(haveComments_temp);
    setOnlyHaveRating(onlyHaveRating_temp);
    // setOnlyHaveRating([
    //   {
    //     date: 1416146318,
    //     memberUID: "c95ebb49-d1f3-4622-adbb-ba96649a5209",
    //     rating: 5,
    //     commentUID: "d9105984-df0f-46ec-817c-5f08e56d37af",
    //     isPurchase: false,
    //     profileName: "tumthunyathorn"
    //   }
    // ]);
    return [...haveComments_temp, ...onlyHaveRating_temp];
  };

  useEffect(() => {
    if (props.initComments) {
      // setLatest(true);
      setAllCount(props.initComments);
      setComments(mergedComments(props.initComments).sort(compareDate, true));
    }
  }, [props.initComments]);

  useEffect(() => {
    if (activeBtnPos === 0) {
      setComments([...haveComments, ...onlyHaveRating].sort(compareDate, true));
    }
    if (activeBtnPos === 1) {
      setComments(haveComments.sort(compareDate, true));
    } else if (activeBtnPos === 2) {
      setComments(onlyHaveRating.sort(compareDate, true));
    }
  }, [activeBtnPos]);

  useEffect(() => {
    const updatingComments = async () => {
      if (props.updateComments) {
        let response = await fetchComments(props.bookUID);
        if (response.status) {
          props.setUpdateComments(false);
          if (response.data) {
            setAllCount(response.data);
            setActiveBtnPos(0);
            setComments(mergedComments(response.data));
          }
        }
      }
    };
    updatingComments();
  }, [props.updateComments, props.bookUID]);

  // useEffect(() => {
  //   if (comments) {
  //     setComments(mergedComments(comments).sort(compareDate));
  //   }
  // }, [latest]);

  const compareDate = (a, b, boo = true) => {
    if (a.date > b.date) {
      return boo ? -1 : 1;
    } else if (a.date < b.date) {
      return boo ? 1 : -1;
    }
    return 0;
  };

  const onDeleteReviewFunc = async (bookUID, date) => {
    let response = await deleteComment(bookUID, date);
    if (response.status) {
      props.setUpdateComments(true);
    }
  };

  const eachComment = (comment) => {
    return (
      <div className="eachComment" key={comment.commentUID}>
        <Row style={{ margin: "8px" }}>
          <Col span={12} style={{ height: "40px" }}>
            <img
              style={{ width: "35px", height: "35px", borderRadius: "50%", verticalAlign: "top" }}
              src={comment.profileImage ? `${comment.profileImage}` : "/images/book/worm.png"}
              alt={`reader`}
            />
            <div style={{ display: "inline-block", marginLeft: "8px", marginTop: "1px" }}>
              <p className="commentatorName">{comment.profileName}</p>
              <Rate className="star-10px" disabled value={comment.rating} />
            </div>
          </Col>
          {comment.isPurchase ? (
            <Col span={12} align="right">
              <img
                style={{ width: "12px", height: "12px" }}
                src="/images/book/verified.svg"
                alt={`verified`}
              />
              <span className="purchased">ตรวจสอบว่ามีการซื้อแล้ว</span>
            </Col>
          ) : (
            ""
          )}
        </Row>
        {comment.description ? <p className="comment-Description">{comment.description}</p> : ""}
        <Row>
          <Col span={12} align="left">
            <p className="comment-BottomLabel">
              {millisecsToDateTime(comment.date, "hr-min") + " น."}
            </p>
          </Col>
          {/* <Col span={2}><p className="comment-BottomLabel">ตอบกลับ</p></Col> */}
          {props.memberUID === comment.memberUID && (
            <Col span={12} align="right">
              <Popconfirm
                title={<p style={{ fontFamily: "Prompt" }}>{`ต้องการลบความคิดเห็นนี้?`}</p>}
                onConfirm={() => onDeleteReviewFunc(props.bookUID, comment.date)}
                onCancel={() => {
                  console.log("");
                }}
                okText={<p style={{ fontFamily: "Prompt", marginTop: "1px" }}>{`ตกลง`}</p>}
                cancelText={<p style={{ fontFamily: "Prompt", marginTop: "1px" }}>{`ยกเลิก`}</p>}
              >
                <img className="trashOfComment" src="/images/logos/trash.svg" alt={`trash`} />
              </Popconfirm>
            </Col>
          )}
        </Row>
      </div>
    );
  };

  return comments && comments.length ? (
    <div>
      <Row type="flex" justify="space-between">
        <Divider style={{ color: "#e6e0dd", margin: "0 0 8px 0" }} />
        <Col span={props.isMobile ? 17 : 7}>
          <p className="commentLabel">คะแนนรีวิว</p>
          <Rate className="star-14px" allowHalf disabled value={starCount.total} />
          <p className="avgRate">{` ${starCount.total.toFixed(1)}/5`}</p>
          {/* <p className="totalRate">{` (${starCount.count} รีวิว)`}</p> */}
        </Col>
        {/*          <Col span={7} align="right">
            <p style={{ color: "#189aeb", paddingTop: "13px" }}>{"ดูทั้งหมด >"}</p>
          </Col>*/}
        <Col
          span={props.isMobile ? 24 : 17}
          align={props.isMobile ? (props.iscenterMobile ? "center" : "left") : "right"}
          style={{ marginBottom: "12px" }}
        >
          <div
            onClick={() => setActiveBtnPos(0)}
            className={`commentsBtn-${activeBtnPos === 0 ? "" : "in"}active`}
            style={{ marginLeft: "0px" }}
          >
            <span>ทั้งหมด</span>
            <br />
            <span className="bottom">({commentsCount + ratingCommentsCount} รีวิว)</span>
          </div>
          <div
            onClick={() => {
              if (commentsCount > 0) setActiveBtnPos(1);
            }}
            className={`commentsBtn-${activeBtnPos === 1 ? "" : "in"}active${
              commentsCount === 0 ? "-0" : ""
            }`}
          >
            <span>ที่มีคอมเมนต์</span>
            <br />
            <span className="bottom">({commentsCount} รีวิว)</span>
          </div>
          <div
            onClick={() => {
              if (ratingCommentsCount > 0) setActiveBtnPos(2);
            }}
            className={`commentsBtn-${activeBtnPos === 2 ? "" : "in"}active${
              ratingCommentsCount === 0 ? "-0" : ""
            }`}
          >
            <span>ที่มีเฉพาะดาว</span>
            <br />
            <span className="bottom">({ratingCommentsCount} รีวิว)</span>
          </div>
        </Col>
        {/* <Col span={12} align="right">
          {comments.length > 1 ? (
            <Tooltip
              placement="bottom"
              title={<p style={{ fontFamily: "Prompt", margin: "0 0" }}>เรียงตามเวลา</p>}
            >
              <img
                onClick={() => {
                  setLatest(!latest);
                }}
                className="sortComments"
                style={{ width: "20px", height: "24px" }}
                src="/images/book/comment-sort.svg"
                alt={`comment-sort`}
              />
            </Tooltip>
          ) : (
            ""
          )}
        </Col> */}
        <Divider style={{ color: "#e6e0dd", margin: "0 0 16px 0" }} />
      </Row>
      {comments.sort(compareDate).map((comment, index) => {
        return eachComment(comment);
        // if (props.isMobile) {
        //   if (index < 4) {
        //     return eachComment(comment);
        //   }
        // } else {
        //   return eachComment(comment);
        // }
      })}
    </div>
  ) : (
    <div />
  );
};

const mapStateToProps = (state) => {
  return {
    Authentication: state.Authentication,
  };
};
export default connect(mapStateToProps)(Comments);
