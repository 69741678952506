import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import PropTypes from 'prop-types';
import { showLoading } from '../../../../Actions/Page';
import { fetch } from '../../../../Utilitys/ApiRequest';
import { Col } from 'antd';
import { actionRemoveAllCart, fetchShoppingCart } from '../../../../Actions/Payment';
import { deleteWishlist } from '../../../../Actions/Ebook';

const BankTransfer = ({
	shoppingCart,
	showLoading,
	history,
	setSelectedForm,
	contentType,
	coinID,
	actionRemoveAllCart
}) => {
	const [payload, setPayload] = useState({
		returnUrl: window.location.href,
		cancelUrl: window.location.href,
		contentType,
		paymentType: 'TF',
		contentData: null
	});

	useEffect(() => {
		setSelectedForm('banktransfer_form');
		setContentData();
	}, []);

	const handleFormSubmit = async e => {
		e.preventDefault();
		showLoading(true);
		const { data } = await fetch('post', 'payment/promptpay', payload);
		removeWishlist_removeCart(payload);
		showLoading(false);
		if (data) {
			localStorage.setItem('banktransfer', JSON.stringify(data));
			if (data.htmlContent) {
				document.write(`${data.htmlContent}`);
			} else {
				history.push(`/payment/banktransfer/${data.orderCode}`);
			}
		}
	};

	const removeWishlist_removeCart = payload => {
		if (payload.contentType === 'ebook') {
			actionRemoveAllCart(payload.contentData);
			payload.contentData.map(async e => {
				await deleteWishlist(e);
				// let wl_delete = await deleteWishlist(e);
				// if (wl_delete.status) {
				//   console.log("deleted wishlist");
				// }
			});
		}
	};

	const setContentData = async () => {
		const data = await shoppingCart.map(book => book.bookUID);
		if (contentType === 'coin') {
			setPayload({ ...payload, contentData: [coinID] });
		} else if (contentType === 'ebook') {
			setPayload({ ...payload, contentData: data });
		}
	};

	return (
		<Col span={24}>
			<form onSubmit={e => handleFormSubmit(e)} id='banktransfer_form'></form>
		</Col>
	);
};

BankTransfer.propTypes = {
	shoppingCart: PropTypes.array.isRequired,
	setSelectedForm: PropTypes.func.isRequired,
	contentType: PropTypes.string.isRequired,
	shoppingCart: PropTypes.array.isRequired,
	showLoading: PropTypes.func.isRequired,
	history: PropTypes.object.isRequired
};

const mapStateToProps = state => ({
	shoppingCart: state.Payment.shoppingCart
});

const mapDispatchToProps = dispatch => {
	return {
		showLoading: showLoading,
		actionRemoveAllCart: data => {
			dispatch(actionRemoveAllCart(data));
		}
	};
};

export default connect(mapStateToProps, mapDispatchToProps)(BankTransfer);
