import React from "react";

function Privacy() {
  const privacy = () => {
    const contents_1 = [
      "hytexts.com คือร้านหนังสืออิเล็กทรอนิกส์(E-book)ที่ได้รับสิทธิ์อย่างถูกต้องในการโฆษณา จัดจำหน่ายหนังสืออิเล็กทรอนิกส์(E-book)จากสำนักพิมพ์ที่เข้าร่วม",
      "หนังสืออิเล็กทรอนิกส์(E-book)ที่จัดจำหน่ายในhytexts.com จะเป็นรูปแบบไฟล์PDF หรือePub ซึ่งจะถูกเข้ารหัสเพื่อควบคุมลิขสิทธิ์( Digital Right Management) ทุกไฟล์ สมาชิกสามารถนำหนังสืออิเล็กทรอนิกส์(E-book)ของ hytexts.com ไปอ่านในอุปกรณ์ที่รองรับต่างๆเช่น iPhone ,iPad ,Android,eReader,Pc และ Mac",
      "เมื่อผู้ใช้บริการได้เข้าหรือออกจากhytexts.com รวมถึงการเข้าชมหน้าต่างๆของเว็บไซต์ hytexts.comจะมีการจัดเก็บค่าIP Address รวมถึงชนิดเว็บเบราเซอร์ของสมาชิกทั้งนี้ก็เพื่อนำข้อมูลเหล่านั้นมาวิเคราะห์และนำไปพัฒนาบริการของhytexts.com เพื่อตอบสนองความต้องการของสมาชิกในอนาคต",
    ];
    const contents_gather = [
      "1. ในการสมัครสมาชิกกับ hytexts.com ผู้ใช้บริการจะต้องกรอกข้อมูลชื่อ ที่อยู่ เบอร์โทรศัพท์ อีเมลให้ถูกต้องและครบถ้วน ซึ่งการเก็บข้อมูลดังกล่าวใช้สำหรับพัฒนารูปการให้บริการ การบริการหลังการขาย การประมวลผลข้อมูลเพื่อจัดทำข้อมูลทางการตลาดและสิทธิประโยชน์ของสมาชิก",
      "2. ข้อมูลส่วนบุคคลที่สมาชิกได้ให้หรือใช้ผ่านการประมวลผลของเครื่องคอมพิวเตอร์ที่ควบคุมการทำงาน ของ hytexts.com ทั้งหมดนั้นสมาชิกยอมรับและตกลงว่าเป็นสิทธิและกรรมสิทธิ์ของบริษัท hytexts.com จะรักษาข้อมูลเหล่านั้นไว้เป็นความลับตามเกณฑ์มาตรฐานสากลสำหรับการรักษาข้อมูลเป็นความลับและจะไม่อนุญาตให้ผู้อื่นเข้าถึงข้อมูลดังกล่าวได้นอกจากพนักงานผู้ที่ได้รับอนุญาตจาก hytexts.com อย่างไรก็ตามเพื่อความปลอดภัยในข้อมูลส่วนบุคคลของสมาชิก สมาชิกควรปฏิบัติตามข้อกำหนดและเงื่อนไขการให้บริการของเว็บไซต์ของบริษัทแต่ละเว็บไซต์โดยเคร่งครัด",
      "3. ข้อมูลทางสถิติสมาชิก(demographic)เฉพาะ เพศ, อายุ, ระดับการศึกษา,อาชีพ, จังหวัดและประเทศที่อยู่อาศัยจะถูกเก็บข้อมูลทุกครั้งที่มีการดาวน์โหลดตัวอย่าง หนังสืออิเล็กทรอนิกส์(E-book) และการสั่งซื้อหนังสืออิเล็กทรอนิกส์(E-book) ซึ่งข้อมูลดังกล่าวจะถูกแจ้งให้กับสำนักพิมพ์ที่เป็นเจ้าของลิขสิทธ์หนังสืออิเล็กทรอนิกส์(E-book) เพื่อใช้ในการวิเคารห์ทางสถิติโดยที่ hytexts.com จะไม่มีการเปิดเผยชื่อ-นามสกุล ,email address, ชื่อโปรไฟล์ หรือข้อมูลอื่นที่ไม่ได้แจ้งไว้ดังข้างต้นเป็นอันขาด",
      "4. ท่านอาจมีสิทธิขอให้ทาง Hytexts ดำเนินการลบข้อมูลที่เก็บร่วบรวมไว้ ซึ่งข้อมูลดังกล่าว เป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูล เว้นเสียแต่ว่า การเก็บรักษาข้อมูลดังกล่าวของเรานั้นเป็นไปเพื่อการปฏิบัติตามกฎหมาย หรือเพื่อก่อตั้งสิทธิเรียกร้องตามกฎหมาย เพื่อการใช้ หรือการปกป้องสิทธิเรียกร้องตามกฎหมาย โดยการแจ้งความจำนงมาที่่ contact@hytexts.com",
    ];

    const contents_thirdPerson = [
      "1. hytexts.com อาจใช้ข้อมูลที่ได้รับจากสมาชิกเพื่อใช้ในการการส่งเสริมการตลาดหรือให้ข้อมูลแก่หน่วยงานที่ทำหน้าที่ด้านการตลาดโดย hytexts.com เป็นผู้ว่าจ้างพันธมิตรทางธุรกิจหรือผู้ให้การสนับสนุนทางธุรกิจ ตามที่ hytexts.com เห็นสมควร",
      "2. การเปิดเผยข้อมูลนั้น ๆ เป็นไปโดยถูกต้องตามกฎหมายหรือตามคำสั่งของหน่วยงานของรัฐที่เกี่ยวข้อง",
    ];
    const contents_cookie =
      "คุกกี้ คือไฟล์ข้อความขนาดเล็กซึ่งถูกคัดลอกลงในฮาร์ดดิสก์ของคุณโดยเว็บไซต์ คุกกี้ไม่ทำให้คอมพิวเตอร์ของคุณเสียหาย และไม่มีไวรัสใดๆ ทั้งสิ้นคุกกี้จากเว็บไซต์ของเราจะไม่เก็บรวบรวมข้อมูลส่วนตัวของคุณคุณสามารถปิดการใช้งานคุกกี้ได้ตลอดเวลาผ่านการตั้งค่าในบราวเซอร์ของคุณตามกฎแล้วคุกกี้จะถูกใช้บนเว็บไซต์ของเราตามระยะเวลาที่คุณใช้งานเท่านั้น ทั้งนี้เพื่อใช้สำหรับวัตถุประสงค์ที่ไม่เป็นที่เปิดเผย การประเมินผลทางสถิติ และเพื่อการปรับปรุงความง่าย และสะดวกต่อการใช้งานสำหรับผู้ใช้ต่อไป ในบางกรณี คุกกี้อาจถูกใช้งานเพื่อวัตถุประสงค์อื่นๆ ในบางส่วนของเว็บไซต์คุณจะได้รับการแจ้งเตือนในกรณีนี้หากคุณเข้าใช้งานในส่วนที่เกี่ยวข้องดังกล่าว";
    const contents_cookie_list = [
      "1. คุกกี้คืออะไร",
      "คุกกี้ คือไฟล์ข้อความขนาดเล็กซึ่งถูกดาวน์โหลดไปยังคอมพิวเตอร์ของลูกค้า ไฟล์นี้อนุญาตให้เรา บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด สามารถรวบรวมข้อมูลเกี่ยวกับประวัติการเข้าชมเว็บไซต์ของผู้เยี่ยมชมได้",
      "2. ประโยชน์ของคุกกี้",
      "ด้วยการรวบรวมคุกกี้ เว็บไซต์จะสามารถมอบประสบการณ์การใช้งานที่ดีขึ้น และตรงกับความต้องการของลูกค้าในอนาคตได้ข้อมูลนี้จะถูกใช้โดย บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด เท่านั้น ดังนั้นการโอนถ่ายข้อมูลคุกกี้จึงไม่สามารถเกิดขึ้นได้",
      "คุกกี้จะบันทึกการตั้งค่าแรกของเว็บไซต์ไว้ด้วยเช่นกัน ซึ่งจะช่วยให้ลูกค้าได้รับค่าที่ตั้งไว้เดิมแต่แรกทุกครั้งที่ใช้งาน หากคุกกี้ถูกลบการตั้งค่าทุกอย่างจะกลับไปที่ค่าเริ่มต้น",
      "3. คุกกี้ไม่ทำให้เกิดอันตรายต่อคอมพิวเตอร์ของคุณ",
      "วัตถุประสงค์หลักของคุกกี้คือการรวบรวมข้อมูลในรูปแบบไฟล์ ซึ่งไม่มีหน้าที่อื่นใดนอกเหนือจากนี้",
      "4. สิ่งที่คุกกี้ไม่ได้รวบรวมมีอะไรบ้าง",
      "คุกกี้ไม่เก็บรวบรวมข้อมูลเกี่ยวกับข้อมูลรายละเอียดส่วนบุคคลของคุณเช่น หมายเลขโทรศัพท์ หรือวันเดือนปีเกิดของคุณหากมีการเก็บข้อมูลเพิ่มเติมนอกเหนือจากนี้จะต้องได้รับความยินยอมจากลูกค้าก่อนการเก็บข้อมูล",
      "5. วิธีการปิดการทำงานของคุกกี้ทำอย่างไร",
      "ลูกค้าสามารถปิดการตั้งค่าการทำงานของคุกกี้ได้โดยเข้าไปที่หน้าการตั้งค่าบราวเซอร์ และตั้งค่าความเป็นส่วนตัวเพื่อหยุดการรวบรวมข้อมูลทุกอย่างของคุกกี้ในอนาคต",
    ];
    const contents_responsibility =
      "hytexts.com ขอสงวนสิทธิ์ในการปฏิเสธความรับผิดทั้งหมดในกรณีที่ข้อมูลส่วนบุคคลของสมาชิกเสียหายหรือสูญหายเนื่องจากความผิดพลาดใดๆ ระหว่างส่งข้อมูลระหว่าง herevgo กับผู้ใช้บริการเหตุสุดวิสัยใดๆการถูกจารกรรมข้อมูลคอมพิวเตอร์(Hack) และ/หรือการกระทำใดๆที่ขัดกฎหมายไม่ว่าการกระทำนั้นจะเกิดจากบุคคลใด ๆ ก็ตาม";
    const contents_copyrights =
      "ลิขสิทธิ์ตามกฎหมาย(Copyrights) ข้อมูลหนังสืออิเล็กทรอนิส์(E-book) ข้อมูลตัวอย่างหนังสืออิเล็กทรอนิส์(E-book),ข้อความ ภาพ เสียงเนื้อหา ส่วนประกอบใด ๆ ทั้งหมดของ hytexts.com แต่ไม่จำกัดเฉพาะเครื่องหมายการค้าเครื่องหมายบริการ ชื่อทางการค้าที่ปรากฏบน hytexts.com เป็นงานอันได้รับความคุ้มครองตามกฎหมายทรัพย์สินทางปัญญาของไทยโดยชอบด้วยกฎหมายของ hytexts.com แต่เพียงผู้เดียว  หากบุคคลใดลอกเลียน ปลอมแปลงทำซ้ำ ดัดแปลง เผยแพร่ต่อสาธารณชนจำหน่าย มีไว้ให้เช่าหรือกระทำการใดๆในลักษณะที่เป็นการแสวงหาผลประโยชน์ทางการค้าหรือประโยชน์โดยมิชอบไม่ว่าโดยประการใดๆ จากทรัพย์สินทางปัญญาดังกล่าวข้างต้นโดยไม่ได้รับอนุญาตจากhytexts.com hytexts.comจะดำเนินการตามกฎหมายกับผู้ละเมิดสิทธิดังกล่าวโดยทันที";
    const contents_privacyChange = [
      "hytexts.com สามารถเปลียนแปลงนโยบายความเป็นส่วนตัวได้ตลอดเวลา เมื่อมีการเปลี่ยนแปลงหรือเพิ่มเติมบริการหรือเมื่อเราได้รับข้อเสนอแนะจากสมาชิก การเปลี่ยนแปลงใดๆในนโยบายจะมีการแจ้งถึงสมาชิกผ่านทางไปรษณีย์อิเล็กทรอนิกส์",
      "หากสมาชิกมีคำถามที่เกี่ยวข้องกับนโยบายความเป็นส่วนตัวของเราสมาชิกสามารถติดต่อผ่านที่อยู่ไปรษณีย์อิเล็กทรอนิกส์ contact@hytexts.com หรือผ่านหน้า“ติดต่อเรา”",
    ];

    const privacy_right = [
      "การเข้าถึง: ผู้ใช้งานอาจมีสิทธิ์ในการขอเข้าถึงหรือขอรับสำเนาข้อมูลส่วนบุคคลที่เราเก็บรวบรวม ใช้หรือเปิดเผยเกี่ยวกับผู้ใช้งาน ทั้งนี้ เพื่อความเป็นส่วนตัวและความปลอดภัยของผู้ใช้งาน เราอาจขอให้ผู้ใช้งานพิสูจน์ตัวตนของผู้ใช้งานก่อนจะให้ข้อมูลตามที่ผู้ใช้งานขอ",
      "การแก้ไขให้ถูกต้อง: ผู้ใช้งานอาจมีสิทธิ์ขอให้มีการดำเนินการแก้ไขข้อมูลส่วนบุคคลที่เราได้เก็บรวบรวม ใช้หรือเปิดเผยเกี่ยวกับผู้ใช้งาน ให้ ถูกต้อง เป็นปัจจุบัน สมบูรณ์ และไม่ก่อให้เกิดความเข้าใจผิด",
      "การคัดค้าน: ผู้ใช้งานอาจมีสิทธิ์คัดค้านการเก็บรวบรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งาน เช่น การคัดค้านการตลาดแบบตรง",
      "การขอให้ระงับการใช้: ผู้ใช้งานอาจมีสิทธิ์ขอให้ระงับการใช้ข้อมูลส่วนบุคคลของผู้ใช้งานในบางกรณี",
      "การถอนความยินยอม: สำหรับวัตถุประสงค์ที่ผู้ใช้งานได้ให้ความยินยอมให้เราเก็บรวบรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของผู้ใช้งาน ผู้ใช้งานมีสิทธิ์ที่จะถอนความยินยอมของผู้ใช้งาน ณ เวลาใด ก็ได้",
      "การลบหรือทำลายข้อมูล: ผู้ใช้งานอาจมีสิทธิ์ขอให้เราดำเนินการลบหรือทำลาย หรือทำให้ข้อมูลส่วนบุคคลของผู้ใช้งานที่เราเก็บรวบรวม ใช้หรือเปิดเผย เป็นข้อมูลที่ไม่สามารถระบุตัวบุคคลที่เป็นเจ้าของข้อมูล เว้นเสียแต่ว่า การเก็บรักษาข้อมูลดังกล่าวของเรานั้นเป็นไปเพื่อการปฏิบัติตามกฎหมาย หรือเพื่อก่อตั้งสิทธิ์เรียกร้องตามกฎหมาย เพื่อการใช้ หรือการปกป้องสิทธิ์เรียกร้องตามกฎหมาย",
      "การยื่นเรื่องร้องเรียน: ผู้ใช้งานอาจมีสิทธิ์ยื่นเรื่องร้องเรียนไปยังหน่วยงานที่มีอำนาจในกรณีที่ผู้ใช้งานเชื่อว่าการเก็บรวบรวม ใช้หรือเปิดเผยข้อมูลส่วนบุคคลของเรานั้นไม่ชอบด้วยกฎหมายหรือไม่สอดคล้องกับกฎหมายคุ้มครองข้อมูลที่บังคับใช้",
    ];

    return (
      <div className="side">
        <p className="side-title-c">นโยบายความเป็นส่วนตัว</p>
        {contents_1.map((e) => (
          <p className="side-content-lessBottom">
            {e}
            <br />
          </p>
        ))}
        <p className="side-title">การรวบรวมข้อมูล</p>
        {contents_gather.map((e) => (
          <p className="side-content-lessBottom">
            {e}
            <br />
          </p>
        ))}
        <p className="side-title">การเปิดเผยข้อมูลแก่บุคคลที่สาม</p>
        {contents_thirdPerson.map((e) => (
          <p className="side-content-lessBottom">
            {e}
            <br />
          </p>
        ))}
        <p className="side-title">คุกกี้ (COOKIE)</p>
        <p className="side-content-lessBottom">
          {contents_cookie}
          <br />
        </p>
        {contents_cookie_list.map((e, index) => (
          <p className="side-content-lessBottom" key={index}>
            {e}
            <br />
          </p>
        ))}
        <p className="side-title">ความรับผิดชอบ</p>
        <p className="side-content-lessBottom">
          {contents_responsibility}
          <br />
        </p>
        <p className="side-title">ลิขสิทธิ์ (COPYRIGHTS)</p>
        <p className="side-content-lessBottom">
          {contents_copyrights}
          <br />
        </p>
        <p className="side-title">การเปลี่ยนแปลงนโยบายความเป็นส่วนตัว</p>
        {contents_privacyChange.map((e) => (
          <p className="side-content-lessBottom">
            {e}
            <br />
          </p>
        ))}

        <p className="side-title">สิทธิ์ของเจ้าของข้อมูลส่วนบุคคล</p>
        <p className="side-content-lessBottom">
          ภายใต้บทบัญญัติแห่งกฎหมายและข้อยกเว้นตามกฎหมายที่เกี่ยวข้อง
          ผู้ใช้งานอาจมีสิทธิ์ตามที่ระบุไว้
        </p>
        <p className="list-in-content">
          <ul>
            {privacy_right.map((e, index) => (
              <li key={index}>{e}</li>
            ))}
          </ul>
        </p>

        <p className="side-title">ระยะเวลาในการที่เราเก็บข้อมูลส่วนบุคคล</p>
        <p className="side-content-lessBottom">
          เราจะเก็บรักษาข้อมูลส่วนบุคคลของท่านในระยะเวลาเท่าที่จำเป็นอย่างสมเหตุสมผล
          เพื่อให้บรรลุตามวัตถุประสงค์ที่เราได้รับข้อมูลส่วนบุคคลนั้นมา <br />
          และเพื่อปฏิบัติตามภาระหน้าที่ทางกฎหมายและกฎข้อบังคับต่างๆ อย่างไรก็ตาม
          เราอาจเก็บข้อมูลส่วนบุคคลของท่านนานขึ้นหากจำเป็นตามกฎหมาย
          <br />
          ที่ใช้บังคับ
        </p>
      </div>
    );
  };
  return <>{privacy()}</>;
}

export default Privacy;
