import React from "react";
const contents_1 = [
  "หากพบว่ามีผลงานที่เข้าข่ายผิดกฎหมาย บริษัทฯ จะดำเนินการระงับการเผยแพร่และแจ้งให้เจ้าของผลงานทราบ เพื่อดำเนินการแก้ไขต่อไป",
  "และหากพบว่าเจ้าของผลงานมีการส่งผลงานที่ผิดกฎหมายเข้ามาซ้ำซาก บริษัทฯ จะดำเนินการระงับบัญชีผู้ใช้ของเจ้าของผลงานต่อไป",
  "อย่างไรก็ดี บริษัทฯ ได้พัฒนาระบบเพื่อตรวจสอบ และป้องกันการนำ ภาพและเนื้อหาที่มีลิขสิทธิ์หรือไม่เหมาะสมมาวางขายบนแพลตฟอร์ม และบริษัทฯ",
  "ได้แต่งตั้งทีมงานที่มีประสบการณ์เชี่ยวชาญด้านเนื้อหาและรูปภาพเพื่อที่จะคอยช่วยดูแล ตรวจจับ และป้องกัน ไม่ให้มีเนื้อหาที่ละเมิดลิขสิทธิ์",
  ,
  "หรือผิดกฎหมาย นอกจากนี้ บริษัทฯ ได้จัดให้มีระบบในการร้องเรียนจากสมาชิกให้ทีมงานบริษัทฯ โดยตรง ผู้ที่ประสงค์จะให้บริษัทฯ ในฐานะผู้ให้บริการ",
  "ดำเนินการต่อเนื้อหาที่เข้าข่ายผิดกฎหมาย สามารถดำเนินการแจ้งบริษัทฯ ได้พร้อมเอกสารหลักฐานประกอบแล้วส่งมาที่",
];

const userService = [
  "มาตรการดูแลผู้ใช้บริการซึ่งได้รับความเสียหายจากการใช้บริการเป็นไปตามนโยบายของบริษัทฯ",
  "ถ้าท่านมีปัญหาใด ๆเกี่ยวกับการให้บริการแพลตฟอร์มดิจิทัล โปรดติดต่อ",
];
const IllegalContent = () => {
  const hytextsAddress = (
    <div>
      <p className="side-title-bold">
        บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด (Hytexts)
      </p>
      <p className="side-content-lessBottom">
        3 อาคารพร้อมพันธุ์ 3 ชั้น 7 ห้อง 701-702 ซอย ลาดพร้าว 3 แขวงจอมพล
        เขตจตุจักร กรุงเทพมหานคร 10900 <br />
        <br />
        โทร: 089-134-7470
        <br />
        (ทุกวัน 9.00-17.30 น.)
        <br />
        <br />
        อีเมล : contact@hytexts.com <br />
        <br />
        บริษัทจะดำเนินการและตอบกลับภายใน 7 วันทำการ
      </p>
    </div>
  );
  return (
    <div className="side">
      <div className="section-space">
        <div>
          <p className="side-title-hytexts">
            ขั้นตอนการดำเนินการต่อเนื้อหาที่เข้าข่ายผิดกฎหมาย
          </p>
          {contents_1.map((e, index) => (
            <p className="side-content-lessBottom" key={index}>
              {e}
              <br />
            </p>
          ))}
        </div>

        {hytextsAddress}

        <div>
          <p className="side-title-bold">
            การจัดระดับการนำเสนอเนื้อหาที่เหมาะสมต่อผู้ใช้บริการแต่ละกลุ่ม
          </p>
          <p className="side-content-lessBottom">
            บริษัทฯ
            มีหลักเกณฑ์ในการจัดระดับการนำเสนอเนื้อหาที่เหมาะสมต่อผู้ใช้บริการแต่ละกลุ่ม
            โดยคำนึงถึง ปัจจัยหลักในการจัดอันดับขายดี คือ ยอดขาย <br /> และ
            ปัจจัยหลักในการจัดระดับหนังสือที่คุณน่าจะสนใจ
            ขึ้นกับความเกี่ยวข้องของหนังสือที่ผู้ใช้บริการมี
            ซึ่งแพลตฟอร์มจะแบ่งเนื้อหาออกเป็น 3 ประเภท
            <br /> โดยคำนึงถึง
          </p>

          <p className="side-content-lessBottom">
            <p className="list-in-content">
              <li>
                ประเภทแรก คือ เนื้อหาทั่วไปที่สามารถเข้าถึงได้บนหน้าเว็บไซต์
              </li>
              <li>
                ประเภทสอง คือ เนื้อหาที่เหมาะกับผู้ใช้งานที่มีอายุ 18 ปีขึ้นไป
                โดยจะมีสัญลักษณ์ 18+ หรือ 18 ปีขึ้นไป
              </li>
              <li>
                ประเภทสาม คือ เนื้อหาที่เหมาะกับผู้ใช้งานบางกลุ่ม
                ซึ่งจะจำกัดการแสดงผลให้เฉพาะผู้ใช้บางกลุ่มเท่านั้น เช่น
                หนังสือรุ่น เป็นต้น
              </li>
            </p>
          </p>
          <p className="side-content-lessBottom">
            การจัดอันดับกลุ่มหนังสือที่แสดงบนหน้าเว็บไซต์นั้น บริษัทฯ
            มีหลักเกณฑ์ในการจัดอันดับการนำเสนอ
            โดยคำนึงถึงปัจจัยหลักในการจัดอันดับขายดี คือ <br /> ยอดขาย และ
            ปัจจัยหลักในการจัดระดับหนังสือที่คุณน่าจะสนใจ
            ขึ้นกับความเกี่ยวข้องของหนังสือที่ผู้ใช้บริการมี
            ซึ่งแพลตฟอร์มจะแบ่งกลุ่มไว้ 6 <br />
            กลุ่มประเภทดังนี้
          </p>
          <p className="side-content-lessBottom list-in-content">
            <li>
              โปรโมชั่น - เฉพาะหนังสือที่มีการจัดโปรโมชั่น
              โดยเรียงตามลำดับของวันที่สิ้นสุดโปรโมชั่นก่อน
            </li>
            <li>มาใหม่ - เรียงตามลำดับการขายหนังสือ จากล่าสุดไปเก่าสุด</li>
            <li>ขายดี - จัดอันดับจากยอดขาย ณ กรอบเวลาปัจจุบันเป็นปัจจัยหลัก</li>
            <li>
              ละครฮิต - เฉพาะหนังสือที่ติดป้าย TV คือหนังสือที่ถูกทำเป็นละคร
            </li>
            <li>
              ฟรี - เฉพาะหนังสือที่ดาวน์โหลดได้ฟรี โดยใช้ความนิยม ณ
              กรอบเวลาปัจุบันเป็นปัจจัยหลัก
            </li>
          </p>
        </div>

        <div>
          <p className="side-title-bold">มาตรการดูแลผู้ใช้บริการ</p>
          {userService.map((e, index) => (
            <p className="side-content-lessBottom" key={index}>
              {e}
              <br />
            </p>
          ))}
        </div>

        {hytextsAddress}
        <p className="side-content-lessBottom">
          ทั้งนี้ หากยังไม่ได้รับการแก้ไขภายในกรอบระยะเวลาที่กำหนดไว้
          ท่านสามารถติดต่อศูนย์ช่วยเหลือและจัดการปัญหาออนไลน์ (1212ETDA) สายด่วน
          1212 <br /> (24 ชั่วโมง) อีเมล 1212@mdes.go.th
          เพื่อให้คำแนะนำและรับเรื่อง ร้องเรียนดังกล่าว
        </p>
      </div>
    </div>
  );
};

export default IllegalContent;
