import React from "react";
import { connect } from "react-redux";
const AlreadyMember = (props) => {
  return (
    <div className="register-creator-container-isAuthor">
      <p className="isAuthor">เป็นสมาชิกของ Hytexts เรียบร้อยแล้ว</p>
      <img
        src="/images/badges/alreadyMember.png"
        style={{
          width:
            props.Page.screensize.width < 370
              ? props.Page.screensize.width - 80
              : "100%",
          height: "100%",
        }}
        alt=""
      />
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    Page: state.Page,
  };
};

export default connect(mapStateToProps)(AlreadyMember);
