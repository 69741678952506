import React, { useState, useEffect } from "react";
import { Form, Input, Icon, Button } from "antd";
import validator from "validator";
import PropTypes from "prop-types";
import { FacebookLogin } from "../../Login";
import { connect } from "react-redux";
import { showLoading } from "../../../Actions/Page";
import { showError } from "../../../Actions/Message";
import { fetchLogin } from "../../../Actions/Authentication";
import ObjectUitil from "../../../Utilitys/Object";
import authenObj from "../../../Utilitys/Authentication";
import firebase from "../../../Service/Firebase";
import { Link, withRouter } from "react-router-dom";
import { DownloadApp } from "./Login-children";
import { navModal, navModalMode } from "../../../Actions/Page";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);

const Login = (props) => {
  const [usernameError, setUsernameError] = useState("");
  const [usernameerrormessage, setUsernameerrormessage] = useState("");
  const [passwordError, setPasswordError] = useState("");
  const [passworderrormessage, setPassworderrormessage] = useState("");

  const queryParam = ObjectUitil.getQueryStringFormURL(props.location.search || "");

  useEffect(() => {
    checkLineLoginCallback();
    props.form.validateFields();
  }, []);

  useEffect(() => {
    if (authenObj.isLogin(props.Authentication.userToken)) {
      props.dispatch(navModal(false));
      props.dispatch(navModalMode({ loginModal: 0, registerModal: 0, registerEmailModal: 0 }));
      //   if (this.props.history && this.props.match.path !== "/login") {
      //     return window.location.reload();
      //   } else {
      //     return <Redirect to="/" />;
      //   }
    }
  }, [props.Authentication.userToken]);

  const checkLineLoginCallback = () => {
    if (queryParam.state === "LineCallBack") {
      if (queryParam.code) {
        //fetch line login data
        // this.dispatch(showLoading(true))
        // fetch line login data
        // this.dispatch(fetchLogin('line'))
      } else if (queryParam.error) {
        props.dispatch(showError({ message: `Line Login Error ${this.queryParam.error}` }));
      }
    }
  };

  const fieldOnchangehandle = (e) => {
    props.form.validateFields();
    switch (e.target.name) {
      case "username":
        if (
          e.target.value !== "" &&
          !validator.isEmail(e.target.value) &&
          !ObjectUitil.isPhoneNumber(e.target.value)
        ) {
          setUsernameError("error");
          setUsernameerrormessage("อีเมลหรือเบอร์โทรศัพท์ไม่ถูกต้อง");
        } else {
          setUsernameError("");
          setUsernameerrormessage("");
        }
        break;
      default:
        break;
    }
  };
  const errorHandleCheck = (fieldsError) => {
    return Object.keys(fieldsError).some((field) => fieldsError[field]);
  };
  const handleSubmit = (e) => {
    e.preventDefault();
    props.form.validateFields((err, values) => {
      if (!err) {
        if (validator.isEmail(values.username) || ObjectUitil.isPhoneNumber(values.username)) {
          props.dispatch(
            fetchLogin(
              "email",
              {
                userID: values.username,
                userPassword: values.password,
              },
              props.match.path
            )
          );
        }
      }
    });
  };

  const onFacebookLoginHandle = (result, data) => {
    if (result) {
      props.dispatch(fetchLogin("facebook", data.user, props.match.path));
    }
    props.dispatch(showLoading(false));
  };

  const onLineLoginHandle = () => {
    var channelid = "1586815286";
    var callbackurl = authenObj + "/login";
    var state = "LineCallBack";
    var url = `https://access.line.me/oauth2/v2.1/authorize?response_type=code&client_id=${channelid}&redirect_uri=${callbackurl}&scope=profile&state=${state}`;
    window.location.href = url;
  };

  const onGmailLoginHandle = () => {
    var provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().languageCode = "th";
    firebase
      .auth()
      .signInWithPopup(provider)
      .then((result) => {
        var user = result.additionalUserInfo.profile;
        let payload = {
          id: user.id,
          email: user.email,
          name: user.name,
          picture: user.picture,
          given_name: user.given_name,
          family_name: user.family_name,
        };
        props.dispatch(fetchLogin("gmail", payload, props.match.path));
      })
      .catch((error) => {
        // props.dispatch(showError({ message: `Gmail Login Error ${error.message}` }));
      });
  };

  const { getFieldDecorator, getFieldsError } = props.form;
  return (
    <div className="login-container">
      <h1>เข้าสู่ระบบ</h1>
      <p style={{ marginBottom: "16px" }}>ด้วย Social Network หรือ QR CODE ของคุณ</p>
      <div className="login-channel-container">
        <div className="loginIconSet">
          <FacebookLogin onLogin={onFacebookLoginHandle}>
            <button className="login-facebook">Log in with Facebook</button>
          </FacebookLogin>
          {/* <button className="login-line" onClick={onLineLoginHandle}>
            Log in with Line
          </button> */}
          <button className="login-google" onClick={onGmailLoginHandle}>
            Log in with Google
          </button>
          <button
            className="login-qrcode"
            onClick={() => {
              if (props.Page.screensize.width <= 900) {
                props.history.push("/login/qrcode");
              } else {
                props.toQrcode(true);
              }
            }}
          >
            Log in with QR CODE
          </button>
        </div>

        <h3>หรือ</h3>
        <Form name="normal_login" className="login-form" onSubmit={handleSubmit}>
          <h4>อีเมลหรือเบอร์โทรศัพท์</h4>
          <Form.Item
            validateStatus={usernameError ? "error" : ""}
            help={usernameerrormessage || ""}
            style={{ marginBottom: "16px" }}
          >
            {getFieldDecorator("username", {
              id: "username",
              rules: [
                {
                  required: true,
                  message: "Please input your email or phone number",
                },
              ],
            })(
              <Input
                name="username"
                prefix={<Icon type="user" style={{ color: "rgba(0,0,0,.25)" }} />}
                placeholder="อีเมลหรือเบอร์โทรศัพท์"
                onChange={fieldOnchangehandle}
              />
            )}
          </Form.Item>
          <h4>รหัสผ่าน</h4>
          <Form.Item
            validateStatus={passwordError ? "error" : ""}
            help={passworderrormessage || ""}
            className="password-login"
          >
            {getFieldDecorator("password", {
              id: "password",
              rules: [
                {
                  required: true,
                  message: "Please input your password",
                },
              ],
            })(
              <Input.Password
                prefix={<Icon type="lock" style={{ color: "rgba(0,0,0,.25)" }} />}
                type="password"
                placeholder="รหัสผ่าน"
                name="password"
              />
            )}
          </Form.Item>
          <Link
            className="login-form-forgot"
            to="#"
            onClick={() => {
              if (props.Page.screensize.width <= 900) {
                props.history.push("/renewpassword");
              } else {
                props.toForgetPassword(true);
              }
            }}
          >
            ลืมรหัสผ่าน ?
          </Link>
          <div style={{ marginBottom: "16px" }}>
            <Button
              type="primary"
              htmlType="submit"
              className="login-form-button"
              disabled={errorHandleCheck(getFieldsError()) || usernameError !== ""}
            >
              เข้าสู่ระบบ
            </Button>
          </div>
        </Form>
      </div>
      <p className="noaccountyet">
        คุณยังไม่มีบัญชี ?{" "}
        <Link
          className="register"
          to={props.Page.screensize.width > 900 ? "#" : "/register"}
          onClick={props.toRegister}
        >
          สมัครสมาชิก
        </Link>
      </p>
      <DownloadApp />
    </div>
  );
};

const mapStateToProps = (state) => {
  return { Page: state.Page, Authentication: state.Authentication };
};
Login.propTypes = {
  form: PropTypes.object,
  dispatch: PropTypes.func,
  history: PropTypes.object,
  Authentication: PropTypes.object,
  location: PropTypes.object,
  isModal: PropTypes.bool,
};
export default withRouter(connect(mapStateToProps)(Form.create({ name: "normal_login" })(Login)));
