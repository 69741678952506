import React, { useRef, useState, useEffect } from "react";
import { Row, Col } from "antd";
import { connect } from "react-redux";
import {
  fetchShoppingCart,
  fetchRemoveCart,
  makeHCpurchase,
  removeCartItemFromLocalStorage,
} from "../../Actions/Payment";
import { ACTION_SHOW_LOADING } from "../../Actions/Page";
import PropTypes from "prop-types";
import { Link } from "react-router-dom";
import { Helmet } from "react-helmet";
import { withRouter } from "react-router-dom";
import authenUtility from "../../Utilitys/Authentication";
import { navModal, navModalMode } from "../../Actions/Page";

import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);

const CartItem = (props) => {
  const [animation, setAnimation] = useState("normal");
  const [className, setClassName] = useState("cart-item");

  useEffect(() => {
    switch (animation) {
      case "normal":
        setClassName("cart-item");
        break;

      case "delete":
        setClassName("cart-item-delete");
        break;

      case "was-delete":
        setClassName("cart-item-was-delete");
        break;

      default:
        break;
    }
  }, [animation]);

  return (
    <div className={className}>
      <Helmet>
        <title>Hytexts: ตะกร้าสินค้า</title>
      </Helmet>
      <Row gutter={[0, 8]}>
        <Col xs={5} sm={2}>
          <div className="cover">
            <img
              style={{ height: "auto", width: "100%" }}
              src={props.item.coverImage}
              alt=""
            />
          </div>
        </Col>
        <Col xs={15} sm={16}>
          <div className="medata">
            <Link to={"/ebook/" + props.item.bookUID}>
              <h2 className="title">{props.item.title}</h2>
            </Link>
            <Link to={"/author/" + props.item.authorName}>
              <h3 className="author">{`ผู้เขียน: ${props.item.authorName}`}</h3>
            </Link>
            {props.item.publisherName ? (
              <Link to={"/publisher/" + props.item.creatorUID}>
                <h3 className="author">{`สำนักพิมพ์: ${props.item.publisherName}`}</h3>
              </Link>
            ) : (
              ""
            )}
          </div>
        </Col>
        <Col span={4} align={props.screenWidth >= 576 ? "right" : "left"}>
          <div className="price">
            {props.item.priceTHB > 0 ? `฿${props.item.priceTHB}` : "ฟรี"}
          </div>
        </Col>
        <Col span={2} align="right">
          <Link
            to="#"
            className="remove"
            onClick={() => {
              props.onRemoveHandle(props.item);
              props.checkIsArea(true);
              // setTimeout(() => {
              //   setAnimation("delete");
              //   setTimeout(() => {
              //     setAnimation("was-delete");
              //     setAnimation("normal");
              //   }, 500);
              // }, 500);
            }}
          >
            <img
              style={{ height: "20px", width: "26px" }}
              src="/images/logos/trash.svg"
              alt=""
            />
          </Link>
        </Col>
      </Row>
    </div>
  );
};

CartItem.propTypes = {
  index: PropTypes.number,
  item: PropTypes.object,
  onRemoveHandle: PropTypes.func,
};
export const ListCartItem = ({
  cartItems = [],
  onRemoveHandle,
  screenWidth,
  checkIsArea,
}) => {
  if (cartItems.length) {
    return cartItems.map((el, index) => {
      return (
        <CartItem
          item={el}
          key={index}
          index={index}
          onRemoveHandle={onRemoveHandle}
          checkIsArea={checkIsArea}
          screenWidth={screenWidth}
        />
      );
    });
  } else {
    return (
      <div className="emptycart">
        ตะกร้าสินค้าว่างเปล่า​
        <Link to={"/"} className="continue-btn">
          <p>เลือกซื้อเพิ่ม</p>
        </Link>
      </div>
    );
  }
};

const ShoppingCart = (props) => {
  const myRef = useRef(null);
  const [isbottomShow, setIsbottomShow] = useState(
    props.Payment.shoppingCart.length > 4
  );
  const [checkIsArea, setCheckIsArea] = useState(false);

  useEffect(() => {
    window.scrollTo(0, 0);
    props.dispatch(ACTION_SHOW_LOADING(true));
    props.dispatch(fetchShoppingCart());
    document.addEventListener("scroll", trackScrolling);
  }, []);

  useEffect(() => {
    if (checkIsArea) {
      setCheckIsArea(false);
    }
    if (isArea()) {
      setIsbottomShow(true);
    } else {
      if (isbottomShow) {
        setIsbottomShow(false);
      }
    }
  }, [isbottomShow, checkIsArea]);

  useEffect(() => {
    return () => {
      document.removeEventListener("scroll", trackScrolling);
    };
  }, []);

  const isArea = () => {
    if (myRef.current) {
      return (
        props.Payment.shoppingCart.length > 4 &&
        window.pageYOffset + window.innerHeight <=
          myRef.current.offsetHeight + myRef.current.offsetTop
      );
    }
  };

  const trackScrolling = () => {
    if (isArea()) {
      setIsbottomShow(true);
    } else {
      setIsbottomShow(false);
    }
  };

  const onRemoveHandle = (cartItem) => {
    props.dispatch(fetchRemoveCart(cartItem));
    removeCartItemFromLocalStorage(cartItem.bookUID);
    if (
      props.Payment.shoppingCart.length < 5 &&
      myRef.current &&
      window.pageYOffset + window.innerHeight >
        myRef.current.offsetHeight + myRef.current.offsetTop
    ) {
      setIsbottomShow(false);
    }
  };

  const descriptions = () => {
    return (
      <div className="total-price">
        <p className="cart-bottom-left">
          ท่านสามารถอ่าน e-book ที่ท่านซื้อไว้ผ่าน แอปพลิเคชันและเว็บไซต์
          Hytexts eBook Store บนอุปกรณ์เหล่านี้เท่านั้น
        </p>
        <Row className="cart-bottom-label">
          <Col xs={24} sm={8} align="center">
            1. iOS/iPadOS (11 ขึ้นไป)
            {props.Page.screensize.width <= 576 && (
              <>
                <br />
                {imageAsLink[0]}
              </>
            )}
          </Col>
          <Col xs={24} sm={8} align="center">
            2. Android (5.0 ขึ้นไป)
            {props.Page.screensize.width <= 576 && (
              <>
                <br />
                {imageAsLink[1]}
              </>
            )}
          </Col>
          <Col xs={24} sm={8} align="center">
            3. Boox Ereader (Android 6.0 ขึ้นไป)
            {props.Page.screensize.width <= 576 && (
              <>
                <br />
                {imageAsLink[2]}
              </>
            )}
          </Col>
        </Row>
        {props.Page.screensize.width > 576 && (
          <Row style={{ marginBottom: "32px" }}>
            <Col span={8}>{imageAsLink[0]}</Col>
            <Col span={8}>{imageAsLink[1]}</Col>
            <Col span={8}>{imageAsLink[2]}</Col>
          </Row>
        )}
        <p className="cart-bottom-left">
          หากต้องการอ่าน e-book ที่วางจำหน่ายบน Books หรือ Google Play Book
          จะต้องซื้อผ่าน Application ของ Books และ Google Play Book เท่านั้น
        </p>
        <Row className="cart-bottom-label" justify="center">
          <Col xs={24} sm={8} align="center">
            1. Books
            {props.Page.screensize.width <= 576 && (
              <>
                <br />
                {imageAsLink[3]}
              </>
            )}
          </Col>
          <Col xs={24} sm={8} align="center">
            2. Google Play Book
            {props.Page.screensize.width <= 576 && (
              <>
                <br />
                {imageAsLink[4]}
              </>
            )}
          </Col>
        </Row>
        {props.Page.screensize.width > 576 && (
          <Row style={{ marginBottom: "32px" }} justify="center">
            <Col xs={24} sm={8}>
              {imageAsLink[3]}
            </Col>
            <Col xs={24} sm={8}>
              {imageAsLink[4]}
            </Col>
          </Row>
        )}
        <p className="cart-bottom-left">
          *ไม่สามารถอ่านบนเว็บไซต์หรือดาวน์โหลดไฟล์มาอ่านด้วยโปรแกรมอื่น ๆ ได้
        </p>
        <p className="cart-bottom-left-red">
          *ไม่มีการจัดส่งหนังสือเป็นรูปเล่มให้ทางไปรษณีย์
        </p>
        <p className="cart-bottom-left">
          *E-BOOK ที่ซื้อบนเว็บ Hytexts หรือบน App Hytexts{" "}
          <span className="cart-bottom-left-red">ไม่สามารถ Print ได้</span>
        </p>
        <p className="cart-bottom-left">
          *หากต้องการซื้อเป็น{" "}
          <span className="cart-bottom-left-red">“รูปเล่ม”</span> กรุณาติดต่อ
          <span className="cart-bottom-left-red">สำนักพิมพ์หรือนักเขียน</span>
          โดยตรง
        </p>
        <p className="cart-bottom-left">
          *E-BOOK ที่ซื้อบนเว็บ Hytexts หรือบน App Hytexts{" "}
          <span className="cart-bottom-left-red">
            ไม่สามารถอ่านบน Kindle ได้
          </span>
        </p>
      </div>
    );
  };
  const getAllFreeEbook = async () => {
    props.dispatch(ACTION_SHOW_LOADING(true));
    let allCart = props.Payment.shoppingCart.map((book) => book.bookUID);
    let res = await makeHCpurchase(allCart);
    if (res.status) {
      props.history.push(`/payment/success/${res.data.orderCode}`);
    }
  };
  const goPaymentPage = () => {
    props.history.push(`/payment`);
  };
  const totalPrice = () => {
    return (
      <div>
        {props.Payment.totalValue !== 0 && (
          <p className="price-label">ยอดที่ต้องชำระ</p>
        )}
        {props.Payment.totalValue === 0 ? (
          <Link to="#">
            <button
              className="free-book"
              onClick={() => {
                if (authenUtility.isLogin()) getAllFreeEbook();
                else {
                  if (props.Page.screensize.width > 900) {
                    props.dispatch(navModal(true));
                    props.dispatch(
                      navModalMode({
                        loginModal: 1,
                        registerModal: 0,
                        registerEmailModal: 0,
                      })
                    );
                  } else {
                    props.history.push(`/login`);
                  }
                }
              }}
            >
              อ่านอีบุ๊คฟรี
            </button>
          </Link>
        ) : (
          <Link to="#">
            <button
              className="price-total"
              onClick={() => {
                if (authenUtility.isLogin()) goPaymentPage();
                else {
                  if (props.Page.screensize.width > 900) {
                    props.dispatch(navModal(true));
                    props.dispatch(
                      navModalMode({
                        loginModal: 1,
                        registerModal: 0,
                        registerEmailModal: 0,
                      })
                    );
                  } else {
                    props.history.push(`/login`);
                  }
                }
              }}
            >
              ฿{props.Payment.totalValue}
            </button>
          </Link>
        )}
        <br />
        <Link to={"/"}>
          <button className="continue-btn" type="primary">
            เลือกซื้อเพิ่ม
          </button>
        </Link>
      </div>
    );
  };

  const header = () => {
    return (
      <Row
        className="titleCart"
        type="flex"
        justify="space-between"
        align="bottom"
      >
        <Col
          span={props.Payment.shoppingCart.length === 0 ? 24 : 14}
          align={props.Payment.shoppingCart.length === 0 ? "center" : "left"}
        >
          ตะกร้าสินค้า
        </Col>
        {props.Payment.shoppingCart.length > 0 && (
          <Col span={10} className="item-num" align="right">
            {props.Page.screensize.width > 576
              ? `มีทั้งหมด ${props.Payment.shoppingCart.length} รายการ`
              : `${props.Payment.shoppingCart.length} รายการ`}
          </Col>
        )}
      </Row>
    );
  };
  return (
    <div className="cart-container">
      {header()}
      <div className="cartItem-container">
        <ListCartItem
          screenWidth={props.Page.screensize.width}
          cartItems={props.Payment.shoppingCart}
          onRemoveHandle={onRemoveHandle}
          checkIsArea={setCheckIsArea}
        />
      </div>
      <Row type="flex" justify="center">
        {isbottomShow && (
          <div className="total-price-bottom">{totalPrice()}</div>
        )}
      </Row>
      {props.Payment.shoppingCart.length ? (
        <div className="total-price" ref={myRef}>
          {totalPrice()}
        </div>
      ) : (
        <div ref={myRef} style={{ margin: "100px 0px" }}></div>
      )}
      {descriptions()}
    </div>
  );
};

export const imageAsLink = [
  <>
    <a
      className="apps-icon"
      href={`https://apps.apple.com/app/hytexts-com-ebook-store/id908903530`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        style={{ height: "41px", width: "auto" }}
        src="/images/logos/appstore.png"
        alt=""
      />
    </a>
  </>,
  <>
    <a
      className="plays-icon"
      href={`https://play.google.com/store/apps/details?id=com.IPJ1988.hytexts.ebookstore`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        style={{ height: "41px", width: "auto" }}
        src="/images/logos/playstore.png"
        alt=""
      />
    </a>
  </>,
  <>
    <a
      href={`https://play.google.com/store/apps/details?id=com.hytexts.android.ereader`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        style={{ height: "41px", width: "auto" }}
        src="/images/logos/ereader.svg"
        alt=""
      />
    </a>
  </>,
  <>
    <a
      href={`https://www.apple.com/th/apple-books/`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        style={{ height: "45px", width: "45px" }}
        src="/images/book/buymore1.png"
        alt=""
      />{" "}
    </a>
  </>,
  <>
    <a
      href={`https://play.google.com/store/search?q=hytexts&c=books&hl=th`}
      target="_blank"
      rel="noopener noreferrer"
    >
      <img
        style={{ height: "45px", width: "45px" }}
        src="/images/book/buymore2.png"
        alt=""
      />
    </a>
  </>,
];

const mapStateToProps = (state) => {
  return {
    Payment: state.Payment,
    Page: state.Page,
    authentication: state.Authentication,
  };
};

ShoppingCart.propTypes = {
  Payment: PropTypes.object,
  dispatch: PropTypes.func,
};
export default withRouter(connect(mapStateToProps)(ShoppingCart));
