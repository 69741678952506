import React, { useState, useEffect, Fragment } from "react";
import PropTypes from "prop-types";
import { fetchListPayment } from "../../../Actions/Payment";
import PaymentMethod from "./PaymentMethod";
import { showLoading } from "../../../Actions/Page";
import { connect } from "react-redux";
import queryString from "query-string";

const PaymentMethodList = ({
  setSelectedForm,
  contentType,
  history,
  noHytextsCoin,
  authentication,
  setLoading_PaymentMethodList,
}) => {
  const [listPayment, setListPayment] = useState([]);
  const [activeIndex, setActiveIndex] = useState(-1);
  const [getListAgain, setGetListAgain] = useState(false);

  useEffect(() => {
    if (authentication.userToken) {
      getList();
    }
    const searchText = window.location.search;
    const query = queryString.parse(searchText);
    if (query.PayerID !== undefined && query.paymentId !== undefined) {
      setActiveIndex(1);
    }
  }, [authentication.userToken]);

  useEffect(() => {
    if (getListAgain) {
      getList();
      setGetListAgain(false);
    }
  }, [getListAgain]);

  const getList = async () => {
    const result = await fetchListPayment();
    if (result.status) {
      setListPayment([...result.data.listPayment]);
      setLoading_PaymentMethodList(false);
    } else {
      if (result.errorCode === "socket hang up") {
        setGetListAgain(true);
      }
    }
  };

  const paymentMethod = listPayment.map((item, index) => {
    if (noHytextsCoin && noHytextsCoin.boo && item.paymentType === "HC") {
      return "";
    } else if (item.paymentType === "LP") {
      return "";
    } else {
      return (
        <PaymentMethod
          key={index}
          index={index}
          paymentTitle={item.name}
          paymentType={item.paymentType}
          paymentLogo={
            item.name === "Hytexts Coin"
              ? `/images/payment/HytextsCoin.png`
              : `/images/payment/${item.name}.png`
          }
          activeIndex={activeIndex}
          setActiveIndex={setActiveIndex}
          setSelectedForm={setSelectedForm}
          contentType={contentType}
          coinID={noHytextsCoin ? noHytextsCoin.coinID : ""}
          coinAmount={noHytextsCoin ? noHytextsCoin.coinAmount : ""}
          history={history}
        />
      );
    }
  });
  return <Fragment>{paymentMethod}</Fragment>;
};

PaymentMethodList.propTypes = {
  setSelectedForm: PropTypes.func.isRequired,
  contentType: PropTypes.string.isRequired,
  history: PropTypes.object.isRequired,
};

const mapStateToProps = (state) => {
  return { authentication: state.Authentication };
};

export default connect(mapStateToProps, { showLoading })(PaymentMethodList);
