import React, { useState, useEffect } from "react";
import { message, Button, Divider } from "antd";
import { Link } from "react-router-dom";
import { addFavorite, deleteFavorite, getFavorite } from "../../Actions/Member";
import { connect } from "react-redux";
export const CategoryBtn = (props) => {
  message.config({
    top: "15%",
    duration: 2,
    maxCount: 1,
  });

  const [subcatFollowing, setSubcatFollowing] = useState(false);

  useEffect(() => {
    const fetchFav = async () => {
      if (props.memberUID !== "") {
        let fav = await getFavorite("SubCategory");
        if (fav.status) {
          let filter_fav =
            fav.data && fav.data.favorites
              ? fav.data.favorites.filter((el) => {
                  return parseInt(el.contentID, 10) === props.subcatId;
                })
              : [];
          let isfav = false;
          if (filter_fav.length) {
            isfav = true;
          }
          setSubcatFollowing(isfav);
        }
      }
    };
    if (props.subcatId) {
      fetchFav();
    }
    // eslint-disable-next-line
  }, [props.subcatId]);

  const handleSubcatFollowing = async () => {
    if (subcatFollowing) {
      let delete_fav = await deleteFavorite("SubCategory", {
        catID: props.catId,
        subCatID: props.subcatId,
      });
      if (delete_fav.status) {
        setSubcatFollowing(false);
        message.error(
          <span className="spanSubcatFollow">ยกเลิกการติดตามหมวด "{props.subcatName}"</span>
        );
      }
    } else {
      let add_fav = await addFavorite("SubCategory", {
        catID: props.catId,
        subCatID: props.subcatId,
      });
      if (add_fav.status) {
        setSubcatFollowing(true);
        message.success(
          <span className="spanSubcatFollow">ติดตามหมวด "{props.subcatName}" แล้ว</span>
        );
      }
    }
  };

  const catBtn = () => {
    return (
      props.catName && (
        <p className="catName">
          <Link to={`/all/categories/${props.catId}`} target="_blank">
            <img
              style={{ height: "30px", width: "30px", borderRadius: "50%", marginRight: "4px" }}
              src={"/images/book/category.svg"}
              alt=""
            />
            <div className="name">{props.catName}</div>
          </Link>
        </p>
      )
    );
  };

  const subcatBtn = (alone) => {
    return (
      <p className={alone ? "subcatNameAlone" : "subcatName"}>
        <Link
          to={`/all/categories/${props.catId}/subcategories/${props.subcatId}?title=${props.subcatName}`}
          target="_blank"
        >
          {alone ? (
            <img
              style={{ height: "30px", width: "30px", borderRadius: "50%", marginRight: "4px" }}
              src={"/images/book/category.svg"}
              alt=""
            />
          ) : (
            ""
          )}
          <div className="name">{props.subcatName}</div>
        </Link>
        <Divider
          type="vertical"
          style={{
            height: "36px",
            background: "#c8c8c8",
            transform: props.isMobile ? "translateY(1px)" : "translateY(0px)",
            marginRight: "0px",
          }}
        />
        {followSubcatBtn()}
      </p>
    );
  };

  const followSubcatBtn = () => {
    return subcatFollowing ? (
      <p className="followingbtn" onClick={handleSubcatFollowing}>
        <img alt="" src="/images/book/checktrue.png" style={{ width: "10px", height: "8px" }} />
      </p>
    ) : (
      <p
        className="followbtn"
        onClick={() => {
          if (props.memberUID !== "") handleSubcatFollowing();
          else props.showLoginModal();
        }}
      >
        +
      </p>
    );
  };

  return (
    <div
      style={
        props.isMobile
          ? { marginBottom: "0px", color: "black" }
          : { marginBottom: "16px", color: "black" }
      }
    >
      {props.catName !== props.subcatName ? (
        <>
          {catBtn()}
          {props.subcatName ? subcatBtn() : ""}
        </>
      ) : (
        <>{subcatBtn(true)}</>
      )}
    </div>
  );
};
const mapStateToProps = (state) => {
  return {
    Authentication: state.Authentication,
  };
};
export default connect(mapStateToProps)(CategoryBtn);
