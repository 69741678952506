import React from "react";
import { Affix } from "antd";
import "./privacy.scss";
import PDPAForm from "./PDPAForm";
import { Link, useParams } from "react-router-dom";
import Privacy from "./Privacy";
import TermOfUse from "./TermOfUse";
import AllowDataSubject from "./AllowDataSubject";
import IllegalContent from "./IllegalContent";

const Index = () => {
  const { privacyType } = useParams();

  const displayPage = () => {
    switch (privacyType) {
      case "policy":
        return <Privacy />;
      case "term":
        return <TermOfUse />;
      case "user-request-pdpa":
        return <PDPAForm />;
      case "allow-data-subject":
        return <AllowDataSubject />;
      default:
      case "content-illegal":
        return <IllegalContent />;
    }
  };

  return (
    <div className="privacy-page">
      <Affix
        className="navigate-box"
        offsetTop={{
          top: 10,
          bottom: 10,
        }}
      >
        <div>
          <h3>ข้อตกลงการใช้บริการ</h3>
          <ul>
            <li>
              <Link to="/privacy/policy">นโยบายความเป็นส่วนตัว</Link>
            </li>
            <li>
              <Link to="/privacy/term">เงื่อนไขการใช้บริการ</Link>
            </li>
            <li>
              <Link to="/privacy/user-request-pdpa">
                ขอใช้สิทธิสำหรับเจ้าของข้อมูลส่วนบุคคล
              </Link>
            </li>
            <li>
              <Link to="/privacy/allow-data-subject">มาตรการแจ้งเตือน</Link>
            </li>
            <li>
              <Link to="/privacy/content-illegal">
                ขั้นตอนการดำเนินการ ต่อเนื้อหาที่เข้าข่ายผิด กฎหมาย
              </Link>
            </li>
          </ul>
        </div>
      </Affix>

      {displayPage()}
    </div>
  );
};

export default Index;
