import React, { useRef, useState, useEffect } from "react";
// import Slider from "react-slick";
import "./Carousel.scss";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { fetchBanner } from "../../Actions/Ebook";
import Carousel, { Dots } from "@brainhubeu/react-carousel";
import "@brainhubeu/react-carousel/lib/style.css";
import { connect } from "react-redux";

const CarouselClass = (props) => {
  const [banners, setBanners] = useState([]);
  const [value, setValue] = useState(0);
  const [draggable, setDraggable] = useState(true);
  var moved;
  const slideDiv = useRef(null);

  const fetchBannerAction = async () => {
    let bannerResult = await fetchBanner();
    if (bannerResult.status && bannerResult.data) {
      setBanners(bannerResult.data.banners);
      window.scrollTo(0, 0);
    }
  };

  useEffect(() => {
    slideDiv.current.addEventListener("mousedown", downListener);
    slideDiv.current.addEventListener("mousemove", moveListener);
    slideDiv.current.addEventListener("mouseup", upListener);
    fetchBannerAction();
  }, []);

  const downListener = () => {
    moved = false;
  };
  const moveListener = () => {
    moved = true;
  };
  const upListener = () => {
    setDraggable(moved);
  };

  useEffect(() => {
    return () => {
      slideDiv.current.removeEventListener("mousedown", downListener);
      slideDiv.current.removeEventListener("mousemove", moveListener);
      slideDiv.current.removeEventListener("mouseup", upListener);
    };
  }, []);

  const onChange = (value) => {
    setValue(value);
  };
  return (
    <div className="container" ref={slideDiv}>
      <Carousel
        value={value}
        onChange={onChange}
        centered
        infinite
        itemWidth={props.screensize > 810 ? 810 : props.screensize}
        slidesPerPage={2}
        offset={0}
        autoPlay={10000}
        animationSpeed={1000}
        keepDirectionWhenDragging
        draggable={draggable}
      >
        {banners.map((el, index) => {
          return (
            <div className="slide" key={index}>
              <img
                style={{ paddingLeft: "2px", paddingRight: "2px" }}
                src={el.imageUrl}
                onClick={() => {
                  if (!draggable) {
                    window.open(el.link, "_blank");
                    setDraggable(true);
                  }
                }}
                alt=""
              />
            </div>
          );
        })}
      </Carousel>
      {props.Page.browserName.match(/(Safari)/i) ? (
        ""
      ) : (
        <Dots value={value} onChange={onChange} number={banners.length} />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return { authentication: state.Authentication, Page: state.Page };
};

export default connect(mapStateToProps)(CarouselClass);
