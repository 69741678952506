import React, { useState } from "react";
import { Modal } from "antd";
import { connect } from "react-redux";
import { withRouter } from "react-router-dom";
import LoginView from "../Views/Login/Login";
import RegisterView from "../Views/Register/Register";
import RegisterEmailView from "../Views/Register/Register-form";
import { navModal, navModalMode } from "../../Actions/Page";
import ForgetPasswordForm from "../Views/Login/ForgetPasswordBox";
import QrCodeView from "../Views/QrCodeView";
const ModalApp = props => {
  const [loginClass, setLoginClass] = useState("");
  const [qrCodeClass, setQrCodeClass] = useState("");
  const [registerClass, setRegisterClass] = useState("");
  const [registerEmailClass, setRegisterEmailClass] = useState("");
  const [forgetPasswordClass, setForgetPasswordClass] = useState("");
  const [isQrcode, setIsQrcode] = useState(false);
  const [isForgetPassword, setIsForgetPassword] = useState(false);
  const toRegister = () => {
    setTimeout(() => {
      setRegisterClass("fadein");
      setLoginClass("fadeout");
    }, 1);
    setTimeout(() => {
      props.dispatch(
        navModalMode({
          loginModal: 0,
          registerModal: 1,
          registerEmailModal: 0
        })
      );
    }, 1);
  };
  const toRegister_fromEmail = () => {
    setTimeout(() => {
      setRegisterClass("fadein");
      setRegisterEmailClass("fadeout");
    }, 1);
    setTimeout(() => {
      props.dispatch(navModalMode({ loginModal: 0, registerModal: 1, registerEmailModal: 0 }));
    }, 1);
  };
  const toLogin = () => {
    setTimeout(() => {
      setLoginClass("fadein");
      setRegisterClass("fadeout");
    }, 1);
    setTimeout(() => {
      props.dispatch(navModalMode({ loginModal: 1, registerModal: 0, registerEmailModal: 0 }));
    }, 1);
  };
  const toRegisterEmail = () => {
    setTimeout(() => {
      setRegisterEmailClass("fadein");
      setRegisterClass("fadeout");
    }, 1);
    setTimeout(() => {
      props.dispatch(navModalMode({ loginModal: 0, registerModal: 0, registerEmailModal: 1 }));
    }, 1);
  };
  const toLogin_email = () => {
    setTimeout(() => {
      setLoginClass("fadein");
      setRegisterEmailClass("fadeout");
    }, 1);
    setTimeout(() => {
      props.dispatch(navModalMode({ loginModal: 1, registerModal: 0, registerEmailModal: 0 }));
    }, 1);
  };
  const endingForgetPassword = boo => {
    if (boo) {
      props.dispatch(navModal(false));
      setForgetPasswordClass("fadeout");
      setLoginClass("");
      props.dispatch(navModalMode({ loginModal: 0, registerModal: 0, registerEmailModal: 0 }));
    }
  };
  const toForgetPassword = boo => {
    if (boo) {
      setTimeout(() => {
        setForgetPasswordClass("fadein");
        setLoginClass("fadeout");
      }, 1);
      setTimeout(() => {
        props.dispatch(
          navModalMode({
            loginModal: 0,
            registerModal: 0,
            registerEmailModal: 0
          })
        );
        setIsForgetPassword(true);
      }, 1);
    } else {
      setTimeout(() => {
        setForgetPasswordClass("fadeout");
        setLoginClass("fadein");
      }, 1);
      setTimeout(() => {
        props.dispatch(
          navModalMode({
            loginModal: 1,
            registerModal: 0,
            registerEmailModal: 0
          })
        );
        setIsForgetPassword(false);
      }, 1);
    }
  };
  const toQrcode = boo => {
    if (!boo) {
      setTimeout(() => {
        setQrCodeClass("fadeout");
        setLoginClass("fadein");
      }, 1);
      setTimeout(() => {
        props.dispatch(
          navModalMode({
            loginModal: 1,
            registerModal: 0,
            registerEmailModal: 0
          })
        );
        setIsQrcode(false);
      }, 1);
    } else {
      setTimeout(() => {
        setQrCodeClass("fadein");
        setLoginClass("fadeout");
      }, 1);
      setTimeout(() => {
        props.dispatch(
          navModalMode({
            loginModal: 0,
            registerModal: 0,
            registerEmailModal: 0
          })
        );
        setIsQrcode(true);
      }, 1);
    }
  };

  return props.navModal ? (
    <Modal
      // title="เข้าสู่ระบบ"
      visible={
        // this.memberUID !== "" ||
        props.navModal
      }
      onOk={null}
      onCancel={() => {
        toQrcode(false);
        props.dispatch(navModal(false));
        setLoginClass("");
        setRegisterClass("");
        setRegisterEmailClass("");
        props.dispatch(navModalMode({ loginModal: 0, registerModal: 0, registerEmailModal: 0 }));
      }}
      footer={null}
      width={370}
    >
      {props.registerModal ? (
        <div className={registerClass}>
          <RegisterView
            {...props}
            toLogin={toLogin}
            toRegisterEmail={toRegisterEmail}
            isModal
            key={2}
          />
        </div>
      ) : props.registerEmailModal ? (
        <div className={registerEmailClass}>
          <RegisterEmailView
            {...props}
            toLogin_email={toLogin_email}
            toRegister_fromEmail={toRegister_fromEmail}
            isModal
            key={3}
          />
        </div>
      ) : props.loginModal ? (
        <div className={loginClass}>
          <LoginView
            {...props}
            toRegister={toRegister}
            isModal
            key={1}
            toQrcode={toQrcode}
            toForgetPassword={toForgetPassword}
          />
        </div>
      ) : isQrcode ? (
        <div className={qrCodeClass}>
          <QrCodeView isModal key={4} toQrcode={toQrcode} />
        </div>
      ) : isForgetPassword ? (
        <div className={forgetPasswordClass}>
          <ForgetPasswordForm
            isModal
            key={5}
            toForgetPassword={toForgetPassword}
            endingForgetPassword={endingForgetPassword}
          />
        </div>
      ) : (
        ""
      )}
    </Modal>
  ) : (
    ""
  );
};

const mapStateToProps = state => {
  return {
    navModal: state.Page.navModal,
    loginModal: state.Page.navModalMode.loginModal,
    registerModal: state.Page.navModalMode.registerModal,
    registerEmailModal: state.Page.navModalMode.registerEmailModal
  };
};
export default withRouter(connect(mapStateToProps)(ModalApp));
