import React from "react";

export const readerLabel = [
  "registration",
  "purchase_e_Book",
  "purchase_hytexts_credit",
  "money_transfer",
  "download_e_book",
  "manages_devices",
  "reading_settings",
  "supported_devices",
];
export const readerSubLabel = ["1", "2", "3", "4", "5"];

export const belowForReader = [
  {
    head: "การสมัครสมาชิก",
    subHead: [
      "การสมัครสมาชิกสำหรับอ่านบน IOS",
      "การสมัครสมาชิกสำหรับอ่านบน Android",
      "การสมัครสมาชิกสำหรับอ่านบน Website",
    ],
  },
  {
    head: "การสั่งซื้อ E-Book",
    subHead: [
      "การสั่งซื้อ E-Book (IOS)",
      "การสั่งซื้อ E-Book (Android)",
      "การสั่งซื้อ E-Book (Website)",
    ],
  },
  {
    head: "การสั่งซื้อ Hytexts Credit",
    subHead: [
      "การสั่งซื้อ Hytexts Credit (IOS)",
      "การสั่งซื้อ Hytexts Credit (Android)",
      "การสั่งซื้อ Hytexts Credit (Website)",
    ],
  },
  { head: "หน้าแจ้งโอนเงิน", subHead: ["หน้าแจ้งโอนเงิน (Facebook)", "หน้าแจ้งโอนเงิน (Line)"] },
  {
    head: "การดาวน์โหลด E-Book",
    subHead: [
      "การดาวน์โหลด E-Book (IOS)",
      "การดาวน์โหลด E-Book (Android)",
      "การดาวน์โหลด E-Book (E-Reader)",
    ],
  },
  {
    head: "การจัดการอุปกรณ์",
    subHead: ["การจัดการอุปกรณ์ (IOS)", "การจัดการอุปกรณ์ (Android)"],
  },
  {
    head: "การตั้งค่าการอ่าน",
    subHead: [
      "การตั้งค่าการอ่าน EPUB บน IOS",
      "การตั้งค่าการอ่าน PDF บน IOS",
      "การตั้งค่าการอ่าน EPUB บน Android",
      "การตั้งค่าการอ่าน EPUB บน E-Reader",
      "วิธีอ่าน E-Book บน PC",
    ],
  },
  { head: "อุปกรณ์ที่รองรับ" },
];

const listOfDevice = [
  "1. iOS",
  "2. Android",
  "3. Windows/Mac OS (สำหรับอ่านบนโปรแกรม Adobe Digital Edition)",
  "4. เครื่อง Boox E-Reader",
];

export const SideForReader = (props) => {
  return props.index === 7 ? (
    <div className="side">
      <p className={props.subIndex ? "side-title-c" : "side-title"}>อุปกรณ์ที่รองรับ</p>
      <p className="side-content-lessBottom">
        E-Book จากร้าน Hytexts สามารถอ่านได้บนอุปกรณ์ในระบบต่าง ๆ เช่น
      </p>
      {listOfDevice.map((e, index) => (
        <p className="side-content-lessBottom" key={index}>
          {e}
        </p>
      ))}
    </div>
  ) : (
    <div className="side">
      {props.index === 6 && props.subIndex + 1 === 5 ? (
        <div
          style={{
            transform: "translateY(100px)",
            position: "absolute",
            width: "440px",
            height: "320px",
            cursor: "pointer",
          }}
          onClick={() =>
            window.open(
              "https://www.adobe.com/sea/solutions/ebook/digital-editions/download.html",
              "_blank"
            )
          }
        ></div>
      ) : (
        ""
      )}
      <img
        src={`/images/faq/forReader/${props.index}/${props.subIndex + 1}.png`}
        onLoad={props.handleImageChange}
        onError={props.handleImageChange}
      />
    </div>
  );
};
