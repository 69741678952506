import React from "react";
import ForgetPasswordForm from "../Views/Login/ForgetPasswordBox";
import { withRouter } from "react-router-dom";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);
const ForgetPasswordView = props => {
  const endingForgetPassword = boo => {
    if (boo) {
      props.history.push("/");
    }
  };
  return <ForgetPasswordForm endingForgetPassword={endingForgetPassword} />;
};

export default withRouter(ForgetPasswordView);
