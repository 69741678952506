import React, { useEffect, useState } from "react";
import { Upload, Button, Icon, message } from "antd";

const PDPAUpload = (props) => {
  const [fileList, setFileList] = useState();
  const acceptExt = [".jpg", ".jpeg", ".png", ".pdf"];

  useEffect(() => {
    props.formik.setFieldValue("files", fileList);
    props.onChangeFileList(fileList);
  }, [fileList]);

  const dummyRequest = (componentsData) => {
    if (componentsData?.onSuccess)
      componentsData.onSuccess(componentsData.file);
  };

  const onRemove = (index) => {
    fileList.splice(index, 1);
    setFileList([...fileList]);
  };

  const uploadProps = {
    name: "file",
    accept: ".jpg, .jpeg, .png, .pdf",
    customRequest: (e) => {
      dummyRequest(e);
    },
    fileList: fileList,

    onChange(info) {
      let newFileList = info.fileList.map((el) => {
        let extName = "." + el.name.split(".").pop().toLowerCase();
        if (acceptExt.includes(extName)) {
          return { ...el, status: "done" };
        }
      });
      if(newFileList.includes(undefined)){
        message.warning('รองรับเฉพาะไฟล์ .jpg, .jpeg, .png, .pdf')
      }
      newFileList = newFileList.filter(el => el !== undefined);
     

      setFileList(newFileList);
    },
  };

  return (
    <div className="pdpa-file-upload__container">
      <div className="pdpa-file-upload">
        <div>
          <h4>อัปโหลดไฟล์ประกอบ (จำกัด 5 ไฟล์)</h4>
          <div>จำนวนไฟล์ที่อัปโหลด : {fileList?.length ?? 0}</div>
        </div>

        <div className="input-group" style={{ width: "fit-content" }}>
          <Upload {...uploadProps} disabled={(fileList?.length ?? 0) >= 5}>
            <Button type="primary" className="buybtn" shape="round" disabled={(fileList?.length ?? 0) >= 5}>
              <Icon type="upload" /> แนบไฟล์
            </Button>
          </Upload>
          {props.formik?.errors?.files && props.formik?.touched?.files ? (
            <div className="error-detail">{props.formik.errors.files}</div>
          ) : null}
        </div>
      </div>

      <div className="file-list">
        {fileList?.map((el, index) => (
          <div className="file-row">
            <div className="file-row-name">
              <Icon type="link" /> {el.name}
            </div>

            <Icon type="delete" onClick={() => onRemove(index)} />
          </div>
        ))}
      </div>
    </div>
  );
};

export default PDPAUpload;
