import React, { useState, useEffect } from "react";
import { Row, Col, Button, Tooltip } from "antd";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { navModal, navModalMode } from "../../Actions/Page";
import { addFavorite, deleteFavorite } from "../../Actions/Member";
import authenUtility from "../../Utilitys/Authentication";
const AuthorPublisherBox = props => {
  let span = Math.floor(24 / props.numCollection);
  const [following, setFollowing] = useState(props.isfav);
  const handleFollowing = async type => {
    if (!following) {
      let add_fav =
        type === "author"
          ? await addFavorite("Author", props.name)
          : await addFavorite("Publisher", props.creatorUID);
      if (add_fav.status) {
        setFollowing(true);
      }
    } else {
      let delete_fav =
        type === "author"
          ? await deleteFavorite("Author", props.name)
          : await deleteFavorite("Publisher", props.creatorUID);
      if (delete_fav.status) {
        setFollowing(false);
      }
    }
  };
  useEffect(() => {
    setFollowing(props.isfav);
  }, [props.isfav]);
  const showLoginModal = () => {
    props.dispatch(navModal(true));
    props.dispatch(navModalMode({ loginModal: 1, registerModal: 0, registerEmailModal: 0 }));
  };
  const followBtn = () => {
    if (following)
      return (
        <Button
          className="followingbtn"
          onClick={() => {
            if (authenUtility.isLogin()) handleFollowing(props.type);
            else showLoginModal();
          }}
        >
          ติดตามแล้ว
        </Button>
      );
    else
      return (
        <Button
          className="followbtn"
          onClick={() => {
            if (authenUtility.isLogin()) handleFollowing(props.type);
            else showLoginModal();
          }}
        >
          ติดตาม
        </Button>
      );
  };
  return (
    <Col span={span} align="center">
      <Row className="authorpublisherBox">
        <Col span={9}>
          <img
            src={props.profileImage ? props.profileImage : "/images/book/worm.png"}
            alt=""
            style={{
              width: "102px",
              height: "102px",
              margin: "16px 16px",
              objectFit: "cover",
              borderRadius: "50%",
              border: "1px solid #bdbdbd"
            }}
          />
        </Col>
        <Col span={15}>
          <Row type="flex">
            <Col span={21} align="left">
              {/* <Tooltip
                placement="top"
                title={<p style={{ fontFamily: "Prompt", margin: "0 0" }}>{props.publisherName}</p>}
              > */}
              <p className="displayname">{props.publisherName}</p>
              {/* </Tooltip> */}
              <div style={{ marginLeft: "8px" }}>{followBtn()}</div>
            </Col>
          </Row>
          <Row style={{ marginTop: "12px" }}>
            <Col span={7} align="center">
              <p className="labeltop">{props.ebooks}</p>
              <p className="labelunder">อีบุ๊ค</p>
            </Col>
            {props.type === "author" && <Col span={3}></Col>}
            <Col span={7} align="center">
              <p className="labeltop">{props.followers}</p>
              <p className="labelunder">ผู้ติดตาม</p>
            </Col>
            <Col span={7} align="center">
              {props.type === "publisher" && (
                <div>
                  <p className="labeltop">{props.writers}</p>
                  <p className="labelunder">นักเขียน</p>
                </div>
              )}
            </Col>
          </Row>
        </Col>
      </Row>
    </Col>
  );
};

export default withRouter(connect(null)(AuthorPublisherBox));
