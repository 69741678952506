import React, { useState, useEffect, useRef, Fragment } from "react";
import PropTypes from "prop-types";
import { Row, Col, Input, Form, message } from "antd";
import { connect } from "react-redux";
import { fetch } from "../../../../Utilitys/ApiRequest";
import authen from "../../../../Utilitys/Authentication";
import { showLoading } from "../../../../Actions/Page";

const formItemLayout = {
  labelCol: {
    xl: { span: 24 },
  },
  wrapperCol: {
    xl: { span: 24 },
  },
};

const CreditCard = ({
  setSelectedForm,
  shoppingCart,
  contentType,
  showLoading,
  coinID,
  coinAmount,
}) => {
  message.config({
    top: "15%",
    duration: 2,
    maxCount: 1,
  });
  const expireRef = useRef();
  const cardNumberRef = useRef();

  const [creditCard, setCreditCard] = useState({
    nameOnCard: "",
    cardNumber: "",
    expire: "",
    cvv: "",
  });

  const [encrypted, setEncrypted] = useState({
    encryptedCardInfo: "",
    maskedCardInfo: "",
    expMonthCardInfo: "",
    expYearCardInfo: "",
    cardholderName: "",
  });

  const [errors, setErrors] = useState({
    nameOnCard: "",
    cardNumber: "",
    expire: "",
    cvv: "",
  });

  const [lengthValue, setLengthValue] = useState({
    cardNumber: [0],
    expire: [0],
  });

  const [cursorPosition, setCursorPosition] = useState({
    cardNumber: [0],
    expire: [0],
  });

  const [payload, setPayload] = useState({
    creditcard: null,
    returnUrl: `${authen.URL}/payment/success`,
    cancelUrl: window.location.href,
    contentType,
    paymentType: "CC",
  });

  const [paymentForm, setPaymentForm] = useState(null);

  useEffect(() => {
    setContentData();
  }, []);

  useEffect(() => {
    if (
      creditCard.nameOnCard.trim().length !== 0 &&
      creditCard.cardNumber.trim().length !== 0 &&
      creditCard.cardNumber.trim().length === 19 &&
      creditCard.expire.trim().length !== 0 &&
      creditCard.expire.trim().length === 5 &&
      creditCard.cvv.trim().length !== 0 &&
      creditCard.cvv.trim().length === 3
    ) {
      setSelectedForm("credit_card_form");
    } else {
      setSelectedForm("");
    }
  }, [creditCard, setCreditCard]);

  useEffect(() => {
    setPayload({ ...payload, creditcard: encrypted });
  }, [encrypted, setEncrypted]);

  useEffect(() => {
    if (paymentForm !== null) {
      document.write(`${paymentForm}`);
    }
  }, [paymentForm, setPaymentForm]);

  useEffect(() => {
    fetch2c2p();
  }, [payload]);

  const setContentData = async () => {
    const data = await shoppingCart.map((book) => book.bookUID);
    if (contentType === "coin") {
      setPayload({ ...payload, contentData: [coinID] });
    } else if (contentType === "ebook") {
      setPayload({ ...payload, contentData: data });
    }
  };

  const fetch2c2p = async () => {
    if (
      encrypted.encryptedCardInfo !== null &&
      encrypted.encryptedCardInfo !== undefined &&
      encrypted.encryptedCardInfo !== ""
    ) {
      const { data } = await fetch("post", "payment/cc", payload);
      // console.log({ fetch2c2p: data });
      setPaymentForm(data);
    }
  };

  const inputHandlerChange = async (e) => {
    switch (e.target.name) {
      case "nameOnCard":
        if (!e.target.value.match(/([A-Za-z])/g)) {
          setCreditCard({ ...creditCard, nameOnCard: "" });
          setErrors({ ...errors, cardName: "" });
        } else {
          setCreditCard({ ...creditCard, nameOnCard: e.target.value });
          setEncrypted({ ...encrypted, cardholderName: e.target.value });
          setErrors({ ...errors, nameOnCard: "กรุณาให้ข้อมูล" });
        }
        break;
      case "cardNumber":
        const newCardId = e.target.value
          .match(/\d*/g)
          .join("")
          .match(/(\d{0,4})(\d{0,4})(\d{0,4})(\d{0,4})/)
          .slice(1)
          .join("-")
          .replace(/-*$/g, "");
        if (newCardId.length <= 19) {
          let newPos;
          let prevLength_cardnumber = lengthValue.cardNumber[1];
          let prevPos_cardnumber = cursorPosition.cardNumber[1];
          let curPos = e.target.selectionStart;
          e.persist();
          if (creditCard.cardNumber.length < 19) {
            setCreditCard({ ...creditCard, cardNumber: newCardId });
            setLengthValue({
              ...lengthValue,
              cardNumber: [prevLength_cardnumber, newCardId.length],
            });
            setCursorPosition({
              ...cursorPosition,
              cardNumber: [prevPos_cardnumber, curPos],
            });
            prevLength_cardnumber = lengthValue.cardNumber[lengthValue.cardNumber.length - 1];
            newPos = await e.target.selectionStart;
          } else if (creditCard.cardNumber.length === 19 && newCardId.length < 19) {
            setCreditCard({ ...creditCard, cardNumber: newCardId });
            setLengthValue({
              ...lengthValue,
              cardNumber: [prevLength_cardnumber, newCardId.length],
            });
            setCursorPosition({
              ...cursorPosition,
              cardNumber: [prevPos_cardnumber, curPos],
            });
            prevLength_cardnumber = lengthValue.cardNumber[lengthValue.cardNumber.length - 1];
            newPos = await e.target.selectionStart;
          } else {
            setCursorPosition({
              ...cursorPosition,
              cardNumber: [prevPos_cardnumber, curPos],
            });
            prevPos_cardnumber = cursorPosition.cardNumber[1];
          }
          if (newCardId.length < prevLength_cardnumber) {
            if (newPos < newCardId.length) {
              cardNumberRef.current.input.setSelectionRange(newPos, newPos);
            } else {
              cardNumberRef.current.input.setSelectionRange(newCardId.length, newCardId.length);
            }
          } else if (newCardId.length > prevLength_cardnumber) {
            if (newPos < newCardId.length - 1) {
              if (newCardId.charAt(newPos - 1) === "-") {
                cardNumberRef.current.input.setSelectionRange(newPos + 1, newPos + 1);
              } else {
                cardNumberRef.current.input.setSelectionRange(newPos, newPos);
              }
            } else if (newPos === newCardId.length) {
              cardNumberRef.current.input.setSelectionRange(newPos, newPos);
            }
          } else if (prevLength_cardnumber === newCardId.length) {
            if (newCardId.length === 19)
              cardNumberRef.current.input.setSelectionRange(curPos, curPos);
            else {
              cardNumberRef.current.input.setSelectionRange(newPos, newPos);
            }
          }
        }
        break;
      case "expire":
        let newExpire = e.target.value
          .match(/\d*/g)
          .join("")
          .match(/(\d{0,2})(\d{0,2})/)
          .slice(1)
          .join("/")
          .replace(/\/*$/g, "");
        if (newExpire.length <= 5) {
          let newPos;
          let prevLength_expire = lengthValue.expire[1];
          let prevPos_expire = cursorPosition.expire[1];
          let curPos = e.target.selectionStart;
          e.persist();
          if (creditCard.expire.length < 5) {
            setCreditCard({ ...creditCard, expire: newExpire });
            setLengthValue({
              ...lengthValue,
              expire: [prevLength_expire, newExpire.length],
            });
            setCursorPosition({
              ...cursorPosition,
              expire: [prevPos_expire, curPos],
            });
            prevLength_expire = lengthValue.expire[lengthValue.expire.length - 1];
            newPos = await e.target.selectionStart;
          } else if (creditCard.expire.length === 5 && newExpire.length < 5) {
            setCreditCard({ ...creditCard, expire: newExpire });
            setLengthValue({
              ...lengthValue,
              expire: [prevLength_expire, newExpire.length],
            });
            setCursorPosition({
              ...cursorPosition,
              expire: [prevPos_expire, curPos],
            });
            prevLength_expire = lengthValue.expire[lengthValue.expire.length - 1];
            newPos = await e.target.selectionStart;
          } else {
            setCursorPosition({
              ...cursorPosition,
              expire: [prevPos_expire, curPos],
            });
            prevPos_expire = cursorPosition.expire[1];
          }

          if (newExpire.length < prevLength_expire) {
            if (newPos < newExpire.length) {
              expireRef.current.input.setSelectionRange(newPos, newPos);
            } else {
              expireRef.current.input.setSelectionRange(newExpire.length, newExpire.length);
            }
          } else if (newExpire.length > prevLength_expire) {
            if (newPos < newExpire.length - 1) {
              if (newExpire.charAt(newPos - 1) === "/") {
                expireRef.current.input.setSelectionRange(newPos + 1, newPos + 1);
              } else {
                expireRef.current.input.setSelectionRange(newPos, newPos);
              }
            } else if (newPos === newExpire.length) {
              expireRef.current.input.setSelectionRange(newPos, newPos);
            }
          } else if (prevLength_expire === newExpire.length) {
            if (newExpire.length === 5) expireRef.current.input.setSelectionRange(curPos, curPos);
            else {
              expireRef.current.input.setSelectionRange(newPos, newPos);
            }
          }
        }
        break;
      case "cvv":
        const reg = /[0-9]{0,3}/;
        if (!isNaN(e.target.value) && reg.test(e.target.value) && e.target.value.length <= 3) {
          setCreditCard({ ...creditCard, cvv: e.target.value });
        }
        break;
      default:
        if (e.target.type === "password") {
          const reg = /[0-9]{0,3}/;
          if (!isNaN(e.target.value) && reg.test(e.target.value) && e.target.value.length <= 3) {
            setCreditCard({ ...creditCard, cvv: e.target.value });
          }
        }
        break;
    }
  };

  const inputHandlerError = (e) => {
    switch (e.target.name) {
      case "cardNumber":
        if (e.target.value.replace(/[^0-9]/g, "").length === 16) {
          setErrors({ ...errors, cardNumber: "" });
        } else {
          setErrors({ ...errors, cardNumber: "กรุณาให้ข้อมูลเป็นตัวเลข 16 หลัก" });
        }
        break;
      case "expire":
        if (e.target.value.replace(/[^0-9]/g, "").length === 4) {
          setErrors({ ...errors, expire: "" });
        } else {
          setErrors({ ...errors, expire: "กรุณาให้ข้อมูลอย่างครบถ้วน" });
        }
        break;
      case "cvv":
        if (e.target.value.replace(/[^0-9]/g, "").length === 3) {
          setErrors({ ...errors, cvv: "" });
        } else {
          setErrors({ ...errors, cvv: "กรุณาให้ข้อมูลอย่างครบถ้วน" });
        }
        break;
      default:
        break;
    }
  };

  const handlerSubmit = async (e) => {
    e.preventDefault();
    let data;
    await window.Checkout((el) => {
      data = el;
      console.log(el);
      if (
        el.encryptedCardInfo === null ||
        el.encryptedCardInfo === undefined ||
        el.encryptedCardInfo === ""
      ) {
        message.error(
          <span style={{ fontFamily: "Prompt", fontSize: "16px" }}>
            ข้อมูลการชำระเงินไม่ถูกต้อง
          </span>
        );
      }
    });
    setEncrypted({ ...data, cardholderName: creditCard.nameOnCard.trim() });
  };

  return (
    <Fragment>
      <Form {...formItemLayout} onSubmit={(e) => handlerSubmit(e)} id="credit_card_form">
        <Row gutter={[16, 8]} style={{ marginBottom: "14px" }} className="creditcard-active-below">
          <Col span={24}>
            ชื่อที่ปรากฎบนบัตร
            <Input
              name="nameOnCard"
              value={creditCard.nameOnCard}
              type="text"
              onChange={(e) => inputHandlerChange(e)}
            />
          </Col>
          <Col xs={24} sm={12}>
            หมายเลขบัตร
            <Input
              name="cardNumber"
              type="text"
              ref={cardNumberRef}
              placeholder="xxxx-xxxx-xxxx-xxxx"
              maxLength={19}
              value={creditCard.cardNumber}
              onChange={(e) => {
                inputHandlerChange(e);
                inputHandlerError(e);
              }}
            />
          </Col>
          <Col xs={12} sm={6}>
            วันหมดอายุ
            <Input
              name="expire"
              type="text"
              ref={expireRef}
              placeholder="01/24"
              maxLength={5}
              value={creditCard.expire}
              onChange={(e) => {
                inputHandlerChange(e);
                inputHandlerError(e);
              }}
            />
          </Col>
          <Col xs={12} sm={6}>
            CVV2/CVC2
            <Input
              name="cvv"
              type="password"
              data-encrypt="cvv"
              placeholder="xxx"
              maxLength={3}
              value={creditCard.cvv}
              onChange={(e) => {
                inputHandlerChange(e);
                inputHandlerError(e);
              }}
            />
          </Col>
        </Row>
        <input
          type="hidden"
          data-encrypt="cardnumber"
          value={creditCard.cardNumber.replace(/[-]/g, "")}
        />
        <input type="hidden" data-encrypt="month" value={creditCard.expire.split(`/`)[0] || ""} />
        <input
          type="hidden"
          data-encrypt="year"
          value={"20" + creditCard.expire.split(`/`)[1] || ""}
        />
        <input type="hidden" value="" name="encryptedCardInfo" id="encryptedCardInfo" />
        <input type="hidden" value="" name="maskedCardInfo" id="maskedCardInfo" />
        <input type="hidden" value="" name="expMonthCardInfo" id="expMonthCardInfo" />
        <input type="hidden" value="" name="expYearCardInfo" id="expYearCardInfo" />
      </Form>
    </Fragment>
  );
};

CreditCard.propTypes = {
  setSelectedForm: PropTypes.func.isRequired,
  shoppingCart: PropTypes.array.isRequired,
  contentType: PropTypes.string.isRequired,
  showLoading: PropTypes.func.isRequired,
};

const mapStateToProps = (state) => ({
  shoppingCart: state.Payment.shoppingCart,
});

export default connect(mapStateToProps, { showLoading })(CreditCard);
