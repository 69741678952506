import React, { useState, useEffect, useRef } from "react";
import {
  Layout,
  // Menu,
  // Tabs,
  Row,
  Col,
  Badge,
  Tooltip,
  Dropdown,
  Menu,
  AutoComplete,
  Icon,
  Input,
  Divider,
  Upload,
} from "antd";
// import css from "./Nav.less";
import "./Nav.scss";
import { connect } from "react-redux";
import PropTypes from "prop-types";
import Account from "../../Utilitys/Authentication";
import { withRouter } from "react-router-dom";
import FlashSale from "../Collection/FlashSale";
import { Link } from "react-router-dom";
import { navModal, navModalMode } from "../../Actions/Page";
import ModalApp from "../Collection/ModalApp";
import { fetchLogOut } from "../../Actions/Authentication";
import {
  requestMemberInfo,
  receiveMemberInfo,
  updateProfileImage,
} from "../../Actions/Member";
import { fetchSearchEbook } from "../../Actions/Ebook";
import { ip, oldWebURL } from "../../Constants/appInfo";
import ScrollContainer from "react-indiana-drag-scroll";
import { isIOS13, isAndroid, isIOS } from "react-device-detect";

import { fetch } from "../../Utilitys/ApiRequest";
const { Option, OptGroup } = AutoComplete;
const { SubMenu } = Menu;

// const { TabPane } = Tabs;
// const { SubMenu } = Menu;
const Nav = (props) => {
  const [memberUID, setMemberUID] = useState(
    Account.getMemberUID(
      props.authentication.userToken || "",
      props.history.location.pathname
    )
  );

  const [isScrolling, setIsScrolling] = useState(false);
  const [dataSource, setDataSource] = useState([]);
  const [searchResults, setSearchResults] = useState([]);
  const [searchBoxValue, setSearchBoxValue] = useState("");
  const [profileImage, setProfileImage] = useState("");

  const dispatch = props.dispatch;
  const searchInput = useRef(null);
  const screenWidth = props.Page.screensize.width;
  const screenHeight = props.Page.screensize.height;
  const iOS_Mac = props.Page.OSName.match(/(iOS|Mac OS)/i);
  const iPhoneX_11 = screenWidth === 375 && screenHeight === 812;
  const iPadPro11_0 = screenWidth === 834 && screenHeight === 1194;
  const iPhone11_promax = screenWidth === 414 && screenHeight === 896;

  const convertTobase64 = (imgfile) => {
    return new Promise((resolve) => {
      var file = imgfile;
      var reader = new FileReader();
      reader.onload = function (event) {
        resolve(event.target.result);
      };
      reader.readAsDataURL(file);
    });
  };

  const categoryTab = (mobile) => {
    return (
      <ScrollContainer
        className={mobile ? "navbtn-set-isMobile" : "navbtn-set"}
      >
        {props.tabData.map((e, index) => {
          return (
            <div
              style={
                screenWidth <= 434
                  ? index === props.tabData.length - 1
                    ? { paddingLeft: "25px", paddingRight: "25px" }
                    : { paddingLeft: "25px" }
                  : index === 0
                  ? { paddingLeft: "0px" }
                  : { paddingLeft: "16px" }
              }
              key={e.path}
              value={e.path}
              onClick={() => handleTabClick(e.path)}
              className={
                props.location.pathname === e.path ? "navbtn-active" : "navbtn"
              }
            >
              {e.title}
            </div>
          );
        })}
      </ScrollContainer>
    );
  };
  useEffect(() => {
    setMemberUID(
      Account.getMemberUID(
        props.authentication.userToken || "",
        props.history.location.pathname
      )
    );
    setDataSource([]);
    setSearchBoxValue("");
  }, [props.history.location.pathname]);

  const customRequest = (req) => {
    convertTobase64(req.file).then(async (filedata) => {
      let newpic = await updateProfileImage(filedata, req.file.type);
      if (newpic.status) {
        setProfileImage(newpic.data.image);
      }
    });
  };

  const handleTabClick = (e) => {
    if (typeof e === "string") {
      props.history.push(`${e}`);
    } else {
      props.history.push(`${e.target.value}`);
    }
  };
  const onSelect = async (e) => {
    searchInput.current.blur();
    const result = await searchResults.find(({ contentID }) => contentID === e);
    if (result.type) {
      props.history.push(
        `/${result.type === "KeyWord" ? "tag" : result.type.toLowerCase()}/${
          Number.isInteger(result.contentID)
            ? result.title
            : result.contentID &&
              result.contentID[0] &&
              String(result.contentID)[0].localeCompare("_") === 0
            ? result.contentID.substring(1)
            : result.contentID
        }`
      );
      setDataSource([]);
      setSearchBoxValue("");
    }
  };
  const onSearch = async (text) => {
    setSearchBoxValue(text);
    var objOneKeyWord = { type: "keyword", children: [] };
    var objEbook = { type: "Ebook", children: [] };
    var objAuthor = { type: "Author", children: [] };
    var objPublisher = { type: "Publisher", children: [] };
    var objKeyWord = { type: "KeyWord", children: [] };
    if (text.length >= 3) {
      let result = await fetchSearchEbook(text);
      if (result.status && result.data) {
        objOneKeyWord.children.push({
          title: `ค้นหาจากคำว่า "${text}"`,
          contentID: text,
          type: "keyword",
        });
        // eslint-disable-next-line
        result.data.searchResults.map((el) => {
          if (el.type === "Ebook") {
            objEbook.children.push(el);
          } else if (el.type === "Author") {
            objAuthor.children.push(el);
          } else if (el.type === "Publisher") {
            objPublisher.children.push(el);
          } else if (el.type === "KeyWord") {
            objKeyWord.children.push(el);
          }
        });
        let dataSource = [objOneKeyWord];
        if (objAuthor.children.length) {
          dataSource.push(objAuthor);
          if (objPublisher.children.length) {
            dataSource.push(objPublisher);
            if (objEbook.children.length) {
              dataSource.push(objEbook);
              if (objKeyWord.children.length) {
                dataSource.push(objKeyWord);
              }
            }
          } else {
            if (objEbook.children.length) {
              dataSource.push(objEbook);
              if (objKeyWord.children.length) {
                dataSource.push(objKeyWord);
              }
            } else {
              if (objKeyWord.children.length) {
                dataSource.push(objKeyWord);
              }
            }
          }
        } else if (objPublisher.children.length) {
          dataSource.push(objPublisher);
          if (objEbook.children.length) {
            dataSource.push(objEbook);
            if (objKeyWord.children.length) {
              dataSource.push(objKeyWord);
            }
          } else {
            if (objKeyWord.children.length) {
              dataSource.push(objKeyWord);
            }
          }
        } else if (objEbook.children.length) {
          dataSource.push(objEbook);
          if (objKeyWord.children.length) {
            dataSource.push(objKeyWord);
          }
        } else if (objKeyWord.children.length) {
          dataSource.push(objKeyWord);
        }
        setDataSource(dataSource);
        setSearchResults([
          { title: text, contentID: "_" + String(text), type: "keyword" },
          ...result.data.searchResults,
        ]);
      }
    } else {
      setDataSource([]);
    }
  };
  const searchBar = () => {
    if (dataSource.length) {
      return dataSource.map((el) => {
        return (
          <OptGroup
            key={el.type === "keyword" ? "ค้นหาอีบุ๊ค" : el.type}
            label={
              el.type !== "keyword" ? (
                <span>
                  <img
                    src={`/images/nav/search-${el.type.toLowerCase()}.svg`}
                    style={{
                      width: "18px",
                      height: "15px",
                      transform: "translateY(-2px)",
                    }}
                    alt=""
                  />
                  {" " + (el.type === "KeyWord" ? "Tag" : String(el.type))}
                </span>
              ) : (
                ""
              )
            }
          >
            {el.children.map((e) => (
              <Option
                key={e.contentID}
                value={
                  el.type === "keyword"
                    ? "_" + String(e.contentID)
                    : e.contentID
                }
                style={{ display: "flex", padding: "0 0" }}
              >
                <p
                  style={{
                    width: "100%",
                    padding: "0 0 0 20px",
                    margin: "0 0",
                  }}
                >
                  {e.title}
                </p>
              </Option>
            ))}
          </OptGroup>
        );
      });
    }
  };

  useEffect(() => {
    document.addEventListener("scroll", trackScrolling);
    const setDeviceUID = async () => {
      localStorage.setItem("deviceuid", await ip);
    };
    setDeviceUID();
  }, []);

  const trackScrolling = () => {
    setIsScrolling(true);
  };
  useEffect(() => {
    if (isScrolling) setIsScrolling(false);
  }, [isScrolling]);

  useEffect(() => {
    const fetchMemberInfo = async () => {
      let memberInfo = await requestMemberInfo();
      if (memberInfo.status) {
        if (memberInfo.data.profileImage) {
          setProfileImage(memberInfo.data.profileImage.image);
        } else {
          setProfileImage("/images/book/worm.png");
        }
        dispatch(receiveMemberInfo(memberInfo.data));
      } else {
        dispatch(fetchLogOut());
      }
    };
    if (memberUID !== "") {
      fetchMemberInfo();
    }
  }, [memberUID]);

  const profileDropdownBtn = (
    <Menu
      className={
        props.memberInfo.memberType === "creator"
          ? "profile-dropdown-creator"
          : "profile-dropdown"
      }
    >
      <Row className="profile">
        <Col span={9}>
          <a
            href={`${oldWebURL}/webservice/loginwithtoken.php?type=profile&token=${props.authentication.userToken}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={profileImage}
              alt=""
              style={{
                width: "59px",
                height: "59px",
                marginTop: "4px",
                objectFit: "cover",
                borderRadius: "50%",
              }}
            />
          </a>
          <Upload accept="image/*" customRequest={(req) => customRequest(req)}>
            <img
              src="/images/nav/camera.svg"
              alt=""
              style={{
                borderRadius: "25%",
                backgroundColor: "white",
                paddingLeft: "2px",
                width: "21px",
                height: "18px",
                transform: "translate(42px, -20px)",
              }}
              className="camera"
            />
          </Upload>
        </Col>
        <Col span={15}>
          <Row>
            <a
              href={`${oldWebURL}/webservice/loginwithtoken.php?type=profile&token=${props.authentication.userToken}`}
              target="_blank"
              rel="noopener noreferrer"
            >
              <p className="displayname">{props.memberInfo.displayName}</p>
            </a>
          </Row>
          <Row>
            <Col span={7} align="center">
              <p className="labeltop">{props.memberInfo.ebooks || 0}</p>
              <p className="labelunder">อีบุ๊ค</p>
            </Col>
            <Col span={12} offset={4} align="center">
              <p className="labeltop">{props.memberInfo.following || 0}</p>
              <p className="labelunder">กำลังติดตาม</p>
            </Col>
          </Row>
        </Col>
      </Row>
      {screenWidth >= 768 ? <Divider /> : ""}
      {screenWidth < 768 ? (
        <div style={{ textAlign: "center" }} className="searchBar-inlist">
          {/* <Divider type="vertical" className="divider-searchBox-nav" style={{ margin: "0 0" }} /> */}
          <AutoComplete
            ref={searchInput}
            dropdownClassName="search-bar-inlist-dropdown"
            dataSource={searchBar()}
            style={{ width: 198, marginBottom: "4px", marginTop: "4px" }}
            onSelect={onSelect}
            onSearch={onSearch}
            value={searchBoxValue}
            optionLabelProp="value"
            placeholder="ค้นหา"
          >
            <Input
              suffix={<Icon type="search" className="search-bar-icon" />}
            />
          </AutoComplete>
        </div>
      ) : (
        ""
      )}
      <Menu.Item key="1">
        <Link to="/wishlist">
          <img src="/images/book/wishlist.png" alt="" />
          Wishlist
        </Link>
      </Menu.Item>
      <Menu.Item key="2">
        {/* <Link to="/library"> */}
        {/* <Link to="#" onClick={goToOldLibrary}> */}
        <a
          href={
            isAndroid
              ? "https://play.google.com/store/apps/details?id=com.IPJ1988.hytexts.ebookstore&hl=th"
              : isIOS || isIOS13
              ? "https://apps.apple.com/app/hytexts-ebook-store/id908903530"
              : `${oldWebURL}/webservice/loginwithtoken.php?type=library&token=${props.authentication.userToken}`
          }
          target="_blank"
          rel="noopener noreferrer"
        >
          <img src="/images/nav/library.svg" alt="" />
          ชั้นหนังสือ
        </a>
        {/* </Link> */}
      </Menu.Item>
      {props.memberInfo.memberType === "creator" ? (
        <Menu.Item key="6">
          <a
            href={`${oldWebURL}/webservice/loginwithtoken.php?type=upload&token=${props.authentication.userToken}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img
              src={
                props.memberInfo.email === "paymenthytexts@gmail.com"
                  ? "/images/nav/buildbook.svg"
                  : "/images/nav/uploadbook.svg"
              }
              alt=""
            />
            {props.memberInfo.email === "paymenthytexts@gmail.com"
              ? "สร้างอีบุ๊ค"
              : "อัพโหลดหนังสือ"}
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      {props.memberInfo.memberType === "creator" ? (
        <Menu.Item key="7">
          <a
            href={`${oldWebURL}/webservice/loginwithtoken.php?type=ebook&token=${props.authentication.userToken}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/nav/myebook.svg" alt="" />
            My e-book
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      {props.memberInfo.memberType === "creator" ? (
        <Menu.Item key="8">
          <a
            href={`${oldWebURL}/webservice/loginwithtoken.php?type=report&token=${props.authentication.userToken}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/nav/report.svg" alt="" />
            ยอดขาย
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      {props.memberInfo.memberType === "creator" ? (
        <Menu.Item key="9">
          <a
            href={`${oldWebURL}/webservice/loginwithtoken.php?type=writer&token=${props.authentication.userToken}`}
            target="_blank"
            rel="noopener noreferrer"
          >
            <img src="/images/nav/search-author.svg" alt="" />
            นามปากกา
          </a>
        </Menu.Item>
      ) : (
        ""
      )}
      <Menu.Item key="3">
        {/* <a
          href={`${oldWebURL}/webservice/loginwithtoken.php?type=purchased&token=${props.authentication.userToken}`}
          target="_blank"
          rel="noopener noreferrer"
        > */}
        <Link to="/payment/history">
          <img
            style={{ height: "20px" }}
            src="/images/nav/history.svg"
            alt=""
          />
          ประวัติการซื้อ
        </Link>
        {/* </a> */}
      </Menu.Item>
      {/*<Menu.Item key="4">
        <Link to="#">
          <img
            style={{ height: "18px" }}
            src="/images/nav/settings.svg"
            alt=""
          />
          ตั้งค่า
        </Link>
      </Menu.Item>*/}
      <Menu.Item key="5">
        <Link
          to="#"
          onClick={() => {
            dispatch(fetchLogOut());
          }}
        >
          <img style={{ height: "18px" }} src="/images/nav/logout.svg" alt="" />
          ออกจากระบบ
        </Link>
      </Menu.Item>
    </Menu>
  );

  const loginMenu = (
    <Menu>
      <Menu.Item>
        <div
          onClick={() => {
            dispatch(navModal(true));
            dispatch(
              navModalMode({
                loginModal: 1,
                registerModal: 0,
                registerEmailModal: 0,
              })
            );
          }}
        >
          นักอ่าน
        </div>
      </Menu.Item>
      <Menu.Item>
        <div
          onClick={() => {
            window.open("https://admin-content.hibrary.me/");
          }}
        >
          นักเขียน / สำนักพิมพ์
        </div>
      </Menu.Item>
    </Menu>
  );

  const menuIconSet = () => {
    return (
      <>
        {memberUID !== "" ? (
          <Dropdown
            overlay={profileDropdownBtn}
            trigger={["click"]}
            placement="bottomRight"
          >
            <img
              className="profile-btn"
              src={profileImage || "/images/book/worm.png"}
              alt=""
            />
          </Dropdown>
        ) : (
          <>
            <Dropdown overlay={loginMenu}>
              <button className="login-btn">เข้าสู่ระบบ</button>
            </Dropdown>
          </>

          //    <button
          //   className="login-btn"
          //   onClick={() => {
          //     dispatch(navModal(true));
          //     dispatch(
          //       navModalMode({
          //         loginModal: 1,
          //         registerModal: 0,
          //         registerEmailModal: 0,
          //       })
          //     );
          //   }}
          // >
          //   เข้าสู่ระบบ
          // </button>
        )}
        <Tooltip
          placement="bottom"
          title={
            <p style={{ fontFamily: "Prompt", margin: "0 0" }}>Hytexts Coin</p>
          }
        >
          <p className="hytextscoin">
            <img
              className="yellowcoin"
              src="/images/nav/yellowcoin.png"
              alt=""
            />
            <span className="remaincoin">
              {props.memberInfo.coinData.remainCoin}
            </span>
            <Divider
              type="vertical"
              style={{
                height: "24px",
                background: "#c8c8c8",
                transform: "translateY(1px)",
                marginRight: "0px",
              }}
            />
            <Link
              to="/hytextscoin"
              style={{ paddingLeft: "8px", paddingRight: "8px" }}
            >
              <span style={{ color: "#189aeb", fontSize: "19px" }}>+</span>
            </Link>
          </p>
        </Tooltip>
        {memberUID !== "" ? (
          <Tooltip
            placement="bottom"
            title={
              <p style={{ fontFamily: "Prompt", margin: "0 0" }}>ชั้นหนังสือ</p>
            }
          >
            <a
              style={{ marginRight: "16px" }}
              href={
                isAndroid
                  ? "https://play.google.com/store/apps/details?id=com.IPJ1988.hytexts.ebookstore&hl=th"
                  : isIOS || isIOS13
                  ? "https://apps.apple.com/app/hytexts-ebook-store/id908903530"
                  : `${oldWebURL}/webservice/loginwithtoken.php?type=library&token=${props.authentication.userToken}`
              }
              target="_blank"
              rel="noopener noreferrer"
            >
              <img
                className="nav-logo"
                src="/images/nav/library.svg"
                alt=""
                style={{ transform: "translateY(1px)" }}
              />
            </a>
            {/* </Link> */}
          </Tooltip>
        ) : (
          ""
        )}
        <Tooltip
          placement="bottom"
          title={
            <p
              style={{
                fontFamily: "Prompt",
                margin: "0 0",
              }}
            >
              ตะกร้าสินค้า
            </p>
          }
        >
          <Link to="/shoppingcart">
            <img
              className="nav-logo"
              style={{ width: "28px", transform: "translateY(2px)" }}
              src="/images/nav-mobile/3-active.svg"
              alt=""
            />
            <Badge className="navBadge" count={props.payment.numCart}></Badge>
          </Link>
        </Tooltip>
      </>
    );
  };

  if (props.isMobile) {
    return (
      <>
        {props.location.pathname === "/" ||
        props.tabData.some((e) => e.path === props.location.pathname) ? (
          <Layout.Header
            className="navbar"
            style={{
              position: "fixed",
              zIndex: 100,
              width: "100%",
              top: "0",
              minWidth: "290px",
              lineHeight: "40px",
            }}
          >
            <>
              {window.scrollY <= 50 ? (
                <Row
                  type="flex"
                  justify="center"
                  align="middle"
                  style={
                    window.scrollY > 50
                      ? { height: 0, opacity: 0 }
                      : { height: 50, opacity: 1 }
                  }
                >
                  <Col span={24} align="center">
                    <Link className="menuLogo" to={"/"}>
                      <img
                        className="menuLogo"
                        src="/logoHytexts.png"
                        alt="Hytexts Logo"
                      />
                    </Link>
                  </Col>
                </Row>
              ) : (
                ""
              )}
              {categoryTab(true)}
            </>
          </Layout.Header>
        ) : (
          ""
        )}
        {props.location.pathname.includes("/ebook/") ? (
          <Layout.Header
            className="navbar"
            style={{
              position: "fixed",
              zIndex: 100,
              width: "100%",
              top: "0",
              minWidth: "290px",
              lineHeight: "40px",
            }}
          >
            {props.showFlashSale.isShow &&
            props.location.pathname.includes("/ebook/") ? (
              <div className="flash-sale-ebook">
                <FlashSale showListBelow={false} />
              </div>
            ) : (
              ""
            )}
          </Layout.Header>
        ) : (
          ""
        )}
        <Layout.Header
          className={
            props.location.pathname.includes("/ebook/")
              ? "navbar-noshadow"
              : "navbar"
          }
          style={{
            position: "fixed",
            zIndex: 100,
            width: "100%",
            minWidth: "290px",
            bottom: "0",
            lineHeight: "40px",
          }}
        >
          <nav className="navMenuSet-footer">
            <Row
              className="navbtn-set-isMobile"
              type="flex"
              justify="space-between"
            >
              {props.tabDataFooter.map((e, index) => {
                return (
                  <Col
                    span={Math.floor(24 / props.tabDataFooter.length)}
                    key={e.path}
                  >
                    <button
                      value={e.path}
                      onClick={() => handleTabClick(e.path)}
                      className={
                        props.location.pathname === e.path
                          ? "navbtn-active-footer"
                          : "navbtn-footer"
                      }
                    >
                      <img
                        alt=""
                        src={
                          "/images/nav-mobile/" +
                          (index + 1) +
                          (props.location.pathname === e.path
                            ? "-active"
                            : "") +
                          ".svg"
                        }
                      />
                      {e.path === "/shoppingcart" &&
                      props.payment.numCart > 0 ? (
                        props.payment.numCart <= 10 ? (
                          <span className="numCart">
                            {props.payment.numCart}
                          </span>
                        ) : (
                          <span className="numCart">10+</span>
                        )
                      ) : (
                        ""
                      )}
                      <p
                        style={{
                          marginTop: "2px",
                          marginBottom:
                            iOS_Mac &&
                            (iPhoneX_11 || iPhone11_promax || iPadPro11_0)
                              ? "3em"
                              : "1em",
                        }}
                      >
                        {e.title}
                      </p>
                    </button>
                  </Col>
                );
              })}
            </Row>
          </nav>
        </Layout.Header>
      </>
    );
  } else {
    return (
      <Layout.Header
        className="navbar"
        style={{
          position: "fixed",
          zIndex: 100,
          width: "100%",
          minWidth: "290px",
        }}
      >
        <nav className="navMenuSet">
          <Row type="flex" justify="space-between" style={{ height: 50 }}>
            {memberUID !== "" ? (
              <Col xs={3} sm={0} align="left">
                <Dropdown
                  overlay={profileDropdownBtn}
                  trigger={["click"]}
                  placement="bottomRight"
                >
                  <img
                    className="profile-btn"
                    src={profileImage || "/images/book/worm.png"}
                    alt=""
                  />
                </Dropdown>
              </Col>
            ) : (
              ""
            )}
            <Col xs={8} sm={10} align="left">
              <div>{menuIconSet()}</div>
            </Col>
            <Col span={4} align="center">
              <Link className="menuLogo" to={"/"}>
                <img
                  className="menuLogo"
                  src="/logoHytexts.png"
                  alt="Hytexts Logo"
                />
              </Link>
            </Col>
            <Col span={8} offset={2} align="right" className="searchBar-top">
              {/* <Divider type="vertical" className="divider-searchBox" /> */}
              <AutoComplete
                ref={searchInput}
                dropdownClassName="search-bar-dropdown"
                dataSource={searchBar()}
                style={
                  screenWidth >= 1250 ? { width: "65%" } : { width: "85%" }
                }
                onSelect={onSelect}
                onSearch={onSearch}
                value={searchBoxValue}
                optionLabelProp="value"
                placeholder="ค้นหาอีบุ๊ค นักเขียน แฮชแท็ก #"
              >
                <Input
                  suffix={<Icon type="search" className="search-bar-icon" />}
                />
              </AutoComplete>
            </Col>
          </Row>
          {categoryTab()}
        </nav>
        {/* {this.props.authentication.userToken} */}
        {props.showFlashSale.isShow &&
        props.location.pathname.includes("/ebook/") ? (
          <div className="flash-sale-ebook">
            <FlashSale showListBelow={false} />
          </div>
        ) : (
          ""
        )}
        <ModalApp />
      </Layout.Header>
    );
  }
};

const mapStateToProps = (state) => {
  return {
    ebook: state.Ebook,
    authentication: state.Authentication,
    payment: state.Payment,
    Page: state.Page,
    showFlashSale: state.Page.showFlashSale,
    navModal: state.Page.navModal,
    memberInfo: state.Member.memberInfo,
  };
};

Nav.propTypes = {
  tabData: PropTypes.array,
  numCart: PropTypes.number,
  authentication: PropTypes.object,
  ebook: PropTypes.object,
  dispatch: PropTypes.func,
  payment: PropTypes.object,
};
export default withRouter(connect(mapStateToProps)(Nav));
