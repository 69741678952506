import React, { useState, useEffect } from "react";
import { Row, Col, Divider, Button, Result } from "antd";
import { withRouter } from "react-router-dom";
import { Link } from "react-router-dom";
import { fetchOrderDetail, actionRemoveAllCart } from "../../../Actions/Payment";
import { ACTION_SHOW_LOADING } from "../../../Actions/Page";
import { connect } from "react-redux";
import { millisecsToDateTime } from "../../../Utilitys/changeFormatDateTime";
import authenUtility from "../../../Utilitys/Authentication";
import { deleteWishlist } from "../../../Actions/Ebook";
import { requestMemberInfo, receiveMemberInfo } from "../../../Actions/Member";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);

const PaymentSuccess = (props) => {
  const [order, setOrder] = useState("");
  const [contentType, setContentType] = useState("");
  const [haveErrorCode, setHaveErrorCode] = useState(false);
  const [isHytextsCoin, setIsHytextsCoin] = useState(null);
  useEffect(() => {
    const orderCode = props.match.params.orderCode;
    if (orderCode && orderCode.substring(0, 1).toLowerCase() === "c") {
      setIsHytextsCoin(true);
    } else {
      setIsHytextsCoin(false);
    }
    props.dispatch(ACTION_SHOW_LOADING(true));
    const gotOrderCode = () => {
      if (orderCode) {
        actionOrderDetail(orderCode);
        // actionOrderDetail("HT915792449782f");
      }
    };
    const queryParams = props.history.location.search;
    if (queryParams) {
      queryParams.split("&").map((e) => {
        if (e.includes("errorCode")) {
          if (e.split("=")[1] === "00") {
            gotOrderCode();
          } else {
            setHaveErrorCode(true);
          }
        }
      });
    } else {
      gotOrderCode();
    }
  }, []);

  const fetchMemberInfo = async () => {
    let memberInfo = await requestMemberInfo();
    if (memberInfo.status) {
      props.dispatch(receiveMemberInfo(memberInfo.data));
    }
  };

  const actionOrderDetail = async (code) => {
    const res = await fetchOrderDetail(code);
    if (res.status) {
      setOrder(res.data);
      setContentType(res.data.contentType);
      if (authenUtility.isLogin() !== "") {
        fetchMemberInfo();
      }
      if (res.data.contentType === "ebook") {
        props.dispatch(actionRemoveAllCart(res.data.orderDetail.map((e) => e.bookUID)));
        res.data.orderDetail.map(async (e) => {
          await deleteWishlist(e.bookUID);
          // let wl_delete = await deleteWishlist(e.bookUID);
          // if (wl_delete.status) {
          //   console.log("deleted wishlist");
          // }
        });
      }
      props.dispatch(ACTION_SHOW_LOADING(false));
    }
  };
  const paymentName = (type) => {
    switch (type) {
      case "CC":
        return "Credit Card";
      case "PP":
        return "PayPal";
      case "TF":
        return "โอนเงินธนาคาร";
      case "HC":
        return "Hytexts Coin";
      case "LP":
        return "Line Pay";
      default:
        return type;
    }
  };
  // if (1 === 1) {
  //   if (order.paymentType === "TF") {
  //     return (
  //       <PaymentSuccessTF
  //         screenWidth={props.Page.screensize.width}
  //         orderCode={order.orderCode}
  //         paidAmount={order.paidAmount}
  //         qrPayment={order.qrPayment}
  //         deadline={
  //           millisecsToDateTime(order.saleTimestamp + 3 * 86400, "hr-min", "dd/mm/yyyy").split(
  //             " "
  //           )[0]
  //         }
  //       />
  //     );
  //   } else {
  if (order !== "")
    return (
      <div className="paymentSuccess">
        <h3 className="prompt26">การสั่งซื้อเสร็จสมบูรณ์</h3>
        {props.Page.screensize.width >= 768 ? (
          <Row type="flex" justify="space-between" style={{ margin: "16px 0 24px 0" }}>
            <Col xs={24} md={5} align="left">
              <h3 className="BaiJamJuree16">เลขที่การสั่งซื้อ</h3>
              <h3 className="BaiJamJuree16">{order.orderCode}</h3>
            </Col>
            <Col xs={24} md={6} align="left">
              <h3 className="BaiJamJuree16">วันที่สั่งซื้อ</h3>
              {/* <h3 className="BaiJamJuree16">22/01/2018 16:16 น.</h3> */}
              <h3 className="BaiJamJuree16">
                {order.saleTimestamp &&
                  millisecsToDateTime(order.saleTimestamp, "hr-min", "dd/mm/yyyy") + " น."}
              </h3>
            </Col>
            <Col xs={24} md={5} align="left">
              <h3 className="BaiJamJuree16">ช่องทางการชำระเงิน</h3>
              <h3 className="BaiJamJuree16">{paymentName(order.paymentType)}</h3>
            </Col>
          </Row>
        ) : (
          <div>
            <Row type="flex" justify="space-between" style={{ margin: "24px 0 0 0" }}>
              <Col span={10} align="left">
                <h3 className="BaiJamJuree16">เลขที่การสั่งซื้อ</h3>
              </Col>
              <Col span={14} align="right">
                <h3 className="BaiJamJuree16">{order.orderCode}</h3>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" style={{ margin: "8px 0 0 0" }}>
              <Col span={10} align="left">
                <h3 className="BaiJamJuree16">วันที่สั่งซื้อ</h3>
              </Col>
              <Col span={14} align="right">
                {/* <h3 className="BaiJamJuree16">22/01/2018 16:16 น.</h3> */}
                <h3 className="BaiJamJuree16">
                  {order.saleTimestamp &&
                    millisecsToDateTime(order.saleTimestamp, "hr-min", "dd/mm/yyyy") + " น."}
                </h3>
              </Col>
            </Row>
            <Row type="flex" justify="space-between" style={{ margin: "8px 0 0 0" }}>
              <Col span={14} align="left">
                <h3 className="BaiJamJuree16">ช่องทางการชำระเงิน</h3>
              </Col>
              <Col span={10} align="right">
                <h3 className="BaiJamJuree16">{paymentName(order.paymentType)}</h3>
              </Col>
            </Row>
          </div>
        )}
        <Divider
          style={{
            margin: "0 0 16px 0",
            width: props.Page.screensize.width > 750 ? "692px" : "100%",
            background: "#dbdbdb",
          }}
        />
        <div className="aftercart">
          {order &&
            contentType === "ebook" &&
            order.orderDetail.map((el, index) => {
              return (
                <Row
                  key={index}
                  type="flex"
                  justify="space-between"
                  align="top"
                  gutter={8}
                  style={{ margin: "0 0 16px 0" }}
                >
                  <Col xs={5} sm={3} align="left">
                    <img
                      src={el.coverImage}
                      style={{ height: "auto", width: "100%", marginRight: "16px" }}
                      alt=""
                    />
                  </Col>
                  <Col span={13} align="left">
                    <Link to={"/ebook/" + el.bookUID}>
                      <p className="title">{el.title}</p>
                    </Link>
                    <Link to={"/author/" + el.authorName}>
                      <p className="author">ผู้เขียน: {el.authorName}</p>
                    </Link>
                    {el.publisherName ? (
                      <Link to={"/publisher/" + el.creatorUID}>
                        <p className="author">สำนักพิมพ์: {el.publisherName}</p>
                      </Link>
                    ) : (
                      ""
                    )}
                  </Col>
                  <Col xs={6} sm={8} align="right" className="price">
                    ฿{el.price}
                  </Col>
                </Row>
              );
            })}
          {order &&
            contentType === "coin" &&
            order.orderDetail.map((el, index) => {
              return (
                <Row
                  key={index}
                  type="flex"
                  justify="space-between"
                  align="top"
                  style={{ margin: "0 0 16px 0" }}
                >
                  <Col span={12} align="left">
                    <p className="coinReceive" style={{ marginBottom: "2px" }}>
                      {el.coinReceive || el.coinRecieve} C
                    </p>
                    <p className="coinBonus">+Free {el.coinBonus} C</p>
                  </Col>
                  <Col span={8} align="right" className="price">
                    ฿{order.paidAmount}
                  </Col>
                </Row>
              );
            })}
        </div>
        <Divider
          style={{
            margin: "16px 0 0 0",
            width: props.Page.screensize.width > 750 ? "692px" : "100%",
            background: "#dbdbdb",
          }}
        />
        <Row type="flex" justify="end" align="middle">
          <Col span={2}>
            <p className="prompt16" style={{ margin: "10px 0px" }}>
              รวม
            </p>
          </Col>
          <Col span={2}>
            <Divider type="vertical" style={{ height: "55px", background: "#dbdbdb" }} />
          </Col>
          <Col span={5} align="right">
            <p
              className="prompt18"
              style={{ fontWeight: "600", margin: "10px 0px", color: "black" }}
            >
              ฿{order.paidAmount}
            </p>
          </Col>
        </Row>
        <Divider
          style={{
            margin: "0 0 32px 0",
            width: props.Page.screensize.width > 750 ? "692px" : "100%",
            background: "#dbdbdb",
          }}
        />
        <Button
          className="toHome"
          onClick={() => {
            props.history.push("/");
          }}
        >
          กลับไปหน้าหลัก
        </Button>
      </div>
    );
  else if (haveErrorCode) {
    return (
      <div className="paymentSuccess">
        <Result
          status="404"
          title={
            <p style={{ fontFamily: "Bai Jamjuree", fontSize: "20px" }}>
              ขออภัย{<br />}ข้อมูลการชำระเงินไม่ถูกต้อง
            </p>
          }
        />
        <Button
          className="toHome"
          onClick={() => {
            if (isHytextsCoin) {
              props.history.push("/hytextscoin");
            } else {
              props.history.push("/payment");
            }
          }}
        >
          กลับไปหน้าชำระเงิน
        </Button>
      </div>
    );
  } else {
    return <div></div>;
  }
  //   }
  // }
};

const mapStateToProps = (state) => {
  return {
    Page: state.Page,
    authentication: state.Authentication,
  };
};

export default withRouter(connect(mapStateToProps)(PaymentSuccess));
