import React from "react";

const AllowDataSubject = () => {
  const contents_1 = [
    " ตาม​ประกาศกระทรวงดิจิทัลเพื่อเศรษฐกิจและสังคม เรื่อง ขั้นตอนการแจ้งเตือน",
    "การระงับการทําให้แพร่หลายของข้อมูลคอมพิวเตอร์และการนําข้อมูลคอมพิวเตอร์ออกจากระบบคอมพิวเตอร์ พ.ศ. 2560 ",
    "ผู้ที่ประสงค์จะให้สำนักงานในฐานะผู้ให้บริการ การระงับการทำให้แพร่หลาย และการนําข้อมูลออกจากระบบคอมพิวเตอร์ ",
    "สำหรับกรณีที่มีข้อมูลใดที่อาจเป็นความผิดตามมาตรา 14 แห่งพระราชบัญญัติว่าด้วยการกระทำความผิดเกี่ยวกับคอมพิวเตอร์ พ.ศ.2550 ",
    "และที่แก้ไขเพิ่มเติม สามารถดำเนินการแจ้งสำนักงานได้พร้อมเอกสารหลักฐานประกอบแล้วส่งมาที",
  ];

  const address = [
    "3 อาคารพร้อมพันธุ์ 3 ชั้น 7 ห้อง 701-702 ซอย ลาดพร้าว 3 แขวงจอมพล เขตจตุจักร กรุงเทพมหานคร 10900",
    "หรือติดต่ออีเมล : contact@hytexts.com",
  ];

  return (
    <div className="side">
      <div className="section-space">
        <div>
          <p className="side-title-hytexts">
            ขอใช้สิทธิสำหรับเจ้าของข้อมูลส่วนบุคคล
          </p>
          {contents_1.map((e, index) => (
            <p className="side-content-lessBottom" key={index}>
              {e}
              <br />
            </p>
          ))}
        </div>

        <div>
          {" "}
          <p className="side-title-bold">
            บริษัท ไฮเท็คซ์ อินเตอร์แอคทีฟ จำกัด (Hytexts)
          </p>
          {address.map((e, index) => (
            <p className="side-content-lessBottom" key={index}>
              {e}
              <br />
            </p>
          ))}
        </div>

        <div>
          <p className="side-title-bold">เอกสารหลักฐานประกอบ</p>
          <ul>
            <li className="side-content-lessBottom">
              <a
                href="https://s3.ap-southeast-1.amazonaws.com/hti.elibrary/public/form/complaintform_Hytexts.pdf"
                target="blank"
              >
                แบบฟอร์มข้อร้องเรียนเพื่อระงับหรือลบการแพร่หลายของข้อมูลคอมพิวเตอร์​
                (Com​​​​​plaint Form)
              </a>
            </li>
            <li className="side-content-lessBottom">
              ลงบันทึกประจําวัน
              หรือแจ้งความร้องทุกข์เพื่อเป็นหลักฐานต่อพนักงานสอบสวนหรือเจ้าหน้าที่ตํารวจ
            </li>
            <li className="side-content-lessBottom">
              เอกสารหลักฐานที่แสดงให้เห็นถึงการกระทําความผิดหรือหลักฐานอื่นที่เกี่ยวข้องแก่ผู้ให้บริการ
            </li>
            <li className="side-content-lessBottom">
              ​หนังสือมอบอำนาจ (กรณีมอบอำนาจ)
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
};

export default AllowDataSubject;
