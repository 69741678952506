import React, { useState, useEffect } from "react";
import EbookView from "./EbookView";
import { Row, Col, Result } from "antd";
import PropTypes from "prop-types";
import { EbookViewLoader } from "../ContentLoader";
import { withRouter } from "react-router-dom";
import ScrollContainer from "react-indiana-drag-scroll";

const EbookCollection = (props) => {
  if (props.isHomePage && props.screenWidth <= 1020) {
    return (
      <ScrollContainer className="collection-home">
        <ListEbookView {...props} />
      </ScrollContainer>
    );
  } else {
    return (
      <div className="collection">
        <ListEbookView {...props} />
      </div>
    );
  }
};

const ListEbookView = (props) => {
  const span = Math.floor(24 / props.numCollection);
  const [wishlistDeletedArr, setWishlistDeletedArr] = useState([]);

  const [localEbooks, setLocalEbooks] = useState(props.ebooks);

  const [toLibArr, setToLibArr] = useState([]);
  const [toLibRemoveArr, setToLibRemoveArr] = useState([]);

  const [libDeletedArr, setLibDeletedArr] = useState([]);
  const [libRemoveDeletedArr, setLibRemoveDeletedArr] = useState([]);

  const [ebookDeleted, setEbookDeleted] = useState(null);

  useEffect(() => {
    if (ebookDeleted !== null) {
      if (props.isWishlist) {
        setWishlistDeletedArr([...wishlistDeletedArr, ebookDeleted]);
      } else {
        if (props.isLib === 1) {
          setLibDeletedArr([...libDeletedArr, ebookDeleted]);
          setToLibRemoveArr([...toLibRemoveArr, ebookDeleted]);
          setToLibArr(toLibArr.filter((el) => el.bookUID !== ebookDeleted.bookUID));
        } else if (props.isLib === 2) {
          setLibRemoveDeletedArr([...libRemoveDeletedArr, ebookDeleted]);
          setToLibArr([...toLibArr, ebookDeleted]);
          setToLibRemoveArr(toLibRemoveArr.filter((el) => el.bookUID !== ebookDeleted.bookUID));
        }
      }
      setEbookDeleted(null);
    }
  }, [ebookDeleted, props.isLib]);

  useEffect(() => {
    if (props.isLib === 1 && props.ebooks) {
      if (toLibArr.length) {
        toLibArr.forEach((newbook) => {
          let propsUnique = props.ebooks.filter((b) => {
            return b.bookUID !== newbook.bookUID;
          });
          let sameProps = propsUnique.length === props.ebooks.length;
          if (sameProps) {
            setLocalEbooks([...propsUnique, newbook]);
          } else {
            setLocalEbooks(props.ebooks);
          }
        });
      } else {
        setLocalEbooks(props.ebooks);
      }
    } else if (props.isLib === 2 && props.ebooks) {
      if (toLibRemoveArr.length) {
        toLibRemoveArr.forEach((newbook) => {
          let propsUnique = props.ebooks.filter((b) => {
            return b.bookUID !== newbook.bookUID;
          });
          let sameProps = propsUnique.length === props.ebooks.length;
          if (sameProps) {
            setLocalEbooks([...propsUnique, newbook]);
          } else {
            setLocalEbooks(props.ebooks);
          }
        });
      } else {
        setLocalEbooks(props.ebooks);
      }
    }
  }, [props.ebooks]);

  useEffect(() => {
    if (ebookDeleted === null) {
      setLibDeletedArr([]);
      setLibRemoveDeletedArr([]);
    }
  }, [props.isLib]);

  if (props.isEmpty && localEbooks.length === 0) {
    return (
      <Result
        status="404"
        title={
          <p className="empty">
            ขออภัย{<br />}
            {props.isWishlist ? "ไม่มีอีบุ๊คใน Wishlist" : "ไม่พบข้อมูลอีบุ๊ค"}
          </p>
        }
      />
    );
  } else {
    if (props.isHomePage && props.screenWidth <= 1020) {
      if (props.ebooks.length) {
        return [
          ...props.ebooks.map((data, index) => (
            <EbookView
              key={index}
              ebook={data}
              flashSale={props.flashSale}
              isHomePage={props.isHomePage}
              index={index}
            />
          )),
          <div style={{ width: "8px" }}></div>,
        ];
      } else {
        return [
          ...Array(
            (props.preloadNumCollection ? props.preloadNumCollection : props.numCollection) *
              props.rowOnLoading
          ),
        ].map((data, index) => (
          <EbookViewLoader key={index} isHomePage={props.isHomePage} index={index} />
        ));
      }
    } else {
      if (props.ebooks.length || localEbooks.length) {
        return (
          <Row
            type="flex"
            justify={props.match.path === "/" ? "space-between" : "start"}
            gutter={props.screenWidth <= 900 ? [8, 8] : [16, props.marginBottom]}
          >
            {props.isLib
              ? localEbooks
                  .filter((e) => {
                    if (props.isWishlist) {
                      return !wishlistDeletedArr.includes(e.bookUID);
                    } else if (props.isLib) {
                      if (props.isLib === 1) {
                        return !libDeletedArr.includes(e);
                      } else if (props.isLib === 2) {
                        return !libRemoveDeletedArr.includes(e);
                      }
                    } else {
                      return ![].includes(e.bookUID);
                    }
                  })
                  .map((data, index) => (
                    <Col span={span} key={index}>
                      <EbookView
                        ebook={data}
                        flashSale={props.flashSale}
                        isWishlist={props.isWishlist}
                        isLib={props.isLib}
                        setEbookDeleted={setEbookDeleted}
                        isTopic={props.isTopic}
                      />
                    </Col>
                  ))
              : props.ebooks
                  .filter((e) => {
                    if (props.isWishlist) {
                      return !wishlistDeletedArr.includes(e.bookUID);
                    } else if (props.isLib) {
                      if (props.isLib === 1) {
                        return !libDeletedArr.includes(e);
                      } else if (props.isLib === 2) {
                        return !libRemoveDeletedArr.includes(e);
                      }
                    } else {
                      return ![].includes(e.bookUID);
                    }
                  })
                  .map((data, index) => (
                    <Col
                      span={span}
                      key={index}
                      style={{ marginBottom: props.screenWidth <= 900 ? "0px" : "16px" }}
                    >
                      <EbookView
                        ebook={data}
                        flashSale={props.flashSale}
                        isWishlist={props.isWishlist}
                        isLib={props.isLib}
                        setEbookDeleted={setEbookDeleted}
                        isRelatedBook={props.isRelatedBook}
                        isTopic={props.isTopic}
                      />
                    </Col>
                  ))}
          </Row>
        );
      } else {
        return (
          <Row
            type="flex"
            justify={props.match.path === "/" ? "space-between" : "start"}
            gutter={props.screenWidth <= 900 ? [8, 8] : [16, 32]}
          >
            {[
              ...Array(
                (props.preloadNumCollection ? props.preloadNumCollection : props.numCollection) *
                  props.rowOnLoading
              ),
            ].map((data, i) => (
              <Col span={span} key={i} style={{ maxHeight: 300, textAlign: "center" }}>
                <EbookViewLoader />
              </Col>
            ))}
          </Row>
        );
      }
    }
  }
};

ListEbookView.propTypes = {
  ebooks: PropTypes.array,
  numCollection: PropTypes.number,
};
EbookCollection.propTypes = {
  ebooks: PropTypes.array,
};
export default withRouter(EbookCollection);
