import React, { useState, useEffect, useRef } from "react";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import { flashSaleExp } from "../../../Actions/Page";
import { postComment, deleteComment } from "../../../Actions/Member";
import {
  // Button,
  Row,
  Col,
  Rate,
  Modal,
  Divider,
  //  , Layout
} from "antd";
import HomeList from "../../BookList/HomeList";
import "./Book.scss";
import { DownloadAppPanel } from "../../Book-components/DownloadAppPanel";
import Comments from "../../Book-components/Comments";
import WriteReview from "../../Book-components/WriteReview";
import Tags from "../../Book-components/Tags";
// import PurchaseChannel from "../../Book-components/PurchaseChannel";
import BookDetails from "../../Book-components/BookDetails";
import CategoryBtn from "../../Book-components/CategoryBtn";
import ReviewWishlistShareGift from "../../Book-components/Review-Wishlist-Share-Gift";
import TrynPrice from "../../Book-components/TrynPrice";
import AuthorPublisher from "../../Book-components/Author-Publisher";
import { fetchCategoryInfoById } from "../../../Actions/Ebook";
import {
  fetchAddCart,
  fetchRemoveCart,
  removeCartItemFromLocalStorage,
} from "../../../Actions/Payment";
import {
  showFlashSale,
  isAddCart,
  navModal,
  navModalMode,
  ACTION_SHOW_LOADING,
} from "../../../Actions/Page";
import {
  fetchEbookDetail,
  fetchRelatedEbook,
  updateEbookViewStatistic,
} from "../../../Actions/Ebook";
import CoverFiletype from "../../Book-components/Cover-Filetype";
import Account from "../../../Utilitys/Authentication";
import { Helmet } from "react-helmet";
import Cookies from "universal-cookie";
import moment from "moment";
import * as Scroll from "react-scroll";

import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);

var Element = Scroll.Element;
const { info } = Modal;
const cookies = new Cookies();

const Book = (props) => {
  const memberUID = Account.getMemberUID(props.authentication.userToken || "");
  const [ebook, setEbook] = useState({});
  const [relatedEbook, setRelatedEbook] = useState([]);
  const [updateComments, setUpdateComments] = useState(false);
  const [catImg, setCatImg] = useState("");
  const [subcatImg, setSubcatImg] = useState("");
  const myRef = useRef(null);
  const myRef2 = useRef(null);
  const bookUID = props.match.params.bookUID;

  const addcart = () => {
    props.dispatch(isAddCart(true));
    props.dispatch(fetchAddCart(ebook));
  };

  const removecart = () => {
    props.dispatch(isAddCart(false));
    removeCartItemFromLocalStorage(bookUID);
    props.dispatch(fetchRemoveCart(ebook));
  };

  const revisedDescription = (d) => {
    if (d) {
      return d.split(/(\r\n|\n|\r)/gm).map((i) => {
        return (
          <p
            key={Math.random().toString(5).substring(4)}
            style={{ margin: "0" }}
          >
            {i}
          </p>
        );
      });
    } else {
      return "";
    }
  };

  const showLoginModal = () => {
    if (props.Page.screensize.width > 900) {
      props.dispatch(navModal(true));
      props.dispatch(
        navModalMode({ loginModal: 1, registerModal: 0, registerEmailModal: 0 })
      );
    } else {
      props.history.push("/login");
    }
  };

  const fetchEbook = async () => {
    setEbook({});
    let ebookDetail = await fetchEbookDetail(bookUID);
    if (ebookDetail.status && ebookDetail.data) {
      let ebook = ebookDetail.data;
      setEbook(ebook);
      updateEbookViewStatistic(bookUID);
      // fetchBtnImage(ebook.catId, ebook.subcatId);
      if (ebook && ebook.promotions) {
        props.dispatch(
          flashSaleExp(
            ebook.promotions[ebook.promotions.length - 1]?.endSaleDate
          )
        );
      }
      if (ebook)
        props.dispatch(
          showFlashSale({
            isShow: ebook.isPromotions,
            discount: Math.round(ebook.discount),
            offsetTop: myRef.current ? myRef.current.offsetTop : 400,
            ebook: ebook,
          })
        );
      let filter = props.Payment.shoppingCart.filter((el) => {
        if (ebook) {
          return el.bookUID === bookUID;
        }
      });
      if (filter.length) {
        props.dispatch(isAddCart(true));
      }
      props.dispatch(ACTION_SHOW_LOADING(false));
    } else {
      // confirm({
      //   title: <p style={{ fontFamily: "Prompt" }}>ขออภัย{<br />}ไม่พบข้อมูลอีบุ๊ค</p>,
      //   okType: "danger",
      //   okText: <p style={{ fontFamily: "Prompt", marginTop: "0.33em" }}>{`ไปหน้าหลัก`}</p>,
      //   cancelText: <p style={{ fontFamily: "Prompt", marginTop: "0.33em" }}>{`ยกเลิก`}</p>,
      //   onOk() {
      //     props.history.push("/");
      //     props.dispatch(ACTION_SHOW_LOADING(false));
      //   },
      //   onCancel() {
      //     props.dispatch(ACTION_SHOW_LOADING(false));
      //   },
      // });
      let secondsToGo = 10;
      const modal = info({
        title: <p style={{ fontFamily: "Prompt" }}>ขออภัย ไม่พบข้อมูลอีบุ๊ค</p>,
        onOk() {
          props.history.push("/");
          props.dispatch(ACTION_SHOW_LOADING(false));
        },
        okText: (
          <p
            style={{ fontFamily: "Prompt", marginTop: "0.33em" }}
          >{`ไปหน้าหลัก`}</p>
        ),
        content: (
          <p
            style={{ fontFamily: "Prompt" }}
          >{`กลับไปหน้าหลักภายใน ${secondsToGo} วินาที`}</p>
        ),
      });
      const timer = setInterval(() => {
        secondsToGo -= 1;
        modal.update({
          content: (
            <p
              style={{ fontFamily: "Prompt" }}
            >{`กลับไปหน้าหลักภายใน ${secondsToGo} วินาที`}</p>
          ),
        });
      }, 1000);
      setTimeout(() => {
        clearInterval(timer);
        props.history.push("/");
        props.dispatch(ACTION_SHOW_LOADING(false));
        modal.destroy();
      }, secondsToGo * 1000);
    }
  };

  const fetchRelated = async () => {
    let related = await fetchRelatedEbook(
      bookUID,
      "priority",
      "true",
      false,
      9
    );
    if (related.status) {
      if (related.data) {
        setRelatedEbook(related.data.ebooks);
      }
    }
  };

  const fetchBtnImage = async (catId, subcatId) => {
    let catInfo = await fetchCategoryInfoById(catId, 1);
    if (catInfo.status) {
      let catImagePath = catInfo.data.imagePath;
      let subCatObj = catInfo.data.subcats.filter(
        (e) => e.subcatId === subcatId
      )[0];
      setCatImg(catImagePath);
      if (subCatObj && subCatObj.imagePath) {
        setSubcatImg(subCatObj.imagePath);
      } else {
        setSubcatImg(catImagePath);
      }
    }
  };

  const addReview = async (obj) => {
    let response = await postComment(
      bookUID,
      obj.rating,
      obj.review ? obj.review : ""
    );
    if (response.status) {
      setUpdateComments(true);
    }
  };

  useEffect(() => {
    props.dispatch(ACTION_SHOW_LOADING(true));
    fetchEbook();
    fetchRelated();
  }, [props.location.pathname]);

  useEffect(() => {
    return () => {
      props.dispatch(
        showFlashSale({
          isShow: false,
        })
      );
    };
  }, []);

  return (
    <div className="book-container">
      <Helmet>
        <title>{`Hytexts: ${ebook && ebook.title ? ebook.title : ""}`}</title>
      </Helmet>
      <p className="booktitle">{ebook ? ebook.title : ""}</p>
      {ebook && (
        <Row gutter={[32, 32]} type="flex" justify="center">
          {/* {ebook.authorName && props.Page.screensize.width < 768 && (
            <Col span={24} align="center" style={{ padding: "0 16px" }}>
              <AuthorPublisher
                type="author"
                imgSrc={
                  ebook.authorProfileImage ? ebook.authorProfileImage : "/images/book/worm.png"
                }
                name={ebook.authorName}
                memberUID={memberUID}
                creatorUID={ebook.creatorUID}
                showLoginModal={showLoginModal}
                isSmallMobile={props.Page.screensize.width <= 545}
                isMobile={props.Page.screensize.width < 768 && props.Page.screensize.width > 545}
              />
            </Col>
          )} */}
          <Col xs={13} md={12}>
            <CoverFiletype
              ebook={(({
                isPromotions,
                discount,
                isNew,
                isTV,
                isBestSeller,
                coverImage,
                fileType,
              }) => ({
                isPromotions,
                discount,
                isNew,
                isTV,
                isBestSeller,
                coverImage,
                fileType,
              }))(ebook)}
            />
          </Col>
          <Col
            xs={24}
            md={12}
            justify="center"
            style={
              props.Page.screensize.width >= 768
                ? { padding: "16px" }
                : { padding: "0 16px" }
            }
          >
            <div
              style={
                props.Page.screensize.width >= 768
                  ? { textAlign: "left" }
                  : { textAlign: "center" }
              }
            >
              {/* {ebook.authorName && props.Page.screensize.width >= 768 && ( */}
              <AuthorPublisher
                type="author"
                imgSrc={
                  ebook.authorProfileImage
                    ? ebook.authorProfileImage
                    : "/images/book/worm.png"
                }
                name={ebook.authorName}
                memberUID={memberUID}
                creatorUID={ebook.creatorUID}
                showLoginModal={showLoginModal}
                screenWidth={props.Page.screensize.width}
              />
              {/* )}*/}
              {ebook.publisherName && (
                <AuthorPublisher
                  type="publisher"
                  imgSrc={
                    ebook.publisherProfileImage
                      ? ebook.publisherProfileImage
                      : "/images/book/worm.png"
                  }
                  name={ebook.publisherName}
                  creatorUID={ebook.creatorUID}
                  memberUID={memberUID}
                  showLoginModal={showLoginModal}
                  screenWidth={props.Page.screensize.width}
                  isMobile={
                    props.Page.screensize.width < 768 &&
                    props.Page.screensize.width > 545
                  }
                />
              )}
              <CategoryBtn
                catImg={catImg}
                catName={ebook.catName}
                subcatImg={subcatImg}
                subcatName={ebook.subcatName}
                catId={ebook.catId}
                subcatId={ebook.subcatId}
                memberUID={memberUID}
                showLoginModal={showLoginModal}
                is
                Mobile={props.Page.screensize.width < 768}
              />

              {ebook.subcatId === 394 || ebook.isAdult ? (
                <p style={{ color: "red" }}>
                  นิยายเรื่องนี้มีเนื้อหาที่เหมาะสำหรับผู้มีอายุ 18 ปีขึ้นไป
                </p>
              ) : (
                ""
              )}
            </div>
            <TrynPrice
              isPurchase={ebook.isPurchase}
              isPromotions={ebook.isPromotions}
              isAddCart={props.isAddCart}
              priceTHB={ebook.priceTHB}
              discount={ebook.discount}
              addcartFunc={addcart}
              removecartFunc={removecart}
              samplePath={ebook.samplePath}
              isMobile={props.Page.screensize.width <= 768}
              bookUID={bookUID}
            />
            {ebook.comments && ebook.comments.star && (
              <Row
                type="flex"
                justify="center"
                style={{ marginBottom: "20px" }}
              >
                <Rate
                  className="star-14px"
                  disabled
                  allowHalf
                  defaultValue={ebook.comments.star.total}
                />
                <span style={{ marginLeft: "8px" }}>
                  ({ebook.comments.star.count})
                </span>
              </Row>
            )}
            <ReviewWishlistShareGift
              myRef={myRef2}
              isPurchase={ebook.isPurchase}
              memberUID={memberUID}
              bookUID={bookUID}
              bookTitle={ebook.title}
              showLoginModal={showLoginModal}
            />
            <div ref={myRef} />
            {props.Page.screensize.width < 768 ? (
              <Divider style={{ color: "#e6e0dd", margin: "0 0 16px 0" }} />
            ) : (
              ""
            )}
            {props.Page.screensize.width >= 768 ? (
              <BookDetails
                priceCover={ebook.priceCover}
                oldPriceTHB={ebook.oldPriceTHB}
                priceTHB={ebook.priceTHB}
                discount={ebook.discount}
                fileType={ebook.fileType}
                fileSize={ebook.fileSize}
                pageCount={ebook.pageCount}
                saleDate={ebook.saleDate}
              />
            ) : (
              ""
            )}
          </Col>
        </Row>
      )}

      {/* {ebook && ebook.description && ( */}
      <div
        style={{
          marginBottom: props.Page.screensize.width >= 768 ? "32px" : "16px",
          marginTop: props.Page.screensize.width >= 576 ? "26px" : "16px",
        }}
      >
        {ebook && ebook.description && (
          <>
            <p className="synopsis">เรื่องย่อ</p>
            <div className="synopsis-passage">
              {revisedDescription(ebook.description)}
            </div>
          </>
        )}
        <div className="noteTitle">หมายเหตุ</div>

        <div className="noteDetail">
          *ไม่สามารถอ่านบนเว็บไซต์หรือดาวน์โหลดไฟล์มาอ่านด้วยโปรแกรมอื่น ๆ ได้
          <br />
          <span className="dangerLabel">
            *ไม่มีการจัดส่งหนังสือเป็นรูปเล่มให้ทางไปรษณีย์
          </span>
          <br />
          *E-BOOK ที่ซื้อบนเว็บ Hytexts หรือบน App Hytexts{" "}
          <span className="dangerLabel">ไม่สามารถ Print ได้</span>
          <br />
          *หากต้องการซื้อเป็น <span className="dangerLabel">“รูปเล่ม”</span>
          กรุณาติดต่อ{" "}
          <span className="dangerLabel">สำนักพิมพ์หรือนักเขียน</span>โดยตรง
          <br />
          *E-BOOK ที่ซื้อบนเว็บ Hytexts หรือบน App Hytexts{" "}
          <span className="dangerLabel">ไม่สามารถอ่านบน Kindle ได้</span>
        </div>
        <Tags tags={ebook.tags} />
        <Divider
          style={
            props.Page.screensize.width >= 768
              ? { color: "#e6e0dd" }
              : { color: "#e6e0dd", margin: "8px 0 0 0" }
          }
        />
      </div>
      {/* )} */}
      {props.Page.screensize.width < 768 ? (
        <>
          <BookDetails
            priceCover={ebook.priceCover}
            oldPriceTHB={ebook.oldPriceTHB}
            priceTHB={ebook.priceTHB}
            discount={ebook.discount}
            fileType={ebook.fileType}
            fileSize={ebook.fileSize}
            pageCount={ebook.pageCount}
            saleDate={ebook.saleDate}
          />
          <Divider style={{ color: "#e6e0dd", margin: "0 0 16px 0" }} />
        </>
      ) : (
        ""
      )}
      <Element name="reviewElement"></Element>
      {ebook && (
        <>
          <Row id="review">
            <HomeList
              title="อีบุ๊คที่เกี่ยวข้อง"
              bookUID={bookUID}
              relatedTitle={ebook.title}
              relatedEbook={relatedEbook}
              numCollection={
                props.Page.screensize.width >= 768
                  ? 6
                  : props.Page.screensize.width > 390
                  ? 4
                  : 3
              }
            />
          </Row>
          {relatedEbook.length > 0 ? (
            <Divider style={{ color: "#e6e0dd", margin: "0 0 24px 0" }} />
          ) : (
            ""
          )}
        </>
      )}
      {ebook && (
        <WriteReview
          memberUID={memberUID}
          showLoginModal={showLoginModal}
          addReview={addReview}
        />
      )}
      {ebook && (
        <Comments
          initComments={ebook.comments}
          bookUID={bookUID}
          memberUID={memberUID}
          updateComments={updateComments}
          setUpdateComments={setUpdateComments}
          isMobile={props.Page.screensize.width <= 676}
          iscenterMobile={props.Page.screensize.width <= 430}
        />
      )}
    </div>
  );
};

const mapStateToProps = (state) => {
  return {
    ebook: state.Ebook,
    authentication: state.Authentication,
    Payment: state.Payment,
    isAddCart: state.Page.isAddCart,
    memberInfo: state.Member.memberInfo,
    Page: state.Page,
  };
};

export default withRouter(connect(mapStateToProps)(Book));
