import React from "react";
import "./index.css";
import App from "./Components/App";
import * as serviceWorker from "./serviceWorker";
import { Provider } from "react-redux";
import { store } from "./store";
import { BrowserRouter } from "react-router-dom";
import { render } from "react-dom";
import ReactGA from "react-ga";

ReactGA.initialize("UA-162700535-1");

const rootElement = document.getElementById("root");
if (rootElement.hasChildNodes()) {
  render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    rootElement
  );
} else {
  render(
    <Provider store={store}>
      <BrowserRouter>
        <App />
      </BrowserRouter>
    </Provider>,
    rootElement
  );
}
serviceWorker.unregister();

if (process.env.REACT_APP_ENV == "production") {
  console.log = () => {};
  console.info = () => {};
  console.debug = () => {};
  console.warn = () => {};
  console.error = () => {};
}
