import { RECEIVE_MEMBER_INFO } from "../Constants/actionTypes";
import { fetch } from "../Utilitys/ApiRequest";
import { ip } from "../Constants/appInfo";

export const receiveMemberInfo = data => ({
  type: RECEIVE_MEMBER_INFO,
  memberInfo: data
});

export const requestMemberInfo = async () => {
  return await fetch("get", `members/account`);
};

export const updateProfileImage = async (filedata, fileType) => {
  var params = {
    filedata: filedata,
    fileType: fileType
  };
  return await fetch("post", `members/profile/image`, params);
};

export const addFavorite = async (type, uuid_authorname) => {
  var params = {
    type: type,
    contentID: uuid_authorname
  };
  return await fetch("post", `members/favorites`, params);
};

export const deleteFavorite = async (type, uuid_authorname) => {
  var params = {
    type: type,
    contentID: uuid_authorname
  };
  return await fetch("delete", `members/favorites`, params);
};

export const getFavorite = async type => {
  return await fetch("get", `members/favorites?type=${type}`);
};

export const fetchCoinData = async () => {
  return await fetch("get", "members/getCoinData");
};

export const fetchMemberLibrary = async lastEvaluatedKey => {
  var lastKey = "";
  if (lastEvaluatedKey) {
    lastKey = "?lastEvaluatedKey=" + encodeURIComponent(JSON.stringify(lastEvaluatedKey));
  }
  return await fetch("get", `libraries${lastKey}`);
};

export const fetchMemberLibrary_Remove = async lastEvaluatedKey => {
  var lastKey = "";
  if (lastEvaluatedKey) {
    lastKey = "?lastEvaluatedKey=" + encodeURIComponent(JSON.stringify(lastEvaluatedKey));
  }
  return await fetch("get", `libraries/remove${lastKey}`);
};

export const moveEbookToLibrary = async bookUID => {
  return await fetch("post", `libraries`, { ebooks: bookUID });
};

export const moveEbookToLibrary_Remove = async bookUID => {
  return await fetch("post", `libraries/remove`, { ebooks: bookUID });
};

export const readComment = async (bookUID, lastEvaluatedKey) => {
  var lastKey = "";
  if (lastEvaluatedKey) {
    lastKey = "?lastEvaluatedKey=" + encodeURIComponent(JSON.stringify(lastEvaluatedKey));
  }
  return await fetch("get", `ebooks/comment/${bookUID}${lastKey}`);
};

export const fetchComments = async bookUID => {
  return await fetch("get", `ebooks/comment/${bookUID}`);
};

export const postComment = async (bookUID, rating, description = "") => {
  return await fetch("post", `ebooks/comment/${bookUID}`, {
    rating: rating,
    description: description
  });
};

export const deleteComment = async (bookUID, date) => {
  return await fetch("delete", `ebooks/comment/${bookUID}`, {
    date: date
  });
};

export const registerAuthor = async payload => {
  return await fetch("post", `register/author`, payload);
};

export const registerPublisher = async payload => {
  return await fetch("post", `register/publisher`, payload);
};

export const registerPayment = async payload => {
  return await fetch("post", `register/payment`, payload);
};
