import React, { useState, useEffect, createRef } from "react";
import { Col, Row, Menu } from "antd";
import { SideForEreader, belowForEreader, ereaderLabel } from "./forEreader";
import {
  SideForReader,
  belowForReader,
  readerLabel,
  readerSubLabel
} from "./forReader";
import { SideForWriter, belowForWriter, writerLabel } from "./forWriter";
import { ClipLoader } from "react-spinners";
import { css } from "@emotion/core";
import "./FAQ.scss";
import { withRouter } from "react-router-dom";
import { connect } from "react-redux";
import ReactGA from "react-ga";

ReactGA.pageview(window.location.pathname + window.location.search);
const { SubMenu } = Menu;
const FAQ = props => {
  const [arrowDirection, setArrowDirection] = useState(["down", "up", "up"]);
  const [readerActiveIndex, setReaderActiveIndex] = useState(-1);
  const [writerActiveIndex, setWriterActiveIndex] = useState(-1);
  const [ereaderActiveIndex, setEreaderActiveIndex] = useState(-1);

  const [readerSubActiveIndex, setReaderSubActiveIndex] = useState(-1);
  const [afterFirstLoad, setAfterFirstLoad] = useState(false);
  const imageDiv = createRef();
  const [loading, setLoading] = useState(true);

  const override = css`
    display: block;
    margin: 15% auto;
  `;

  const imagesLoaded = parentNode => {
    const imgElements = [...parentNode.querySelectorAll("img")];
    for (let i = 0; i < imgElements.length; i += 1) {
      const img = imgElements[i];
      if (!img.complete) {
        return false;
      }
    }
    return true;
  };

  const handleImageChange = () => {
    if (imageDiv.current) {
      setLoading(!imagesLoaded(imageDiv.current));
    }
  };

  const renderSpinner = () => {
    if (!loading) {
      return null;
    }
    return (
      <div className="sweet-loading" style={{ position: "absolute" }}>
        <ClipLoader css={override} size={50} color={"#123abc"} loading={true} />
      </div>
    );
  };

  // useEffect(() => {
  //   if (props.history.location.search) {
  //     if (props.history.location.search.split("=")[1] === "reader") {
  //       setArrowDirection(["down", "up", "up"]);
  //       setReaderActiveIndex(0);
  //       setWriterActiveIndex(-1);
  //       setEreaderActiveIndex(-1);
  //     } else if (props.history.location.search.split("=")[1] === "writer") {
  //       setArrowDirection(["up", "down", "up"]);
  //       setReaderActiveIndex(-1);
  //       setWriterActiveIndex(0);
  //       setEreaderActiveIndex(-1);
  //     }
  //   }
  // }, [props.history.location.search]);

  useEffect(() => {
    if (readerActiveIndex >= 0) {
      props.history.push(`/faq/reader/${readerLabel[readerActiveIndex]}`);
      setReaderActiveIndex(readerActiveIndex);
      if (readerSubActiveIndex >= 0 && readerActiveIndex < 7) {
        props.history.push(
          `/faq/reader/${readerLabel[readerActiveIndex]}/${readerSubLabel[readerSubActiveIndex]}`
        );
        setAfterFirstLoad(true);
      }
    }
    if (writerActiveIndex >= 0) {
      props.history.push(`/faq/writer/${writerLabel[writerActiveIndex]}`);
      setWriterActiveIndex(writerActiveIndex);
    }
    if (ereaderActiveIndex >= 0) {
      props.history.push(`/faq/ereader/${ereaderLabel[ereaderActiveIndex]}`);
      setEreaderActiveIndex(ereaderActiveIndex);
    }
  }, [
    readerActiveIndex,
    writerActiveIndex,
    ereaderActiveIndex,
    readerSubActiveIndex
  ]);

  useEffect(() => {
    if (afterFirstLoad) {
      setReaderSubActiveIndex(0);
    }
  }, [readerActiveIndex]);

  useEffect(() => {
    if (props.match.params.section) {
      const s = props.match.params.section;
      if (s === "reader") {
        setArrowDirection(["down", "up", "up"]);
        setWriterActiveIndex(-1);
        setEreaderActiveIndex(-1);
        if (props.match.params.index) {
          setReaderActiveIndex(readerLabel.indexOf(props.match.params.index));
          if (props.match.params.subindex) {
            setReaderSubActiveIndex(
              readerSubLabel.indexOf(props.match.params.subindex)
            );
          } else {
            setReaderSubActiveIndex(0);
          }
        } else {
          setReaderActiveIndex(0);
          setReaderSubActiveIndex(0);
        }
      } else if (s === "writer") {
        setArrowDirection(["up", "down", "up"]);
        setReaderActiveIndex(-1);
        setEreaderActiveIndex(-1);
        if (props.match.params.index) {
          setWriterActiveIndex(writerLabel.indexOf(props.match.params.index));
        } else {
          setWriterActiveIndex(0);
        }
      } else if (s === "ereader") {
        setArrowDirection(["up", "up", "down"]);
        setReaderActiveIndex(-1);
        setWriterActiveIndex(-1);
        if (props.match.params.index) {
          setEreaderActiveIndex(ereaderLabel.indexOf(props.match.params.index));
        } else {
          setEreaderActiveIndex(0);
        }
      }
    }
  }, [props.match.params.section]);

  const downArrow = (
    <img
      src="/images/logos/downArrow.png"
      style={{ width: "12px", height: "7px" }}
    />
  );

  const upArrow = (
    <img
      src="/images/logos/upArrow.png"
      style={{ width: "12px", height: "7px" }}
    />
  );

  const subjectRow = (direction, node) => {
    var temp_arrowDirection = [...arrowDirection];
    if (direction === "down") {
      temp_arrowDirection[node] = "up";
      return (
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="subjectRow-blue"
          onClick={() => {
            setArrowDirection(temp_arrowDirection);
          }}
        >
          <Col span={1}>
            <div className="prefix" />
          </Col>
          <Col span={21}>
            <p className="subject">{subjectTitle[node]}</p>
          </Col>
          <Col span={2}>{upArrow}</Col>
        </Row>
      );
    } else if (direction === "up") {
      temp_arrowDirection[node] = "down";
      switch (node) {
        case 0:
          temp_arrowDirection = ["down", "up", "up"];
          break;
        case 1:
          temp_arrowDirection = ["up", "down", "up"];
          break;
        case 2:
          temp_arrowDirection = ["up", "up", "down"];
          break;
        default:
          break;
      }
      return (
        <Row
          type="flex"
          justify="space-between"
          align="middle"
          className="subjectRow"
          onClick={() => {
            setArrowDirection(temp_arrowDirection);
            switch (node) {
              case 0:
                setReaderActiveIndex(0);
                setWriterActiveIndex(-1);
                setEreaderActiveIndex(-1);
                break;
              case 1:
                setReaderActiveIndex(-1);
                setWriterActiveIndex(0);
                setEreaderActiveIndex(-1);
                break;
              case 2:
                setReaderActiveIndex(-1);
                setWriterActiveIndex(-1);
                setEreaderActiveIndex(0);
                break;
              default:
                break;
            }
          }}
        >
          <Col span={1}>
            <div className="prefix" />
          </Col>
          <Col span={21}>
            <p className="subject">{subjectTitle[node]}</p>
          </Col>
          <Col span={2}>{downArrow}</Col>
        </Row>
      );
    }
  };
  const subjectTitle = [
    "สำหรับนักอ่าน",
    "สำหรับนักเขียน/สำนักพิมพ์",
    "E-READER"
  ];

  const setActiveFunc = (type, index) => {
    if (type === "reader") {
      setReaderActiveIndex(index);
    }
    if (type === "writer") {
      setWriterActiveIndex(index);
    }
    if (type === "ereader") {
      setEreaderActiveIndex(index);
    }
  };

  const setSubActiveFunc = (type, index, iindex) => {
    if (type === "reader") {
      setReaderActiveIndex(index);
      setReaderSubActiveIndex(iindex);
    }
    if (type === "writer") {
      setWriterActiveIndex(index);
    }
    if (type === "ereader") {
      setEreaderActiveIndex(index);
    }
  };

  return (
    <div>
      <Row className="faq">
        <Col span={8} className="leftfaq">
          <p className="title">FAQ</p>
          {subjectRow(arrowDirection[0], 0)}
          {arrowDirection[0] === "down" && (
            <Row className="belowSubjectRow">
              <Menu
                onClick={e => {
                  if (e.keyPath.length === 2) {
                    setActiveFunc(
                      "reader",
                      parseInt(e.keyPath[1].slice(e.keyPath[1].length - 1), 10)
                    );
                    setSubActiveFunc(
                      "reader",
                      parseInt(e.keyPath[1].slice(e.keyPath[1].length - 1), 10),
                      parseInt(e.keyPath[0].slice(e.keyPath[0].length - 1), 10)
                    );
                  } else if (e.keyPath.length === 1) {
                    setActiveFunc(
                      "reader",
                      parseInt(e.keyPath[0].slice(e.keyPath[0].length - 1), 10)
                    );
                  }
                }}
                style={{ fontFamily: "Prompt", borderRight: "none" }}
                selectedKeys={
                  readerActiveIndex === 7
                    ? ["sub" + readerActiveIndex]
                    : ["sub" + readerActiveIndex + "-" + readerSubActiveIndex]
                }
                openKeys={["sub" + readerActiveIndex]}
                mode="inline"
              >
                {belowForReader.map((e, index) => {
                  if (e.subHead) {
                    return (
                      <SubMenu
                        key={"sub" + index}
                        onTitleClick={e => {
                          const key = parseInt(
                            e.key.slice(e.key.length - 1),
                            10
                          );
                          if (readerActiveIndex === key) {
                            setActiveFunc("reader", -1);
                          } else {
                            setActiveFunc("reader", key);
                          }
                        }}
                        style={{ fontSize: "16px" }}
                        title={e.head}
                      >
                        {e.subHead &&
                          e.subHead.map((ee, iindex) => (
                            <Menu.Item key={"sub" + index + "-" + iindex}>
                              {ee}
                            </Menu.Item>
                          ))}
                      </SubMenu>
                    );
                  }
                })}
                <Menu.Item key={"sub7"}>อุปกรณ์ที่รองรับ</Menu.Item>
              </Menu>
            </Row>
          )}
          {subjectRow(arrowDirection[1], 1)}
          {arrowDirection[1] === "down" && (
            <Row className="belowSubjectRow">
              <Menu
                onClick={e => {
                  setActiveFunc(
                    "writer",
                    parseInt(e.keyPath[0].slice(e.keyPath[0].length - 1), 10)
                  );
                }}
                style={{ fontFamily: "Prompt", borderRight: "none" }}
                selectedKeys={["sub" + writerActiveIndex]}
                mode="inline"
              >
                {belowForWriter.map((e, index) => {
                  return <Menu.Item key={"sub" + index}>{e}</Menu.Item>;
                })}
              </Menu>
            </Row>
          )}
          {subjectRow(arrowDirection[2], 2)}
          {arrowDirection[2] === "down" && (
            <Row className="belowSubjectRow">
              <Menu
                onClick={e => {
                  console.log(e.keyPath);
                  setActiveFunc(
                    "ereader",
                    parseInt(e.keyPath[0].split("sub")[1], 10)
                  );
                }}
                style={{ fontFamily: "Prompt", borderRight: "none" }}
                selectedKeys={["sub" + ereaderActiveIndex]}
                mode="inline"
              >
                {belowForEreader.map((e, index) => {
                  return <Menu.Item key={"sub" + index}>{e.title}</Menu.Item>;
                })}
              </Menu>
            </Row>
          )}
        </Col>
        <Col
          span={props.Page.screensize.width > 900 ? 16 : 24}
          // style={{ left: "34%" }}
        >
          {renderSpinner()}
          {arrowDirection[0] === "down" && readerActiveIndex > -1 && (
            <div className="sideSubject" ref={imageDiv}>
              <SideForReader
                handleImageChange={handleImageChange}
                index={readerActiveIndex}
                subIndex={readerSubActiveIndex}
              />
            </div>
          )}
          {arrowDirection[1] === "down" && writerActiveIndex > -1 && (
            <div className="sideSubject" ref={imageDiv}>
              <SideForWriter
                handleImageChange={handleImageChange}
                index={writerActiveIndex}
              />
            </div>
          )}
          {arrowDirection[2] === "down" && ereaderActiveIndex > -1 && (
            <div className="sideSubject" ref={imageDiv}>
              <SideForEreader
                handleImageChange={handleImageChange}
                index={ereaderActiveIndex}
              />
            </div>
          )}
        </Col>
      </Row>
    </div>
  );
};
const mapStateToProps = state => {
  return {
    Page: state.Page
  };
};
export default withRouter(connect(mapStateToProps)(FAQ));
