import { RECEIVE_MEMBER_INFO } from "../Constants/actionTypes";

const INITIAL_STATE = {
  memberInfo: {
    coinData: { remainCoin: 0, totalCoin: 0, totalUse: 0 },
    displayName: "",
    profileImage: { image: "" },
    following: 0
  }
};
const Member = (state = INITIAL_STATE, action) => {
  switch (action.type) {
    case RECEIVE_MEMBER_INFO:
      return Object.assign({}, state, {
        memberInfo: action.memberInfo
      });
    default:
      return state;
  }
};
export default Member;
